import React from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'carbon-components-react';

import cx from './BookCell.module.scss';
import cn from 'classnames';
import history from 'src/history';
import { RES_PATH } from 'src/config/environment';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

const BookCell = ({ book, ...props }) => {
  const bookURL = () => {
    return `/books/${book.book.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };

  return (
    <div className={cn(cx.bookCell)}
         onMouseDown={onMouseDown}
         onMouseUp={onMouseUp(goBook)}
    >
      <div className={cn(cx.imageContainer)} style={{ backgroundImage: `url(${RES_PATH}${book.book.image})` }}/>
      <div className={cn(cx.genres)}>{book.book.tags.map(tag => tag.toUpperCase()).join(' | ')}</div>
      <h4 className={cn(cx.name)}>{book.book.name}</h4>
      <div className={cn(cx.writer)}>by {book.author.name}</div>
    </div>
  )
};

BookCell.propTypes = {
};

export default BookCell;
