import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'carbon-components-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { toggleDeleteBGSoundDialog, deleteBGSound } from 'src/modules/actions/bgsounds.actions';

const DeleteBGSoundDialog = ({ modalOpen, deletePending, ...actions }) => {

  return (
    modalOpen ?
    <Modal
      aria-label=''
      open
      onRequestClose={() => actions.toggleDeleteBGSoundDialog()}
      onSecondarySubmit={() => actions.toggleDeleteBGSoundDialog()}
      onRequestSubmit={actions.deleteBGSound}
      primaryButtonText='Ok'
      secondaryButtonText='Cancel'
      modalHeading={'Warning'}
      primaryButtonDisabled={deletePending}
    >
      <p className={'bx--modal-content__text'} style={{ marginBottom: '2rem' }}>
        Delete this sound?
      </p>
    </Modal> : null
  )
}

DeleteBGSoundDialog.propTypes = {
  toggleDeleteBGSoundDialog: PropTypes.func,
  deleteBGSound: PropTypes.func,
  deletePending: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    modalOpen: !!state.bgsounds.deleteDialog,
    pendingRequest: state.bgsounds.deletingSound,
    deletePending: state.bgsounds.deletePending
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleDeleteBGSoundDialog,
    deleteBGSound
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBGSoundDialog);