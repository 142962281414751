import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { searchTag, saveTag } from 'src/components/author/AddBookPage/tag-request';
import { tagLabel } from 'src/components/author/AddBookPage/util';

const fromFormValues = arr => {
  if (arr?.length) {
    return arr.map(t => ({ label: tagLabel(t), value: t }));
  }
  return [];
};
const toFormValues = opts => {
  if (opts?.length) {
    return opts.map(o => o.value);
  }
  return [];
};

const customStyles = {
  control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: '#262626',
      border: 0,
      borderBottom: '1px solid #6f6f6f',
      color: '#f4f4f4',
      outline: 0,
      boxSizing: 'border-box',
      boxShadow: state.isFocused ? '0 0 0 1px #fff' : 0,
      '&:hover': {
        borderBottom: 'auto',
      }
  }),
  input: (provided) => ({ ...provided, color: '#f4f4f4' }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: '#262626'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#161616' : '#f4f4f4',
    backgroundColor: state.isFocused ? '#e5e5e5' : '#262626',
    padding: 16,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    '& svg': {
      fill: '#111111'
    }
  })
};

const TagSearch = ({initialValue, onChange}) => {
  const [initState, setInitState] = useState(initialValue);
  const [value, setValue] = useState(fromFormValues(initialValue));
  const [handler, setHandler] = useState(null);
  const [lastTags, setLastTags] = useState([]);

  useEffect(() => {
    if (initialValue !== initState) {
      setValue(fromFormValues(initialValue));
      setInitState(initialValue);
    }
  }, [initialValue, initState]);

  const promiseOptions = async inputValue => {
    if (handler) {
      clearTimeout(handler);
    }
    const promise = new Promise(resolve => {
      setHandler(
        setTimeout(async () => {
          const tags = await searchTag(inputValue);
          setLastTags(tags);
          resolve(tags);
        }, 300)
      );
    });
    return await promise;
  };

  const _onChange = async _arr => {
    let arr = [...(_arr || [])];
    arr = arr.map(t => ({ label: t.label.toLowerCase(), value: t.value.toLowerCase() }));
    if (arr?.length > value?.length) {
      const last = arr?.length > 0 ? arr[arr.length - 1] : null;
      if (last) {
        const existing = lastTags.find(t => t.value === last.value);
        if (!existing) {
          try {
            const savedTag = await saveTag(last.value);
            if (!savedTag || !savedTag.id) {
              return;
            }
            arr[arr.length - 1] = { value: savedTag.id, label: tagLabel(last.label)}; // adds # to just created tag
          } catch (e) {
            return;
          }
        }
      }
    }
    onChange(toFormValues(arr));
    setValue(arr);
  };

  return (
    <AsyncCreatableSelect
      styles={customStyles}
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      isMulti
      onChange={_onChange}
      value={value}
    />
  )
};

TagSearch.defaultProps = {
  initialValue: [],
  onChange: () => {}
};

TagSearch.propTypes = {
  initialValue: PropTypes.array,
  onChange: PropTypes.func
};

export {
  TagSearch
}
