
  import axios from 'axios';
  import { getAuthHeader } from 'src/modules/api/utils';
  import { throttle } from 'src/utils';
  import * as Book from 'src/modules/model/book';

  export  const getPayPalOrder = async (book, chapter, subscription) => {
    let response;
    if (Book.isChapterPriceTier(book) && chapter) {
      response = await axios.post(`/chapters/${chapter.uuid}/purchase`, {}, getAuthHeader());
    } else if (subscription) {
      response = await axios.post(`/books/${book.uuid}/subscribe`, {subscription, type:'paypal'}, getAuthHeader());
    } else if (Book.isBookPriceTier(book)) {
      response = await axios.post(`/books/${book.uuid}/subscribe`, {type:'paypal'}, getAuthHeader());
    } else {
      console.log('Cannot get stripe\'s sessionId: malformed parameters #redirectToCheckout.');
      return;
    }

    return response.data;
  }

  export const completePayPalOrder = async (orderId) => {
    return await axios.post(`/sessions/paypal`, {orderId}, getAuthHeader());
  }