import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import cs from './ShareBlockReading.module.scss';
import { isChapterPriceTier, isBookPriceTier } from 'src/modules/model/book';

const ShareBlockReading = ({ className, book, onShared, endSheet }) => {

  const sharedLink = () => `https://fictionate.me/books/${book.uuid}${book.share ? (`?code=${book.share.code}`) : ''}`

  React.useEffect(() => {
    const notifyTwitterShare = (e) => {
      onShared();
    }

    window.twttr && window.twttr.ready(twttr => {
      twttr.events.bind(
        'tweet',
        notifyTwitterShare
      );
    })

    return () => {
      window.twttr && window.twttr.events.unbind('tweet', notifyTwitterShare);
    }
  }, [onShared]);

  const shareFacebook = () => {
    window.FB && window.FB.ui(
      {
        method: 'share',
        href: sharedLink()
      },
      // callback
      function(response) {
        if (response && !response.error_message) {
          onShared();
        }
      }
    );
  }

  const renderShareText = () => {
    if (endSheet) {
      return <div className={cs.support}>Support <strong>{book.author.name}</strong> and share his book with friends</div>
    } else if (book.share) {

      const { state } = book.share;
      if (isChapterPriceTier(book)) {
        if (state === 'created') {
          return <div className={cs.support}>or share with friends and get it <strong>for FREE</strong></div>
        } else if (state === 'shared') {
          return <div className={cs.support}>Support <strong>{book.author.name}</strong> and share his book with friends</div>
        }
      } else if (isBookPriceTier(book)) {
        return <div className={cs.support}>or share with friends and get extra chapter <strong>for FREE</strong></div>
      }
    }
  }

  return (
    <>{!book?.share?.state && !endSheet ? null :
      <div className={classNames(cs.shareBlock, className)}>
        <div className={cs.info}>
          <span>{renderShareText()}</span>
        </div>
        <div className={cs.button}>
          <button className={cs.facebookButton} onClick={shareFacebook}>
            <img src={process.env.PUBLIC_URL + "/fb24.svg"} width={32} height={32} alt={''}/>
            <div className={cs.share}>
              Share
            </div>
          </button>
        </div>
        <div className={cs.button}>
          <a className={cs.twitterButton} href={`https://twitter.com/intent/tweet?text=${sharedLink()}`}>
            <img src={process.env.PUBLIC_URL + "/tw24.svg"} width={32} height={32} alt={''}/>
            <div className={cs.share}>
              Share
            </div>
          </a>
        </div>
      </div>
    }</>
  )
}

ShareBlockReading.propTypes = {
  className: PropTypes.string.isRequired,
  book: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    priceTier: PropTypes.string.isRequired,
    share: PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  }).isRequired,
  onShared: PropTypes.func.isRequired,
  endSheet: PropTypes.bool
}

export default ShareBlockReading;
