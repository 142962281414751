import React from 'react';
import PropTypes from 'prop-types';
import { PlayFilled24, TrashCan24, PauseFilled24 } from '@carbon/icons-react';
import cs from './SoundContextMenu.module.scss';
import { Provider } from 'react-redux';
import store from 'src/modules/store';
import useSoundContext from './useSoundContext';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import classNames from 'classnames';

export const Component = ({ audioFile,
                            removeSound,
                            delay, length, volume, fadetime: fadeTime,
                            changeAttributeValue,
                            htmlFragment,
                            chapterUuid,
                            narratorUuid,
                            bookUuid,
                            modelName,
                            simplePlayer,
                            ...rest

                          }) => {

  const [innerDelay, setInnerDelay] = React.useState(Number.parseInt(delay));
  const [innerLength, setInnerLength] = React.useState(Number.parseInt(length));
  const [innerFadeTime, setInnerFadeTime] = React.useState(Number.parseInt(fadeTime));
  const [innerVolume, setInnerVolume] = React.useState(Number.parseInt(volume));
  const { play, setPlay, generating } = useSoundContext({
                                                          htmlFragment,
                                                          chapterUuid,
                                                          narratorUuid,
                                                          bookUuid,
                                                          innerDelay,
                                                          innerLength,
                                                          innerFadeTime,
                                                          innerVolume
                                                        });

  const _removeSound = () => {
    removeSound();
  }

  const disabled = !audioFile && !simplePlayer;

  React.useEffect(() => { if (simplePlayer) setPlay(true); }, [simplePlayer, setPlay]);

  // workaround Safari autoplay issue
  React.useEffect(() => {
    const audio = new Audio();
    audio.arc = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA';
    const promise = audio.play();
    if (promise !== undefined) {
      promise.catch(_ => { /* do nothing */ }).then(() => { /* do nothing */ });
    }
  }, []);

  return (
    <div className={cs.content}>
      { !simplePlayer && <div className={cs.name}>
        {audioFile ? (<>{audioFile.name} {generating && <>...generating</>}</>) : 'no sound'}
      </div> }
      <div className={cs.controls}>
        <button className={classNames('ck ck-button ck-off', { 'ck-disabled': disabled, [cs.disabled]: disabled })}
                onClick={() => {
                  if (!disabled) setPlay(!play)
                }}>
          {!play && <PlayFilled24/>}
          {play && !generating && <PauseFilled24/>}
          {generating &&
            <Loader
              type="TailSpin"
              color={'#000'}
              height={16}
              width={16}
            />
          }
        </button>
        { !simplePlayer && <button className={'ck ck-button ck-off'} onClick={_removeSound}><TrashCan24/></button> }
        { modelName === 'sound' && !simplePlayer &&
          <div>
            Offset (sec)
            <input type='number' value={innerDelay} min={0} disabled={disabled} onChange={(e) => {
              const delay = e.target.value;
              setInnerDelay(delay);
              changeAttributeValue('delay', delay);
            }}/>
          </div>
        }
        { modelName === 'soundeffect' && !simplePlayer &&
          <div>
            Length
            <input type='number' value={innerLength} min={0} disabled={disabled} onChange={(e) => {
              const length = e.target.value;
              setInnerLength(length);
              changeAttributeValue('length', length);
            }}/>
          </div>
        }
      </div>
      {!simplePlayer && <>
        <div className={cs.controls}>
          <div style={{flexGrow: 1}}/>
          <div>
            Fade time
            <input type='number' value={innerFadeTime} min={0} disabled={disabled} onChange={(e) => {
              const fadeTime = e.target.value;
              setInnerFadeTime(fadeTime);
              changeAttributeValue('fadetime', fadeTime);
            }}/>
          </div>
        </div>
        <div className={cs.controls}>
          <div style={{flexGrow: 1}}/>
          <div>
            Volume
            <input type='number' value={innerVolume} min={0} max={100} disabled={disabled} onChange={(e) => {
              const volume = e.target.value;
              setInnerVolume(volume);
              changeAttributeValue('volume', volume);
            }}/>
          </div>
        </div>
      </> }
    </div>
  )
}

Component.defaultProps = {
  delay: '0'
}

Component.propTypes = {
  audioFile: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    key: PropTypes.string
  }),
  delay: PropTypes.string,
  length: PropTypes.string,
  volume: PropTypes.string,
  fadeTime: PropTypes.string,
  removeSound: PropTypes.func,
  changeAttributeValue: PropTypes.func,
  htmlFragment: PropTypes.string,
  generatedSamples: PropTypes.object,
  saveSampleId: PropTypes.func,
  chapterUuid: PropTypes.string,
  narratorUuid: PropTypes.string,
  bookUuid: PropTypes.string,
  simplePlayer: PropTypes.bool
}

export const SoundContextMenu = props => <Provider store={store}><Component {...props} /></Provider>
