import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import cs from './styles.module.scss';
import { useIfMobileScreen } from 'src/hooks';
import { fetchLibrary } from 'src/modules/actions/me.actions';
import AppLayout from 'src/layout/AppLayout';
import BookCard from 'src/components/_shared/PublicBookCard';
import BookCell from 'src/components/_shared/Book/BookCell';

export const MyLibraryPage = ({ loading, books, ...actions }) => {
  const isMobile = useIfMobileScreen();

  const {fetchLibrary} = actions;
  useEffect(() => {
    fetchLibrary();
  }, [fetchLibrary]);

  return (
    <>
      {loading && <Loading/>}
      <AppLayout>
        <div className={cn('bx--grid', [cs.library])}>
          <h3>My Library</h3>
        </div>

        {(!loading && books.length === 0) &&
          <div className={cs.noBooks}>
            <h3>There are no books here yet.</h3>
          </div>
        }

        <div className={cn('bx--grid', cs.grid, { [cs.mobileVersion]: isMobile })}>
          <div className={cs.books}>
          {
            books.map((item, index) => (
              <div className={cs.trendingItem} key={index}>
                {isMobile ? <BookCell author={item.author} book={item.book} isNew={item.new} myLibraryMode /> : <BookCard {...item} />}
              </div>
            ))
          }
          </div>
        </div>
      </AppLayout>
    </>
  )
};

MyLibraryPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  books: PropTypes.arrayOf(PropTypes.shape({
    updatedAt: PropTypes.number,
    new: PropTypes.bool,
    bool: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    author: PropTypes.shape({
      uuid: PropTypes.string
    }),
    chapter: PropTypes.shape({
      uuid: PropTypes.string
    })
  })),

  fetchLibrary: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.me.loading,
    books: state.me.books
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchLibrary }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyLibraryPage);
