import * as chaptersApi from 'src/modules/api/chapters.api';
import { ACTION_TYPES } from 'src/modules/reducers/chapters.reducer';
import { ACTION_TYPES as BOOK_ACTION_TYPES } from 'src/modules/reducers/books.reducer';
import history from 'src/history';
import * as utils from '../api/utils';

const rejected = () => ({ type: ACTION_TYPES.FETCH_CHAPTER_REJECTED });

export function fetchChapter(chapterUuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_CHAPTER_PENDING });

    const response = await chaptersApi.getChapter(chapterUuid);
    if (response.status === 200) {
      let chapter = { ...response.data };
      if (chapter.fileType === 'html') {
        delete chapter.filePath;
        chapter.text = await chaptersApi.getChapterText(chapterUuid);
      }

      dispatch({ type: ACTION_TYPES.FETCH_CHAPTER_FULFILLED, chapter});
    } else {
      dispatch(rejected());
      history.push('/notFound');
    }
  }
}

export const forgetChapter = () => ({ type: ACTION_TYPES.FORGET_CHAPTER });

export function deleteChapter(chapterUuid, index) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_PENDING });

    const response = await chaptersApi.deleteChapter(chapterUuid);
    if (response.status === 200) {
      dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_FULFILLED, index });
    } else {
      dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_REJECTED });
    }
  }
}

export const uploadChapterAudioFile = (
  chapterId,
  file,
  handleProgressUpdate
) => {
  return async dispatch => {
    const b64Sound = (await utils.toBase64(file)).split(',')[1];
    const payload = {
      info: {},
        file: {
        data: b64Sound,
        type: file ? 'mp3' : 'webm'
      }
    }

    const { abortController, promise } = chaptersApi.postChapterAudioFile(chapterId, payload, handleProgressUpdate);

    const response = await promise;

    if (response?.status === 200) {
      
    }
  }
}

// export function generateChapterAudio(chapterUuid, forced=false) {
//   return async dispatch => {
//     dispatch({ type: BOOK_ACTION_TYPES.CHAPTER_GENERATE_PENDING });
//     const response = await chaptersApi.generateChapterAudio(chapterUuid, forced);
//     if (response.status === 200) {
//       dispatch({ type: BOOK_ACTION_TYPES.FETCH_CHAPTER_FULFILLEDD, index });
//     } else {
//       dispatch({ type: BOOK_ACTION_TYPES.CHAPTER_GENERATE_REJECTED });
//     }
//   }
// }