import React from 'react';
import { Redirect, Route } from 'react-router';

import { isAuthenticated } from 'src/modules/api/cookie.api';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={ props => isAuthenticated() ? <Component { ...props } {...rest} /> :
      <Redirect
        to={{ pathname: '/login', state: { from: props.location } }}
      />
    }
  />
);

export default PrivateRoute;
