import React from 'react';

import cs from './Order.module.scss';
import { useIfMobileScreen } from 'src/hooks';

export default ({ order, style }) => {
  const isMobile = useIfMobileScreen();
  const _style = {
    ...style,
    width: isMobile ? '40px' : '39px',
    height: isMobile ? '28px' : '35px'
  };

  return (
    <div className={cs.order} style={_style}>
      #{order}
    </div>
  )
}
