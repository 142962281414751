import React from 'react';
import PropTypes from 'prop-types';

import cs from './ProgressOverlay.module.scss';

const BufferingOverlay = ({ buffering, playlist, setCancelBuffering, wouldStartPlaying }) => {

  const stopAll = () => {
    const playing = playlist.find(t => t.playing);
    if (playing) {
      wouldStartPlaying(playing);
    }
    setCancelBuffering(true);
  }

  return (
    <>
      { buffering ?
        <div className={cs.overlay} onClick={stopAll}>
          <div className={cs.container}>
            <div className={cs.textContainer} style={{ marginBottom: 0 }}>
              Buffering ...
            </div>
          </div>
        </div> : null
      }
    </>
  )
}

BufferingOverlay.defaultProps = {
  buffering: false,
  playlist: []
}

BufferingOverlay.propTypes = {
  buffering: PropTypes.bool,
  playlist: PropTypes.array
}

export default BufferingOverlay;
