import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import history from 'src/history';
import { OverflowMenu, OverflowMenuItem, Tag } from 'carbon-components-react';
import { RequestQuote24 } from '@carbon/icons-react';
import cn from 'classnames';

import cs from './ChapterItem.module.scss';
import { dateOpts } from 'src/modules/api/utils';
import Delimiter from 'src/components/_shared/Delimiter';
import { formatDuration } from 'src/utils/duration';
import Button from 'src/components/_shared/DesignV2/Button';
import * as cookiesApi from 'src/modules/api/cookie.api';
import { RES_PATH } from 'src/config/environment';

export const ChapterItem = ({ match, chapter, narration, onDeleteChapter, onTogglePublished, onToggleFree, onAddChapterAbove, onSharableLink, onPreview, generate, createNarration, deleteNarration, uploadFile }) => {
  const onEdit = () => history.push(`${match.url}/chapter/${chapter.uuid}/edit`);

  return (
    <>
      <div className={cs.chapter}>

        {
          !chapter.published && <div style={{ flexShrink: 0, paddingTop: '0.25rem'}}><RequestQuote24/></div>
        }

        <div style={{flexShrink: 0}}>
          <div className={cn(cs.chapterTitle, {[cs.draftTitle]: !chapter.published})}>
            {chapter.name}
          </div>
          <div className={cs.chapterAdded}>
            { chapter.published ?
              new Date(chapter.created * 1000).toLocaleDateString("en-US", dateOpts):
              'Draft'
            }
          </div>
        </div>

        { chapter.free &&
          <Tag type='green' style={{marginLeft: '1rem', whiteSpace: "nowrap"}}>free</Tag>
        }

        <div style={{flexBasis: '100%'}} />

        <div style={{flexShrink: 0, border: "1px solid white", lineHeight: '36px', paddingLeft: '8px', paddingRight: '8px'}}>
          <Narration 
            narration={narration} 
            chapter={chapter} 
            generate={generate} 
            createNarration={createNarration}
            deleteNarration={deleteNarration}
            uploadFile={uploadFile}
          />
        </div>
        <OverflowMenu
          direction='top'
          flipped={true}
        >
          <OverflowMenuItem
            itemText="Edit"
            onClick={onEdit}
          />
          <OverflowMenuItem
            itemText={chapter.published ? "Unpublish" : "Publish"}
            onClick={onTogglePublished}
          />
          <OverflowMenuItem
            itemText={chapter.free ? "Make Paid" : "Make Free"}
            onClick={onToggleFree}
          />
          <OverflowMenuItem
            itemText="Delete"
            hasDivider
            isDelete
            onClick={onDeleteChapter}
          />
          <OverflowMenuItem
            itemText="Add chapter above"
            hasDivider
            isDelete
            onClick={onAddChapterAbove}
          />
          <OverflowMenuItem
            itemText="Share Access"
            hasDivider
            isDelete
            onClick={onSharableLink}
          />
          <OverflowMenuItem
            itemText="Preview"
            hasDivider
            isDelete
            onClick={onPreview}
          />
        </OverflowMenu>

      </div>
      <Delimiter/>
    </>
  )
};

const label = (chapter) => {
  if(chapter?.audioState){
    switch(chapter?.audioState?.state) {
      case "waiting":
        return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Waiting...</Tag>)
      case "inProgress":
        return (<Tag type='teal' style={{marginLeft: '0.5rem'}}>Processing {Math.round(chapter?.audioState?.progress * 100.0)}%</Tag>)
      case "succeeded":
        return formatDuration(chapter?.audio?.duration)
      case "failed":
        return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Not available</Tag>)
      default:
        return (<></>)
    }
  } else if (chapter?.audio?.duration) {
    return formatDuration(chapter.audio.duration)
  } else {
    return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Not Generated</Tag>)
  }
};

const action = (chapter, generate) => {
  if(chapter?.audioState){
    switch(chapter?.audioState?.state) {
      case "succeeded":
        return (
        <Button 
          style={{
            display: 'inline',
            width: 'fit-content',
            padding: '4px 12px 4px 12px',
          }} 
          kind='normal' 
          size='small'
          onClick={() => window.location.href = `${RES_PATH}${chapter?.audio?.path}?AuthToken=${cookiesApi.getAuthToken()}`} >
          Play
        </Button>);
      case "failed":
        return (
          <Button 
            style={{
              display: 'inline',
              width: 'fit-content',
              padding: '4px 12px 4px 12px',
            }} 
            kind='normal' 
            size='small'
            onClick={() => generate()} >
            Regenerate
          </Button>);      
      default:
        return (<></>)
    }
  } else if (chapter?.audio?.duration) {
    return formatDuration(chapter.audio.duration)
  } else {
    return (          
      <Button 
      style={{
        display: 'inline',
        width: 'fit-content',
        padding: '4px 12px 4px 12px',
      }} 
      kind='normal' 
      size='small'
      onClick={() => generate()} >
      Generate
    </Button>);
  }
};

const ManualNarration = ({chapter, createNarration, deleteNarration, uploadFile}) => {
  const fileInput = useRef();
  const selectFile = () => {
      fileInput.current.click();
  }

  const handleChange = async e => {
    const file = e.target?.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };
  return (
    <>
      Manual Narration: 
      {chapter.narration === undefined &&
        <Button
          style={{
            display: 'inline',
            width: 'fit-content',
            padding: '4px 12px 4px 12px',
            marginLeft: '8px'
          }} 
          kind='normal' 
          size='small'
          onClick={createNarration} >
          Create
        </Button>
      }
      {chapter.narration === undefined &&
      <Button 
        style={{
          display: 'inline',
          width: 'fit-content',
          padding: '4px 12px 4px 12px',
          marginLeft: '8px'
        }} 
        kind='normal' 
        size='small'
        onClick={selectFile} 
      >
      <input
        style={{display: 'none'}}
        ref={fileInput}
        type='file'
        onChange={handleChange}
        accept="audio/mpeg"
      />        
        Upload
      </Button>
      }
      {chapter.narration !== undefined &&
        <Button
          style={{
            display: 'inline',
            width: 'fit-content',
            padding: '4px 12px 4px 12px',
            marginLeft: '8px'
          }} 
          kind='normal' 
          size='small'
          onClick={createNarration} 
        >
          View
        </Button>
      }
      {chapter.narration !== undefined &&
        <Button
          style={{
            display: 'inline',
            width: 'fit-content',
            padding: '4px 12px 4px 12px',
            marginLeft: '8px'
          }} 
          kind='normal' 
          size='small'
          onClick={deleteNarration} 
        >
          Delete
        </Button>
      }
      {chapter?.audio?.path &&
        <Button 
          style={{
            display: 'inline',
            width: 'fit-content',
            padding: '4px 12px 4px 12px',
            marginLeft: '8px'
          }} 
          kind='normal' 
          size='small'
          onClick={() => window.location.href = `${RES_PATH}${chapter.audio.path}?AuthToken=${cookiesApi.getAuthToken()}`} >
          Play
        </Button>
      }
    </>
  );
}

const Narration = ({narration, chapter, generate, createNarration, deleteNarration, uploadFile}) =>  {
  switch (narration) {
    case 'automatic':
      return (<>AI Narration: {label(chapter)}, {action(chapter, generate)}</>);
    case 'manual':
      return (
        <ManualNarration 
          narration={narration} 
          chapter={chapter}
          createNarration={createNarration}
          deleteNarration={deleteNarration}
          uploadFile={uploadFile}
          />);
    default:
      return (<>No narration available</>);
  }
}

ChapterItem.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    created: PropTypes.number.isRequired,
    free: PropTypes.bool.isRequired
  }),
  onDeleteChapter: PropTypes.func.isRequired,
  onTogglePublished: PropTypes.func.isRequired
};

export default ChapterItem;
