import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'
import Coupon from 'src/modules/model/coupon';

export async function applyCoupon(id) {
    const response = await axios.post('/me/coupons', { id } , getAuthHeader());
    if(response.status !== 200) {
        throw new Error("Bad response");
    }
    return new Coupon(response.data);
}

export async function postChapterCoupon(chapterId, expiration) {
    try {
      return await axios.post(`/coupons`, 
      { 
          expiration, 
          content: { 
              type:"chapter",
              content: {
                  uuid: chapterId
              }
          }
      }, getAuthHeader());
    } catch (error) {
      return error.response;
    }
}

export async function getChapterCoupons(chapterUUID) {
    try {
      return await axios.get(`/me/coupons/created?chapter=${chapterUUID}`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
}

export async function deleteCoupon(uuid) {
  try {
    return await axios.delete(`/coupons/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}