import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import cs from './EndSheet.module.scss';
import { getCurrentTheme } from 'src/components/reader/ReadingPage/helpers';
import ShareBlockReading from 'src/components/_shared/ShareBlockReading';
import ReactGA from 'react-ga4';

const EndSheet = ({ book, className, style }) => {
  return (
    <>
      { book ?
        <div className={cn(className, cs[getCurrentTheme().name])} style={{...style}}>
          <div style={{ marginTop: '48px', padding: '1rem'}}>
            <div className={cs.chapterName}>No more chapters are available yet</div>
            <div className={cs.relativeContainer}>
              <ShareBlockReading className={cs.shareBlock}
                                 book={book}
                                 onShared={() => {
                                   ReactGA.event({ category: 'Share', action: 'Chapter'});
                                 }}
                                 endSheet
              />
            </div>
          </div>
        </div> : null
      }
    </>
  )
}

EndSheet.propTypes = {
  book: PropTypes.shape({}),
  className: PropTypes.any,
  style: PropTypes.any
}

export default EndSheet;
