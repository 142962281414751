import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'
import { REQUEST_LIMIT } from 'src/components/CompetitionPage/constants';

export async function getTagsHome(tags) {
  try {
    var params = tags.map( item => "tags[]="+item).join("&")
    return await axios.get('/tags/books/home?' + params, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getTagBooks(tags, sort, offset, limit = REQUEST_LIMIT) {
  try {
    var params = tags.map( item => "tags[]="+item).join("&")

    return await axios.get(`/tags/books?${params}&sort=${sort}&limit=${limit}&offset=${offset}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}