import * as cookiesApi from 'src/modules/api/cookie.api';
import { API_URL } from 'src/config/environment';
import { resizeImage } from 'src/modules/api/utils';

class UploadAdapterPlugin {
    constructor( editor ) {
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            return new UploadAdapter( loader );
        };    
    }
}

class UploadAdapter {
    loader = null
    xhr = null

    constructor( loader ) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then((file) => { return resizeImage(file, 1024, 1024) })
            .then( file => new Promise( ( resolve, reject ) => {
                let values = file.split(';')
                let data = values[1].split(',')[1]
                let type = values[0].split(':')[1].split('/')[1]

                this._initRequest();
                this._initListeners( resolve, reject );
                this._sendRequest( { data: data, type: type } );
            } ) );
    }

    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.open( 'POST', `${API_URL}/resources`, true );
        xhr.responseType = 'json';
        this.xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.setRequestHeader(cookiesApi.TOKEN_KEY, cookiesApi.getAuthToken())
    }

    _initListeners( resolve, reject ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file.`;

        xhr.addEventListener( 'error', (error) => {
            reject( genericErrorText ) 
        });
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }
            resolve( {
                default: response.url
            } );
        } );

        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    _sendRequest( object ) {
        this.xhr.send(JSON.stringify(object));
    }
}

export default UploadAdapterPlugin;
