import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';

import history from 'src/history';
import cx from './static-layout.module.scss';

const StaticLayout = ({ children, contentClass }) => {
  return (
    <div className={cx.base}>
      <div className={cx.buttons}>
        <Button kind='ghost'
                onClick={() => { history.push('/s/terms-of-service')}}>
          Terms Of Service
        </Button>
        <Button kind='ghost'
                onClick={() => { history.push('/s/privacy-policy')}}>
          Privacy Policy
        </Button>
        <Button kind='ghost'
                onClick={() => { history.push('/s/content-guidelines')}}>
          Content Guidelines
        </Button>
      </div>
      <div className={classNames(cx.content, contentClass)}>
        {children}
      </div>
    </div>
  )
};

StaticLayout.defaultProps = {
  contentClass: ''
};

StaticLayout.propTypes = {
  contentClass: PropTypes.string
};

export default StaticLayout;
