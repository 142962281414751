export function findCurrentChapter(book, currentChapterExternal) {
  const chapters = book?.chapters;
  return currentChapterExternal || book?.progress?.chapter || chapters?.[0]?.uuid;
}

export function getNextChapter(chapters, currentChapter) {
  return getNextChapterObject(chapters, currentChapter)?.uuid;
}

export function getPrevChapter(chapters, currentChapter) {
  return getPrevChapterObject(chapters, currentChapter).uuid
}

export function getNextChapterObject(chapters, currentChapter) {
  const i = chapters.findIndex(c => c.uuid === currentChapter);
  if (i >= 0 && chapters.length > i + 1) return chapters[i + 1];
  else return null;
}

export function getPrevChapterObject(chapters, currentChapter) {
  const i = chapters.findIndex(c => c.uuid === currentChapter);
  if (i > 0) return chapters[i - 1];
  else return null;
}
