import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Loading } from 'carbon-components-react';
import { Information20 } from '@carbon/icons-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import classes from './index.module.scss';
import history from 'src/history';
import Layout from 'src/layout/ContentLayout';
import BookCard from 'src/components/_shared/BookCard';
import ImportEpubDialog from './ImportEpubDialog';
import { openModal } from 'src/modules/actions/modal.actions';
import { fetchBooks, checkJob } from 'src/modules/actions/writers.actions';
import {
  deleteBook,
  importEpub,
  toggleBookVisibility
} from 'src/modules/actions/books.actions';
import JobImport from 'src/components/author/MyBooksPage/JobImport';

export const MyBooksPage = ({
                              match, fetchBooks, loading,
                              books, jobs,
                              account, ...actions }) => {

  const [error, setError] = React.useState();
  const fileDialog = React.useRef();

  const ActionButtons = () => {
    const disabled = loading || !canAddBook();
    return (
      <div className={classes.buttonsBlock}>
        <Button kind='tertiary'
                disabled={disabled}
                onClick={() => history.push(`${match.url}/add`)}
        >
          Add Book
        </Button>
        <Button kind='tertiary'
                disabled={disabled}
                onClick={() => {
                  fileDialog.current.click()
                }}
        >
          Import Epub
        </Button>
      </div>
    );
  }

  const canAddBook = () => !!account.name;

  useEffect(() => {
    fetchBooks();
  }, [fetchBooks]);

  const onDeleteBook = (book, index) => {
    actions.openModal({
      body: '',
      modalHeading: 'Delete this book?',
      onRequestSubmit: () => actions.deleteBook(book.uuid, index)
    });
  };

  const handleEpubFileChange = async file => {
    actions.importEpub(file);
  }

  return (
    <Layout
      title="My Books"
      renderAction={ActionButtons}
      error={error}
    >
      <div className='bx--grid'>

        { !canAddBook() &&
          <>
            <div className='bx--row'>
              <div
                className={cn(
                  'bx--col',
                  classes.info
                )}
              >
                <Information20/><span style={{ marginLeft: '1rem' }}>In order to add books as writer we
                need more information about you</span>
              </div>
            </div>
            <div className='bx--row' style={{ marginBottom: '2rem'}}>
              <div className='bx--col'>
                <Button kind="tertiary"
                        onClick={() => history.push('/profile')}>
                  Add information to Profile
                </Button>
              </div>
            </div>
          </>
        }

        {(!loading && canAddBook() && books && books.length === 0) &&
          <div className={classes.noBooks}>
            <h3>There are no books here yet.</h3>
          </div>
        }

        <div className='bx--col'>
          {
            <div className={classes.cards}>
              {!loading && jobs?.map?.((job, i) => (
                <JobImport job={job} key={i} checkJob={actions.checkJob}/>
              ))}
              {!loading && books?.map?.((book, i) => (
                <BookCard
                  key={i}
                  {...book}
                  match={match}
                  onDelete={() => onDeleteBook(book, i)}
                  toggleBookVisibility={actions.toggleBookVisibility}
                />
              ))}
            </div>
          }
        </div>
      </div>

      {
        loading && <div className={classes.loading}>
          <Loading withOverlay={false} small description='Loading Books...'/>
          <div>Loading Books...</div>
        </div>
      }

      <ImportEpubDialog ref={fileDialog} handleEpubFileChange={handleEpubFileChange} onError={setError}/>
    </Layout>
  )
};

MyBooksPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  books: PropTypes.arrayOf(PropTypes.any),
  account: PropTypes.shape({
    name: PropTypes.string
  }),
  openModal: PropTypes.func.isRequired,
  deleteBook: PropTypes.func.isRequired,
  toggleBookVisibility: PropTypes.func.isRequired,
  importEpub: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    progress: PropTypes.number,
    state: PropTypes.string.isRequired
  })),
  checkJob: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.writers.loading,
    books: state.writers.mybooks,
    account: state.authentication.account,
    jobs: state.writers.jobs
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchBooks,
    openModal,
    deleteBook,
    toggleBookVisibility,
    importEpub,
    checkJob
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBooksPage);
