export const ACTION_TYPES = {
  CREATE_ACCOUNT_PENDING: 'authentication/CREATE_ACCOUNT_PENDING',
  CREATE_ACCOUNT_FULFILLED: 'authentication/CREATE_ACCOUNT_FULFILLED',
  CREATE_ACCOUNT_REJECTED: 'authentication/CREATE_ACCOUNT_REJECTED',

  LOGIN_ACCOUNT_PENDING: 'authentication/LOGIN_ACCOUNT_PENDING',
  LOGIN_ACCOUNT_FULFILLED: 'authentication/LOGIN_ACCOUNT_FULFILLED',
  LOGIN_ACCOUNT_REJECTED: 'authentication/LOGIN_ACCOUNT_REJECTED',

  UPDATE_PROFILE_PENDING: 'authentication/UPDATE_PROFILE_PENDING',
  UPDATE_PROFILE_FULFILLED: 'authentication/UPDATE_PROFILE_FULFILLED',
  UPDATE_PROFILE_REJECTED: 'authentication/UPDATE_PROFILE_REJECTED',

  MESSAGE: 'authentication/MESSAGE',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  RESTORE_SESSION: 'authentication/RESTORE_SESSION',

  SET_IS_AUTHOR_SELECTED: 'authentication/SET_IS_AUTHOR_SELECTED'
};

export const initialMessageState = {
  message: null,
  messageKind: null,
  messageTitle: null,
}

const initialState = {
  loading: false,
  isAuthenticated: false,
  account: {},
  isAuthor: false,
  ...initialMessageState
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_ACCOUNT_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.CREATE_ACCOUNT_FULFILLED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.CREATE_ACCOUNT_REJECTED:
      return {
        ...state,
        loading: false,
        ...(action.messageInstance?.toJS() || {})
      };
    case ACTION_TYPES.LOGIN_ACCOUNT_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.LOGIN_ACCOUNT_FULFILLED:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        account: action.account
      };    
    case ACTION_TYPES.LOGIN_ACCOUNT_REJECTED:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        ...(action.messageInstance?.toJS() || {})
      };
    case ACTION_TYPES.MESSAGE:
      return {
        ...state,
        ...(action.messageInstance?.toJS() || initialMessageState)
      }
    case ACTION_TYPES.CLEAR_AUTH:
      return { ...initialState };
    case ACTION_TYPES.RESTORE_SESSION:
      return {
        ...state,
        isAuthenticated: true,
        account: action.session
      };

    case ACTION_TYPES.UPDATE_PROFILE_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        account: {...action.account }
      };
    case ACTION_TYPES.UPDATE_PROFILE_REJECTED:
      return {
        ...state,
        loading: false,
        ...(action.messageInstance?.toJS() || {})
      };
    case ACTION_TYPES.SET_IS_AUTHOR_SELECTED:
      return {
        ...state,
        isAuthor: action.isAuthor
      };
    default:
      return state;
  }
}
