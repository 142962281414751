import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'carbon-components-react';
import { ChevronRight16 } from '@carbon/icons-react';

import cs from './styles.module.scss';
import history from 'src/history';
import { RES_PATH } from 'src/config/environment';

export const BookPortrait = ({ book, children, showLikes }) => {

  return (
    <>
      { book &&
        <div className={cs.portraitArea}>

          <div className={cs.portraitContainer}>
            <div className={cs.portraitInnerContainer}>
              <div className={cs.portraitInner}
                   style={{ backgroundImage: `url(${RES_PATH + book.image})`}}
              />
              {/* {book.likes && showLikes ? <div className={cs.liked}>{book.likes}{' '}liked</div> : null} */}
            </div>
          </div>

          <div className={cs.portraitDescription}>
            <h1 className={cs.title}>
              {book.name}
            </h1>

            <div className={cs.author}
                 onClick={() => history.push(`/writers/${book?.author?.uuid}`)}
            >
              {book?.author?.name}
              <ChevronRight16 style={{verticalAlign: 'middle'}} />
            </div>

            <div>
              <div className={cs.tags}>
                {
                  book?.tags?.map((tag, i) =>

                    <Tag key={i}
                         type='cool-gray'
                         style={{ cursor: 'pointer' }}
                         onClick={() => history.push(`/tags/${tag}`)}
                    >
                      {tag}
                    </Tag>
                  )
                }
              </div>
            </div>
            {/* {showLikes && <div className={cs.likes}><Like {...book} size='l' showLikes={false} enableSharing/></div>} */}
          </div>
          {children}
        </div>
      }
    </>
  )
};

BookPortrait.defaultProps = {
  showLikes: true
};

BookPortrait.propTypes = {
  book: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    tags: PropTypes.array,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  }),

  children: PropTypes.any,
  showLikes: PropTypes.bool
};

export default BookPortrait;
