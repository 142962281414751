import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';

export async function getBlogPost(postId, surface) {
  try {
    const response = await axios.get(`/pages/${surface ?? "blog"}/${postId}`, getAuthHeader());
    if (response && response.status === 200) {
      return response
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function getBlog(offset, limit = 8) {
  try {
    const response = await axios.get(`/blog/posts?limit=${limit}&offset=${offset}`, getAuthHeader());
    if (response && response.status === 200) {
      return response
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function getMyBlog(offset, limit = 8) {
  try {
    const response = await axios.get(`/me/blog/posts?limit=${limit}&offset=${offset}`, getAuthHeader());
    if (response && response.status === 200) {
      return response
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function getMyBlogPost(uuid) {
  try {
    const response = await axios.get(`/me/blog/posts/${uuid}`, getAuthHeader());
    if (response && response.status === 200) {
      return response
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function putBlogPost(uuid, blogPost) {
  try {
    return await axios.put(`/me/blog/posts/${uuid}`, blogPost, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function postBlogPost(blogPost) {
  try {
    return await axios.post(`/me/blog/posts`, blogPost, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function deleteBlogPost(uuid) {
  try {
    return await axios.delete(`/me/blog/posts/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}