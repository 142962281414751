import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';
import { ACTION_TYPES } from 'src/modules/reducers/bgsounds.reducer';
import * as utils from '../api/utils';

export function fetchBGFiles(bookId) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_BG_FILES_PENDING });
    try {
      const res = await axios.get(`/books/${bookId}/audioFiles`, getAuthHeader());
      if (res?.status === 200) {
        dispatch({ type: ACTION_TYPES.FETCH_BG_FILES_FULFILLED, files: res.data.items })
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_BG_FILES_REJECTED });
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_BG_FILES_REJECTED });
    }
  }
}

export function toggleDeleteBGSoundDialog(uuid) {
  return (dispatch, getState) => {
    return dispatch({
      type: getState().bgsounds.deleteDialog ? ACTION_TYPES.BG_FILES_OFF : ACTION_TYPES.BG_FILES_ON,
      deletingSound: uuid
    });
  }
}

export function deleteBGSound() {
  return async (dispatch, getState) => {

    const error = () => dispatch({ type: ACTION_TYPES.DELETE_BG_FILE_REJECTED, error: 'Sound is not deleted' });

    dispatch({ type: ACTION_TYPES.DELETE_BG_FILE_PENDING });

    try {
      const soundUuid = getState().bgsounds.deletingSound;
      const bookId = getState().books.book.uuid;
      const res = await axios.delete(`/books/${bookId}/audioFiles/${soundUuid}`, getAuthHeader());

      if (res?.status === 200) {
        dispatch({ type: ACTION_TYPES.DELETE_BG_FILE_SUCCESS, uuid: soundUuid });
      } else {
        error();
      }
    } catch(e) {
      error();
    }
  }
}

export function saveSound(values) {
  return async (dispatch, getState) => {
    console.log(values);
    dispatch({ type: ACTION_TYPES.SAVE_BG_FILE_PENDING });
    try {
      dispatch(clearFormError());
      const bookId = getState().books.book.uuid;
      const mp3 = (await utils.toBase64(values.file.file)).split(',')[1];
      const requestBody = {
        info: values.info,
        file: {
          type: 'mp3',
          data: mp3
        }
      }
      const res = await axios.post(`/books/${bookId}/audioFiles`, requestBody, getAuthHeader());
      if (res?.status === 200) {
        dispatch({ type: ACTION_TYPES.SAVE_BG_FILE_FULFILLED });
        dispatch(fetchBGFiles(bookId));
      } else {
        dispatch({ type: ACTION_TYPES.SAVE_BG_FILE_REJECTED, error: 'File is not uploaded' })
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.SAVE_BG_FILE_REJECTED, error: 'File is not uploaded' })
    }
  }
}

export const toggleFormVisibility = () => ({ type: ACTION_TYPES.TOGGLE_FORM });

export const clearFormError = () => ({ type: ACTION_TYPES.CLEAR_FORM_ERROR });

export const generateSample = async ({bookUuid, chapterUuid, narratorUuid, htmlFragment}) => {
  const data = { htmlData: btoa(unescape(encodeURIComponent(htmlFragment)))};
  if (chapterUuid) {
    data.chapter = chapterUuid;
  }
  if (narratorUuid) {
    data.narrator = narratorUuid;
  }
  return await axios.post(`/books/${bookUuid}/generateSample`, data, getAuthHeader());
}

export const getJob = async (jobId) => {
  return await axios.get(`/jobs/${jobId}`, getAuthHeader());
}

export const getAudioSample = async (sampleId) => {
  return await axios.get(`/samples/${sampleId}`, { responseType: 'arraybuffer', ...getAuthHeader() });
}

export const saveSampleId = (fragmentHash, sampleId) => ({ type: ACTION_TYPES.SAVE_SAMPLE_ID, hash: fragmentHash, id: sampleId })
