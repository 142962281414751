import axios from 'axios';
import randomColor from 'randomcolor';
import { ACTION_TYPES} from 'src/modules/reducers/personas.reducer';
import { getAuthHeader } from 'src/modules/api/utils';
import { hashCode } from 'src/utils';

function injectColor(persona) {
  return { ...persona, color: randomColor({ seed: hashCode(persona.uuid) }) }
}

function injectNarrator(persona, narrator) {
  return { ...persona, narrator: narrator || undefined }
}

export const DEFAULT_NARRATOR_ALWAYS_ID = "0";

export function fetchPersonas(bookId, maybeChapterUuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_PERSONAS_PENDING });
    try {
      const [persData, narrData] = await Promise.all([
        axios.get(`/books/${bookId}/personas`, getAuthHeader()).then(res => ({ status: res.status, data: res.data })),
        maybeChapterUuid ?
          axios.get(`/chapters/${maybeChapterUuid}/narrator`, getAuthHeader()).then(res => ({ uuid: res.data.uuid })) :
          Promise.resolve({ uuid: undefined })
      ]);

      if (persData?.status === 200) {
        dispatch({
          type: ACTION_TYPES.FETCH_PERSONAS_FULFILLED,
          personas: persData.data.items
            .map(injectColor)
            .map(p => injectNarrator(p, p.uuid === narrData.uuid || (!narrData.uuid && p.id === DEFAULT_NARRATOR_ALWAYS_ID)))
        });
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_PERSONAS_REJECTED });
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_PERSONAS_REJECTED });
    }

  }
}

export const togglePersonasFormVisibility = (editPersonaId, chapterUuid) => ({ type: ACTION_TYPES.TOGGLE_FORM, editPersonaId, chapterUuid });

export function saveCharacter(values) {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.SAVE_CHARACTER_PENDING });
    try {
      dispatch(clearFormError());
      const bookId = getState().books.book.uuid;
      let res;
      let type_;
      if (values.uuid) {
        res = await axios.put(`/books/${bookId}/personas/${values.uuid}`, values, getAuthHeader());
        type_ = ACTION_TYPES.SAVE_CHARACTER_FULFILLED_UPDATE;
      } else {
        res = await axios.post(`/books/${bookId}/personas`, values, getAuthHeader());
        type_ = ACTION_TYPES.SAVE_CHARACTER_FULFILLED;
      }

      if (values.narrator) {
        if (getState().personas.personas.find(pers => pers.narrator)?.uuid !== res.data.uuid) {
          getState().personas.chapterUuid && axios.put(`/chapters/${getState().personas.chapterUuid}/narrator`, { uuid: res.data.uuid }, getAuthHeader()).then(() => {});
        }
      } else {
        if (!getState().personas.personas.find(pers => pers.narrator && pers.uuid !== values.uuid)) {
          const defaultNarrator = getState().personas.personas.find(pers => pers.id === DEFAULT_NARRATOR_ALWAYS_ID);
          if (defaultNarrator) {
            getState().personas.chapterUuid && axios.put(`/chapters/${getState().personas.chapterUuid}/narrator`, {uuid: defaultNarrator.uuid}, getAuthHeader()).then(() => {});
            dispatch({ type: ACTION_TYPES.UPDATE_CHARACTER, character: { ...defaultNarrator, narrator: true }});
          }
        }
      }

      if (res?.status === 200) {
        dispatch({ type: type_, persona: injectNarrator(injectColor(res.data), values.narrator || undefined) });
        dispatch(togglePersonasFormVisibility(undefined, undefined));
      } else {
        dispatch({ type: ACTION_TYPES.SAVE_CHARACTER_REJECTED, error: 'Character is not saved' });
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.SAVE_CHARACTER_REJECTED, error: 'Character is not saved' });
    }
  }
}

export const clearFormError = () => ({ type: ACTION_TYPES.CLEAR_FORM_ERROR });

export function toggleDeletePersonaDialog() {
  return (dispatch, getState) => {
    return dispatch({
      type: getState().personas.deletePersonaDialog ? ACTION_TYPES.DELETE_PERSONA_OFF : ACTION_TYPES.DELETE_PERSONA_ON
    });
  }
}

export function deletePersona() {
  return async (dispatch, getState) => {

    const error = () => dispatch({ type: ACTION_TYPES.DELETE_PERSONA_REJECTED, error: 'Character is not deleted' });

    dispatch({ type: ACTION_TYPES.DELETE_PERSONA_PENDING });

    try {
      const personaId = getState().personas.editPersonaId;
      const personaUuid = getState().personas.personas.find(p => p.id === personaId)?.uuid
      if (!personaUuid) {
        error();
        return;
      }

      const bookId = getState().books.book.uuid;
      const res = await axios.delete(`/books/${bookId}/personas/${personaUuid}`, getAuthHeader());

      if (res?.status === 200) {
        dispatch({ type: ACTION_TYPES.DELETE_PERSONA_SUCCESS, uuid: personaUuid });
      } else {
        error();
      }
    } catch(e) {
      error();
    }
  }
}
