import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormPage from 'src/components/_shared/form/FormPage';
import history from 'src/history';
import { FORM_ERROR } from 'final-form';
import { NotificationContext } from 'src/components/ErrorContext/ErrorContext';

export const Edit = props => {
  const { match, title, listRoute, get, post, put, properties } = props;
  const [object, setObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    const uuid = match.params.uuid;
    if (uuid) {
        setLoading(true);
        get(uuid).then(item => {
            setObject(item)
            setLoading(false);
        });
    }
  }, [get, match]);

  return (
      <FormPage
        loading={loading} 
        crumbs={[{title: title ?? "Items List", link: listRoute}]}
        properties={properties}
        value={object}
        submit={(value)=> {
            const uuid = match.params.uuid;
            if (uuid) {
              put(uuid, value).then((response) => {
                if (response && response.status === 200) {
                  history.push(listRoute);
                } else {
                  notificationContext.showMessage(`Saving Failed: "${response.data.details}"`, 'error');
                  console.log({[FORM_ERROR]: "Failed to save"});
                }
              })
            } else {
              return post(value).then((response) => {
                if (response && response.status === 200) {
                  history.push(listRoute);
                } else {
                  notificationContext.showMessage(`Creation Failed: "${response.data.details}"`, 'error');
                  console.log({[FORM_ERROR]: "Failed to save"});
                }
              })
            }
        }}
       />
  )
};

Edit.propTypes = {
    content: PropTypes.any, 
    match: PropTypes.shape({
        url: PropTypes.string.isRequired
    }).isRequired, 
    listRoute: PropTypes.string.isRequired, 
    get: PropTypes.func.isRequired, 
    post: PropTypes.func.isRequired, 
    put: PropTypes.func.isRequired, 
    properties: PropTypes.any.isRequired 
};

export default Edit;