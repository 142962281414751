import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { searchUser } from './search-request';

const customStyles = {
  control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: '#262626',
      border: 0,
      borderBottom: '1px solid #6f6f6f',
      color: '#f4f4f4',
      outline: 0,
      boxSizing: 'border-box',
      boxShadow: state.isFocused ? '0 0 0 1px #fff' : 0,
      '&:hover': {
        borderBottom: 'auto',
      }
  }),
  input: (provided) => ({ ...provided, color: '#f4f4f4' }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: '#262626'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#161616' : '#f4f4f4',
    backgroundColor: state.isFocused ? '#e5e5e5' : '#262626',
    padding: 16,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    '& svg': {
      fill: '#111111'
    }
  }),
  singleValue: ((provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? '#161616' : '#f4f4f4',
      backgroundColor: state.isFocused ? '#e5e5e5' : '#262626',
    };
  })
};

const UserSearch = ({initialValue, onChange}) => {
  const [initState, setInitState] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  const [handler, setHandler] = useState(null);

  useEffect(() => {
    if (initialValue !== initState) {
      setValue(initialValue);
      setInitState(initialValue);
    }
  }, [initialValue, initState]);

  const promiseOptions = async inputValue => {
    if (handler) {
      clearTimeout(handler);
    }
    const promise = new Promise(resolve => {
      setHandler(
        setTimeout(async () => {
          const users = (await searchUser(inputValue));
          resolve(users);
        }, 300)
      );
    });
    return await promise;
  };

  const _onChange = async _value => {
    setValue(_value);
    onChange(_value.uuid)
  };

  return (
    <Async
    styles={customStyles}
      cacheOptions
      defaultOptions
      value={value}
      getOptionLabel={e => e.name}
      getOptionValue={e => e.uuid}
      loadOptions={promiseOptions}
      onChange={_onChange}
    />
  )
};

UserSearch.defaultProps = {
  initialValue: undefined,
  onChange: () => {}
};

UserSearch.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func
};

export {
  UserSearch
}
