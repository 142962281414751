import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'carbon-components-react';

import { closeModal } from 'src/modules/actions/modal.actions';

const ModalWindow = ({ open, options: { body, onRequestSubmit, ...rest }, ...actions }) => {

  return (
    <Modal
      aria-label=''
      open={open}
      onRequestClose={actions.closeModal}
      onSecondarySubmit={actions.closeModal}
      primaryButtonText='Ok'
      secondaryButtonText='Cancel'
      onRequestSubmit={onRequestSubmit}
      {...rest}
    >
      {body && body}
    </Modal>
  )
};

ModalWindow.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    body: PropTypes.string,
    onRequestSubmit: PropTypes.func
  }),
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    open: state.modal.open,
    options: state.modal.options
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow);
