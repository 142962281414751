import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, InlineNotification, Loading, Modal, FormGroup } from 'carbon-components-react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import cn from 'classnames';
import classes from './profile-page.module.scss';
import cs from './ProfilePage.module.scss';
import common from 'src/layout/form/common.module.scss';
import ContentLayout from 'src/layout/ContentLayout';
import InputText from 'src/components/_shared/form/InputText';
import InputArea from 'src/components/_shared/form/InputArea';
import { updateProfile, deleteAccount } from 'src/modules/actions/authentication.actions';
import { fetchProfile, clearProfileData } from 'src/modules/actions/profile.actions';
import RowWrapper from 'src/layout/form/RowWrapper';
import InputImage from 'src/components/_shared/form/InputImage';
import { RES_PATH } from 'src/config/environment';
import { LinkItem } from '../BookPage/LinkItem';
import * as linksApi from 'src/modules/api/links.api';
import { useState } from 'react';
import ModalForm from 'src/components/_shared/form/ModalForm';
import { useCallback } from 'react';
import { ModalPortal } from 'src/components/_shared/ModalPortal';
import history from 'src/history';

const required = value => (value ? undefined : 'Required');

export const ProfilePage = ({ match, loading, account, profile, ...actions }) => {
  let submitTrigger = null;
  const [links, setLinks] = useState();
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const fetchAction = actions.fetchProfile;
  const clearAction = actions.clearProfileData;
  useEffect(() => {
    fetchAction();
    return () => { clearAction(); }
  }, [fetchAction, clearAction]);

  useEffect(() => {
    if (profile.links) {
      setLinks(profile.links)
    }
  }, [profile.links, setLinks]);

  const deleteAccount = useCallback(() => {
    actions.deleteAccount();
    setDeleteModalVisible(false);
  }, [actions]);
  return (
    <>
      <Loading active={loading} />
      <ContentLayout
        title="Profile"
        renderAction={() => 
          <div className={classes.actionButtons}>
            <Button disabled={loading} onClick={() => submitTrigger()}>
            Save
            <Loading small={true} withOverlay={false} active={loading}/>
            </Button>
            <Button className={classes.publicView} kind='tertiary' onClick={() => history.push(`/writers/${profile.uuid}`)}>
            Public View
            </Button>
          </div>
        }
      >
        { !loading && profile &&
          <Form
            initialValues={{ email: profile.email, name: profile.name, about: profile.about }}
            onSubmit={ (values) => actions.updateProfile(profile.uuid, values, profile.photo, match.path) }
            render={({ handleSubmit, submitting, submitError, pristine, values }) => {
              submitTrigger = handleSubmit;
              const disable = submitting || loading;

              return (
                <>
                  <form onSubmit={handleSubmit} className={cs.container}>

                    {/* <div className={cn('bx--grid', cs.half, cs.avatarContainer)}>
                      <Field
                        id='photo'
                        name='photo'
                        component={InputImage}
                        disabled={disable}
                        initialSrc={profile && profile.photo && RES_PATH + profile.photo}
                        width={100}
                        height={100}
                        scaledWidth={200}
                        scaledHeight={200}
                        imagePlaceholder='user.svg'
                        saveOriginalProportion
                      />
                    </div> */}
                    <div className={cn('bx--grid', cs.half)}>
                      <div className='bx--row'>
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Name*
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                          <Field
                            id='name'
                            name='name'
                            component={InputText}
                            validate={required}
                            disabled={disable}
                          />
                        </div>
                      </div>

                      { (account.writer || match.path.endsWith('become')) &&
                        <div className='bx--row'>
                          <div
                            className={cn(
                              'bx--col-lg-4',
                              'bx--col-md-2',
                              common.fieldTitle
                            )}
                          >
                            About*
                          </div>
                          <div className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                          >
                            <Field
                              id='about'
                              name='about'
                              component={InputArea}
                              validate={required}
                              disabled={disable}
                            />
                          </div>
                        </div>
                      }
                  <div
                        className={cn('' +
                          'bx--row',
                          classes.changeContainer
                        )}
                      >
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Picture*
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                        <FormGroup legendText="Only .jpg and .png files. 500kb max file size">
                        <Field
                              id='photo'
                              name='photo'
                              component={InputImage}
                              initialSrc={profile && profile.photo && RES_PATH + profile.photo}
                              width={100}
                              height={100}
                              scaledWidth={200}
                              scaledHeight={200}
                              // imagePlaceholder='user.svg'
                              saveOriginalProportion
                            />
                        </FormGroup>              
                        </div>
                      </div>

                      <div
                        className={cn('' +
                          'bx--row',
                          classes.changeContainer
                        )}
                      >
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Email*
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                          <Field
                            name='email'
                            component={InputText}
                            validate={required}
                            disabled={disable}
                          />
                        </div>
                      </div>
                    <div
                        className={cn('' +
                          'bx--row',
                          classes.changeContainer
                        )}
                      >
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Links
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                          {links?.map(item => 
                            (<LinkItem 
                              href={item.link}
                              note= {item.type}
                              onDelete={() => {
                                linksApi.deleteLink(item.uuid).then(()=> {
                                  setLinks(links.filter(obj => item.uuid !== obj.uuid));
                                })
                              }}
                              showCopy={false}
                            />)
                          )}
                          <div>
                            <Button kind="secondary" size="sm" onMouseUp={()=>setAddLinkModal(true)}>Add Link</Button>
                          </div>
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6',
                            cs.link,
                          )}
                        >
                          <a style={{cursor: 'pointer'}} onClick={() => setDeleteModalVisible(true)}>Delete Account</a>
                        </div>
                      </div>
                    </div>
                  </form>
                  {submitError &&
                  <div>
                    <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                  </div>
                  }
                </>
              )
            }}
          />
        }
          <DeleteAccountModal 
          open={deleteModalVisible} 
          onClose={() => setDeleteModalVisible(false)}
          onSubmit={deleteAccount}
          />
          <ModalForm
            modalHeading = "Add a link"
            action = "Add"
            open = {addLinkModal}
            properties={[
              { title: "Link", type: "text", id: "link"},
              {
                title: "Type", type: "dropdown", id: "type", 
                default: "web",
                dropdown: {
                  options: [
                    { id: "web", labelText: "Web", value: "web" },
                    { id: "twitter", labelText: "Twitter", value: "twitter" },
                    { id: "instagram", labelText: "Instagram", value: "instagram" },
                    { id: "pinterest", labelText: "Pinterest", value: "pinterest" },
                    { id: "patreon", labelText: "Patreon", value: "patreon" },
                  ]
                }
              },
            ]}
            cancel={()=> setAddLinkModal(false)}
            submit={(value) => {
              linksApi.postLink(value.link, value.type).then(() => {
                linksApi.getLinks().then((response) =>{
                  if (response.status === 200) {
                    setLinks(response.data.items);
                    setAddLinkModal(false);
                  }
                })
              })
            }}
          />
          </ContentLayout>
    </>
  )
};

const DeleteAccountModal = ({ open, onClose, onSubmit }) => {
  return (
    <ModalPortal>
      <Modal
        aria-label=''
        open={open}
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        primaryButtonText='Delete Account'
        secondaryButtonText='Cancel'
        modalHeading='Are you sure you want to delet your account?'
      >
        <p className={'bx--modal-content__text'}>
          You will not be able to access your books and receive notifications.
        </p>
      </Modal>
    </ModalPortal>
  )
}

ProfilePage.propTypes = {
  loading: PropTypes.bool.isRequired,
  account: PropTypes.shape({
    writer: PropTypes.bool
  }).isRequired,
  updateProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  profile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    about: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string
  }),
  fetchProfile: PropTypes.func.isRequired,
  clearProfileData: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.profile.loading,
    avatar: state.profile.avatar, // ???
    account: state.authentication.account,
    profile: state.profile.data
  }
}

export default connect(mapStateToProps, { updateProfile, deleteAccount, fetchProfile, clearProfileData })(ProfilePage);
