import React from 'react';
import PropTypes from 'prop-types';

import cs from './ProgressOverlay.module.scss';

const ProgressOverlay = ({ status, cancelCheckingStatus }) => {

  return (
    <>
      { status?.state === 'inProgress' ?
        <div className={cs.overlay} onClick={cancelCheckingStatus}>
          <div className={cs.container}>
            <div className={cs.textContainer}>
              Please wait,<br/>
              our AI is generating an audio file
            </div>
            <div className={cs.progressContainer}>
              <div className={cs.progress} style={{ width: `${status.progress * 100}%`}}/>
            </div>
          </div>
        </div> : null
      }
    </>
  )
}

ProgressOverlay.defaultProps = {
  status: {}
}

ProgressOverlay.propTypes = {
  status: PropTypes.shape({
    state: PropTypes.string,
    progress: PropTypes.number
  }),
  cancelCheckingStatus: PropTypes.func
}

export default ProgressOverlay;
