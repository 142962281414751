import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'carbon-components-react';

import classes from './styles.module.scss';
import { resizeImage } from 'src/modules/api/utils';

import '@pqina/pintura/pintura.css';
import { getEditorDefaults, createDefaultImageWriter } from '@pqina/pintura';
import { PinturaEditor } from '@pqina/react-pintura';

export const InputImage = ({ input, initialSrc, width, height, scaledWidth, scaledHeight, imagePlaceholder, saveOriginalProportion}) => {

  const aspectRatio = scaledWidth / scaledHeight;
  const [imagePath, setImagePath] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const defaultImage = process.env.PUBLIC_URL + '/' + imagePlaceholder;

  const onSave = async (props) => {
    console.log(`props: ${JSON.stringify(props)}`);
  }

  const editorConfig = getEditorDefaults({
    imageWriter: createDefaultImageWriter({
      // Scale all input canvas data to fit a 4096 * 4096 rectangle
      canvasMemoryLimit: 4096 * 4096,

      // Fix image orientation
      orientImage: true,

      // Don't retain image EXIF data
      copyImageHead: false,

      // Convert all input images to jpegs
      mimeType: 'image/jpeg',

      // Reduce quality to 50%
      quality: 0.8,

      store: (state, options, onprogress) =>
        new Promise((resolve, reject) => {
          const { dest } = state;
          resizeImage(dest, scaledWidth, scaledHeight).then((data)=>{
            setImage(data);
            input.onChange(data);
            setOpen(false);
          })
        }),
      // Limit size of output to this size
      targetSize: {
          width: scaledWidth,
          height: scaledHeight,
          fit: 'contain',
          upscale: false,
      },

      // Show all output props
      outputProps: [],
    }),
  });

  const handleChange = async e => {
    const file = e.target?.files?.[0];
    if (file) {
      setImagePath(file);
      setOpen(true)
    }
  };

  return (
    <div>

      <label className={classes.inputLabel}>
        <input
          {...input}
          type='file'
          onChange={handleChange}
          className={classes.inputFile}
          value={undefined}
          accept="image/x-png,image/jpeg"
        />

        <div style={{
          width,
          height,
          backgroundImage: `url(${image || initialSrc || defaultImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer'
        }}
        />
      </label>
      <Modal
        // size='xs'
        open={open}
        passiveModal
        onRequestClose={() => setOpen(false)}
        // onRequestSubmit={onSubmit}
        // primaryButtonText='Save'
      >
          <div className="App" style={{ height: '100%', minHeight: '60vh' }}>
              <PinturaEditor
                  {...editorConfig}
                  src={imagePath}
                  imageCropAspectRatio={aspectRatio}
                  // onProcess={onSave}
                  // imageWriter={onSave}

                ></PinturaEditor>
          </div>
      </Modal>

    </div>
  )
};

InputImage.defaultProps = {
  imagePlaceholder: 'upload.svg',
  aspectRatio: 0.72,
  saveOriginalProportion: false
}

InputImage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  scaledWidth: PropTypes.number.isRequired,
  scaledHeight: PropTypes.number.isRequired,
  imagePlaceholder: PropTypes.string,
  saveOriginalProportion: PropTypes.bool
}

export default InputImage;
