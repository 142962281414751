import React from 'react';
import { Switch } from 'react-router';

import PrivateRoute from 'src/components/auth/PrivateRoute';
import AddBookPage from 'src/components/author/AddBookPage';
import MyBooksPage from 'src/components/author/MyBooksPage';
import BookPage from 'src/components/author/BookPage';
import AddChapter from 'src/components/author/AddChapter';
import {
  MakeBookVisibleModal,
  useBookVisibleAttention
} from 'src/components/author/make-book-visible';
import { PersonasForm } from 'src/components/author/add-personas';
import { DeletePersonaDialog } from 'src/components/author/delete-persona-dialog';
import { DeleteBGSoundDialog } from 'src/components/author/delete-bgsound-dialog';
import { AddSoundForm } from 'src/components/author/add-sound';

export const AuthorRoutes = ({ match }) => {
  const bookVisibleCtx = useBookVisibleAttention();

  return (
    <>
      <Switch>
        <PrivateRoute path={`${match.url}/:bookUuid/chapter/add`}
                      component={AddChapter}
                      openMakeBookVisibleAttention={bookVisibleCtx.openMakeBookVisibleAttention}
        />
        <PrivateRoute path={`${match.url}/:bookUuid/chapter/:chapterUuid/edit`}
                      component={AddChapter}
                      openMakeBookVisibleAttention={bookVisibleCtx.openMakeBookVisibleAttention}
        />
        <PrivateRoute path={`${match.url}/:uuid/edit`} component={AddBookPage} />
        <PrivateRoute path={`${match.url}/add`} exact component={AddBookPage} />
        <PrivateRoute path={`${match.url}/:id`} component={BookPage} />
        <PrivateRoute component={MyBooksPage} />
      </Switch>
      <MakeBookVisibleModal {...bookVisibleCtx}/>
      <PersonasForm />
      <AddSoundForm />
      <DeletePersonaDialog />
      <DeleteBGSoundDialog />
    </>
  )
};

export default AuthorRoutes;

