import { useEffect } from 'react';

import { DEFAULT_TITLE } from 'src/config/constants';

export const useBookTitle = (book) => {
  const name = book?.name;
  const author = book?.author?.name;

  return useEffect(() => {
    if (name && author) {
      document.title = `Fictionate.me: ${name} by ${author}`;
    }

    return () => {
      document.title = DEFAULT_TITLE;
    }
  });
};
