import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cs from './Player.module.scss';
import { CloseIcon } from './icons/CloseIcon';
import { DownloadIcon } from './icons/DownloadIcon';
import { TrashIcon } from './icons/TrashIcon';
import { NextIcon } from './icons/NextIcon';
import { PlayerPlayIcon as PlayIcon } from './icons/PlayerPlayIcon';
import { PrevIcon } from './icons/PrevIcon';
import classNames from 'classnames';
import { PauseOutlineFilled32 } from '@carbon/icons-react';
import { formatDuration } from 'src/utils/duration';
import { throttle } from 'src/utils';
import { getSeekerPercentClick } from 'src/utils/player';
import { ModalPortal } from '../_shared/ModalPortal';
import { Modal } from 'carbon-components-react';

export const Player = ({ src, onClose, hasNext, hasPrev, onPrev, onNext, onDelete, onDownload }) => {
  const audioEl = useRef();
  const [canPlay, setCanPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [rest, setRest] = useState(null);
  const [percent, setPercent] = useState(0);
  const [deleteShow, setDeleteShow] = useState(false);

  useEffect(() => {
    const audio = audioEl.current;

    if (!audio) return;

    audio.volume = 1.0;

    const onCanPlayThrough = () => {
      setCanPlay(true);
    }

    const onCanPlay = () => {
      setCanPlay(true);
    }

    const onPlay = () => {
      setPlaying(true);
      audio.autoplay = true;
    }

    const onTimeUpdate = throttle((e) => {
      const audio = e.target;
      const currentTime = audio.currentTime;
      const duration = audio.duration;

      setPlayed(currentTime);

      if (Number.isFinite(duration)) {
        setRest(duration - currentTime);
        setPercent(currentTime * 100 / duration);
      }
    }, 1000);

    const onEnded = () => {
      setPlaying(false);
      setPercent(0);
      audio.autoplay = false;
    }

    const onPause = () => {
      setPlaying(false);
      audio.autoplay = false;
    }

    const onLoadedMetadata = (e) => {
      const duration = e.target.duration;
      if (Number.isFinite(duration)) {
        setRest(duration);
      }
    }

    audio.addEventListener('canplaythrough', onCanPlayThrough);
    audio.addEventListener('canplay', onCanPlay);
    audio.addEventListener('play', onPlay);
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', onEnded);
    audio.addEventListener('pause', onPause);
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    return () => {
      if (audio) {
        audio.removeEventListener('canplaythrough', onCanPlayThrough);
        audio.removeEventListener('canplay', onCanPlay);
        audio.removeEventListener('play', onPlay);
        audio.removeEventListener('timeupdate', onTimeUpdate);
        audio.removeEventListener('ended', onEnded);
        audio.removeEventListener('pause', onPause);
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    }
  }, [audioEl]);

  useEffect(() => {
    setRest(null);
    setPercent(0);
    setPlayed(0);
    setCanPlay(false);
    setPlaying(false);
  }, [src]);

  const handleConfirmDelete = () => {
    toggleDeleteShow();
    onDelete();
  }

  const toggleDeleteShow = () => {
    setDeleteShow(!deleteShow);
  }

  const handleSeekerClick = (e) => {
    const audio = audioEl.current;

    if (audio?.duration) {
      const clickPercent = getSeekerPercentClick(e);
      audio.currentTime = clickPercent > 0 ? (audio.duration / 100 * clickPercent) : 0;
    }
  }

  const handlePlayClick = (e) => {
    e.stopPropagation();
    if (canPlay) {
      audioEl.current.play();
    }
  }

  const handlePause = (e) => {
    e.stopPropagation();
    audioEl.current.pause();
  }

  const handlePrev = () => {
    if (hasPrev) {
      onPrev();
    }
  }

  const handleNext = () => {
    if (hasNext) {
      onNext();
    }
  }

  const timePlayed = formatDuration(played);
  const timeRest = rest ? `- ${formatDuration(rest)}` : '--';
  const percentPlayed = `${percent}%`;

  return (
    <div className={cs.player}>
      <div className={cs.buttons} >
        <div>
          <div onClick={toggleDeleteShow}>
            <TrashIcon/>
          </div>
        </div>
        <div>
          <a href={`${src}?d=true`} download="myFile">
            <DownloadIcon/>
          </a>
          <div onClick={onClose}>
            <CloseIcon/>
          </div>
        </div>
      </div>

      <audio
        autoPlay={false}
        controls={false}
        loop={false}
        preload='metadata'
        src={src}
        ref={audioEl}
      />

      <div className={cs.controlsContainer}>
        <div className={cs.controls}>

          <div className={classNames({ [cs.disabled]: !hasPrev })} onClick={handlePrev}>
            <PrevIcon />
          </div>

          {playing ? (
            <div className={classNames(cs.play)} onClick={handlePause}>
              <PauseOutlineFilled32 width={56} height={56} />
            </div>
          ) : (
            <div className={classNames(cs.play, {[cs.disabled]: !canPlay})} onClick={handlePlayClick}>
              <PlayIcon/>
            </div>
          )}

          <div className={classNames({ [cs.disabled]: !hasNext })} onClick={handleNext}>
            <NextIcon />
          </div>

        </div>
      </div>

      <div className={cs.seekerContainer}>

        <div className={cs.seekerWrap} onClick={handleSeekerClick}>
          <div className={cs.seeker}>
            <div className={cs.time} style={{ width: percentPlayed }}/>
          </div>
        </div>

        <div className={cs.times}>
          <div>{timePlayed}</div>
          <div>{timeRest}</div>
        </div>
      </div>

      <DeleteModal open={deleteShow} onClose={toggleDeleteShow} onSubmit={handleConfirmDelete}/>
    </div>
  );
}

const DeleteModal = ({ open, onClose, onSubmit }) => {
  return (
    <ModalPortal>
      <Modal
        aria-label=''
        open={open}
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        primaryButtonText='Delete'
        secondaryButtonText='Cancel'
        modalHeading='Confirm deletion'
      >
        <p className={'bx--modal-content__text'}>
          Delete this audio file?
        </p>
      </Modal>
    </ModalPortal>
  )
}

Player.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
}
