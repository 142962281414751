export const ACTION_TYPES = {
  FETCH_CHAPTER_PENDING: 'chapters/FETCH_CHAPTER_PENDING',
  FETCH_CHAPTER_FULFILLED: 'chapters/FETCH_CHAPTER_FULFILLED',
  FETCH_CHAPTER_REJECTED: 'chapters/FETCH_CHAPTER_REJECTED',

  FORGET_CHAPTER: 'chapters/FORGET_CHAPTER',

  DELETE_CHAPTER_PENDING: 'chapters/DELETE_CHAPTER_PENDING',
  DELETE_CHAPTER_FULFILLED: 'chapters/DELETE_CHAPTER_FULFILLED',
  DELETE_CHAPTER_REJECTED: 'chapters/DELETE_CHAPTER_REJECTED',
};

const defaultState = {
  loading: false,
  chapter: null
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CHAPTER_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_CHAPTER_FULFILLED:
      return {
        ...state,
        loading: false,
        chapter: action.chapter
      };
    case ACTION_TYPES.FETCH_CHAPTER_REJECTED:
      return {
        ...state,
        loading: false,
        chapter: null
      };
    case ACTION_TYPES.FORGET_CHAPTER:
      return {
        ...state,
        chapter: null
      };
    default:
      return state;
  }
}
