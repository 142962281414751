import React from 'react';
import PropTypes from 'prop-types';
import { Tile } from 'carbon-components-react';
import classNames from 'classnames';

import history from 'src/history';
import cs from './styles.module.scss';
import { RES_PATH } from 'src/config/environment';
import ReadButton from 'src/components/reader/BookPage/ReadButton';
import ListenButton from 'src/components/_shared/ListenButton';

export const ContinueReadingItem = (props) => {
  const {item: {book, chapter}} = props;

  const goReading = () => {
    history.push(`/books/${book.uuid}/read`);
  };

  const goListening = () => {
    const chapterId = book.progress?.chapter;
    history.push(`/listen/${book.uuid}` + (chapterId ? `/${chapterId}` : ''));
  }

  const { progress: { progress = 0 } = {}} = book;
  const percent = progress * 100;

  return (
    <Tile className={classNames(cs.tile, cs.designed)}>
      <div className={cs.main}>
        <div style={{ backgroundImage: `url(${RES_PATH}${book.image})`}}
             className={cs.cover}
        />

        <div className={cs.desc}>
          <div className={cs.title}>
            {book.name}
          </div>
          <div className={cs.chapter}>
            {chapter.name}
          </div>
        </div>
      </div>

      <div className={cs.progress}>
        <div className={cs.done} style={{flexBasis: percent + '%'}} />
        <div className={cs.rest} />
      </div>

      <div className={cs.fade}>
        <div>
          {book.textAvailable &&
            <ReadButton className={cs.readButton} book={book} onClick={goReading}/>}
          {(book.narration !== 'none') &&
            <ListenButton className={cs.readButton} book={book} onClick={goListening}/>}
        </div>
      </div>
    </Tile>
  )
};

ContinueReadingItem.propTypes = {

  item: PropTypes.shape({
    chapter: PropTypes.shape({
      name: PropTypes.string
    }),

    book: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      uuid: PropTypes.string,
      progress: PropTypes.shape({
        progress: PropTypes.number
      }),
    }),

    author: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      imagePath: PropTypes.string
    })
  }).isRequired

};

export default ContinueReadingItem;
