import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

//   loadOptions: (inputValue: string, callback: (options: []) => void) => {}
const InputSearch = ({initialValue, onChange, loadOptions, label}) => {
  const [initState, setInitState] = useState(initialValue);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== initState) {
      setValue(initialValue);
      setInitState(initialValue);
    }
  }, [initialValue, initState]);

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        backgroundColor: '#ffffff',
        border: 0,
        borderBottom: '1px solid #6f6f6f',
        color: '#ff0000',
        outline: 0,
        boxSizing: 'border-box',
        boxShadow: state.isFocused ? '0 0 0 1px #fff' : 0,
        '&:hover': {
          borderBottom: 'auto',
        }
    }),
    input: (provided) => ({ ...provided, color: '#f4f4f4' }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: '#262626'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#161616' : '#f4f4f4',
      backgroundColor: state.isFocused ? '#e5e5e5' : '#262626',
      padding: 16,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '& svg': {
        fill: '#111111'
      }
    })
  };

  return (
    <AsyncSelect
      cacheOptions
      styles={customStyles}
      loadOptions={(text, callback) => {
        loadOptions(text, (result) => {
          callback(result.map(item => ({label: label(item), data: item})));
        })
      }}
      defaultOptions
      onChange={(value) => {
        onChange(value.data)
      }}
    />
  )
};

InputSearch.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  loadOptions: PropTypes.func
};

export default InputSearch;