import React from 'react';
import { Switch, Route } from 'react-router';

import AppLayout from 'src/layout/AppLayout';
import PrivateRoute from 'src/components/auth/PrivateRoute';
import BookPage from 'src/components/reader/BookPage';
import ReadingPage from 'src/components/reader/ReadingPage';
import NotFoundPage from 'src/components/_shared/NotFound';
import ReadImmediately from 'src/components/reader/ReadImmediately';

const ReaderRoutes = ({ match }) => {

  return (
    <AppLayout>
      <Switch>
        <Route path={`${match.url}/:bookUuid/read`} component={ReadImmediately} />
        <Route path={`${match.url}/:bookUuid/:chapterUuid`} component={ReadingPage} />
        <Route path={`${match.url}/:bookUuid`} component={BookPage} />
        <PrivateRoute component={NotFoundPage} />
      </Switch>
    </AppLayout>
  )
};

export default ReaderRoutes;
