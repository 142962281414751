import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Loading, InlineNotification, Breadcrumb, BreadcrumbItem, Toggle } from 'carbon-components-react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import common from 'src/layout/form/common.module.scss';
import styles from './styles.module.scss';
import { RES_PATH } from 'src/config/environment';
import { submitBookForm } from 'src/modules/actions/writers.actions';
import { fetchBook, forgetBook, fetchSeries, deleteSeries } from 'src/modules/actions/books.actions';
import * as Book from 'src/modules/model/book';
import Layout, { actionsCommon } from 'src/layout/ContentLayout';
import InputText from 'src/components/_shared/form/InputText';
import InputArea from 'src/components/_shared/form/InputArea';
import RowWrapper from 'src/layout/form/RowWrapper';
import InputImage from 'src/components/_shared/form/InputImage';
import InputSelect from 'src/components/_shared/form/InputSelect';
import InputDropdown from 'src/components/_shared/form/InputDropdown';
import { TagSearch } from 'src/components/author/AddBookPage/tag-search';
import { useStripePayouts } from 'src/hooks';
import PayoutTip from 'src/components/_shared/PayoutTip';
import { Add20, Delete20, Edit20, Delete24} from '@carbon/icons-react';
import EditSeries from './EditSeries'
import { UserSearch } from './Components/UserSearch';

const required = value => (value ? undefined : 'Required');
const requiredRating = value => ((value === "nr") ? 'Required' : undefined);

const Crumbs = ({ book, loading }) => (
  <Breadcrumb className={actionsCommon.breadcrumbContainer}>
    <BreadcrumbItem className={actionsCommon.breadcrumbItem}>
      <Link to='/my-books'>My Books</Link>
    </BreadcrumbItem>
    {(book && !loading) &&
      <BreadcrumbItem className={actionsCommon.breadcrumbItem}>
        <Link to={`/my-books/${book.uuid}`}>
          {book.name}
        </Link>
      </BreadcrumbItem>
    }
  </Breadcrumb>
);

export const AddBookPage = ({ match, submitBookForm, fetchBook, fetchSeries, deleteSeries, forgetBook, book, series, subscriptions, loading, email, account, ...rest }) => {
  const { payoutState, configurePayouts } = useStripePayouts();

  let submitTrigger = null;

  useEffect(() => {
    fetchSeries()
  }, [fetchSeries]);

  useEffect(() => {
    const {params: { uuid } = {}} = match;
    if (uuid) {
      fetchBook(uuid);
    }

    return () => {
      forgetBook();
    };
  }, [fetchBook, forgetBook, match]);

  const title = (book && book.name) || 'New Book';
  const [ratingString, setRatingString] = React.useState('');
  const [pricing, setPricing] = React.useState('free');
  const [narrationString, setNarrationString] = React.useState('');
  const [selectedSeriesUUID, setSelectedSeriesUUID] = React.useState(null);
  const [editAction, setEditAction] = React.useState(false);

  console.log(`Loaded Book`);
  useEffect(() => {
    if (book && book.rating && book.rating !== "nr") {
      setRatingString(Book.RATING_OPTIONS.find( element => element.id === book.rating).desc ?? "");
    }
    if (book && book.pricing) {
      setPricing(book.pricing.pricing);
    }
    if (book && book.narration) {
      setNarrationString(Book.NARRATION_OPTIONS.find( element => element.id === book.narration).desc ?? "");
    }
    if (book?.bookSeries === null || book?.bookSeries === '' || book?.bookSeries === undefined) {
      setSelectedSeriesUUID("$unset")
    } else {
      setSelectedSeriesUUID(book?.bookSeries?.uuid)
    }
  }, [book]);
  const [open, setOpen] = useState(false);

  return (
    <>
    <Layout
      title={title}
      renderAction={() => <Button disabled={loading} onClick={() => submitTrigger()}>Save</Button>}
      breadcrumb={<Crumbs book={book} loading={loading}/>}
    >

      <Form
        initialValues={Book.formInitialValues(book)}
        onSubmit={submitBookForm}
        subscription={{ submitting: true, pristine: true, submitError: true }}
        render={({ handleSubmit, form, submitting, submitError, pristine, values }) => {
          submitTrigger = handleSubmit;
          const disable = submitting || loading;
          return (
            <>
              {disable && <Loading small={true} />}
              <form onSubmit={handleSubmit}>

                <div className="bx--grid bx--grid--full-width">
                  <RowWrapper title='Name*'>
                    <Field
                      id='name'
                      name='name'
                      validate={required}
                      component={InputText}
                      placeholder="Type your book's name"
                      disable={disable}
                    />
                  </RowWrapper>


                  <RowWrapper title="Synopsis*">
                    <Field
                      id='about'
                      name='about'
                      validate={required}
                      component={InputArea}
                      placeholder="Type a short book description"
                      disabled={disable}
                    />
                  </RowWrapper>

                  <RowWrapper title='Visible'>
                    <FormGroup legendText="">
                      <Field
                        id='published'
                        name='published'
                        type='checkbox'
                        component={({ input }) =>
                          <Toggle { ... input }
                                  labelA='Visible only to author'
                                  labelB='Visible to general audience'
                                  className={styles.toggle}
                          /> }
                        disabled={disable}
                      />
                    </FormGroup>
                  </RowWrapper>
                  <RowWrapper title='Completed'>
                    <FormGroup legendText="">
                      <Field
                        id='completed'
                        name='completed'
                        type='checkbox'
                        component={({ input }) =>
                          <Toggle { ... input }
                                  labelA='Book is still in progress'
                                  labelB='Book is completed'
                                  className={styles.toggle}
                          /> }
                        disabled={disable}
                      />
                    </FormGroup>
                  </RowWrapper>
                  <RowWrapper title='AI Narration'>
                    <FormGroup legendText="">
                      <Field name='narration'
                            defaultValue="automatic"
                            disabled={disable}
                            component={InputDropdown}
                            options={Book.NARRATION_OPTIONS}
                            onChange={ event => {
                              setNarrationString(Book.NARRATION_OPTIONS.find( element => element.id === event.target.value).desc)
                            }}
                            >
                     </ Field>
                     <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
                       {narrationString}
                     </div>
                    </FormGroup>
                  </RowWrapper>
                  <RowWrapper title='Book Text Available'>
                    <FormGroup legendText="">
                      <Field id='textAvailable'
                             name='textAvailable'
                             type='checkbox'
                             component={({ input }) =>
                               <Toggle {...input}
                                       labelA=''
                                       labelB=''
                                       className={styles.toggle}
                               /> }
                             disabled={disable}
                      />
                    </FormGroup>
                  </RowWrapper>
                  <RowWrapper title='Custom Book Styles'>
                    <FormGroup legendText="">
                      <Field id='customStyles'
                             name='customStyles'
                             type='checkbox'
                             component={({ input }) =>
                               <Toggle {...input}
                                       labelA=''
                                       labelB=''
                                       className={styles.toggle}
                               /> }
                             disabled={disable}
                      />
                    </FormGroup>
                  </RowWrapper>
                  <PricingRow 
                    disable={disable}
                    setPricing={setPricing}
                    pricing={pricing}
                    payoutState={payoutState}
                    created={book?.uuid !== undefined}
                    subscriptions={subscriptions}
                    configurePayouts={() => configurePayouts(email)}
                  />
                  <RowWrapper title='Rating*'>
                    <Field name='rating'
                           style={{ width: '100%' }}
                           validate={requiredRating}
                           defaultValue="nr"
                           disabled={disable}
                           component={InputDropdown}
                           onChange={ event => {
                            console.log(`Pricing ${event.target.value}`);
                            setRatingString(Book.RATING_OPTIONS.find( element => element.id === event.target.value).desc)
                           }}
                           options={Book.RATING_OPTIONS}
                           >
                    </ Field>
                    <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
                      {ratingString}
                    </div>
                  </RowWrapper>
                  { series &&
                    <RowWrapper title='Book Series'>
                      <div style={{ width:"calc(100% - 170px)", minWidth: "200px", display:"inline-block"}}>
                        <Field name='bookSeries'
                              style={{ width: '100%' }}
                              defaultValue="$unset"
                              component={InputDropdown}
                              onChange={ event => {
                                setSelectedSeriesUUID(event.target.value)
                              }}
                              options={(() => {
                                  var array = [{
                                    "id": "$unset",
                                    "labelText": "No Series Selected",
                                    "value": "$unset",
                                    "desc": ""
                                  }]
                                  array = array.concat(series.map((item, i) =>
                                  ({
                                    "id": item.uuid,
                                    "labelText": item.name,
                                    "value": item.uuid,
                                    "desc": item.about
                                  })
                                ))
                                
                                return array;
                              })() }
                              >
                        </ Field>
                      </ div>
                      <Button 
                      style={{marginTop: "12px", height: "40px"}} kind="ghost"
                      onClick={() => {
                        setEditAction(false);
                        setOpen(true);
                      }}>
                      <Add20/>
                    </ Button>
                    <div style={{display: (selectedSeriesUUID === "$unset") ? "none" : "initial"}}>
                        <Button 
                          style={{marginTop: "12px", height: "40px"}} kind="ghost"
                          onClick={() => {
                            deleteSeries(selectedSeriesUUID)
                          }}
                          >
                          <Delete20/>
                        </ Button>
                            <Button 
                            style={{marginTop: "12px", height: "40px"}} kind="ghost"
                            onClick={() => {
                              setEditAction(true);
                              setOpen(true)
                            }}>
                            <Edit20/>
                          </ Button>
                    </div>
                    </RowWrapper>
                  }
                  <div style={{display: (selectedSeriesUUID === "$unset") ? "none" : "initial"}}>
                    <RowWrapper title='Book Series Index'>
                      <Field name='bookSeriesIndex'
                            style={{ width: '100%' }}
                            defaultValue="0"
                            disabled={disable}
                            component={InputDropdown}
                            onChange={ event => {
                            }}
                            options={(() => {
                              var array = []
                              for(var i = 0; i < 20; ++i) {
                                array.push({
                                  "id": `${i}`,
                                  "labelText": `${i+1}`,
                                  "value": `${i}`,
                                  "desc": `${i}`
                                })
                              }
                              
                              return array;
                            })() }
                            >
                      </ Field>
                    </RowWrapper>
                  </div>

                  <RowWrapper title="Genre/Tags" titleClass={styles.genreTagLabel}>
                    <FormGroup legendText="">
                      <Field
                        id='tags'
                        name='tags'
                        disabled={disable}
                      >
                        {({input}) => (
                          <TagSearch onChange={input.onChange} initialValue={book?.tags}/>
                        )}
                      </Field>
                    </FormGroup>
                  </RowWrapper>
                  {account.publisher && 
                  <RowWrapper title="Writer" titleClass={styles.genreTagLabel}>
                    <FormGroup legendText="">
                      <Field
                        id='writer'
                        name='writer'
                        disabled={disable}
                      >
                        {({input}) => (
                          <UserSearch onChange={input.onChange} initialValue={book?.author}/>
                        )}
                      </Field>
                    </FormGroup>
                  </RowWrapper>
                  }
                  <RowWrapper title="Book Cover*" titleClass={styles.genreTagLabel}>
                    <FormGroup legendText="Only .jpg and .png files. 500kb max file size. It should be at least 600px wide">
                    <Field
                          id='imageFile'
                          name='imageFile'
                          component={InputImage}
                          initialSrc={book && book.image && RES_PATH + book.image}
                          width={77}
                          height={100}
                          scaledWidth={300}
                          scaledHeight={400}
                          saveOriginalProportion
                        />
                    </FormGroup>
                  </RowWrapper>
                  {submitError &&
                    <div className="bx--row" style={{ position: 'absolute', top: '48px', left: '2rem', zIndex: 5000}}>
                      <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                    </div>
                  }
                </div>

              </form>
            </>
          )
        }}
      />
      </Layout>
      { open && 
      <EditSeries open={open} setOpen={setOpen} uuid={editAction ? selectedSeriesUUID : null} />
      }
    </>
  )
};
const PricingRow = ({disable, setPricing, pricing, payoutState, created, deleteSubscription, subscriptions, configurePayouts}) => {

  console.log(`Subscriptions - ${JSON.stringify(pricing)}, ${created}`);
  return (                 
      <RowWrapper title='Pricing'>
        <FormGroup legendText="">
          <Field id='pricing'
                name='pricing'
                component={InputDropdown}
                options={Book.PRICING_OPTIONS}
                defaultSelected={Book.DEFAULT_PRICING}
                disabled={disable}
                onChange={ event => {
                  console.log(`Pricing ${event.target.value}`);
                  setPricing(event.target.value)
                }}
          />
          { payoutState && <PayoutTip payoutState={payoutState} onClick={configurePayouts} /> }
          {(pricing === 'subscription' && !created/*book.uuid === undefined*/ ) &&
            <InlineNotification kind='warning'
              lowContrast
              hideCloseButton
              title="Create a book"
              subtitle="To add subscription tiers, please finalize the book creation first"
            />
          }
          {pricing === 'fixedBook' &&
            <FormGroup legendText="">
              <Field id='priceTier'
                    name='priceTier'
                    component={InputSelect}
                    options={Book.PRICE_OPTIONS}
                    disabled={disable}
              />
            </FormGroup>
          }
          {pricing === 'writersSubscription' &&
            <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
              Book access will be available only through Writer's subscriptions
            </div>            
          }
          {pricing === 'free' &&
            <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
              Anyone can access this book
            </div>            
          }
        </FormGroup>
      </RowWrapper>)
}

function mapStateToProps(state) {
  return {
    loading: state.books.loading,
    book: state.books.book,
    series: state.books.series,
    subscriptions: state.books.subscriptions,
    email: state.authentication.account.email,
    account: state.authentication.account
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ submitBookForm, fetchBook, fetchSeries, deleteSeries, forgetBook }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBookPage);
