export const TOKEN_KEY = 'authtoken';

export const getCookieByName = (name) => {
  const reg = new RegExp(name + '=([^;]+)');
  const [_, result] = document.cookie.match(reg) || []; // eslint-disable-line no-unused-vars

  return result;
};

export const deleteToken = () => {
  document.cookie = TOKEN_KEY + '=deleted;path=/;expires=0';
};

export const setToken = (token) => {
  document.cookie = TOKEN_KEY + '=' + token + ';path=/;max-age=31540000';
};

export const getAuthToken = () => getCookieByName(TOKEN_KEY);

export const isAuthenticated = () => {
  const token = getAuthToken();
  return !!(token && token !== 'deleted');
};
