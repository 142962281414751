import { useEffect } from 'react';

import {DEFAULT_TITLE} from "../config/constants";

export const useAuthorTitle = (name) => {
  return useEffect(() => {
    if (name) {
      document.title = `Fictionate.me: Novels by ${name}`;
    }

    return () => {
      document.title = DEFAULT_TITLE;
    }
  });
};
