import React from 'react';
import PT from 'prop-types';

import common from 'src/layout/form/common.module.scss';

export const RowWrapper = ({ title, children, titleClass = '', childrenClass = ''}) => (
  <div className="bx--row">
    <div className={`bx--col-lg-2 bx--col-md-2 ${common.fieldTitle} ${titleClass}`} style={{minWidth:"100px"}}>
      {title}
    </div>
    <div className={`bx--col-lg-14 bx--col-md-6 ${childrenClass}`}>
      {children}
    </div>
  </div>
);

RowWrapper.propTypes = {
  title: PT.string,
  children: PT.node,
  titleClass: PT.string,
  childrenClass: PT.string
};

export default RowWrapper;
