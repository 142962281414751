import React from 'react';

export const UpArrowImage = () => {
  return (
    <svg width="50" height="60" viewBox="0 0 50 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="48" height="58" rx="5" stroke="#6A6D73" strokeWidth="2"/>
      <path d="M23.9994 36.9907V25.4451L20.7176 28.7826C20.3174 29.1729 19.6904 29.1729 19.2902 28.7826C18.9033 28.3923 18.9033 27.7464 19.2902 27.3561L24.2929 22.2826C24.6665 21.9058 25.3335 21.9058 25.7071 22.2826L30.7098 27.3561C31.0967 27.7464 31.0967 28.3923 30.7098 28.7826C30.5097 28.971 30.2549 29.0787 30.0028 29.0787C29.7493 29.0787 29.4825 28.971 29.2957 28.7826L26.0006 25.4451V36.9907C26.0006 37.5559 25.5603 38 25 38C24.453 38 23.9994 37.5559 23.9994 36.9907Z" fill="#6A6D73"/>
    </svg>
  )
}
