import React from 'react';

import AppLayout from 'src/layout/AppLayout';
import StaticLayout from './static-layout';

import commonStyles from './common.module.scss';

const ContentGuidelinesPage = () => {
  return (
    <AppLayout>
      <StaticLayout contentClass={commonStyles.staticWrapper}>
        <h1>Content Guidelines</h1>
        Content Guidelines
        The purpose of Content Guidelines is to ensure that Fictioante.me is as inclusive and respectful as possible, as well as reliably consistent in terms of content policy. Any content violating these guidelines may be reported to info@ficionate.me.
        <h2>Story Ratings</h2>
        In order to keep Fictioante.me a safe space for all users, authors/originators must provide a rating for every story—either Mature or for Everyone—in accordance with the guidelines below. Fictioante.me will change any rating not in accordance. Prohibited content is never allowed on the Site no matter the rating and stories containing such content will be removed. Report any incorrectly rated story to info@fictionate.me.
        Similar to the NC-17 rating for movies, Mature stories are intended only for an audience aged 17 and older. Fictioante.me places restrictions on Mature content to ensure only the appropriate audience has access.
        <h2>Mature Content</h2>
        A story that contains any of the following is considered Mature:
        <ul>
        <li>Explicit Sex</li>
        <li>Self-harm (e.g. suicide, eating disorders, etc.)</li>
        <li>Graphic depictions of violence (e.g. abuse of a sexual, verbal, emotional, or physical nature)</li>
        </ul>
        <h2>Content that Will be Removed</h2>
        Fictioante.me reserves the right to without notice, remove, or close the account producing, any content including, but not limited to, the following:
        <ul>
        <li>Sexual Content that is pornographic, glorifying of sexual violence, illegal, or solicitation.</li>
        <ul>
        <li>Pornographic: any content intended solely for sexual stimulation</li>
        <li>Glorifying of sexual violence: encouragement of non-consensual sexual acts, including rape, sexual assault, or slavery, or kidnapping for sexual purposes</li>
        <li>Illegal as mandated by US law</li>
        <ul>
        <li>Age of consent: 16 years at minimum; sexual content must not involve characters under this age</li>
        <li>Sex acts: acts such as bestiality or necrophilia</li>
        </ul>
        <li>Solicitation though the positing of :</li>
        <ul>
        <li>Content encouraging sexual private messages (on or off Site)</li>
        <li>Rules to perform private or public sexual roleplay</li>
        </ul>
        </ul>
        <li>Media, defined as images, videos, gifs, and sound clips, that display:</li>
        <ul>
        <li>Private parts fully exposed (e.g. genitalia, breasts, buttocks)</li>
        <li>Sexual intercourse or acts</li>
        <li>Self-harm</li>
        <li>Images posted without consent of subjects</li>
        <li>Illegal act(s): Fictioante.me places an indicator on any such image no longer accessible to other users.</li>
        </ul>
        <li>Violence intended only to shock or disgust</li>
        <li>Hate/Extremist ideology</li>
        <ul>
        <li>Threatening physical violence or death towards an individual or group</li>
        <li>Glorifying, praising, or romanticizing of real life:</li>
        <ul>
        <li>Groups with a violent mission</li>
        <li>Organized hate groups</li>
        <li>Terrorism</li>
        <li>Murderers (including mass shooters)</li>
        </ul>
        </ul>
        <li>Content related to Users</li>
        <ul>
        <li>Personal information that could identify a user, including private messages and pictures or videos</li>
        <ul>
        <li>Any character obviously based on a user’s real-life identity given the details in the story</li>
        </ul>
        <li>Expression of opinions that</li>
        <ul>
        <li>Are violent in nature</li>
        <li>Encourage attack of other users</li>
        <li>Criticize user(s) for personal vendettas</li>
        <li>Bully, threaten, or harass other users</li>
        </ul>
        <li>Active promotion of violence or hatred on the basis of race, ethnicity, religion, disability, gender, age, or orientation</li>
        </ul>
        <li>Spam, defined as content advertising unaffiliated products or services.</li>
        </ul>
        </StaticLayout>
    </AppLayout>
  )
};

export default ContentGuidelinesPage;
