import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../layout/AppLayout';
import { Paragraph } from './Paragraph';
import cs from './Narration.module.scss';
import classNames from 'classnames';
import { deleteAudio, fetchOrCreateNarration, uploadAudioFile, generate } from 'src/modules/actions/narration.actions';
import * as narrationApi from 'src/modules/api/narration.api';
import { fetchBook } from '../../modules/actions/books.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Loading, Modal, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { Recorder } from './Recorder';
import { Player } from './Player';
import { Uploader } from './Uploader';
import { Progress } from './Progress';
import { Chapters } from './Chapters';
import Href from '../_shared/Href';
import Button from '../_shared/DesignV2/Button';
import CopyLink from './CopyLink';
import queryString from 'query-string';
import { RES_PATH } from 'src/config/environment';
import { PlayAudioButton } from '../_shared/PlayAudioButton';
import { ModalPortal } from '../_shared/ModalPortal';
import history from 'src/history';

const preselectedCharacter = () => {
  return queryString.parse(window.location.search)['character']
}
const Narration = ({
                     match: { params: { chapterId }, path},
                     fetchOrCreateNarration,
                     fetchBook,
                     uploadAudioFile,
                     deleteAudio,
                     isLoading,
                     uploading,
                     uploadProgress,
                     uploadingVoice,
                     uploadingName,
                     generate,
                     narrationId,
                     voices,
                     audio,
                     audioState,
                     characters,
                     bookId,
                     book,
                   }) => {
  const preselectedCharacterId = preselectedCharacter();
  const allCharactersItem = {id: -1, name: "All Characters", about: ""};
  const found = preselectedCharacterId && characters?.find(e => e.id === preselectedCharacterId);
  const [voiceId, setVoiceId] = useState(null);
  const [actionType, setActionType] = useState(null);
  const abortRef = useRef(null);
  const [selectedCharacter, setSelectedCharacter] = useState(allCharactersItem);
  const [hideOthers, setHideOthers] = useState(false);
  const [regenerateModalVisible, setRegenerateModalVisible] = useState(false);
  const [publishModalVisible, setPublishModalVisible] = useState(false);
  const locked = preselectedCharacterId !== undefined
  const [audioStateObject, setAudioStateObject] = useState(audioState)
  const [waiting, setWaiting] = useState(false);

  if(audioStateObject?.state === 'inProgress' && !waiting) {
    setWaiting(true);
    console.log(`Will Fetch`);
    setTimeout(() => {
      console.log(`Will Fetch Now`);
      fetchOrCreateNarration(chapterId, true);
      setWaiting(false);
    }, 2000);
  }

  useEffect(() => {
  if(audioStateObject?.state === 'inProgress' && !waiting) {
      setWaiting(true);
      setTimeout(() => {
        fetchOrCreateNarration(chapterId, true);
      }, 2000);
    }

  }, [audioStateObject, chapterId, fetchOrCreateNarration, waiting]);

  useEffect(() => {
    setAudioStateObject(audioState);
  }, [audioState]);
  
  useEffect(() => {
    if (found !== undefined) {
      setSelectedCharacter(found);
    }
  }, [found]);

  useEffect(() => {
    fetchOrCreateNarration(chapterId);
  }, [chapterId, fetchOrCreateNarration]);

  useEffect(() => {
    if (bookId) {
      fetchBook(bookId);
    }
  }, [bookId, fetchBook]);

  if (isLoading) {
    return <Loading />
  }

  if (!book || !voices) {
    return null;
  }

  const handleCapturedVoiceUpload = ({ audioBlob, audioUrl }) => {
    uploadAudioFile({
      chapterId,
      narrationId,
      voiceId,
      audioBlob,
      audioUrl,
      file: undefined,
      setAbortController: (abortController) => abortRef.current = abortController,
    });
  }

  const handleVoiceUpload = (file) => {
    uploadAudioFile({
      chapterId,
      narrationId,
      voiceId,
      audioBlob: undefined,
      audioUrl: undefined,
      file,
      setAbortController: (abortController) => abortRef.current = abortController,
    });
  }

  const handleAbortUpload = () => {
    const { current: controller } = abortRef;
    if (controller) {
      controller.abort();
    }
  }

  const handleClose = () => {
    setVoiceId(null);
    setActionType(null);
  }

  const handleUpload = (voiceId) => {
    setVoiceId(voiceId);
    setActionType('upload');
  }

  const handleRecord = (voiceId) => {
    setVoiceId(voiceId);
    setActionType('record');
  }

  const handleSelectVoice = (voice) => {
    if (voice === voiceId) {
      setVoiceId(null);
      return;
    }
    setVoiceId(voice);
    setActionType(null);
  }

  const handlePlay = (voiceId) => {
    setVoiceId(voiceId);
    setActionType('play');
  }

  const handleDeleteAudio = () => {
    deleteAudio(narrationId, voiceId);
  }

  const index = voiceId && voices.findIndex(voice => voice.uuid === voiceId);
  const selectedVoice = index >= 0 ? voices[index] : null;
  const chapter = book.chapters.find(c => c.uuid === chapterId);

  const showRecorder = !uploading && selectedVoice?.hasAudio === false && actionType === 'record';
  const showUploader = !uploading && selectedVoice?.hasAudio === false && actionType === 'upload';
  const showPlayer = selectedVoice?.hasAudio === true && actionType === 'play';
  const showProgress = uploading && !showPlayer && uploadingVoice === selectedVoice?.uuid;

  const prevVoice = index > 0 && voices.find((voice, selfIndex) => voice.hasAudio && selfIndex < index);
  const nextVoice = index >= 0 && voices.find((voice, selfIndex) => voice.hasAudio && index < selfIndex);
  let progress = 0
  if (selectedCharacter.id != -1) {
    const filteredVoices = voices.filter((voice) => (voice.characterId ?? '0') === selectedCharacter.id);
    const completedFilteredVoices = filteredVoices.filter((voice) => voice.hasAudio);
    progress = Math.round(100 * completedFilteredVoices.length/filteredVoices.length);
  } else {
    const completedFilteredVoices = voices.filter((voice) => voice.hasAudio);
    progress =  Math.round(100 * completedFilteredVoices.length/voices.length);
  }

  const handlePrevClick = () => {
    !!prevVoice && handlePlay(prevVoice.uuid);
  }

  const handleNextClick = () => {
    !!nextVoice && handlePlay(nextVoice.uuid);
  }

  const onGenerateAudio =  () => {
    generate(narrationId)
  }

  const onPublish =  () => {
    narrationApi.publish(narrationId).then(() => {
      fetchOrCreateNarration(chapterId, true)
      // fetchNarrationAsync();
    });
  }

  const allCharacters = [allCharactersItem, ...characters];
  return (
    <AppLayout>
      <div className={cs.container}>
        <div className={cs.sideContainer}>
          <div>
            <Href href={`/my-books/${bookId}/`} className={cs.bookTitle}>{book.name}</Href>
            <div className={cs.chapter}>
              <div>{chapter.name}</div>
              <Chapters chapters={book.chapters} />
            </div>
            <hr className={cs.lineBreak} />
            <ProgressBar
              value={progress}
            />
            { 
              locked ? 
                <div className={cs.characterName}>{selectedCharacter.name}</div>
              :
              <>
                <CaracterSelection characters={allCharacters} selection={selectedCharacter} changed={setSelectedCharacter}/>
              </>
            }
            {
              selectedCharacter.id !== -1 && 
              <div className={cs.characterLink}>
                {!locked && <CopyLink url={`${window.location.href}?character=${selectedCharacter.id}`} shortening="static"/>}
                <Button 
                  className={cs.hideOthers} 
                  type='normal' 
                  onClick={() => setHideOthers(!hideOthers)}>
                    { hideOthers? 'Show Others' : 'Hide Others' }
                </Button>
              </div>
            }
            {
              selectedCharacter.about.length > 0 &&
              <>
                <div className={cs.descriptionTitle}>description</div>
                <div>{selectedCharacter.about}</div>
              </>
            }
            <div className={cs.buttons}>              
              {!audio &&  (audioStateObject?.state !== 'inProgress') && <Button className={cs.button} type='cta' onClick={onGenerateAudio}>GENERATE</Button>}
              {audio && <Button className={cs.button} type='cta' onClick={() => setRegenerateModalVisible(true)}>REGENERATE</Button>}
              {audio && <Button className={cs.button} type='cta'onClick={() => setPublishModalVisible(true)}>PUBLISH</Button>}
              {audio && <PlayAudioButton src={`${RES_PATH}${audio}`} className={cs.smallButton}/>}
            </div>
            <div>
              {audioStateObject?.state === 'inProgress' && 
              <>
                <ProgressBar value={audioStateObject?.progress * 100} />
                <div className={cs.progressSubtitle}>Generating Audio File</div>
              </>}
            </div>
          </div>
          <div>
            {showRecorder && (
              <Recorder
                onRecord={handleCapturedVoiceUpload}
                isLoading={uploading}
                onClose={handleClose}
              />
            )}

            {showPlayer && (
              <Player
                src={`${RES_PATH}${selectedVoice.source}`}
                onClose={handleClose}
                hasNext={!!nextVoice}
                hasPrev={!!prevVoice}
                onPrev={handlePrevClick}
                onNext={handleNextClick}
                onDelete={handleDeleteAudio}
              />
            )}

            {showUploader && (
              <Uploader onClose={handleClose} onUpload={handleVoiceUpload} />
            )}

            {showProgress && (
              <Progress
                name={uploadingName}
                percent={uploadProgress}
                onAbort={handleAbortUpload}
              />
            )}
          </div>
        </div>


        <div>


          {voices?.map(({ text, characterId, uuid, hasAudio, emotions }, i) => {
            const id = characterId ?? '0'
            const selected = id === selectedCharacter.id;
            
            let style = undefined;
            if (selectedCharacter.id !== -1 && !selected) {
              if (hideOthers === true) { return <></>; }
              else {
                style  = {backgroundColor:'#0F0F0F', color: '#5C5F63'}
              }
            }

            let canChange = true 
            if (locked && characterId !== preselectedCharacterId) {
              canChange = false
            }


            return (
              <Paragraph
                key={uuid}
                uuid={uuid}
                className={classNames({ [cs.spaceY]: i > 0 })}
                text={text}
                badges={emotions}
                selected={voiceId === uuid}
                hasAudio={hasAudio}
                onClick={(voice) => {
                  if (selectedCharacter.id !== -1 && id !== selectedCharacter.id){
                    return;
                  }
                  handleSelectVoice(voice);
                }}
                onPlay={handlePlay}
                onRecord={handleRecord}
                onUpload={handleUpload}
                isUploading={uuid === uploadingVoice}
                canRecord={!uploading && canChange}
                canUpload={!uploading && canChange}
                style={style}
              />
            )

          })}


        </div>
      </div>
      <RegenerateModal 
        open={regenerateModalVisible} 
        onClose={() => setRegenerateModalVisible(false)}
        onSubmit={() => {
          onGenerateAudio();
          setRegenerateModalVisible(false);
        }}/>      
        <PublishModal 
        open={publishModalVisible} 
        onClose={() => setPublishModalVisible(false)}
        onSubmit={() => {
          onPublish();
          setPublishModalVisible(false);
        }}/>
    </AppLayout>
  )
}

const RegenerateModal = ({ open, onClose, onSubmit }) => {
  return (
    <ModalPortal>
      <Modal
        aria-label=''
        open={open}
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        primaryButtonText='Generate'
        secondaryButtonText='Cancel'
        modalHeading='Regenerate Narration File?'
      >
        <p className={'bx--modal-content__text'}>
          Current narration will be overwritten by the newly generated audio file. Are you sure?
        </p>
      </Modal>
    </ModalPortal>
  )
}

const PublishModal = ({ open, onClose, onSubmit }) => {
  return (
    <ModalPortal>
      <Modal
        aria-label=''
        open={open}
        onRequestClose={onClose}
        onRequestSubmit={onSubmit}
        primaryButtonText='Publish'
        secondaryButtonText='Cancel'
        modalHeading='Publish narration?'
      >
        <p className={'bx--modal-content__text'}>
          Currently published narration will be overwritten by this narration. Are you sure?
        </p>
      </Modal>
    </ModalPortal>
  )
}

const ProgressBar = ({value}) => {
return (
  <div className={cs.progressBar}>
    <div className={cs.bar}><div className={cs.content} style={{width: `${value}%`}}/></div>
    <div className={cs.label}>{Math.round(value)}%</div>
  </div>);
};

const CaracterSelection = ({characters, selection, changed}) => {
  return (
      <Dropdown
        className={cs.characterSelection}
        type="inline"
        items={characters}
        itemToString={(item) => item.name}
        selectedItem={selection}
        onChange={(item)=> changed(item.selectedItem)}
      />
    );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.narration.isLoading || state.books.loading,
    voices: state.narration.voices,
    audio: state.narration.audio,
    audioState: state.narration.audioState,
    characters: state.narration.characters,
    bookId: state.narration.book,
    book: state.books.book,
    narrationId: state.narration.uuid,
    uploading: state.narration.postingAudioFile,
    uploadProgress: state.narration.postingPercent,
    uploadingVoice: state.narration.postingVoiceId,
    uploadingName: state.narration.postingName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchOrCreateNarration,
    fetchBook,
    uploadAudioFile,
    deleteAudio,
    generate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Narration);
