import React from 'react';
import PropTypes from 'prop-types';
import cs from './Badge.module.scss';
import classNames from 'classnames';

export const badgeTypes = {
  angry: 'angry',
  yelling: 'yelling',
  scared: 'scared'
}

const infos = {
  angry: { color: 'red', icon: '😡'},
  yelling: { color: 'red', icon: '😡'},
  scared: { color: 'blue', icon: '😨'},
}

export const Badge = ({ type, className, ...rest }) => {

  const info = infos[type];

  if (!info) {
    return null;
  }

  return (
    <div className={classNames(cs.badge, cs[info.color])} {...rest}>
      <div>{info.icon}</div>
      <div>{type}</div>
    </div>
  )
}

Badge.propTypes = {
  type: PropTypes.oneOf(Object.keys(badgeTypes)).isRequired,
  className: PropTypes.string,
}
