import React from 'react';
import PropTypes from 'prop-types';
import { ToastNotification } from 'carbon-components-react';
import {
  FacebookShareButton, FacebookIcon, FacebookShareCount,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  PinterestShareButton, PinterestIcon, PinterestShareCount,
  RedditShareButton, RedditIcon, RedditShareCount,
  TelegramShareButton, TelegramIcon,
  LinkedinShareButton, LinkedinIcon
} from 'react-share';

import cs from './ShareBanner.module.scss';
import { RES_PATH } from 'src/config/environment';
import CopyLink from 'src/components/CopyLink';

const textColor = { color: '#f4f4f4' };

const ShareBanner = ({ book }) => {
  const { uuid, name: title, image } = book || {};
  const shareUrl = `https://fictionate.me/books/${uuid}`;
  const exampleImage = RES_PATH + image;

  return (
    <>
      { book ?
        <div>
          <ToastNotification kind={'info'}
                             hideCloseButton
                             lowContrast={true}
                             title={'How to increase book exposure?'}
                             subtitle={<span style={{ ...textColor }}>
                               Always start with sharing through
                               Facebook, Twitter and other social
                               networks with your friends and family.
                             </span>}
                             style={{
                               ...textColor,
                               width: '100%',
                               backgroundColor: '#262626'
                             }}
                             caption={<div style={{ ...textColor }}>
                               Messaging your closest ones directly
                               could be a great way to have immediate
                               attention.
                               Ask them to "Like" your book and leave
                               a review. That will give it an initial
                               boost in ratings.
                               <div
                                 className={cs.shareButtonsContainer}>

                                 <div className={cs.socialItem}>
                                   <FacebookShareButton
                                     url={shareUrl}
                                     quote={title}
                                     className={cs.shareButton}
                                   >
                                     <FacebookIcon size={32} round/>
                                   </FacebookShareButton>
                                   <div className={cs.shareCount}>
                                     <FacebookShareCount
                                       url={shareUrl}/>
                                   </div>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <TwitterShareButton
                                     url={shareUrl}
                                     title={title}
                                     className={cs.shareButton}
                                   >
                                     <TwitterIcon size={32} round/>
                                   </TwitterShareButton>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <WhatsappShareButton
                                     url={shareUrl}
                                     title={title}
                                     separator=":: "
                                     className={cs.shareButton}
                                   >
                                     <WhatsappIcon size={32} round/>
                                   </WhatsappShareButton>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <PinterestShareButton
                                     url={shareUrl}
                                     media={exampleImage}
                                     className={cs.shareButton}
                                   >
                                     <PinterestIcon size={32} round/>
                                   </PinterestShareButton>

                                   <div className={cs.shareCount}>
                                     <PinterestShareCount
                                       url={shareUrl}/>
                                   </div>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <RedditShareButton
                                     url={shareUrl}
                                     title={title}
                                     windowWidth={660}
                                     windowHeight={460}
                                     className={cs.shareButton}
                                   >
                                     <RedditIcon size={32} round/>
                                   </RedditShareButton>

                                   <div className={cs.shareCount}>
                                     <RedditShareCount
                                       url={shareUrl}/>
                                   </div>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <TelegramShareButton
                                     url={shareUrl}
                                     title={title}
                                     className={cs.shareButton}
                                   >
                                     <TelegramIcon size={32} round/>
                                   </TelegramShareButton>
                                 </div>

                                 <div className={cs.socialItem}>
                                   <LinkedinShareButton
                                     url={shareUrl}
                                     className={cs.shareButton}>
                                     <LinkedinIcon size={32} round/>
                                   </LinkedinShareButton>
                                 </div>
                                 <CopyLink url={shareUrl} book={uuid} shortening="book"/>
                               </div>
                             </div>}
          />
        </div> : null
      }
    </>
  )
}

ShareBanner.propTypes = {
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  })
}

export default ShareBanner;
