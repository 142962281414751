export const ACTION_TYPES = {
  FETCH_BLOG_PENDING: 'blog/FETCH_BLOG_PENDING',
  FETCH_BLOG_FULFILLED: 'blog/FETCH_BLOG_FULFILLED',
  FETCH_BLOG_REJECTED: 'blog/FETCH_BLOG_REJECTED',
  FETCH_BLOG_NEW_PAGE_PENDING: 'blog/FETCH_BLOG_NEW_PAGE_PENDING',
  FETCH_BLOG_NEW_PAGE_FULFILLED: 'blog/FETCH_BLOG_NEW_PAGE_FULFILLED',
};

const defaultState = {
  content: { items:[] },
  lastPage: false,
  loading: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BLOG_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_BLOG_FULFILLED:
      return {
        ...state,
        loading: false,
        content: action.data,
        lastPage: action.data.items.length === 0
      };
    case ACTION_TYPES.FETCH_BLOG_REJECTED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.FETCH_BLOG_NEW_PAGE_PENDING:
      return {
        ...state,
        loading: true,
        content: {
          ...state.content
        }
      };
    case ACTION_TYPES.FETCH_BLOG_NEW_PAGE_FULFILLED:
      return {
        ...state,
        loading: false,
        content: {
          ...state.content,
          items: state.content.items.concat(action.data.items)
        },
        lastPage: action.data.items.length === 0
      };
      default:
        return state;
  }
}
