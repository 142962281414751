import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';

import { getChapterBlob, chapterBlobToArrayBuffer } from 'src/modules/api/chapters.api';

function getBook(bookUuid) {
  return axios.get(`/books/${bookUuid}`, getAuthHeader());
}

export async function fetchBook(uuid) {
  try {
    return await getBook(uuid);
  } catch (error) {
    return error.response;
  }
}

export async function fetchSubscriptions(uuid) {
  try {
    return await axios.get(`/books/${uuid}/subscriptions`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function createSubscriptions(uuid,  values) {
  try {
    return await axios.post(`/books/${uuid}/subscriptions`, values, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function deleteSubscriptions(uuid) {
  try {
    return await axios.delete(`/subscriptions/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function fetchSeries() {
  try {
    return await axios.get(`/me/books/series`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function createSeries(name, about) {
  try {
    return await axios.post(`/books/series`, {name, about}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function fetchSeriesObject(uuid) {
  try {
    return await axios.get(`/books/series/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function updateSeries(uuid, name, about) {
  try {
    return await axios.put(`/books/series/${uuid}`, {name, about}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}


export async function deleteSeries(uuid) {
  try {
    return await axios.delete(`/books/series/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getBookAndChapter(bookUuid, chapterUuid) {
  let book;
  try {
    const response = await getBook(bookUuid);
    if (response && response.status === 200) {
      book = response.data;
    }
  } catch (err) {
    console.log(err);
    return null;
  }

  if (book) {
    try {
      const response = await getChapterBlob(chapterUuid, 'epub');
      book.chapter = { uuid: chapterUuid };
      book.chapter.arrayBuffer = await chapterBlobToArrayBuffer(response.data);
    } catch (err) {
      console.log(err);
    }
    return book;
  }

  return null;
}

export async function postBookShare(book, chapter) {
  try {
    return await axios.post(`/share`, { book, chapter }, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function putBookShared(uuid) {
  try {
    return await axios.put(`/share/${uuid}`, { state: 'shared' }, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function putBook(book) {
  try {
    return await axios.put(`/books/${book.uuid}`, book, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function postChapter(bookUuid, chapter, before) {
  try {
    if(before) {
      return await axios.post(`/books/${bookUuid}/chapters?before=${before}`, chapter, getAuthHeader());
    } else {
      return await axios.post(`/books/${bookUuid}/chapters`, chapter, getAuthHeader());
    }
  } catch (error) {
    return error.response;
  }
}

export async function postBookAdded(bookUuid) {
  try {
    return await axios.post(`/books/${bookUuid}/added`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function deleteBookAdded(bookUuid) {
  try {
    return await axios.delete(`/books/${bookUuid}/added`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function postSubscribeBook(bookUuid) {
  try {
    return await axios.post(`/books/${bookUuid}/subscribe`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function deleteBook(bookUuid) {
  try {
    return await axios.delete(`/books/${bookUuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function importEpubFile(epub, onUploadProgress) {
  try {
    return await axios.post('/import', { epub }, { ...getAuthHeader(), onUploadProgress });
  } catch (error) {
    return error.response;
  }
}

export async function shortId(bookUuid) {
  try {
    return await (await axios.post(`/books/${bookUuid}/id`, {}, getAuthHeader())).data;
  } catch (error) {
    return error.response;
  }
}
