import { ACTION_TYPES } from 'src/modules/reducers/genres.reducer';
import { getGenres } from 'src/modules/api/home.api';

export function fetchGenres() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_GENRES_PENDING });

    const response = await getGenres();

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_GENRES_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_GENRES_FULFILLED, data });
    }
  }
}
