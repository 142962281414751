import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';
import { throttle } from 'src/utils';

export async function getNarration(chapterId) {
  try {
    return await axios.get(`/chapters/${chapterId}/narration`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function postNarration(chapterId) {
  try {
    return await axios.post(`/chapters/${chapterId}/narration`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function deleteNarration(chapterId) {
  try {
    return await axios.delete(`/chapters/${chapterId}/narration`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
export function postAudioFile(narrationId, voiceId, payload, onProgressUpdate) {
  const abortController = new AbortController();

  const promise = (async function() {
    const onUploadProgress = throttle(
      ({ loaded, total }) => onProgressUpdate(Math.round(loaded / total * 100)),
      500
    );

    try {
      return await axios.post(`/narrations/${narrationId}/voices/${voiceId}/audio`, payload, {
        ...getAuthHeader(),
        signal: abortController.signal,
        onUploadProgress,
      });
    } catch (error) {
      return error.response;
    }
  })()

  return { abortController, promise };
}

export async function deleteAudioFile(narrationId, voiceId) {
  try {
    return await axios.delete(`/narrations/${narrationId}/voices/${voiceId}/audio`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function generate(narrationId) {
  try {
    return await axios.post(`/narrations/${narrationId}/generate`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function publish(narrationId) {
  try {
    return await axios.post(`/narrations/${narrationId}/publish`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
