import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';

import { tagLabel } from 'src/components/author/AddBookPage/util';

export async function searchTag(query) {
  try {
    const tags = (await axios.get(`/tags?query=${query}`))?.data?.tags ?? [];
    return tags.map(t => ({ label: tagLabel(t.id), value: t.id }));
  } catch (e) {
    console.log(e);
  }
}

export async function saveTag(tagName) {
  try {
    return (await axios.post('/tags', { id: tagName, name: tagName }, getAuthHeader())).data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
