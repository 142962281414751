import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { genMailto } from 'src/modules/api/utils';
import { RES_PATH } from 'src/config/environment';
import ModalForm from 'src/components/_shared/form/ModalForm';

import './styles.css';
import history from 'src/history';
import { clearAuthentication, withMask } from 'src/modules/actions/authentication.actions';
import { redirectToWriterCheckout } from 'src/modules/actions/payment.actions';
import { postWriterEmail } from 'src/modules/api/writers.api';

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

const mailtoLink = genMailto();

export const AccountOverflowMenu = ({ children, account, blogPost, book, clearAuthentication, withMask, redirectToWriterCheckout }) => {
  const goMyPosts = () => history.push('/me/posts');
  const goProfile = () => history.push('/profile');
  const goMyBooks = () => history.push('/my-books');
  const goEarnings = () => history.push('/earnings');
  const goTerms = () => history.push('/s/terms-of-service');
  const mailTo = () => window.location.href = `mailto:${mailtoLink}`;
  const [payWriter, setPayWriter] = useState(null);
  const [emailWriter, setEmailWriter] = useState(null);

  const maskAsWriter = () => { 
    withMask(undefined, getLastItem(window.location.href));
  };

  const payWriterAction = () => { 
    setPayWriter(true)
  };

  const emailWriterAction = () => { 
    setEmailWriter(true)
  };

  const editBook = () => { 
    let uuid = getLastItem(window.location.href);
    history.push(`/my-books/${uuid}/edit`)
  };

  const downloadBookCover = useCallback(() => {
    window.open(`${RES_PATH}${book.image}?d`, '_blank');
  }, [book]);

  const editBlogPost = useCallback((values) => {
    let uuid = blogPost ? blogPost.content.uuid : "";
    history.push(`/me/posts/${uuid}/edit`)
  }, [blogPost]);

  const maskPrompt = () => {
    let login = prompt("Login", '');
    if (login) {
      withMask(login);
    }
  };

  return (
    <>
    <OverflowMenu
      direction='bottom'
      ariaLabel='global-overflow-menu'
      flipped={false}
      renderIcon={() => <>{children}</>}
    >
      <OverflowMenuItem
        itemText={<div>
          <div>{account.login}</div>
          <div>{account.email}</div>
        </div>}
      />
      {account.admin &&
        <OverflowMenuItem
          itemText="Mask"
          onClick={maskPrompt}
        />
      }
      {account.editor &&
        <OverflowMenuItem
          itemText="My Posts"
          onClick={goMyPosts}
        />
      }
      {account.writer &&
        <OverflowMenuItem
          itemText="My Books"
          onClick={goMyBooks}
        />
      }
      <OverflowMenuItem
        itemText="Profile"
        onClick={goProfile}
      />
      {account.writer &&
        <OverflowMenuItem
          itemText="Earnings"
          onClick={goEarnings}
        />
      }
      {account.writer &&
        <OverflowMenuItem
          itemText="Writer's Subscriptions"
          onClick={() => history.push('/me/writer/subscriptions')}
          />
      }
      {account.writer &&
        <OverflowMenuItem
          itemText="Newsletters"
          onClick={() => history.push('/me/writer/newsletters')}
          />
      }
      <OverflowMenuItem
        itemText="My Subscriptions"
        onClick={() => history.push('/me/subscriptions')}
      />
      {account.admin && window.location.href.includes("/writer") &&
        <OverflowMenuItem
        itemText="Login as Writer"
        onClick={maskAsWriter}
        />              
      }
      {account.admin && window.location.href.includes("/writer") &&
        <OverflowMenuItem
        itemText="Pay Writer"
        onClick={payWriterAction}
        />              
      }
      {account?.acl?.includes("emailWriters") && window.location.href.includes("/writer") &&
        <OverflowMenuItem
        itemText="Email Writer"
        onClick={emailWriterAction}
        />              
      }
      {account.admin && window.location.href.includes("/books/") &&
        <OverflowMenuItem
        itemText="Edit Book"
        onClick={editBook}
        />              
      }
      {account.admin && window.location.href.includes("/books/") &&
        <OverflowMenuItem
        itemText="Download Book cover"
        onClick={downloadBookCover}
        />              
      }
      {account.admin && (window.location.href.includes("/blog/") ||
                         window.location.href.includes("/p/")) &&
        <OverflowMenuItem
        itemText="Edit Blog Post"
        onClick={editBlogPost}
        />              
      }      
      {account.admin &&
        <OverflowMenuItem
        itemText="Manage Banners"
        onClick={() => history.push('/admin/banners')}
        />              
      }      
      {account?.acl?.includes("createSubscriptionCoupon") &&
        <OverflowMenuItem
        itemText="Manage Subscriptions"
        onClick={() => history.push('/admin/managedCoupons')}
        />              
      }
      <OverflowMenuItem itemText="Contact Support" onClick={mailTo} />
      <OverflowMenuItem itemText="Terms Of Service" onClick={goTerms}/>
      <OverflowMenuItem
        hasDivider isDelete
        itemText="Sign out"
        onClick={clearAuthentication}
      />
    </OverflowMenu>
    <ModalForm
      modalHeading = "Pay Writer"
      action = "Pay"
      open = {payWriter}
      properties={[
        { title: "Amount ($)", type: "text", id: "amount", default: 1.0},
      ]}
      cancel={()=> setPayWriter(null)}
      submit={(value) => {
        redirectToWriterCheckout(getLastItem(window.location.href), value.amount * 100);
      }}
    />
   <ModalForm
      modalHeading = "Email Writer"
      open = {emailWriter}
      action = "Send"
      properties={[
        { title: "Subject", type: "text", id: "subject"},
        { title: "Title", type: "text", id: "title"},
        { title: "Content", type: "textArea", id: "content"},
      ]}
      cancel={()=> setEmailWriter(null)}
      submit={async (value) => {
        await postWriterEmail(getLastItem(window.location.href), value);
        await setEmailWriter(false);
      }}
    />
    </>
  )
};

AccountOverflowMenu.propTypes = {
  account: PropTypes.shape({
    login: PropTypes.string,
    writer: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    admin: PropTypes.bool,
    editor: PropTypes.bool
  }).isRequired
};

export default connect(
  state => ({ 
    account: state.authentication.account,
    blogPost: state.blogPost,
    book: state.books.book
  }),
  dispatch => bindActionCreators({ clearAuthentication, withMask, redirectToWriterCheckout }, dispatch) )(AccountOverflowMenu);
