import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'carbon-components-react';
import { useLocation } from 'react-router-dom';

import cs from './AuthReqBlockReading.module.scss';
import history from 'src/history';
import { isAuthenticated } from 'src/modules/api/cookie.api';

const AuthReqBlockReading = ({ className, theme }) => {
  const location = useLocation();
  const pushState = { from: location?.pathname }

  return (
    <>
      { !isAuthenticated() &&
        <div className={classNames(cs.authReq, className, cs[theme])}>
          <div>
            Sign in to get all features<br/>
            Store your reading progress<br/>
            Get notified on added chapters
          </div>
          <div className={cs.signUp}>
            <Button kind={'tertiary'}
                    onClick={() => {
                      history.push('/register', pushState)
                    }}
            >
              Create Account
            </Button>
          </div>
          <div className={cs.signIn}>
            Already have an Account?{' '}
            <span
              style={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                history.push('/login', pushState)
              }}>
                Sign In
            </span>
          </div>
        </div>
      }
    </>
  )
}

AuthReqBlockReading.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  theme: PropTypes.string.isRequired
}

export default AuthReqBlockReading;