class AuthMessage {

  message = null;
  title = null;
  kind = null;

  constructor(message) {
    this.message = message;
  }

  toJS() {
    return {
      message: this.message,
      messageTitle: this.title,
      messageKind: this.kind
    }
  }
}

export class ErrAuthMessage extends AuthMessage {

  constructor(message) {
    super(message);
    this.title = 'Login error';
    this.kind = 'error';
  }
}

export class SuccessAuthMessage extends AuthMessage {

  constructor(message) {
    super(message);
    this.title = 'Success';
    this.kind = 'success'
  }
}
