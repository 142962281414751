import React from 'react';
import PropTypes from 'prop-types';
import { InlineNotification, NotificationActionButton } from 'carbon-components-react';

import ps from 'src/modules/model/payout-state';

export const PayoutTip = ({ payoutState, onClick }) => {
  return (
    <>
      { payoutState === ps.CONFIGURED ? null :
        <InlineNotification kind='warning'
                            lowContrast
                            hideCloseButton
                            title={ps.getTitle(payoutState)}
                            subtitle={ps.getSubtitle(payoutState)}
                            actions={
                              <>
                                { (payoutState === ps.NOT_CONFIGURED || payoutState === ps.UPLOAD_PHOTO_ID) ?
                                  <NotificationActionButton onClick={onClick}>
                                    Configure
                                  </NotificationActionButton> : null
                                }
                              </>
                            }
        />
      }
    </>
  )
}

PayoutTip.propTypes = {
  payoutState: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default PayoutTip;
