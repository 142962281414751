import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { Loading } from 'carbon-components-react';

import cx from './list-wrapper.module.scss';
import BookTile from 'src/components/_shared/Book/BookCell';

export default function ListWrapper({ hasNextPage,
                                         isNextPageLoading,
                                         items,
                                         loadNextPage,
                                         sortOrder, listHeight }) {

  const listRef = useRef(null);
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (listRef.current && hasMountedRef.current) {
      listRef.current.resetloadMoreItemsCache();
    }
    hasMountedRef.current = true;
  }, [sortOrder]);

  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = index => !hasNextPage || index < items.length;

  const Item = ({ index, style }) => {
    return (
      <div style={{ ...style }}>
        { !isItemLoaded(index) ?
          <div className={cx.loaderWrapper}>
            <Loading small withOverlay={false}/>
          </div> :
          <div className={cx.itemWrapper}>
            <BookTile {...items[index]} order={index + 1}/>
          </div>
        }
      </div>
    )
  };

  return (
    <InfiniteLoader
      ref={listRef}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          className="List"
          height={listHeight}
          itemCount={itemCount}
          itemSize={208}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}

ListWrapper.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired,
  listHeight: PropTypes.number.isRequired
};
