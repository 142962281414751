import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from './Progress.module.scss';
import { WaveImage } from './images/WaveImage';
import { CloseIcon } from './icons/CloseIcon';

export const Progress = ({ percent = 0, name = '', onAbort }) => {
  const [stop, setStop] = useState(false);

  return (
    <div className={cs.progress}>
      <div className={cs.percentOuter} />
      <div className={cs.percentInner} style={{ width: `${Math.max(4, percent - 3)}%` }} />
      <div className={cs.blackBorders} />

      {stop ? (
        <div className={cs.stopContent}>
          <div>Do you want to stop the upload?</div>
          <div>
            <div onClick={onAbort}>stop</div>
            <div onClick={() => setStop(false)}>continue</div>
          </div>
        </div>
      ) : (
        <div className={cs.content}>
          <WaveImage />
          <div>
            <div>{name}</div>
            <div>{`${percent}%`} uploaded</div>
          </div>
          <div onClick={() => setStop(true)}>
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  )
}

Progress.propTypes = {
  percent: PropTypes.number,
  name: PropTypes.string,
  onAbort: PropTypes.func.isRequired,
}
