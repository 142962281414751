import React, { useState, useCallback }  from 'react';
import { FormGroup } from 'carbon-components-react';
import DateTimePicker from 'react-datetime-picker'
import cs from './styles.module.scss';

export const InputTimePicker = ({ onChange, timestamp, options, meta, defaultValue, placeholder, disabled = false, ...props }) => {

  const [time, setTime] = useState( timestamp ? new Date(timestamp * 1000) : 
                                                (defaultValue ? new Date(defaultValue * 1000) : null));
  if(timestamp === undefined && defaultValue !== undefined){
    onChange(defaultValue);
  }
  const handleChange = useCallback((event) => {
    setTime(event)
    onChange(event / 1000);
  }, [onChange]);

  return (
    <FormGroup legendText=''>
      <DateTimePicker
        className={cs.date}
        onChange={handleChange}
        value={time}
        disableCalendar={true}
        disableClock={true}
      />
    </FormGroup>
  )
};

export default InputTimePicker;
