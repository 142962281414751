import React, { useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic';
import UploadAdapterPlugin from './UploadAdapter'

// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import SoundContextMenu from './SoundContextMenu';

import './ck-styles.css';

export const TextEditor = ({
                             input,
                             height,
                             disableHtmlEmbedPlugin,
                             enableNarratorPlugin,
                             enableBGSoundsPlugin,
                             enableListenPlugin,
                             narratorConfig,
                             bgsoundsConfig }) => {
  const ckBox = useRef(null);
  const personasCss = useRef();

  const [key, setKey] = React.useState(1);
  React.useEffect(() => {
    // update editor
    setKey(key => key + 1);

    // update css that highlights the character's text
    const style = document.createElement('style');
    const cssRules = narratorConfig.personas
      .filter(p => p.id !== '0')
      .map(persona => `character[id='${persona.id}'] {background-color: ${persona.color} !important}`);
    cssRules.push(`.ck-content sound p { border-left: 4px solid #6f6f6f; padding-left: 4px; }`);
    cssRules.push(`.ck-content soundeffect::after { border: 1px solid #6f6f6f; -webkit-filter: grayscale(100%); filter: grayscale(100%); }`);
    bgsoundsConfig.files.forEach(f => {
      cssRules.push(`.ck-content sound[id='${f.uuid}'] p { border-left: 4px solid #FF7400; padding-left: 4px; }`);
      cssRules.push(`.ck-content soundeffect[id='${f.uuid}']::after { border: 1px solid #FF7400; -webkit-filter: grayscale(0%); filter: grayscale(0%); }`);
    });
    const css = cssRules.join('\n');
    style.appendChild(document.createTextNode(css));
    if (!personasCss.current) {
      personasCss.current = document.head.insertAdjacentElement('beforeend', style);
    } else {
      document.head.replaceChild(style, personasCss.current);
      personasCss.current = style;
    }
  }, [narratorConfig.personas, bgsoundsConfig.files]);

  const adjustEditorSize = () => {
    const ckDiv = document.querySelector('.ck-editor__editable_inline');
    if (ckDiv) {
      const ckBottomEdge = ckBox.current.getBoundingClientRect().top;
      var autoHeight = window.innerHeight - ckBottomEdge - 39;
      autoHeight = Math.max(autoHeight, 300);
      const heightValue = height ? height : `${autoHeight}px`;
      document.documentElement.style.setProperty('--ckh', heightValue);
    }
  };

  const soundContextMenuRenderer = useCallback((model, domElement) => {
    if (enableBGSoundsPlugin) {
      const audiofile = bgsoundsConfig.files.find(f => f.uuid === model.id);
      const narrator = narratorConfig.personas.find(p => p.narrator === true);
      return ReactDOM.render(
        <SoundContextMenu audioFile={audiofile}
                          {...model}
                          chapterUuid={bgsoundsConfig.chapterUuid}
                          narratorUuid={narrator?.uuid}
                          bookUuid={bgsoundsConfig.bookUuid} />, domElement)
    }
  }, [enableBGSoundsPlugin, bgsoundsConfig.files, bgsoundsConfig.chapterUuid, bgsoundsConfig.bookUuid, narratorConfig.personas]);

  const listenContextMenuRenderer = useCallback( (htmlFragment, domElement) => {
    if (enableListenPlugin) {
      const narrator = narratorConfig.personas.find(p => p.narrator === true);
      return ReactDOM.render(<SoundContextMenu htmlFragment={htmlFragment}
                                               chapterUuid={bgsoundsConfig.chapterUuid}
                                               narratorUuid={narrator?.uuid}
                                               bookUuid={bgsoundsConfig.bookUuid}
                                               simplePlayer />, domElement)
    }
  }, [enableListenPlugin, bgsoundsConfig.chapterUuid, bgsoundsConfig.bookUuid, narratorConfig.personas])

  const toolbarItems = useMemo(() => {
    const items = [
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'fontColor',
      'fontSize',
      'fontBackgroundColor',
      'fontFamily',
      'removeFormat',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'horizontalLine',
      '|',
      'alignment',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'mediaEmbed',
      'imageUpload',
    ];

    if (!disableHtmlEmbedPlugin) {
      items.splice(5, 0, "htmlEmbed");
    }

    if (enableNarratorPlugin) {
      items.push('narrators');
    }

    if (enableBGSoundsPlugin) {
      items.push('bgsounds');
    }

    if (enableListenPlugin) {
      items.push('listen');
    }
    return items;
  }, [enableNarratorPlugin, disableHtmlEmbedPlugin, enableBGSoundsPlugin, enableListenPlugin]);

  return (
    <div style={{color: '#161616'}} ref={ckBox} className={'ck-fm-editor'}>
      <CKEditor
        key={key}
        editor={ ClassicEditor }
        data={input.value}
        onInit={ editor => {
          adjustEditorSize();
          // CKEditorInspector.attach( editor );
        } }
        onChange={ ( event, editor ) => {} }
        onBlur={ ( event, editor ) => {
          const data = editor.getData();
          const doc = new DOMParser().parseFromString(data, 'text/html');
          const body = doc.getElementsByTagName('body');
          let xhtml = new XMLSerializer().serializeToString(body[0]);
          xhtml = xhtml.substring(xhtml.indexOf('>') + 1, xhtml.length - 7);
          input.onChange(xhtml);
        } }
        onFocus={ ( event, editor ) => {} }
        config={
          {
            fontSize: {
              options: [
                  9,
                  11,
                  13,
                  'default',
                  17,
                  19,
                  21,
                  23,
                  25
              ],
              supportAllValues: true
            },
            fontFamily: {
              supportAllValues: true
            },
            toolbar: {
              items: toolbarItems
            },
            language: 'en',
            table: {
              contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
              ]
            },
            licenseKey: '',
            extraPlugins: [ UploadAdapterPlugin ],
            personas: narratorConfig,
            bgsounds: {
              ...bgsoundsConfig,
              soundContextMenuRenderer,
              soundContextMenuDisposer: ReactDOM.unmountComponentAtNode
            },
            listen: {
              listenContextMenuRenderer,
              listenContextMenuDisposer: ReactDOM.unmountComponentAtNode
            },
            mediaEmbed: {
              previewsInData: true
            },
            image: {
              toolbar: [
                'linkImage',
              ],
            },
          }
        }
      />
    </div>
  )
};

TextEditor.defaultProps = {
  bgsoundsConfig: {
    files: [],
    openAudioForm: () => {},
    deleteAudio: () => {}
  },
  narratorConfig: {
    personas: [],
    openPersonasForm: () => {},
    editPersona: () => {}
  },
  disableHtmlEmbedPlugin: false,
  enableNarratorPlugin: false,
  enableBGSoundsPlugin: false
}

TextEditor.propTypes = {
  input: PropTypes.object,
  bgsoundsConfig: PropTypes.shape({
    files: PropTypes.array,
    openAudioForm: PropTypes.func,
    deleteAudio: PropTypes.func,
    chapterUuid: PropTypes.string,
    bookUuid: PropTypes.string
  }),
  narratorConfig: PropTypes.shape({
    personas: PropTypes.array,
    openPersonasForm: PropTypes.func,
    editPersona: PropTypes.func
  }),
  disableHtmlEmbedPlugin: PropTypes.bool,
  enableNarratorPlugin: PropTypes.bool,
  enableBGSoundsPlugin: PropTypes.bool,
  enableListenPlugin: PropTypes.bool
}

export default TextEditor;
