import React from 'react';
import PropTypes from 'prop-types';
import { Close24, PlayFilledAlt24, TableOfContents24, TextAlignCenter24 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';

import cs from './TopMenu.module.scss';
import history from 'src/history';
import * as Book from 'src/modules/model/book';

const TopMenu = ({ isMobile, mode, onToggleMode, onClose, bookUuid, currentChapter, book, ...rest }) => {

  const _toggleMode = () => {
    onToggleMode(mode === 'player' ? 'list' : 'player');
  }

  const _read = () => {
    const chapter = currentChapter || Book.getChapterToRead(book)?.uuid;
    const to = `/books/${bookUuid}` + (chapter ? `/${chapter}` : '');
    history.push(to);
  }

  return (
    <div className={cs.topMenu}>
      {
        book?.textAvailable && 
        (<Button className={cs.read} onClick={_read}>
          <div>
            Read
            <TextAlignCenter24 />
          </div>
        </Button>)
      }
      { isMobile &&
        <Button className={cs.toggle} onClick={_toggleMode}>
          {{
            player: <TableOfContents24/>,
            list: <PlayFilledAlt24/>
          }[mode]}
        </Button>
      }
      <Button className={cs.close} onClick={onClose}>
        <Close24/>
      </Button>
    </div>
  )
}

TopMenu.propTypes = {
  isMobile: PropTypes.bool,
  mode: PropTypes.string,
  toggleMode: PropTypes.func,
  onClose: PropTypes.func,
  currentChapterExternal: PropTypes.string,
  bookUuid: PropTypes.string
}

export default TopMenu;
