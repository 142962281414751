import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/_shared/DesignV2/Button';
import { VolumeUp16 } from '@carbon/icons-react';
import classNames from 'classnames';

import cs from './ListenButton.module.scss';
import * as Book from 'src/modules/model/book';
import history from 'src/history';

const ListenButton = ({ book, onClick, className, account }) => {

  const chapter = Book.getChapterToRead(book);

  const _onClick = () => {
    if (typeof onClick === 'function') onClick();
    else history.push(`/listen/${book.uuid}` + (chapter ? `/${chapter.uuid}` : ''));
  };

  const show = chapter && (chapter.free || chapter.public || chapter.purchased || book.pricing.state === 'purchased' || Book.isFree(book) || book.author.uuid === account?.uuid);

  return (
    <>
      {(show || typeof onClick === 'function') &&
        <Button kind='normal'
               onClick={_onClick}
               className={classNames(cs.listenButton, className)}
      >
        Listen
        <VolumeUp16/>
      </Button>
      }
    </>
  )
}

ListenButton.propTypes = {
  book: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default ListenButton;
