export const ACTION_TYPES = {
  CREATE_NARRATION_PENDING: 'narration/CREATE_NARRATION_PENDING',
  CREATE_NARRATION_SUCCESS: 'narration/CREATE_NARRATION_SUCCESS',
  CREATE_NARRATION_FAIL: 'narration/CREATE_NARRATION_FAIL',

  DELETE_NARRATION_PENDING: 'narration/DELETE_NARRATION_PENDING',
  DELETE_NARRATION_SUCCESS: 'narration/DELETE_NARRATION_SUCCESS',
  DELETE_NARRATION_FAIL: 'narration/DELETE_NARRATION_FAIL',

  POST_AUDIO_FILE_PENDING: 'narration/POST_AUDIO_FILE_PENDING',
  POST_AUDIO_FILE_SUCCESS: 'narration/POST_AUDIO_FILE_SUCCESS',
  POST_AUDIO_FILE_ABORT: 'narration/POST_AUDIO_FILE_ABORT',

  DELETE_AUDIO_PENDING: 'narration/DELETE_AUDIO_PENDING',
  DELETE_AUDIO_SUCCESS: 'narration/DELETE_AUDIO_SUCCESS',
  DELETE_AUDIO_FAIL: 'narration/DELETE_AUDIO_FAIL',
}

/**
 * isLoading?: boolean,
 * postingAudioFile?: boolean,
 * postingPercent?: number, // 0 - 100
 * postingVoiceId?: string,
 * postingName?: string,
 * deletingAudioFile?: boolean,
 *
 * book?: string,
 * chapter?: string,
 * uuid?: string,
 * voices?: Array<{
 *                  characterId: string,
 *                  text: string,
 *                  uuid: string,
 *                  emotions: [],
 *                  hasAudio?: boolean,
 *                  source?: string,
 *                }>,
 * characters?: Array<{
 *                      id: string,
 *                      name: string,
 *                      about: string,
 *                    }>
 * */


const defaultState = { }

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_NARRATION_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case ACTION_TYPES.CREATE_NARRATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        audioState: action.payload.audioState,
        isLoading: false,
      }
    case ACTION_TYPES.CREATE_NARRATION_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ACTION_TYPES.DELETE_NARRATION_PENDING:
      return {
        ...state,
        isLoading: true,
      }  
    case ACTION_TYPES.DELETE_NARRATION_SUCCESS:
      return {
        isLoading: false,
      }
    case ACTION_TYPES.DELETE_NARRATION_FAIL:
      return {
        isLoading: false,
      }
    case ACTION_TYPES.POST_AUDIO_FILE_PENDING:
      return {
        ...state,
        postingAudioFile: true,
        postingPercent: action.percent ?? 0,
        postingVoiceId: action.voiceId,
        postingName: action.fileName,
      }
    case ACTION_TYPES.POST_AUDIO_FILE_SUCCESS:
      return {
        ...state,
        postingAudioFile: undefined,
        postingPercent: undefined,
        postingVoiceId: undefined,
        postingName: undefined,
        voices: state.voices.map(voice => {
          if (voice.uuid === action.voiceId) {
            return { ...voice, hasAudio: true }
          }
          return voice;
        })
      }
    case ACTION_TYPES.POST_AUDIO_FILE_ABORT:
      return {
        ...state,
        postingAudioFile: undefined,
        postingPercent: undefined,
        postingVoiceId: undefined,
        postingName: undefined,
      }
    case ACTION_TYPES.DELETE_AUDIO_PENDING:
      return {
        ...state,
        deletingAudioFile: true,
      }
    case ACTION_TYPES.DELETE_AUDIO_SUCCESS:
      return {
        ...state,
        voices: state.voices.map(voice => {
          if (voice.uuid === action.voiceId) {
            return { ...voice, hasAudio: false, source: undefined }
          } else {
            return voice;
          }
        }),
        deletingAudioFile: undefined,
      }
    case ACTION_TYPES.DELETE_AUDIO_FAIL:
      return {
        ...state,
        deletingAudioFile: undefined,
      }
    default:
      return state;
  }
}
