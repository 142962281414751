import { isAuthenticated } from 'src/modules/api/cookie.api';

export const DEFAULT_PRICING = 'free';

export const PRICING_OPTIONS = [
  {id: 'free', labelText: 'Free', value: 'free', desc: 'Anyone can access this book'},
  {id: 'fixedBook', labelText: 'Pay Per Book', value: 'fixedBook', desc: 'Once paid, reader will be able to access all book\'s chapters'},
  {id: 'writersSubscription', labelText: 'Subscriptions Only', value: 'writersSubscription', desc: 'Book access will be available only through Writer\'s subscriptions'},
  // {id: 'subscription', labelText: 'Monthly subscription', value: 'subscription', desc: 'While subscribed, users will have accesss to the book with a limited chapter access defined in subscription tiers'},
];

export const PRICE_OPTIONS = [
  {id: 'tier1b', labelText: '$0.99', value: 'tier1b', desc: 'once paid, reader will be able to access all book chapters'},
  {id: 'tier3b', labelText: '$2.99', value: 'tier3b', desc: 'once paid, reader will be able to access all book chapters'},
  {id: 'tier5b', labelText: '$4.99', value: 'tier5b', desc: 'once paid, reader will be able to access all book chapters'},
  {id: 'tier8b', labelText: '$7.99', value: 'tier8b', desc: 'once paid, reader will be able to access all book chapters'},
  {id: 'tier10b', labelText: '$9.99', value: 'tier10b', desc: 'once paid, reader will be able to access all book chapters'},
];

export const SUBSCRIPTION_PRICE_OPTIONS = [
  { id: "tier1", labelText: "0.99$", value: "tier1" },
  { id: "tier2", labelText: "1.99$", value: "tier2" },
  { id: "tier3", labelText: "2.99$", value: "tier3" },
  { id: "tier5", labelText: "4.99$", value: "tier5" },
  { id: "tier8", labelText: "7.99$", value: "tier8" },
  { id: "tier10", labelText: "9.99$", value: "tier10" },
  { id: "tier15", labelText: "14.99$", value: "tier15" },
  { id: "tier20", labelText: "19.99$", value: "tier20" },
  { id: "tier30", labelText: "29.99$", value: "tier30" },
  { id: "tier40", labelText: "39.99$", value: "tier40" },
  { id: "tier50", labelText: "49.99$", value: "tier50" },
];

export const SUBSCRIPTION_COLOR_OPTIONS = [
  { id: "#8209FB", labelText: "", value: "#8209FB" },
  { id: "#0478FF", labelText: "", value: "#0478FF" },
  { id: "#F66537", labelText: "", value: "#F66537" },
  { id: "#EE432C", labelText: "", value: "#EE432C" },
  { id: "#7878DC", labelText: "", value: "#7878DC" },
  { id: "#C86363", labelText: "", value: "#C86363" },
  { id: "#EE2C72", labelText: "", value: "#EE2C72" },
  { id: "#178A5A", labelText: "", value: "#178A5A" },
];
export const RATING_OPTIONS = [
  {id: 'g',
  labelText: 'G: General Audience',
  value: 'g',
  desc: 'All ages admitted. This signifies that the book rated contains nothing most parents will consider offensive for even their youngest children to see or read. Nudity, sex scenes, and scenes of drug use are absent; violence is minimal; snippets of dialogue may go beyond polite conversation but do not go beyond common everyday expressions.'},
  {id: 'pg',
  labelText: 'PG: Parental Guidance Suggested',
  value: 'pg',
  desc: 'Some material may not be suitable for children. This signifies that the book rated may contain some material parents might not like to expose to their young children - material that will clearly need to be examined or inquired about before children are allowed to read the book. Explicit sex scenes and scenes of drug use are absent; Descriptions of nudity, if present, is only briefly, horror and violence do not exceed moderate levels.'},
  {id: 'pg13',
  labelText: 'PG-13: Parents Strongly Cautioned',
  value: 'pg13',
  desc: 'Some material may be inappropriate for children under 13. This signifies that the book rated may be inappropriate for pre-teens. Parents should be especially careful about letting their younger children read this book. Rough or persistent violence is absent; sexually-oriented descriptions of nudity is generally absent; some scenes of drug use may be present; one use of the harsher sexually derived words may be present.'},
  {id: 'r',
  labelText: 'R: Restricted-Under 17 requires Parent or Guardian approval',
  value: 'r',
  desc: 'This signifies that the book rated contains some adult material. Parents are urged to learn more about the book before letting their children read it. An R may be assigned due to, among other things, a book\'s use of language, theme, violence, sex or its portrayal of drug use.'},
  {id: 'nc17',
  labelText: 'NC-17: No One 17 and Under Admitted',
  value: 'nc17',
  desc: 'This signifies that most American parents would feel that the book is patently adult and that children age 17 and under should not be reading it. The book may contain explicit sex scenes, an accumulation of sexually-oriented language, or scenes of excessive violence. The NC-17 designation does not, however, signify that the rated book is obscene or pornographic.'},
];

export const NARRATION_OPTIONS = [
  {id: 'automatic',
  labelText: 'AI Narration',
  value: 'automatic',
  desc: 'Narration will be create by Fictionate.me AI engine.'},
  {id: 'manual',
  labelText: 'Manual',
  value: 'manual',
  desc: 'Narration can be create by uploading a file or manually narrating text pieces through narration interface. Those options could be found in each chapter settings.'},
  {id: 'none',
  labelText: 'Disabled',
  value: 'none',
  desc: 'Audio will not be available for this book'},
];

export function formInitialValues(book) {
  if (!book) return {
    narration: 'automatic',
    textAvailable: true,
  };

  const values = { ...book };

  values.priceTier = book.pricing.priceTier;
  values.bookSeries = book?.bookSeries?.uuid;
  values.pricing = book.pricing.pricing;
  return values;
}

export function isNotFree(book) {
  return book && book.pricing.priceTier !== 'free';
}

export function isFree(book) {
  return book && book.pricing.priceTier === 'free';
}

export function isChapterPriceTier(book) {
  return book && book.pricing.priceTier === 'tier1c';
}

export function isBookPriceTier(book) {
  return book && book.pricing.pricing === 'fixedBook';
}

export function isBookSubscriptionTier(book) {
  return book && book.pricing.pricing === 'subscription';
}

export function priceAmount(book) {
  return book && book.pricing.price && book.pricing.price.amount / 100;
}

export function getLocation(book, chapterUuid, location) {
  if (location) return location;
  const progress = book?.progress;
  return progress?.chapter === chapterUuid ? progress.cfiOffset ?? 1 : 1;
}

export function getPrevChapterUuid(book, currentChapterUuid) {
  const chapters = book.chapters;
  const found = chapters.findIndex(chapter => chapter.uuid === currentChapterUuid);
  if (found > 0) return chapters[found - 1].uuid;
  return null;
}

export function findLastChapter(book) {
  const chapLen = book?.chapters?.length;
  if (chapLen > 0) return book.chapters[chapLen - 1].uuid;
  else return null;
}

export function getNextChapterUuid(book, currentChapterUuid) {
  const chapters = book.chapters;
  const found = chapters.findIndex(chapter => chapter.uuid === currentChapterUuid);
  if (found < chapters.length - 1) return chapters[found + 1].uuid;
  return null;
}

export function getChapterToRead(book) {
  let publicChapter = null;
  let readingChapter = null;
  let purchasedChapter = null;
  let freeChapter = null;
  for (let chapter of (book.chapters || [])) {
    if (chapter.public) {
      publicChapter = chapter;
    }
    if (chapter.uuid === book.progress?.chapter) {
      readingChapter = chapter;
    } else if (chapter.free) {
      freeChapter = chapter;
    } else if (chapter.purchased) {
      purchasedChapter = chapter;
    }
  }

  return (isAuthenticated() && (readingChapter || purchasedChapter || freeChapter || book.chapters?.[0])) || publicChapter;
}

export function isChapterAvailable(book, chapter, account) {
  console.log("isChapterAvailable.Book- ",JSON.stringify(book));
  if (chapter?.public) return true;
  if (isAuthenticated()) {
    if (isFree(book) || chapter?.free) return true;
    if (chapter?.purchased || book?.pricing.state === 'purchased') return true;
    if (book?.author?.uuid === account?.uuid || account?.admin) return true;
  }
  return false;
}
