import React from 'react';

export const PlayIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48842 0 0 4.48625 0 10.0058C0 15.5137 4.48842 20 10 20C15.5116 20 20 15.5137 20 10.0058C20 4.48625 15.5116 0 10 0" fill="#6340EF"/>
      <path d="M13.9382 10.7091C13.9093 10.7477 13.7741 10.9117 13.6679 11.0178L13.61 11.0757C12.7992 11.9537 10.7818 13.2754 9.75868 13.7C9.75868 13.7096 9.15057 13.9604 8.861 13.9701H8.82239C8.37837 13.9701 7.96331 13.7192 7.75096 13.314C7.63513 13.0921 7.52895 12.4457 7.5193 12.4361C7.43243 11.8572 7.37451 10.9706 7.37451 9.99517C7.37451 8.9725 7.43243 8.04631 7.5386 7.47709C7.5386 7.46744 7.64478 6.94645 7.71235 6.77279C7.81853 6.52195 8.01158 6.30969 8.25289 6.17463C8.44594 6.07815 8.64864 6.02991 8.861 6.02991C9.08301 6.03956 9.49806 6.18524 9.66216 6.25181C10.7432 6.67631 12.8089 8.0656 13.6004 8.91462C13.7355 9.04969 13.8803 9.2137 13.9189 9.25229C14.083 9.46454 14.1699 9.72504 14.1699 10.0058C14.1699 10.2557 14.0927 10.5065 13.9382 10.7091Z" fill="white"/>
    </svg>
  )
}
