import { ACTION_TYPES } from 'src/modules/reducers/theme.reducer';
import lightTheme from 'src/styles/theme/light.module.scss';
import darkTheme from 'src/styles/theme/dark.module.scss';

export function getTheme(themeName) {
  return (themeName === "light") ? lightTheme : darkTheme;
}

export function setThemDark() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.CHANGED, theme: "dark"});
  }
}

export function setThemLight() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.CHANGED, theme: "light"});
  }
}
