export const ACTION_TYPES = {
  FETCH_BG_FILES_PENDING: 'bgsounds/FETCH_BG_FILES_PENDING',
  FETCH_BG_FILES_FULFILLED: 'bgsounds/FETCH_BG_FILES_FULFILLED',
  FETCH_BG_FILES_REJECTED: 'bgsounds/FETCH_BG_FILES_REJECTED',

  BG_FILES_ON: 'bgsounds/BG_FILES_ON',
  BG_FILES_OFF: 'bgsounds/BG_FILES_OFF',
  DELETE_BG_FILE_PENDING: 'bgsounds/DELETE_BG_FILE_PENDING',
  DELETE_BG_FILE_SUCCESS: 'bgsounds/DELETE_BG_FILE_SUCCESS',
  DELETE_BG_FILE_REJECTED: 'bgsounds/DELETE_BG_FILE_REJECTED',

  SAVE_BG_FILE_PENDING: 'bgsounds/SAVE_BG_FILE_PENDING',
  SAVE_BG_FILE_FULFILLED: 'bgsounds/SAVE_BG_FILE_FULFILLED',
  SAVE_BG_FILE_REJECTED: 'bgsounds/SAVE_BG_FILE_REJECTED',

  TOGGLE_FORM: 'bgsounds/TOGGLE_FORM',
  CLEAR_FORM_ERROR: 'bgsounds/CLEAR_FORM_ERROR',

  SAVE_SAMPLE_ID: 'bgsounds/SAVE_SAMPLE_ID'
}

const formDefaultState = {
  form: undefined,
  savingSound: undefined,
  soundError: undefined
}

const defaultDeleteState = {
  deleteDialog: undefined,
  deletingSound: undefined,
  deletePending: undefined
}

const defaultState = {
  loading: undefined,
  files: [],
  generatedSamples: undefined,
  ...defaultDeleteState
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BG_FILES_PENDING:
      return {
        ...state,
        loading: true
      }
    case ACTION_TYPES.FETCH_BG_FILES_FULFILLED:
      return {
        ...state,
        loading: false,
        files: action.files
      }
    case ACTION_TYPES.FETCH_BG_FILES_REJECTED:
      return {
        ...state,
        loading: false
      }
    case ACTION_TYPES.BG_FILES_ON:
      return {
        ...state,
        deleteDialog: true,
        deletingSound: action.deletingSound
      }
    case ACTION_TYPES.BG_FILES_OFF:
      return {
        ...state,
        ...defaultDeleteState
      }
    case ACTION_TYPES.DELETE_BG_FILE_PENDING:
      return {
        ...state,
        deletePending: true
      };
    case ACTION_TYPES.DELETE_BG_FILE_SUCCESS:
      return {
        ...state,
        ...defaultDeleteState,
        ...formDefaultState,
        files: state.files.filter(p => p.uuid !== action.uuid)
      };
    case ACTION_TYPES.DELETE_BG_FILE_REJECTED:
      return {
        ...state,
        ...defaultDeleteState,
        soundError: action.error
      };
    case ACTION_TYPES.TOGGLE_FORM:
      return {
        ...state,
        ...formDefaultState,
        form: !state.form
      };
    case ACTION_TYPES.CLEAR_FORM_ERROR:
      return {
        ...state,
        soundError: undefined
      }
    case ACTION_TYPES.SAVE_BG_FILE_PENDING:
      return {
        ...state,
        savingSound: true
      }
    case ACTION_TYPES.SAVE_BG_FILE_FULFILLED:
      return {
        ...state,
        ...formDefaultState
      }
    case ACTION_TYPES.SAVE_BG_FILE_REJECTED:
      return {
        ...state,
        savingSound: undefined,
        soundError: action.error
      }
    case ACTION_TYPES.SAVE_SAMPLE_ID:
      return {
        ...state,
        generatedSamples: { ...(state.generatedSamples || []), [action.hash]: action.id }
      }
    default:
      return state;
  }
}