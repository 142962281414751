import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'carbon-components-react';

import { submitChapterForm } from 'src/modules/actions/books.actions';

const MakeBookVisible = ({ modalOpen, bookUuid, formValues, onJustClose, submitChapterForm, before }) => {
  
  const _onSecondarySubmit = () => {
    submitChapterForm(bookUuid, formValues, false, false, before);
    onJustClose();
  };

  const _onRequestSubmit = () => {
    submitChapterForm(bookUuid, formValues, false, true, before);
    onJustClose();
  };

  return (
    <Modal
      aria-label=''
      open={modalOpen}
      onRequestClose={onJustClose}
      onSecondarySubmit={_onSecondarySubmit}
      onRequestSubmit={_onRequestSubmit}
      primaryButtonText='Make Visible'
      secondaryButtonText='Keep Hidden'
      modalHeading='Make the book visible?'
    >
      <p className={'bx--modal-content__text'}>
        Your book is not visible to the general audience. Do you want to make it visible?
      </p>
    </Modal>
  )
};

MakeBookVisible.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onJustClose: PropTypes.func,
  formValues: PropTypes.any,
  submitChapterForm: PropTypes.func,
  bookUuid: PropTypes.string
};

function mapStateToProps(state) {
  return {
    loading: state.books.loading,
  }
}

const mapDispatchToProps = {
    submitChapterForm
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeBookVisible);
