import React from 'react';
import PropTypes from 'prop-types';
import cs from './Paragraph.module.scss';
import { Badge, badgeTypes } from './Badge';
import classNames from 'classnames';
import { AddIcon } from './icons/AddIcon';
import { RecordIcon } from './icons/RecordIcon';
import { PlayIcon } from './icons/PlayIcon';

export const Paragraph = ({
                            uuid,
                            text,
                            badges,
                            className,
                            selected = false,
                            disabled = false,
                            onClick,
                            onPlay,
                            onRecord,
                            onUpload,
                            hasAudio,
                            canRecord = true,
                            canUpload = true,
                            isUploading = false, 
                            style}) => {

  const handleRecord = (e) => {
    e.stopPropagation();
    if (canRecord) {
      onRecord(uuid);
    }
  }

  const handlePlay = (e) => {
    e.stopPropagation();
    onPlay(uuid);
  }

  const handleUpload = (e) => {
    e.stopPropagation();
    if (canUpload) {
      onUpload(uuid);
    }
  }

  let textStyle = style
  if (selected) {
    textStyle = {
      ...style,
      color: 'white',
    }
  }

  return (

    <div
      className={classNames(className, cs.paragraph, {
        [cs.selected]: selected,
        [cs.disabled]: disabled,
        [cs.isUploading]: isUploading ,
      })}
      style={textStyle}
    >
      <div className={cs.badges}>
        {badges.map((type, i) => <Badge key={i} type={type}/>)}
      </div>
      {text}

      {hasAudio ? (
        <div className={classNames(cs.icon, cs.iconPlay)} onClick={handlePlay}><PlayIcon/></div>
        ) : (
          <>
            {
              canUpload &&
              <div
                className={classNames(cs.icon, cs.iconAdd, { [cs.disabled]: !canUpload })}
                onClick={handleUpload}>
                <AddIcon />
              </div>
            }
            {
              canRecord &&
              <div
                className={classNames(cs.icon, cs.iconRecord, { [cs.disabled]: !canUpload })}
                onClick={handleRecord}>
                <RecordIcon />
              </div>
            }
          </>
        )
      }
    </div>

  )
}

Paragraph.propTypes = {
  uuid: PropTypes.string,
  text: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(badgeTypes))),
  className: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  hasAudio: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onRecord: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  canRecord: PropTypes.bool,
  canUpload: PropTypes.bool,
}
