import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'carbon-components-react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cs from './styles.module.scss';
import { fetchCategory, cleanUp } from 'src/modules/actions/home.actions';
import AppLayout from 'src/layout/AppLayout';
import cn from "classnames";
import BookCell from 'src/components/_shared/Book/BookCell';

const SeeAllPage = ({ match, books, loading, categoryName, ...actions}) => {

  const { fetchCategory, cleanUp } = actions;

  useEffect(() => {
    fetchCategory(match.params.id);
  }, [fetchCategory, match]);

  useEffect(() => {
    return () => {
      cleanUp();
    }
  }, [cleanUp]);
  return (
    <>
      <Loading active={loading}/>
      <AppLayout>
        <div className='bx--grid'>
          <div className={cn('bx--row', [cs.title])}>
            <div className='bx--col'>
              <h3 style={{ marginRight: '3rem'}}>{categoryName}</h3>
            </div>
          </div>
        </div>
        { !loading && books &&
          <div className={cs.cards}>
            {
              books.map((book, index) => {
                return (
                  <BookCell key={index}
                            book={book.book}
                            author={book.author}
                            updatedAt={book.updatedAt}
                            isNew={book.new}
                  />
                )
              })
            }
          </div>
        }
      </AppLayout>
    </>
  )
};

SeeAllPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  books: PropTypes.array.isRequired,
  categoryName: PropTypes.string.isRequired,

  fetchCategory: PropTypes.func.isRequired,

  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.home.loading,
    books: state.home.books,
    categoryName: state.home.categoryName
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCategory, cleanUp }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeAllPage);
