import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';

export async function getBooks() {
  try {
    return await axios.get('/me/books', getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getNotificationsConfig() {
  try {
    const token = getAuthHeader();
    return await axios.get('/me/notifications', token);
  } catch (error) {
    return error.response;
  }
}

export async function saveNotificationsConfig(notificationsConfig) {
  try {
    return await axios.put('/me/notifications', notificationsConfig, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function requestEmailVerification() {
  try {
    return await axios.post('/verify', {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
