import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import cs from './ReadingPopup.module.scss';

const ReadingPopup = ({ children, show, className, style }) => {

  return (
    <>
      {
        show ?
          <div className={classNames(cs.popup, className)} style={style}>
            <div>{children}</div>
          </div> : null
      }
    </>
  )
}

ReadingPopup.propTypes = {
  show: PropTypes.any,
  className: PropTypes.object,
  style: PropTypes.object
}

export default ReadingPopup;
