export const ACTION_TYPES = {
  INFO_MESSAGE: 'application/INFO_MESSAGE',
  FULL_SCREEN_MODE: 'application/FULL_SCREEN_MODE',

  STATUS: 'application/STATUS'
};

const initialState = {
  infoMessage: null,
  fullScreen: false,
  status: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.INFO_MESSAGE:
      return {
        ...state,
        infoMessage: action.infoMessage
      };
    case ACTION_TYPES.FULL_SCREEN_MODE:
      return {
        ...state,
        fullScreen: action.fullScreen
      };

    case ACTION_TYPES.STATUS:
      return {
        ...state,
        status: action.status
      };


    default:
      return state;
  }
}
