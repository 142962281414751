import React from 'react';

import basicModalPage from 'src/layout/basicModalPage';
import history from 'src/history';

import cs from './ForgotPasswordEmailSent.module.scss';

const ForgotPasswordEmailSentPage = () => {
  return (
    <>
      <div className={cs.title}>
        Restore Password email sent
      </div>
      <div className={cs.text}>
        You will receive a password restoration email shortly.
        Please follow the link in the email to restore your password.
      </div>
    </>
  )
}

const formStyles = {
  paddingTop: 36,
  paddingBottom: 48
}

export default basicModalPage(ForgotPasswordEmailSentPage, null, formStyles, () => { history.push('/') });
