import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'carbon-components-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  toggleDeletePersonaDialog,
  deletePersona
} from 'src/modules/actions/personas.actions';

const DeletePersonaDialog = ({ modalOpen, ...actions }) => {

  return (
    <Modal
      aria-label=''
      open={modalOpen}
      onRequestClose={() => actions.toggleDeletePersonaDialog()}
      onSecondarySubmit={() => actions.toggleDeletePersonaDialog()}
      onRequestSubmit={actions.deletePersona}
      primaryButtonText='Ok'
      secondaryButtonText='Cancel'
      modalHeading={'Warning'}
      primaryButtonDisabled={false}
    >
      <p className={'bx--modal-content__text'} style={{ marginBottom: '2rem' }}>
        Delete this character?
      </p>
    </Modal>
  );
}

DeletePersonaDialog.propTypes = {
  toggleDeletePersonaDialog: PropTypes.func,
  modalOpen: PropTypes.bool,
  pendingRequest: PropTypes.bool,
  deletePersona: PropTypes.func
}

function mapStateToProps(state) {
  return {
    modalOpen: !!state.personas.deletePersonaDialog,
    pendingRequest: state.personas.deletingPersona
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleDeletePersonaDialog,
    deletePersona
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePersonaDialog);
