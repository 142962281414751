import React from 'react';
import PropTypes from 'prop-types';
import { Tile, Button, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';

import history from 'src/history';
import classes from './index.module.scss';
import { RES_PATH } from 'src/config/environment';

export const BookCard = ({ match, uuid, name, image, chaptersCount, readersCount, published, ...actions }) => {

  const visibilityText = published ? "Hide from Users" : "Make visible";

  return (
    <Tile className={classes.bookCard}>
      <div
        className={classes.poster}
        style={{ backgroundImage: `url("${RES_PATH}${image}")`, backgroundSize: 'cover' }}
        onClick={() => history.push(`${match.url}/${uuid}`)}
      />
      <div className={classes.desc}>

        <div>
          <div className={classes.title} title={name}>{name}</div>
          <div className={classes.counters}>
            <div>{chaptersCount} chapters</div>
            <div>{readersCount} readers</div>
          </div>
        </div>

        <div className={classes.toolbar}>
          <Button
            size='small'
            kind='secondary'
            onClick={() => history.push(`${match.url}/${uuid}/chapter/add`)}
          >
            Add chapter
          </Button>

          <OverflowMenu>
            <OverflowMenuItem
              itemText="Edit Book"
              onClick={() => history.push(`${match.url}/${uuid}/edit`)}
            />
            <OverflowMenuItem itemText={visibilityText}
                              onClick={() => actions.toggleBookVisibility(uuid, !published)}
            />
            <OverflowMenuItem
              itemText="Delete"
              isDelete
              onClick={actions.onDelete}
            />
          </OverflowMenu>
        </div>

      </div>
    </Tile>
  );
};

BookCard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  chaptersCount: PropTypes.number.isRequired,
  readersCount: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  published: PropTypes.bool.isRequired,
  toggleBookVisibility: PropTypes.func.isRequired
};

export default BookCard;
