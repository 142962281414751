import React from 'react';
import PropTypes from 'prop-types';

import cs from './styles.module.scss';
import Delimiter from 'src/components/_shared/Delimiter';

const BookDescription = ({ children }) => {

  return (
    <div className={cs.description}>
      <div className={cs.descriptionTitle}>
        Description
        <Delimiter style={{marginTop: "8px"}} />
      </div>

      <div className={cs.descriptionText}>{children}</div>
    </div>
  )
};

BookDescription.propTypes = {
  children: PropTypes.any
};

export default BookDescription;
