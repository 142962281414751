import React from 'react';

export const RecorderStartIcon = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="34" cy="34" r="34" fill="#63302A"/>
      <circle cx="34" cy="34" r="28" fill="#EE432C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.1748 37.2174H33.825C31.4217 37.2174 29.4736 35.2927 29.4736 32.9203V28.2971C29.4736 25.9237 31.4217 24 33.825 24H34.1748C36.5781 24 38.5272 25.9237 38.5272 28.2971V32.9203C38.5272 35.2927 36.5781 37.2174 34.1748 37.2174ZM40.5626 32.7829C40.5626 32.2539 40.9966 31.8263 41.5313 31.8263C42.066 31.8263 42.5 32.2539 42.5 32.7829C42.5 37.0866 39.2006 40.6404 34.9692 41.1178V43.0434C34.9692 43.5714 34.5352 44 34.0005 44C33.4648 44 33.0318 43.5714 33.0318 43.0434V41.1178C28.7994 40.6404 25.5 37.0866 25.5 32.7829C25.5 32.2539 25.934 31.8263 26.4687 31.8263C27.0034 31.8263 27.4374 32.2539 27.4374 32.7829C27.4374 36.3558 30.3814 39.2629 34.0005 39.2629C37.6186 39.2629 40.5626 36.3558 40.5626 32.7829Z" fill="white"/>
    </svg>
  )
}
