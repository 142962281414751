import React, { useEffect, useState } from 'react';
import { SwatchesPicker } from 'react-color';

import cs from './styles.module.scss';

export const ColorPicker = ({options, color, property, disable, didChange}) => {
  const [initialColor, setInitialColor] = useState(color);
  const [colorValue, setColorValue] = useState(color);

  const colors = options.map(item =>[item.value])

  useEffect(() => {
    if (color !== initialColor) {
      setInitialColor(color)
      setColorValue(color)
    }
}, [initialColor, color]);


  return (
    <div className={cs.row}>
    <SwatchesPicker 
      colors={colors}
      color={colorValue}
      height={128}
      onChangeComplete={item => {
          didChange(item.hex);
          setColorValue(item.hex);
        }
      }
    />
    </div>
  )
};

export default ColorPicker;
