import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, TextInput, Button, RadioButton, RadioButtonGroup, InlineNotification } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import { useQuery } from 'src/hooks';
import common from 'src/components/auth/_layout/common.module.scss';
import classes from './CreateAccountPage.module.scss';
import Layout from 'src/components/auth/_layout';
import { createAccount, displayAuthError, hideMessages, fbAuthenticate, glAuthenticate } from 'src/modules/actions/authentication.actions';
import { useHideErrorsEffect } from 'src/components/auth/_layout/effects';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import history from 'src/history';

export const CreateAccountPage = ({ createAccount, displayAuthError, hideMessages, isAuthorByDefault, ...props }) => {

  const query = useQuery();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isWriter, setIsWriter] = useState(isAuthorByDefault);
  useHideErrorsEffect({ hideMessages });

  const [key, setKey] = useState(Math.random());
  React.useEffect(() => {
    setTimeout(() => setKey(Math.random()), 100);
  }, []);

  const _createAccount = () => {
    if (password !== '' && password !== confirmation) {
      displayAuthError('Password and confirmation must match');
    } else {
      createAccount(
        { name: '', password, email, isWriter },
        query.get("secret"),
        query.get("badge"),
        query.get("code"),
        "register_email",
        props?.location?.state
      );
    }
  };

  const _onChangeUserKind = kind => {
    setIsWriter(kind === 'writer');
  };

  return <Layout brand="Fictionate.me" title="Create Account">
    { ({ message, messageKind, messageTitle, hideMessages }) =>
    <Form>
      <div className="bx--grid bx--grid--full-width">

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-4',
              'bx--col-md-2',
              common.formTitle
            )}
          >
            E-mail*
          </div>
          <div className={cn(
              'bx--col-lg-12',
              'bx--col-md-6'
            )}
          >
            <FormGroup legendText=''>
              <TextInput
                labelText=''
                id="email"
                placeholder="Type your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete='username'
              />
            </FormGroup>
          </div>
        </div>


        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-4',
              'bx--col-md-2',
              common.formTitle
            )}
          >
            Password*
          </div>

          <div
            className={cn(
              'bx--col-lg-6',
              'bx--col-md-3'
            )}
          >
            <FormGroup legendText=''>
              <TextInput
                labelText=''
                type="password"
                required
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete='new-password'
              />
            </FormGroup>
          </div>

          <div
            className={cn(
              'bx--col-lg-6',
              'bx--col-md-3'
            )}
          >
            <FormGroup legendText=''>
              <TextInput
                labelText=''
                type="password"
                required
                id="confirmation"
                value={confirmation}
                onChange={e => setConfirmation(e.target.value)}
                autoComplete='new-password'
              />
            </FormGroup>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--offset-lg-4',
              'bx--col-md-6',
              'bx--offset-md-2',
              'bx--form__helper-text',
              classes.passHelperText
            )}
          >
            Use 8 or more characters with a mix of uppercase & lowercase letters, numbers & special symbols.
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--offset-lg-4',
              'bx--col-md-6',
              'bx--offset-md-2'
            )}
          >
            <FormGroup legendText=''>
              <RadioButtonGroup
                orientation="horizontal"
                onChange={_onChangeUserKind}
                name="userKind"
                defaultSelected={isAuthorByDefault ? "writer" : "reader"}
              >
                <RadioButton id="radio-1" labelText="I'm a reader" value="reader"/>
                <RadioButton id="radio-2" labelText="I'm a writer" value="writer"/>
              </RadioButtonGroup>
            </FormGroup>
          </div>
        </div>

        {message &&
        <div className="bx--row" style={{ justifyContent: 'space-around'}}>
          <InlineNotification
            kind={messageKind}
            title={messageTitle}
            subtitle={message}
            iconDescription="close"
            onCloseButtonClick={hideMessages}
            lowContrast
            className={classes.error}
          />
        </div>
        }

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-8',
              'bx--offset-lg-4',
              'bx--col-md-4',
              'bx--offset-md-2',
              common.center
            )}
          >
            <FormGroup legendText=''>
              <Button
                className={classes.createButton}
                onClick={_createAccount}
              >
                Create
              </Button>
            </FormGroup>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--offset-lg-4',
              'bx--col-md-6',
              'bx--offset-md-2',
              classes.already
            )}
            style={{ paddingLeft: '1rem', marginBottom: 8 }}
          >
            Already have an Account? <span
            style={{ color: '#0062ff', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              history.push('/login', props?.location?.state) }
            }>
              Sign In
            </span>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-8',
              'bx--offset-lg-4',
              'bx--col-md-4',
              'bx--offset-md-2',
              common.center
            )}
          >
            <FormGroup legendText=''>
              <FacebookLogin
                key={key}
                autoLoad={false}
                appId="806367996457764"
                fields="name,email,picture"
                size="small"
                buttonStyle={{ height: 43, maxWidth: 320, fontSize: '14px' }}
                disableMobileRedirect={true}
                callback={(response) => {
                  console.log("FACEBOOK RESPONSE");
                  console.log(response);
                  props.fbAuthenticate(
                    response.email,
                    response.accessToken,
                    response?.picture?.data?.url,
                    response.userID,
                    "register_facebook",
                    props?.location?.state
                  );
                }}
              />
              <div style={{height: '1rem'}}/>
              <div style={{ maxWidth: 320, margin: 'auto' }}>
                <GoogleLogin
                  clientId="655527373412-jqjleadd3ht5krtantuhfp3hc7jjs93l.apps.googleusercontent.com"
                  buttonText="LOGIN WITH GOOGLE"
                  theme='dark'
                  onSuccess={(response) => {
                    console.log("GOOGLE RESPONSE");
                    console.log(response);
                    props.glAuthenticate(response.tokenId, "register_google", props?.location?.state)
                  }}
                  onFailure={(response) => {
                    console.log("Failed Google Response");
                    console.log(response);
                  }}
                />
              </div>
            </FormGroup>
          </div>
        </div>

      </div>
    </Form>
    }
  </Layout>
};

CreateAccountPage.propTypes = {
  createAccount: PropTypes.func.isRequired,
  displayAuthError: PropTypes.func.isRequired,
  hideMessages: PropTypes.func.isRequired,
  fbAuthenticate: PropTypes.func.isRequired,
  glAuthenticate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthorByDefault: state.authentication.isAuthor
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createAccount, displayAuthError, hideMessages, fbAuthenticate, glAuthenticate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);
