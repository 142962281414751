import * as cookiesApi from 'src/modules/api/cookie.api';
import moment from 'moment';

export const dateOpts = {month: 'short', day: 'numeric', year: 'numeric'};

export const shortDateFormat = timestampValue => {
  return new Date(timestampValue * 1000).toLocaleDateString("en-US", dateOpts)
};

export const timeAgo = timestampValue => {
  const now = new Date();
  const nowMoment = moment(now);
  const pastMoment = moment(new Date(timestampValue * 1000));
  return pastMoment.from(nowMoment);
};

export const RESPONSE_TIME = 300;

export const sleep = (ms = RESPONSE_TIME) => new Promise(resolve => setTimeout(resolve, ms));

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const getAuthHeader = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const urlToken = queryParameters.get("AuthToken")
  if(urlToken) {
    return { headers: { [cookiesApi.TOKEN_KEY]: urlToken } }
  }
  if (!cookiesApi.isAuthenticated()) return {};
  else return { headers: { [cookiesApi.TOKEN_KEY]: cookiesApi.getAuthToken() } };
};

export function ext(filename) {
  let a = filename.split(".");
  if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
    return "";
  }
  return a.pop().toLowerCase();
}

export async function resizeImage(file, scaleWeight, scaledHeight, saveOriginalProportion = true) {
  if (file) {
    const originalCanvas = await drawOriginalImage(file);
    const resizedCanvas = resizeCanvas(originalCanvas, originalCanvas.width, originalCanvas.height, scaleWeight, scaledHeight, saveOriginalProportion);
    const imageData = resizedCanvas.toDataURL("image/jpeg");
    return imageData
  }
}

const resizeCanvas = (i, origWidth, origHeight, scaledWidth, scaledHeight, saveOriginalProportion) => {
  const canvas = document.createElement("canvas");
  if (saveOriginalProportion) {
    const pr = Math.max(origWidth, origHeight) / Math.min(origWidth, origHeight);
    canvas.width = origWidth > origHeight ? scaledHeight * pr : scaledWidth;
    canvas.height = origHeight > origWidth ? scaledWidth * pr : scaledHeight;
  } else {
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
  }
  const ctx = canvas.getContext("2d");
  ctx.drawImage(i, 0, 0, canvas.width, canvas.height);
  return canvas;
};

const drawOriginalImage = file => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext('2d');
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const img = new Image();
      img.onload = function() {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas);
      }
      img.src = event.target.result;
    }
    reader.readAsDataURL(file);
  });
}

export function genMailto() {
  const coded = "JydR@dJuXJRyDXY.qY";
  const key = "FpkoAIT5WbyreDh2JU9ZfRVcLSmCzQYHElxP3Kd4su8tqjv0GBg7OMnwNa16iX";
  const shift=coded.length;
  let link="";
  let ltr;
  for (let i = 0; i < coded.length; i++) {
    if (key.indexOf(coded.charAt(i)) === -1) {
      ltr = coded.charAt(i);
      link += (ltr);
    }
    else {
      ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length;
      link += (key.charAt(ltr));
    }
  }
  return link;
}