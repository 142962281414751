import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cs from './Uploader.module.scss';
import { CloseIcon } from './icons/CloseIcon';
import { useDropzone } from 'react-dropzone'
import { WaveImage } from './images/WaveImage';
import { UpArrowImage } from './images/UpArrowImage';
import classNames from 'classnames';

export const Uploader = ({ onClose, onUpload }) => {

  const onDrop = useCallback(acceptedFiles => {
    onUpload(acceptedFiles[0]);
  }, [onUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop, accept: 'audio/mpeg', multiple: false });

  return (
    <div className={cs.uploader}>

      { !isDragActive && (
        <div className={cs.close} onClick={onClose}>
          <CloseIcon/>
        </div>
      )}

      <div
        className={classNames(cs.dropZone, {
          [cs.activeZone] : isDragActive,
          [cs.nonActiveZone]: !isDragActive,
        })}
        { ...getRootProps() }
      >
        <input {...getInputProps()} />
        {
          isDragActive ? (

            <>
              <UpArrowImage />
              <div>Drop your file to upload</div>
            </>

          ) : (

            <>
              <WaveImage />
              <div>Drag & drop a file to upload</div>
              <div>or</div>
              <div>browse file</div>
            </>

          )
        }
      </div>
    </div>
  )
}

Uploader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}
