import React, { useState, useRef } from 'react';
import PT from 'prop-types';
import { Tooltip, Button} from 'carbon-components-react';
import { TextScale20 } from '@carbon/icons-react';
import cn from 'classnames';

import cs from './themes.module.scss';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { isFontBiggerDisabled, isFontSmallerDisabled } from 'src/components/reader/ReadingPage/helpers';

const style = {
  triggerButton: { maxWidth: '48px' },
  tooltipContainer: { margin: '-1rem'},
  btn: { width: '50%', paddingRight: '1rem', display: 'inline-flex', justifyContent: 'center', fontWeight: 'bold' },
};

const FontSizeButton = ({ onMore, onLess, theme, ...rest }) => {
  const [open, setOpen] = useState(false);
  const onMouseDown = () => { if (!open) setOpen(true) };
  const container = useRef(null);
  useOnClickOutside(container, () => setOpen(false));

  const [moreDis, setMoreDis] = useState(isFontBiggerDisabled());
  const [lessDis, setLessDis] = useState(isFontSmallerDisabled());

  const checkDisabledStatus = () => {
    setLessDis(isFontSmallerDisabled());
    setMoreDis(isFontBiggerDisabled());
  };

  const _onLess = () => {
    onLess();
    checkDisabledStatus();
  };

  const _onMore = () => {
    onMore();
    checkDisabledStatus();
  };

  return (
    <div className={[cs[theme.name]]}>
      <Tooltip direction='bottom'
               showIcon={false}
               open={open}
               triggerText={
                 <Button kind='ghost'
                         style={style.triggerButton}
                         onMouseDown={onMouseDown}
                         className={cs.btn}
                         disabled={!rest.chapterAvailable}
                 >
                   <TextScale20/>
                 </Button>
               }
               className={cn(cs.tooltip, cs[`tooltip__${theme.name}`])}
      >
        <div style={style.tooltipContainer} ref={container}>
          <Button kind="ghost"
                  style={{...style.btn, fontSize: '0.8em'}}
                  onClick={_onLess}
                  disabled={lessDis}
                  className={cs.btn}
          >
            <div>T</div>
          </Button>

          <Button kind="ghost"
                  onClick={_onMore}
                  style={{...style.btn, fontSize: '1.2em'}}
                  disabled={moreDis}
                  className={cs.btn}
          >
            <div>T</div>
          </Button>
        </div>
    </Tooltip>
    </div>
  )
};

FontSizeButton.propTypes = {
  onLess: PT.func.isRequired,
  onMore: PT.func.isRequired,
  theme: PT.any.isRequired,
  chapterAvailable: PT.any
};

export default FontSizeButton;
