import React, { useEffect, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';

import cs from './styles.module.scss';

export const EmojiSelect = ({emoji, property, disable, didChange}) => {
  const [initialValue, setInitialValue] = useState(emoji);
  const [value, setValue] = useState(emoji);

  useEffect(() => {
    if (emoji !== initialValue) {
      setInitialValue(emoji)
      setValue(emoji)
    }
}, [emoji, initialValue]);


  return (
    <div className={cs.row}>
      <div className={cs.preview}>{value}</div>
      <EmojiPicker 
        onEmojiClick={item => {
            didChange(item.emoji);
            setValue(item.emoji);
        }} 
        height={328}
        previewConfig={{showPreview: false}}
      />
    </div>
  )
};

export default EmojiSelect;
