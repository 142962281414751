import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { getAuthHeader } from 'src/modules/api/utils';
import ps from 'src/modules/model/payout-state';
import { PAYOUT_KEY } from 'src/config/environment';

export function useStripePayouts() {
  const [loading, setLoading] = useState(false);
  const [payoutState, setPayoutState] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchState = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get('/me/payouts/state', getAuthHeader());
      if (res?.status === 200) {
        setPayoutState(res.data.state);
        if (res.data.state === ps.TRANSFERS_INACTIVE) {
          setError("Transfers are disabled");
          setErrorMessage(`Stripe account is missing following information: [${res.data.requirenments.map(item=>` ${item.name}`)} ]`)
        } else {
          setError(null);
        }
      } else {
        setPayoutState(ps.API_ERROR);
      }
    } catch (e) {
      setPayoutState(e?.response?.data?.error ?? ps.API_ERROR);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchState();
  }, [fetchState]);

  const createStripeAccount = useCallback(async (country) => {
    setLoading(true);
    try {
      const res = await axios.post('me/payouts/create', {country}, getAuthHeader());
      return res.data.url
    } catch (e) {
      setError("Cannot get configuration url.");
    } finally {
      setLoading(false);
    }
  }, []);

  const configurePayouts = useCallback(async (email) => {
    setLoading(true);
    try {
      const res = await axios.get('me/payouts/start', getAuthHeader());
      const state = res.data.state;
      const urlToRedirect = `https://connect.stripe.com/express/oauth/authorize?
redirect_uri=payouts_configured&
client_id=${PAYOUT_KEY}&
state=${state}&
suggested_capabilities[]=transfers&
suggested_capabilities[]=card_payments&
stripe_user[email]=${encodeURIComponent(email)}&
stripe_user[url]=${encodeURIComponent("https://fictionate.me")}&
stripe_user[business_type]=individual&
stripe_user[business_name]=${encodeURIComponent("Fictionate.me")}`;
      document.location.href = urlToRedirect;
    } catch (e) {
      setError("Cannot get configuration url.");
    } finally {
      setLoading(false);
    }
  }, []);

  const openDashboard = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get('/me/payouts/dashboard', getAuthHeader());
      document.location.href = res.data.link;
    } catch (e) {
      setError("Cannot open the dashboard.");
    } finally {
      setLoading(false);
    }
  }, [])

  const resetPayouts = useCallback(async () => {
    setLoading(true);
    try {
      await axios.delete('/me/payouts', getAuthHeader());
    } catch (e) {
      setError("Cannot open the dashboard.");
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    let handler = null;
    // if (error) {
    //   handler = setTimeout(() => setError(null), 3000);
    // }
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    }
  }, [error])

  return {
    loading, payoutState, configurePayouts, createStripeAccount,  error, errorMessage, openDashboard, resetPayouts, fetchState
  }
}
