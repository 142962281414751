import React from 'react';
import { FormGroup, TextInput } from 'carbon-components-react';

export const InputText = ({ input, meta, type, placeholder, id, disabled = false }) => {
  return (
    <FormGroup legendText=''>
      <TextInput
        {...input}
        type={type}
        invalid={meta.touched && meta.error && true}
        invalidText={meta.error}
        placeholder={placeholder}
        labelText=''
        id={id}
        disabled={disabled}
      />
    </FormGroup>
  )
};

export default InputText;
