import React from 'react';

export default () => {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Comment-no-picture" transform="translate(-2.000000, -2.000000)" fill="#FFFFFF">
          <g id="Person-/-User-/-user--avatar--filled--alt-/-32">
            <path d="M18,2.25 C9.30151519,2.25 2.25,9.30151519 2.25,18 C2.25,26.6984848 9.30151519,33.75 18,33.75 C26.6984848,33.75 33.75,26.6984848 33.75,18 C33.75,13.8228403 32.0906298,9.81676616 29.1369318,6.8630682 C26.1832338,3.90937024 22.1771597,2.25 18,2.25 Z M27,28.033875 L27,27.349875 C27.0650442,24.1587215 24.5642726,21.502402 21.375,21.375 L14.625,21.375 C11.4381894,21.5030328 8.94121144,24.1601376 9.01125,27.34875 C9.01125,27.361125 9,27.3735 9,27.385875 L9,28.044 C4.8218616,24.314453 3.37905805,18.3931723 5.37321995,13.1596573 C7.36738184,7.9261422 12.3846985,4.46640516 17.9852653,4.46290215 C23.5858322,4.45940445 28.6074696,7.91286715 30.6081718,13.1438854 C32.608874,18.3749037 31.1734732,24.2979832 27,28.03275 L27,28.033875 Z M18,7.875 C14.8933983,7.875 12.375,10.3933983 12.375,13.5 C12.375,16.6066017 14.8933983,19.125 18,19.125 C21.1066017,19.125 23.625,16.6066017 23.625,13.5 C23.625,10.3933983 21.1066017,7.875 18,7.875 L18,7.875 Z" id="Fill"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
