import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, FormGroup, TextInput, Button, InlineNotification } from 'carbon-components-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import common from 'src/components/auth/_layout/common.module.scss';
import classes from './LoginPage.module.scss';
import { authenticate, fbAuthenticate, glAuthenticate, hideMessages } from 'src/modules/actions/authentication.actions';
import Layout from 'src/components/auth/_layout';
import { useHideErrorsEffect } from 'src/components/auth/_layout/effects';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import history from 'src/history';

export const LoginPage = (props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  useHideErrorsEffect(props);

  const [key, setKey] = useState(Math.random());
  React.useEffect(() => {
    setTimeout(() => setKey(Math.random()), 100);
  }, []);

  return <Layout brand="Fictionate.me" title="Login">
    { ({ message, messageKind, messageTitle, hideMessages }) =>
    <Form>
      <div className="bx--grid bx--grid--full-width">

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-4',
              'bx--col-md-2',
              common.formTitle
            )}
          >
            E-mail*
          </div>
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--col-md-6'
            )}
          >
            <FormGroup legendText=''>
              <TextInput
                labelText=''
                id="email"
                placeholder="Type your email"
                onChange={e => setLogin(e.target.value)}
                value={login}
                autoComplete='username'
              />
            </FormGroup>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-4',
              'bx--col-md-2',
              common.formTitle
            )}
          >
            Password*
          </div>
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--col-md-6'
            )}
          >
            <FormGroup legendText=''>
              <TextInput
                labelText=''
                type="password"
                required
                id="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
                autoComplete='current-password'
              />
            </FormGroup>
          </div>
        </div>

        {message &&
          <div className="bx--row" style={{ justifyContent: 'space-around'}}>
            <InlineNotification
              kind={messageKind}
              title={messageTitle}
              subtitle={message}
              iconDescription="close"
              onCloseButtonClick={hideMessages}
              lowContrast
              className={classes.error}
            />
          </div>
        }

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-8',
              'bx--offset-lg-4',
              'bx--col-md-4',
              'bx--offset-md-2',
              classes.login,
              common.center
            )}
          >
            <FormGroup legendText=''>
              <Button
                className={classes.createButton}
                onClick={() => {
                  props.authenticate(login, password, "login_email", props.location.state);
                }}
              >
                Login
              </Button>
            </FormGroup>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-12',
              'bx--offset-lg-4',
              'bx--col-md-6',
              'bx--offset-md-2',
              classes.forgot,
              common.center
            )}
          >
            <FormGroup legendText=''>
              <div className={classes.forgotLink}>
                <span style={{ color: '#0062ff', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/register', props?.location?.state) }
                      }>Create Account</span>
                &nbsp;|&nbsp;
                <Link to="/forgot">Forgot Password?</Link>
              </div>
            </FormGroup>
          </div>
        </div>

        <div className="bx--row">
          <div
            className={cn(
              'bx--col-lg-8',
              'bx--offset-lg-4',
              'bx--col-md-4',
              'bx--offset-md-2',
              common.center
            )}
          >
            <FormGroup legendText=''>
              <FacebookLogin
                key={key}
                appId="806367996457764"
                fields="name,email,picture"
                size="small"
                buttonStyle={{ height: 43, maxWidth: 320, fontSize: '14px' }}
                disableMobileRedirect={true}
                callback={(response) => {
                  console.log("FACEBOOK RESPONSE");
                  console.log(response);
                  props.fbAuthenticate(
                    response.email,
                    response.accessToken,
                    response?.picture?.data?.url,
                    response.userID,
                    "login_facebook",
                    props?.location?.state
                  );
                }}
              />
              <div style={{height: '1rem'}}/>
              <div style={{ maxWidth: 320, margin: 'auto' }}>
                <GoogleLogin
                  clientId="655527373412-jqjleadd3ht5krtantuhfp3hc7jjs93l.apps.googleusercontent.com"
                  buttonText="LOGIN WITH GOOGLE"
                  theme='dark'
                  onSuccess={(response) => {
                    console.log("GOOGLE RESPONSE");
                    console.log(response);
                    props.glAuthenticate(response.tokenId, "login_google", props?.location?.state)
                  }}
                  onFailure={(response) => {
                    console.log("Failed Google Response");
                    console.log(response);
                  }}
                />
              </div>
            </FormGroup>
          </div>
        </div>

      </div>
    </Form>
    }
  </Layout>
};

LoginPage.propTypes = {
  authenticate: PropTypes.func.isRequired,
  fbAuthenticate: PropTypes.func.isRequired,
  glAuthenticate: PropTypes.func.isRequired,
  hideMessages: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ authenticate, fbAuthenticate, glAuthenticate, hideMessages }, dispatch);
}

export default connect(() => ({}), mapDispatchToProps)(LoginPage);
