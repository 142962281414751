export const ACTION_TYPES = {
  FETCH_PROFILE_PENDING: 'profile/FETCH_PROFILE_PENDING',
  FETCH_PROFILE_FULFILLED: 'profile/FETCH_PROFILE_FULFILLED',
  FETCH_PROFILE_REJECTED: 'profile/FETCH_PROFILE_REJECTED',
  CLEAN_PROFILE_DATA: 'profile/CLEAN_PROFILE_DATA'
};

const defaultState = {
  loading: false,
  avatar: null,
  data: {}
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PROFILE_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case ACTION_TYPES.FETCH_PROFILE_REJECTED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.CLEAN_PROFILE_DATA:
      return {
        ...defaultState
      };
    default:
      return state;
  }
}
