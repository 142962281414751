import React, { useEffect } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading, Tabs, Tab, Search } from 'carbon-components-react';

import cs from './styles.module.scss';
import AppLayout from 'src/layout/AppLayout';
import NewThisWeekItem from 'src/components/MainPage/NewThisWeekItem';
import AuthorWide from 'src/components/_shared/AuthorWide';
import { doSearch, localQueryInput } from 'src/modules/actions/search.actions';

export const SearchResultsPage = ({ loading, localQuery, books, authors, ...actions }) => {

  const handleEnter = env => {
    if (env.key === 'Enter') {
      if (localQuery !== '') {
        actions.doSearch(localQuery);
      }
    }
  };

  const onChange = evt => actions.localQueryInput(evt.target.value);
  const { doSearch } = actions;

  useEffect(() => {
    if (localQuery !== '') {
      actions.doSearch(localQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doSearch]);

  const noContent = books.length === 0 && authors.length === 0;
  return (
    <>
      <Loading active={loading} />
      <AppLayout>
        <div className={cs.content}>
          <Search value={localQuery}
                     onKeyDown={handleEnter}
                     onChange={onChange}
          />

          {noContent &&
            <div className={cs.noResults}>
              <h3>No results</h3>
            </div>
          }

          {!noContent &&
            <Tabs className={cs.tabs}>
              { books.length > 0 &&
                <Tab label={`Books (${books.length})`}>
                  {books.map((book, index) => (
                    <div className={cs.bookItem} key={index}>
                      <NewThisWeekItem {...book} />
                    </div>
                  ))}
                </Tab>
              }

              { authors.length > 0 &&
                <Tab label={`Authors (${authors.length})`}>
                  {authors.map((author, index) => (
                    <div className={cs.authorItem} key={index}>
                      <AuthorWide {...author} clickable />
                    </div>
                  ))}
                </Tab>
              }

            </Tabs>
          }
        </div>
      </AppLayout>
    </>
  );
};

SearchResultsPage.propTypes = {
  loading: PT.bool.isRequired,
  doSearch: PT.func.isRequired,
  localQuery: PT.string.isRequired,
  localQueryInput: PT.func.isRequired,
  books: PT.array.isRequired,
  authors: PT.array.isRequired
};

function mapStateProps(state) {
  return {
    loading: state.search.loading,
    localQuery: state.search.localQuery,
    books: state.search.books,
    authors: state.search.authors
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ doSearch, localQueryInput }, dispatch);
}

export default connect(mapStateProps, mapDispatchToProps)(SearchResultsPage);
