import { useState, useCallback } from 'react';

export function useBookVisibleAttention() {
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [bookUuid, setBookUuid] = useState(null);
  const [before, setBefore] = useState(undefined);

  const openMakeBookVisibleAttention = useCallback((values, bookUuid, before) => {
    setModalOpen(true);
    setFormValues(values);
    setBookUuid(bookUuid);
    setBefore(before);
  }, [])

  function onJustClose() {
    setModalOpen(false);
    setFormValues(null);
    setBookUuid(null);
  }

  return {
    modalOpen,
    openMakeBookVisibleAttention,
    onJustClose,
    formValues,
    bookUuid,
    before
  }
}
