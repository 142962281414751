import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import './slick.css';
import cx from './banner.module.scss';
import history from 'src/history';
import { BANNERS_ENABLED } from 'src/config/constants';
import { RES_PATH } from 'src/config/environment';

const imageStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover'
};

const settings = {
  className: cx.banner,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  autoplay: true
};

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb();
};

const Banner = ({ banners }) => {
  const openLink = (link) => {
    var internalLink = null;
  
    if (link.startsWith('https://fictionate.me')) {
      internalLink = link.substring(21);
    }
    if (link.startsWith('http://fictionate.me')) {
      internalLink = link.substring(20);
    }
    if (link.startsWith('http://localhost:3000')) {
      internalLink = link.substring(21);
    }
    if (link.startsWith('fictionate:')) {
      internalLink = link.substring(11);
    }
    if (link.startsWith('/')) {
      internalLink = link;
    }
    if (internalLink) {
      console.log('Opening Internal Link: ', internalLink);   
      history.push(internalLink);
    } else {
      window.open(link, '_blank', 'noreferrer');
    }
  };
  return (
    <>
    { (BANNERS_ENABLED && banners && banners.length > 0) ?
      <Slider {...settings}>
        {
          banners.map((banner, index) => (
            <div key={index}>
              <div className={cx.image}
                   onMouseDown={onMouseDown}
                   onMouseUp={onMouseUp(() => openLink(banner.url))}
                   style={{...imageStyle, backgroundImage: `url(${new URL(banner.image, RES_PATH).toString()})`}}
              />
            </div>
          ))
        }
      </Slider> : null
    }
    </>
  )
};

Banner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }))
};

export default Banner;
