import React, { useCallback} from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HeaderGlobalAction } from 'carbon-components-react';
import {
  LightFilled20,
  AsleepFilled20
  } from '@carbon/icons-react';

import { setThemDark, setThemLight } from 'src/modules/actions/theme.actions';

export const ThemeToggle = props => {
  const {theme, setThemDark, setThemLight} = props
  
  const toggleNightMode = useCallback((params) => {
    if(theme === "light"){
      setThemDark();
    } else {
      setThemLight();
    }
  }, [theme, setThemDark, setThemLight]);

  return (
    <HeaderGlobalAction style={{zIndex:2}} onClick={toggleNightMode} aria-label="Color Theme">
      { (theme === "light") ? <LightFilled20/> : <AsleepFilled20/> }
    </HeaderGlobalAction>

  )
};

ThemeToggle.propTypes = {
  setThemDark: PT.func.isRequired,
  setThemLight: PT.func.isRequired
};

function mapStateToProps(state) {
  return {
    ...state.theme
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setThemDark, setThemLight }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggle);