import React from 'react';
import { Close20 } from '@carbon/icons-react';

import cs from './basicModalPage.module.scss';
import AppLayout from 'src/layout/AppLayout';

const basicModalPage = (Component, title, formStyles, onClose = null) => {

  return props => {
    return (
      <AppLayout>
        <div className={cs.page}>
          <div className={cs.container}>
            <div className={cs.formBlock}>
              {title && <div className={cs.title}>{title}</div>}
              <div className={cs.form} style={formStyles}>
                <Component {...props}/>
                {onClose &&
                  <div className={cs.close} onClick={onClose}>
                    <Close20/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    )
  }
}

export default basicModalPage;
