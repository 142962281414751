import React from 'react';
import PropTypes from 'prop-types';

import cs from './Seeker.module.scss';
import { isChapterAvailable } from 'src/modules/model/book';
import { formatDuration } from '../../../utils/duration';

const Seeker = ({ playlist, currentChapter, playing, book }) => {
  const [duration, setDuration] = React.useState();
  const [seek, setSeek] = React.useState();
  const [played, setPlayed] = React.useState();
  const [last, setLast] = React.useState("");
  const [available, setAvailable] = React.useState(false);

  React.useEffect(() => {
    let timeHandler;

    function pos(trk) {
      const howl = trk.howl;
      let _seek = howl?.seek() ?? 0;
      if (typeof _seek === 'number') {
        _seek = Math.floor(_seek);
        setSeek(_seek);
        setPlayed(formatDuration(_seek));
        setLast(formatDuration(trk?.audio?.duration - _seek));
      }
      timeHandler = setTimeout(() => pos(trk), 1000);
    }


    if (currentChapter && playing) {
      const trk = playlist.find(t => t.uuid === currentChapter);
      if (trk && trk.howl) {
        pos(trk);
        setDuration(trk?.audio?.duration);
      }
    }

    return () => {
      if (timeHandler) {
        clearTimeout(timeHandler);
      }
    }
  }, [playing, currentChapter, playlist]);

  React.useEffect(() => {
    const _available = book && playlist && currentChapter &&
      isChapterAvailable(book, playlist.find(t => t.uuid === currentChapter))
    setAvailable(_available);
  }, [book, currentChapter, playlist]);

  const width = () => {
    if (duration > 0 && seek > 0) {
      return (seek * 100 / duration) + '%';
    } else return '0';
  }

  const measureSeekBar = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const percent = 100 * x / width;
    const seek = duration / 100 * percent;
    return {
      percent,
      seek
    }
  }

  const onSeek = (e) => {
    if (currentChapter && playing) {
      const trk = playlist.find(t => t.uuid === currentChapter);
      if (trk && trk.howl) {
        const pp = measureSeekBar(e);
        trk.howl.pos(Math.floor(pp.seek));
      }
    }
  }

  return (
    <>
      {available ? <>
        <div className={cs.seeker} onClick={onSeek}>
          <div className={cs.filler} style={{width: width()}}/>
        </div>
        <div className={cs.duration}>
          <div className={cs.passed}>
            {played}
          </div>
          <div className={cs.rest}>
            {last && <span>-{" " + last}</span>}
          </div>
        </div>
      </> : null}
    </>
  )
}

Seeker.propTypes = {
  playlist: PropTypes.array,
  currentChapter: PropTypes.string,
  playing: PropTypes.bool,
  book: PropTypes.object
}

export default Seeker;
