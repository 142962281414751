import React, { useState, useEffect } from 'react';

import cs from './chapter-popup.module.scss';

const ChapterPopup = ({ children }) => {
  const [show, setShow] = useState(true);

  useEffect(() =>  {
    const handler = setTimeout(() => {
      setShow(false);
    }, 1500);

    return () => {
      setShow(true);
      clearTimeout(handler);
    }
  }, [children]);

  return (
    <>
      {
        show ?
          <div className={cs.popup}>
            <div>{children}</div>
          </div> : null
      }
    </>
  )
}

export default ChapterPopup;
