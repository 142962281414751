import React, { useEffect } from 'react';
import { Tile, ToastNotification } from 'carbon-components-react';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cn from 'classnames';
import classes from './styles.module.scss';
import Header from 'src/components/Header';
import {
  clearStatus,
  hideInfoMessage
} from 'src/modules/actions/application.actions';
import ReadingPopup from 'src/components/_shared/ReadingPopup';
import { getTheme } from 'src/modules/actions/theme.actions';

export const ContentLayout = ({
                                title,
                                renderAction,
                                breadcrumb,
                                children,
                                infoMessage,
                                error,
                                ...props
                              }) => {
                                
  const { clearStatus, hideInfoMessage } = props;
  const theme = getTheme(props.theme)

  useEffect(() => {
    return () => {
      clearStatus();
      hideInfoMessage();
    }
  }, [clearStatus, hideInfoMessage]);

  return (
    <>
      <Header/>
      <ReadingPopup show={error}>{error}</ReadingPopup>
      <Tile className={classes.headTile}>
        <div className={classes.mainRow}>
          <div className={classes.title}>
            {breadcrumb}
            <h3>{title}</h3>
          </div>
          {renderAction && renderAction()}
        </div>
      </Tile>

      <Content className={cn(classes.content, theme.contentColor)}>
        {children}
      </Content>

      {infoMessage &&
        <ToastNotification
          kind="success"
          lowContrast
          title="Success"
          caption={infoMessage}
          onCloseButtonClick={hideInfoMessage}
          style={{ position: 'absolute', top: 0, right: 0 }}
        />
      }
    </>
  )
};

export default connect(
  state => ({
    infoMessage: state.application.infoMessage,
    status: state.application.status
  }),
  dispatch => bindActionCreators({ hideInfoMessage, clearStatus }, dispatch)
)(ContentLayout);

export { default as actionsCommon } from './actions-common-styles.module.scss';
