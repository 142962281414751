export const SORT_OPTIONS = [
  {
    value: 'readersRaiting',
    text: 'Sort: Readers\' Rating'
  },
  {
    value: 'criticsRaiting',
    text: 'Sort: Critics\' Rating'
  },
  {
    value: 'date',
    text: 'Sort: Date Added'
  }
];

export const REQUEST_LIMIT = 20;
