import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'carbon-components-react';

const toggle = ({ input, labelA, labelB }) => <Toggle { ... input } labelA={labelA} labelB={labelB} />;

toggle.propTypes = {
  input: PropTypes.object.isRequired,
  labelA: PropTypes.string,
  labelB: PropTypes.string
}

export default toggle;
