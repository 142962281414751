import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'carbon-components-react';

import gs from 'src/styles/styles.module.scss';
import cs from './BookCell.module.scss';
import classNames from 'classnames';
import { useIfMobileScreen } from 'src/hooks';
import Author from 'src/components/_shared/Author';
import Bookmark from 'src/components/_shared/Book/Bookmark';
import Like from 'src/components/_shared/Like';
import { RES_PATH } from 'src/config/environment';
import history from 'src/history';
import Badge from 'src/components/_shared/Badge';
import Order from './Order';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

const BookCell = ({ book, author, updatedAt, isNew, order, myLibraryMode }) => {
  const isMobile = useIfMobileScreen();
  const [likes, setLikes] = React.useState(book?.likes);
  const [liked, setLiked] = React.useState(book?.liked);

  const bookURL = () => {
    return `/books/${book.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };

  const readBook = (e) => {
    e.stopPropagation();
    history.push(bookURL() + '/read');
  }

  return (
    <div className={classNames(cs.bookCell, { [cs.mobileKind]: isMobile })}
         onMouseDown={onMouseDown}
         onMouseUp={onMouseUp(goBook)}
    >
      <div className={cs.left}>
        <div className={cs.authorBlock}>
          <div className={cs.author}>
            <Author {...author} updatedAt={updatedAt} size37/>
          </div>
          <div className={cs.socialButtons}>
            <div className={cs.likeContainer}>
              <Like {...book}
                    size='m'
                    showLikes={true}
                    style={{ alignItems: 'flex-start' }}
                    counterStyle={{ marginTop: '0.3rem', marginRight: '0.3rem' }}
                    likesChanged={setLikes}
                    likedChanged={setLiked}
                    likes={likes}
                    liked={liked}
              />
            </div>
            <Bookmark uuid={book.uuid} style={{ padding: 0 }}/>
          </div>
        </div>
        <div className={classNames(cs.tagsBlock, gs.noselect)}>
          {book.tags?.map((tag, i) => (
            <React.Fragment key={i}>{tag}{i + 1 < book.tags.length ? " | " : " "}</React.Fragment>
          ))}
        </div>
        <div className={classNames(cs.nameBlock, gs.noselect)}
             onMouseDown={onMouseDown}
             onMouseUp={onMouseUp(goBook)}>
          {book.name}
        </div>
        <div className={classNames(cs.aboutBlock, gs.noselect)}>
          {book.about}
        </div>
        <div className={cs.socialButtons2}>
          {myLibraryMode ?
            <Button kind={'tertiary'}
                    className={cs.readButton}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp(readBook)}
            >
              <span>Read</span>
            </Button> :
            <div className={cs.like}>
              <Like {...book}
                    size='m'
                    showLikes={true}
                    counterStyle={{marginRight: '0.3rem', height: '1rem'}}
                    likesChanged={setLikes}
                    likedChanged={setLiked}
                    likes={likes}
                    liked={liked}
              />
            </div>
          }
          <Bookmark uuid={book.uuid}/>
        </div>
      </div>
      <div className={cs.right}>
        <div
          className={cs.cover}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp(goBook)}
        >
          <div
            className={cs.coverImg}
            style={{ backgroundImage: `url(${RES_PATH}${book.image})`}}
          />
          {(isNew || book.new) && <Badge style={{bottom: '9px', left: '-9px'}}/>}
          {order && <Order order={order} style={{ top: isMobile ? '106px': '113px', left: isMobile ? '-23px' : '-11px' }}/>}
        </div>
      </div>
    </div>
  )
};

BookCell.defaultProps = {
  isNew: false,
  myLibraryMode: false
};

BookCell.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  updatedAt: PropTypes.number,
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    new: PropTypes.bool
  }).isRequired,
  isNew: PropTypes.bool,
  order: PropTypes.number,
  myLibraryMode: PropTypes.bool
};

export default BookCell;
