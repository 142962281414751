import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'

export async function postLink(link, type) {
    try {
      return await axios.post(`/me/links`, 
      { 
          link,
          type, 
      }, getAuthHeader());
    } catch (error) {
      return error.response;
    }
}

export async function getLinks() {
    try {
      return await axios.get(`/me/links`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
}

export async function deleteLink(uuid) {
  try {
    return await axios.delete(`/me/links/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}