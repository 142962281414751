import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button, Loading } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cls from './styles.module.scss';
import AppLayout from 'src/layout/AppLayout';
import { openModal } from 'src/modules/actions/modal.actions';
import { couponInput, couponSubmit } from 'src/modules/actions/coupon.actions';

const ApplyCouponPage = ({ coupon, couponInput, couponSubmit, loading }) => {

  const _onChange = e => couponInput(e.target.value);
  const _onSubmit = () => couponSubmit(coupon);

  return (
    <>
      <Loading active={loading}/>
      <AppLayout>
        <div className={cls.content}>
          <div className={cls.inputRow}>
            <div className={cls.input}>
              <TextInput labelText="" id='coupon' placeholder='Enter Coupon Code' value={coupon} onChange={_onChange}/>
            </div>
            <Button size='small' onClick={_onSubmit}>Activate</Button>
          </div>
          <div className={cls.hintRow}>
            <div style={{ lineHeight: '1.3rem' }}>
              Please enter number on your coupon. After activation all price will reflect discount.
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  )
};

ApplyCouponPage.propTypes = {
  coupon: PropTypes.string,
  couponInput: PropTypes.func,
  loading: PropTypes.bool,
  couponSubmit: PropTypes.func
};

export default connect(
  state => ({
    coupon: state.coupon.coupon,
    loading: state.coupon.loading
  }),
  dispatch => bindActionCreators({ openModal, couponInput, couponSubmit }, dispatch)
)(ApplyCouponPage);
