import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import classes from './header.module.scss';
import history from 'src/history';
import Search from 'src/components/_shared/Search';
import ThemeToggle from 'src/components/_shared/ThemeToggle';
import { InlineNotification, NotificationActionButton } from 'carbon-components-react';
import { requestEmailVerification } from 'src/modules/api/me.api';
import { verify } from 'src/modules/actions/authentication.actions';
import { bindActionCreators } from 'redux';

import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  HeaderContainer,
  SideNav,
  SideNavItems,
  SideNavLink
} from 'carbon-components-react/lib/components/UIShell';
import UserAvatar20 from '@carbon/icons-react/lib/user--avatar/20';
import { Link } from 'react-router-dom';
import AccountOverflowMenu from "./AccountOverflowMenu";

const Span = () => <span />;

const HeaderComponent = ({ isAuthenticated, account, fullScreen, verify }) => {
  const notVerified = (account.verified !== undefined) ? !account.verified : false

  return <>
    <div className={cn({[classes.hideNext]: fullScreen})}/>
    <HeaderContainer
      render={({isSideNavExpanded, onClickSideNavExpand}) =>
        <>
        <Header aria-label="Fictionate.me">
          <SkipToContent/>
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName element={Link} to="/" prefix=""
                      className={cn(classes.headerName)}>
            Fictionate.Me
          </HeaderName>

          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isFixedNav
          >
            <SideNavItems>
              <SideNavLink href="https://swag.fictionate.me">Swag</SideNavLink>
              <SideNavLink element={Link} to="/s/whats-in-here-for-writers">For Writers</SideNavLink>
              <SideNavLink element={Link} to="/genres">Genres</SideNavLink>
              { isAuthenticated ? <SideNavLink element={Link} to="/library"> My Library </SideNavLink> : <Span/>}
              { isAuthenticated ? <SideNavLink element={Link} to="/coupon"> Apply Coupon </SideNavLink> : <Span/>}
              {!account?.writer ? <SideNavLink element={Link} to="/become">Become a writer</SideNavLink> : <Span/>}
              <SideNavLink element={Link} to="/blog"> Blog </SideNavLink>
            </SideNavItems>
          </SideNav>

          <HeaderNavigation aria-label="Fictionate.me">
            <HeaderMenuItem href="https://swag.fictionate.me"> Swag </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/s/whats-in-here-for-writers">For Writers</HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/genres"> Genres </HeaderMenuItem>
            { isAuthenticated ? <HeaderMenuItem element={Link} to="/library"> My Library </HeaderMenuItem> : <Span/>}
            { isAuthenticated ? <HeaderMenuItem element={Link} to="/coupon"> Apply Coupon </HeaderMenuItem> : <Span/> }
            <HeaderMenuItem element={Link} to="/blog"> Blog </HeaderMenuItem>
          </HeaderNavigation>

          {!account?.writer &&
            <HeaderNavigation aria-label="Fictionate.me">
              <HeaderMenuItem element={Link} to="/become">Become a writer</HeaderMenuItem>
            </HeaderNavigation>
          }

          <HeaderGlobalBar>
            
            <Search/>
            {window.location.href.includes("/blog") && <ThemeToggle/>}
            {isAuthenticated && <>
              <AccountOverflowMenu>
                <HeaderGlobalAction aria-label="User Avatar">
                  <UserAvatar20/>
                </HeaderGlobalAction>
              </AccountOverflowMenu>
            </>}

            {!isAuthenticated && <>
              <HeaderGlobalAction
                aria-label="SignIn"
                className={cn(classes.headerActionButton, classes.headerSigninButton)}
                onClick={() => { history.push('/login', { from: history.location.pathname }) } }
              >
                Sign In
              </HeaderGlobalAction>

              <HeaderGlobalAction
                aria-label="Registration"
                className={classes.headerActionButton}
                onClick={() => history.push('/register', { from: history.location.pathname })}
              >
                Sign Up
              </HeaderGlobalAction>
            </>}
          </HeaderGlobalBar>
        </Header>
        { notVerified &&
          <div style={{position: 'absolute', backgroundColor: 'yellow', width: '100%', zIndex: 100}}> 
            <InlineNotification 
            style={{margin:'0px', width:'100%', maxWidth:'100%', minWidth: "150px"}}
                kind='warning'
                      lowContrast
                      hideCloseButton
                      title="Email not verified"
                      actions={
                        <>
                          <NotificationActionButton style={{marginTop: "auto", marginBottom: "auto"}} onClick={() => {
                              requestEmailVerification().then((response)=>{
                                if (!response || response.status !== 200) {
                                  return;
                                }
                                if(response.data.verified === true) {
                                  verify();
                                  window.location.reload()
                                }
                              })
                            }}>
                            Re-send
                          </NotificationActionButton>
                        </>
                      }
              />
          </div>
        }
        </>
      }
    />
  </>
};

HeaderComponent.defaultProps = {
  fullScreen: false
}

HeaderComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  account: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    writer: PropTypes.bool.isRequired
  })
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ verify }, dispatch);
}

export default connect(state => ({
  isAuthenticated: state.authentication.isAuthenticated,
  account: state.authentication.account
}), mapDispatchToProps)(HeaderComponent);
