import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BitlyClient } from 'bitly-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shortId } from 'src/modules/api/books.api'
import cs from './CopyLink.module.scss';

const bitly = new BitlyClient("cf6ab3639821aafc6d2dcc23e62a119a68b2982c", {});

const CopyLink = ({ url, shortening, book }) => {
  const [value, setValue] = useState('');
  const [copied, setCopied] = useState(false);

  const shorten = useCallback(async () => {
    switch (shortening) {
      case SHORTENING_TYPES.static:
        setValue(url);
        return url;
      case SHORTENING_TYPES.book:
      {
        let result;
        try {
          result = await shortId(book);
          setValue(result.url);
        } catch (e) {
          throw e;
        }
        return result
      }
      case SHORTENING_TYPES.bitly:
      default:
      {
        let result;
        try {
          result = await bitly.shorten(url);
          setValue(result.url);
        } catch (e) {
          throw e;
        }
        return result;
      }
    }

  }, [url, shortening, book]);

  useEffect(() => {
    shorten();
  }, [shorten]);

  return (
    <div className={cs.copyLink}>
      <div className={cs.url}>
        {value}
      </div>
      <CopyToClipboard text={value}
                       onCopy={() => setCopied(true)}>
        <div className={cs.button}>{!copied ? 'Copy' : 'Copied'}</div>
      </CopyToClipboard>
    </div>
  )
}

CopyLink.propTypes = {
  url: PropTypes.string.isRequired
}

export default CopyLink;

export const SHORTENING_TYPES = {
  bitly: 'bitly',
  book: 'book',
  static: 'static',
};