import React from 'react';

import cs from './styles.module.scss';

export const Badge = ({ style }) => (
  <div className={cs.badge} style={style}>
    New Chapter
  </div>
);

export default Badge;
