import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, TextInput } from 'carbon-components-react';

import cx from './styles.module.scss';
import { closeNameRequiredDialog } from 'src/modules/actions/modal.actions';
import { updateUserName } from 'src/modules/actions/authentication.actions';
import { toggleComments } from 'src/modules/actions/books.actions';

const NameRequired = ({ open, loading, ...actions }) => {

  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);

  const _onRequestSubmit = () => {
    if (userName && userName.trim().length > 0) {
      actions.updateUserName(userName,
        err => {
          setError(err);
        },
        () => {
          actions.closeNameRequiredDialog();
          actions.toggleComments(true);
        }
      );
    } else {
      setError('Name is required');
    }
  };

  useEffect(() => {
    return () => {
      setUserName('');
      setError(null);
    }
  }, []);

  return (
    <Modal
      aria-label=''
      open={open}
      onRequestClose={actions.closeNameRequiredDialog}
      onSecondarySubmit={actions.closeNameRequiredDialog}
      primaryButtonText='Save'
      secondaryButtonText='Cancel'
      onRequestSubmit={_onRequestSubmit}
      modalHeading='Name is required'
    >
      <p className={'bx--modal-content__text'}>
        Your profile is missing a name. Please enter a name to leave comments.
      </p>
      <div className={cx.form}>
        <div className={cx.label}>Name*</div>
        <div className={cx.input}>
          <TextInput labelText=''
                     id='username'
                     value={userName}
                     onChange={e => {
                       setUserName(e.target.value);
                       setError(null);
                     }}
                     disabled={loading}
                     invalid={error}
                     invalidText={error}
          />
        </div>
      </div>
    </Modal>
  )
};

NameRequired.propTypes = {
  open: PropTypes.bool.isRequired,
  closeNameRequiredDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateUserName: PropTypes.func.isRequired,
  toggleComments: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    open: state.modal.nameRequired,
    loading: state.authentication.loading
  }
}

export default connect(mapStateToProps, { closeNameRequiredDialog, updateUserName, toggleComments })(NameRequired);
