import React from 'react';
import { useQuery } from 'src/hooks';
import { verify } from 'src/modules/actions/authentication.actions';
import { fetchBook } from 'src/modules/actions/books.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as couponsApi from 'src/modules/api/coupons.api';

const URLParamParser = ({verify, fetchBook}) => {
  const verified = useQuery().get("verified")
  const coupon = useQuery().get("c")

  if( verified ) {
    verify()
    window.location.href = window.location.origin + window.location.pathname;
  }
  
  if( coupon ) {
    couponsApi.applyCoupon(coupon).then((coupon)=>{
      if(coupon.chapter !== undefined) {
        window.location.href = window.location.origin + window.location.pathname;
        return;
      }
      if(coupon.book !== undefined) {
        fetchBook(coupon.book.uuid)
      }
    })
  }

  return (
    <>
    </>
  )
};

URLParamParser.propTypes = {
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ verify, fetchBook }, dispatch);
}

export default connect(() => ({}), mapDispatchToProps)(URLParamParser);
