import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import cs from './styles.module.scss';
import { getTheme } from 'src/modules/actions/theme.actions';
  
const Button = ({ kind, className, onClick, children, style, ...props}) => {
  const theme = getTheme(props.theme)

  return (
    <div className={
      cn(kind === "cta" && theme.button_cta, 
         kind === "normal" && theme.button_normal,
         theme.button,
         cs.button, 
         className)} style={style} onClick={onClick}>
      {children}
    </div>
  );
}

Button.propTypes = {
  kind: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
