import {ACTION_TYPES as BLOG_ACTION_TYPES} from 'src/modules/reducers/blog.reducer';
import {ACTION_TYPES as BLOG_POST_ACTION_TYPES} from 'src/modules/reducers/blogPost.reducer';
import { getBlogPost, getBlog, getMyBlog, getMyBlogPost, deleteBlogPost, putBlogPost, postBlogPost } from 'src/modules/api/blog.api';

// Readers' Actions

export function fetchBlogPost(id, surface) {
  return async dispatch => {
    dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_PENDING });

    const response = await getBlogPost(id, surface);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_FULFILLED, data });
    }
  }
}

export function fetchBlog() {
  return async dispatch => {
    dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_PENDING });

    const response = await getBlog(0);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_FULFILLED, data });
    }
  }
}

export function fetchBlogNextPage(count) {
  return async dispatch => {
    dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_NEW_PAGE_PENDING });

    const response = await getBlog(count);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_NEW_PAGE_FULFILLED, data });
    }
  }
}

// Editors' Actions

export function fetchMyPosts() {
  return async dispatch => {
    dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_PENDING });

    const response = await getMyBlog(0);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_FULFILLED, data });
    }
  }
}

export function fetchMyPostsNextPage(count) {
  return async dispatch => {
    dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_NEW_PAGE_PENDING });

    const response = await getMyBlog(count);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_ACTION_TYPES.FETCH_BLOG_NEW_PAGE_FULFILLED, data });
    }
  }
}

export function fetchMyBlogPost(uuid) {
  return async dispatch => {
    dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_PENDING });

    const response = await getMyBlogPost(uuid);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_FULFILLED, data });
    }
  }
}

export const forgetPost = () => ({ type: BLOG_POST_ACTION_TYPES.FORGET });

export function deleteMyBlogPost(uuid) {
  return async dispatch => {
    dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_PENDING });

    const response = await deleteBlogPost(uuid);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_REJECTED });
    } else {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_DELETED});
    }
  }
}

export function updateMyBlogPost(uuid, values) {
  return async dispatch => {
    dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_PENDING });

    const response = await putBlogPost(uuid, values);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_REJECTED });
      return {success: false}
    } else {
      const data = response.data;
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_UPDATED, data });
      return {success: true}
    }
  }
}

export function createBlogPost(values) {
  return async dispatch => {
    dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_PENDING });

    const response = await postBlogPost(values);

    if (!response || response.status !== 200) {
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_REJECTED });
      return {success: false}
    } else {
      const data = response.data;
      dispatch({ type: BLOG_POST_ACTION_TYPES.FETCH_BLOG_POST_UPDATED, data });
      return {success: true}
    }
  }
}

