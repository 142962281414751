import React from 'react';
import PT from 'prop-types';

import cx from './styles.module.scss';
import SectionName from 'src/components/_shared/DesignV2/SectionName';
import { Button } from 'carbon-components-react';
import { ChevronRight20 } from '@carbon/icons-react';
import Href from "src/components/_shared/Href";

const SectionHeader = (props) => {
  return (
    <div className={props.className} >
    <SectionName name={props.title}>
      {props.pushTo ?
      <>
      <Button size='small'
              kind='ghost'
              className={cx.seeAllButton}
      >
        <Href href={props.pushTo}>
          {props.actionName}
        </Href>
        <ChevronRight20/>
      </Button>
      </> : <></>
      }
    </SectionName>
    </div>
  )
};

SectionHeader.propTypes = {
  title: PT.string.isRequired,
  pushTo: PT.string,
  actionName: PT.string
};

export default SectionHeader;
