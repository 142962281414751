import { useEffect } from 'react';

export function useResizeEffect(resizeListener) {

  useEffect(() => {
    let timer = null;
    const listener = () => {
      if (!timer) {
        timer = setTimeout(function () {
          timer = null;
          resizeListener();
        }, 66)
      }
    };

    window.addEventListener('resize', listener, false);

    return () => {
      window.removeEventListener('resize', listener, false);
    }
  }, [resizeListener]);
}
