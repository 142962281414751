import React, { useEffect, useCallback }  from 'react';
import PropTypes from 'prop-types';
import { Button, Loading, InlineNotification, Breadcrumb, BreadcrumbItem, Toggle, FormGroup } from 'carbon-components-react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './index.module.scss';
import Layout, { actionsCommon } from 'src/layout/ContentLayout';
import InputText from 'src/components/_shared/form/InputText';
import RowWrapper from 'src/layout/form/ChapterRowWrapper';
import TextEditor from 'src/components/_shared/form/TextEditor';
import { submitChapterForm } from 'src/modules/actions/books.actions';
import { fetchChapter, forgetChapter } from 'src/modules/actions/chapters.actions';
import { fetchBook, forgetBook } from 'src/modules/actions/books.actions';
import { fetchPersonas, togglePersonasFormVisibility } from 'src/modules/actions/personas.actions';
import { fetchBGFiles, toggleDeleteBGSoundDialog, toggleFormVisibility as toggleAddSoundForm } from 'src/modules/actions/bgsounds.actions';
import { isFree } from 'src/modules/model/book';
import { useQuery } from 'src/hooks';

const required = value => (value ? undefined : 'Required');

function getInitialValues(chapter) {
  if (!chapter) return null;

  const values = {};
  values.uuid = chapter.uuid;
  values.name = chapter.name;
  // values.filePath = chapter.filePath;
  values.text = chapter.text;
  values.free = chapter.free;
  return values;
}

const Crumbs = ({ book, loading }) => (
  <Breadcrumb className={actionsCommon.breadcrumbContainer}>
    <BreadcrumbItem className={actionsCommon.breadcrumbItem}>
      <Link to='/my-books'>
        My Books
      </Link>
    </BreadcrumbItem>

    {(book && !loading) &&
      <BreadcrumbItem className={actionsCommon.breadcrumbItem}>
        <Link to={`/my-books/${book.uuid}`}>
          {book.name}
        </Link>
      </BreadcrumbItem>
    }
  </Breadcrumb>
);

const toggle = ({ input }) => <Toggle { ... input } labelA='No' labelB='Yes'/>;

export const AddChapter = ({
                             match, loading, book, chapter,
                             personas, bgfiles, openPersonasForm,
                             openMakeBookVisibleAttention, ...actions }) => {
  const query = useQuery();
  const before = query.get("before");

  let submitTrigger = null;
  let draft = null;
  let getFormValues = null;

  const _submitChapterForm = useCallback( async (values) => {
    return actions.submitChapterForm(match.params.bookUuid, values, draft, undefined, before);
  }, [before, actions, draft, match.params.bookUuid]);

  const {
    fetchChapter, forgetChapter, fetchBook, forgetBook,
    fetchPersonas, togglePersonasFormVisibility, fetchBGFiles,
    toggleDeleteBGSoundDialog, toggleAddSoundForm
  } = actions;

  useEffect(() => {
    const chapterUuid = match.params.chapterUuid;
    if (chapterUuid) {
      fetchChapter(chapterUuid);
    }
    const bookUuid = match.params.bookUuid;
    if (bookUuid) {
      fetchBook(bookUuid);
      fetchPersonas(bookUuid, chapterUuid);
      fetchBGFiles(bookUuid);
    }
    return () => {
      forgetChapter();
      forgetBook();
    }
  }, [
    fetchChapter,
    forgetChapter,
    fetchBook,
    forgetBook,
    fetchPersonas,
    fetchBGFiles,
    match
  ]);

  return (
    <Layout
      title={(chapter && chapter.name) || 'New Chapter'}
      renderAction={() => (
        <div style={{ flexShrink: 0 }}>
          <Button
            disabled={loading}
            kind='tertiary'
            style={{marginRight: '0.5rem'}}
            onClick={() => {
              draft = true;
              submitTrigger();
            }}
            className={actionsCommon.actionButton}
          >
            Save as draft
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              draft = false;
              if (!book?.published && getFormValues().name) {
                openMakeBookVisibleAttention(getFormValues(), match.params.bookUuid, before);
              } else {
                submitTrigger();
              }
            }}
            className={actionsCommon.actionButton}
          >
            Publish
          </Button>
        </div>
      )}
      breadcrumb={<Crumbs book={book} chapter={chapter} loading={loading}/>}
    >
      <Form
        initialValues={getInitialValues(chapter)}
        onSubmit={_submitChapterForm}
        render={({ handleSubmit, submitting, submitError, pristine, values }) => {
          submitTrigger = handleSubmit;
          getFormValues = () => values;
          const disable = submitting || loading;

          return (
            <>
              {disable && <Loading small={true} />}
              <form onSubmit={handleSubmit}>

                <div className="bx--grid">

                  { !isFree(book) &&
                    <RowWrapper title='Free chapter'>
                      <FormGroup legendText="">
                        <Field
                          id='free'
                          name='free'
                          type='checkbox'
                          component={toggle}
                          disabled={disable}
                        />
                      </FormGroup>
                    </RowWrapper>
                  }

                  <RowWrapper title='Name*'>
                    <Field
                      id="name"
                      name="name"
                      validate={required}
                      component={InputText}
                      placeholder="Type this chapter's name"
                      disable={disable}
                    />
                  </RowWrapper>

                  <RowWrapper title='Add content*' titleClass={styles.uploadTitle}>
                    <Field
                      id="text"
                      name="text"
                      enableNarratorPlugin
                      enableBGSoundsPlugin
                      enableListenPlugin
                      disableHtmlEmbedPlugin
                      bgsoundsConfig={{
                        files: bgfiles,
                        openAudioForm: toggleAddSoundForm,
                        deleteAudio: (uuid) => toggleDeleteBGSoundDialog(uuid),
                        chapterUuid: match.params.chapterUuid,
                        bookUuid: match.params.bookUuid
                      }}
                      narratorConfig={{
                        personas,
                        openPersonasForm: () => togglePersonasFormVisibility(undefined, match.params.chapterUuid),
                        editPersona: ({ id }) => togglePersonasFormVisibility(id, match.params.chapterUuid)
                      }}
                      component={TextEditor}
                    />
                  </RowWrapper>
                  {submitError &&
                    <div className="bx--row" style={{ position: 'absolute', top: '0', left: '2rem' }}>
                      <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                    </div>
                  }
                </div>

              </form>
            </>
          )
        }}
      />
    </Layout>
  )
};

AddChapter.propTypes = {
  openMakeBookVisibleAttention: PropTypes.func,
  fetchPersonas: PropTypes.func,
  personas: PropTypes.array,
  bgfiles: PropTypes.array,
  toggleAddSoundForm: PropTypes.func
};

function mapStateToProps(state) {
  return {
    loading: state.chapters.loading || state.books.loading,
    chapter: state.chapters.chapter,
    book: state.books.book,
    personas: state.personas.personas,
    bgfiles: state.bgsounds.files
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    submitChapterForm, fetchChapter, forgetChapter, fetchBook, forgetBook,
    fetchPersonas, togglePersonasFormVisibility, fetchBGFiles,
    toggleDeleteBGSoundDialog, toggleAddSoundForm
  }, dispatch);
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AddChapter));
