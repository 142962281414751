import React, {
    useCallback, useState
    } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Toggle, Loading, InlineNotification, StructuredListWrapper, StructuredListBody, StructuredListRow, StructuredListCell, OverflowMenu, OverflowMenuItem, StructuredListHead, Button } from 'carbon-components-react';
import { Form, Field } from 'react-final-form';
import RowWrapper from 'src/layout/form/RowWrapper';
import InputText from 'src/components/_shared/form/InputText';
import InputArea from 'src/components/_shared/form/InputArea';
import TextEditor from 'src/components/_shared/form/TextEditor';
import InputSelect from 'src/components/_shared/form/InputSelect';
import InputDropdown from 'src/components/_shared/form/InputDropdown';
import InputTimePicker from 'src/components/_shared/form/InputTimePicker';
import InputImage from 'src/components/_shared/form/InputImage';
import { RES_PATH } from 'src/config/environment';

import styles from './styles.module.scss';
import { useEffect } from 'react';
import ModalForm from '../ModalForm';
import InputSearch from '../InputSearch';
import { ColorPicker } from '../ColorPicker';
import EmojiSelect from '../EmojiSelect';
import cn from 'classnames';

const required = value => (value ? undefined : 'Required');
function requiredDropdown(emptyId) {
    return value => ((value === emptyId) ? 'Required' : undefined);
}

const _getInitialValues = (object, properties) => {
    if (!object) return null;

    var values = {}
    for (var i = 0; i < properties.length; i++) {
        let property = properties[i];
        var value = object[property.id];
        if(value) {
            if(property.convertFrom) {
                value = property.convertFrom(value);
            }
        }
        values[property.id] = value;
    }
    return values;
};

const _convertValues = (newValues, oldValues, properties, useConvertTo = true) => {
    var object = {}
    for (var i = 0; i < properties.length; i++) {
        let property = properties[i];
        var objectValue = undefined
        if(property.type === "dropdown") {
            objectValue = property.dropdown.options.find( element => element.id === newValues[property.id]).value
        } else if(property.type === "objectslist") {
            objectValue = newValues[property.id] ? newValues[property.id].map(item => 
                _convertValues(item, {}, property.objectslist.properties, false)
            ) :  [];
        } else {
            objectValue = newValues[property.id];
        }
        if(objectValue !== undefined && useConvertTo) {
            if(property.convertTo) {
                objectValue = property.convertTo(objectValue);
            }
        }
        if(objectValue === oldValues[property.id]) {
            continue;
        }
        object[property.id] = objectValue;
    }
    return object
};



export const FormContent = props => {
    const {properties, value, submit, loading, setTrigger, style} = props
    const valueObject = value ?? {}
    const formStyle = {paddingBottom: ""}
    const _submitForm = useCallback((values) => {
        var object = _convertValues(values, valueObject, properties)
        return submit(object);
    }, [properties, valueObject, submit]);
    
    return (
        <Form
            style={style}
            initialValues={_getInitialValues(valueObject, properties)}
            onSubmit={(values)=> _submitForm(values, properties)}
            subscription={{ submitting: true, pristine: true, submitError: true }}
            render={({ handleSubmit, submitting, submitError, values }) => {
                setTrigger(handleSubmit)
                const disable = submitting || loading;

                return (
                <>
                    {disable && <Loading small={true} />}
                    <form onSubmit={handleSubmit}>
                        <div className={cn('bx--grid', styles.divContent)} >
                            {properties.map((property, index) => 
                                <PropertyDiv 
                                    property={property} 
                                    index={index} 
                                    disable={disable} 
                                    valueObject={valueObject}
                                />
                            )
                            }
                            {submitError &&
                                <div className="bx--row" style={{ position: 'absolute', top: '0', left: '2rem' }}>
                                    <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                                </div>
                            }
                            </div>
                        </form>
                    </>)
            }}
        />
    );
};

const PropertyDiv = ({property, index, disable, valueObject}) => {
    console.log(`${property.id}[${property.type}] - ${property?.dropdown?.options}`);
    switch (property.type) {
        case "toggle":
            return (
                <RowWrapper title={property.title}>
                    <FormGroup legendText="">
                    <Field
                        id={property.id}
                        name={property.id}
                        type='checkbox'
                        defaultValue={property.default}
                        component={({ input }) =>
                        <Toggle { ... input }
                                labelA={property.toggle?.labelA}
                                labelB={property.toggle?.labelB}
                                className={styles.toggle}
                        /> }
                        disabled={disable}
                    />
                    </FormGroup>
                </RowWrapper>
            );
        case "search":
            return (
                <RowWrapper title={property.title}>
                    <Field
                        id={property.id}
                        name={property.id}
                        disabled={disable}
                        >
                        {({input}) => (
                            <InputSearch 
                                initialValue={valueObject && valueObject[property.id]}
                                disable={disable}
                                loadOptions={property.search.loadOptions}
                                label={property.search.label}
                                onChange={(value) => {
                                    input.onChange(value)
                                }}
                            />
                        )}
                    </Field>
                </RowWrapper>
            );
        case "text":
            return (
                <RowWrapper title={property.title}>
                    <Field
                        id={property.id}
                        name={property.id}
                        validate={property.required && required}
                        component={InputText}
                        placeholder={(property.text && property.text.placeHolder) ? property.text.placeHolder : "" }
                        disable={disable}
                    />
                </RowWrapper>
            );
        case "textArea":
            return (
                <RowWrapper title={property.title}>
                    <Field
                    id={property.id}
                    name={property.id}
                    validate={property.required && required}
                    component={InputArea}
                    placeholder={(property.textArea && property.textArea.placeHolder) ? property.textArea.placeHolder : "" }
                    disabled={disable}
                    />
                </RowWrapper>
            );
        case "htmlEditor":
            return (
                <RowWrapper title={property.title} titleClass={styles.uploadTitle}>
                    <Field
                    id={property.id} 
                    name={property.id}
                    height="80vh"
                    component={TextEditor}
                    />
                </RowWrapper>
            );
        case "radioButtons":
            return (
                <RowWrapper title={property.title}>
                    <FormGroup legendText="">
                    <Field id={property.id}
                            name={property.id}
                            component={InputSelect}
                            options={property.radioButtons.options}
                            defaultSelected={property.default}
                            disabled={disable}
                    />
                    </FormGroup>
                </RowWrapper>
            );
        case "dropdown":
            // const components = property.dropdown.options.find(item => item.id === dropdownStates[property.id])?.components
            // if (components !== undefined ) {
            //     return (<>
            //         <DropdownRow property={property} 
            //         disable={disable} 
            //         dropdownStates={dropdownStates} 
            //         setDropdownStates={setDropdownStates}/>
            //         { components.map((property, index) => 
            //             <PropertyDiv 
            //                 property={property} 
            //                 index={index} 
            //                 disable={disable} 
            //                 valueObject={valueObject}
            //             />
            //         )}
            //     </>);
            // } else {
                return (<DropdownRow 
                    property={property} 
                    disable={disable} />);
            // }
        case "color":
            return (
                <RowWrapper title={property.title}>
                    <Field
                        id={property.id}
                        name={property.id}
                        disabled={disable}
                    >
                        {({input}) => (
                        <ColorPicker 
                            options={property.dropdown.options} 
                            property={property} 
                            disable={disable} 
                            color={valueObject[property.id]}
                            didChange={input.onChange}/>
                            )}
                    </Field>
                </RowWrapper>
            );
            case "emoji":
                return (
                    <RowWrapper title={property.title}>
                        <Field
                            id={property.id}
                            name={property.id}
                            disabled={disable}
                        >
                            {({input}) => (
                            <EmojiSelect 
                                property={property} 
                                disable={disable} 
                                emoji={valueObject[property.id]}
                                didChange={input.onChange}/>
                                )}
                        </Field>
                    </RowWrapper>
                );

        case "image":
            return (
                <div className="bx--col-lg-8 bx--col-md-4">
                    <FormGroup legendText={property.title}>
                    <Field
                        id={property.id}
                        name={property.id}
                        component={InputImage}
                        initialSrc={valueObject && valueObject[property.id] && RES_PATH + valueObject[property.id]}
                        width={property.image?.width ?? 77}
                        height={property.image?.height ?? 100}
                        scaledWidth={property.image?.scaledWidth ?? 300}
                        scaledHeight={property.image?.scaledHeight ?? 400}
                        saveOriginalProportion
                    />
                    </FormGroup>
                </div>
            )
        case "date":
            return (
                <RowWrapper title={property.title}>
                    <Field 
                        id={property.id}
                        name={property.id}
                        component={({ input }) =>
                        <InputTimePicker { ... input }
                        timestamp={valueObject && valueObject[property.id]}
                        defaultValue={property.default}
                        /> }
                        style={{ width: '100%' }}
                        disabled={disable}
                        timestamp={valueObject && valueObject[property.id]}
                        />
            </RowWrapper>
            )
        case "objectslist":
            return (
                <RowWrapper title={property.title}>
                    <Field
                        id={property.id}
                        name={property.id}
                        disabled={disable}
                    >
                        {({input}) => (
                        <ObjectList 
                            list={(valueObject && valueObject[property.id]) ?? []} 
                            property={property} 
                            disable={disable} 
                            didChange={input.onChange}/>
                            )}
                    </Field>
                </RowWrapper>
            );
        case "custom":
            return (property.custom.component)
        default:
            return (<></>)
    }
};

const DropdownRow = ({property, disable}) => {
    const [dropdownStates, setDropdownStates] = React.useState({});

    return (<RowWrapper title={property.title}>
        <Field name={property.id}
            style={{ width: '100%' }}
            validate={property.required && requiredDropdown(property.default)}
            defaultValue={property.default}
            disabled={disable}
            component={InputDropdown}
            onChange={ event => {
                var states = dropdownStates
                let value = property.dropdown.options.find( element => element.id === event.target.value).value
                states[property.id] = value
                setDropdownStates(states)
            }}
            options={property.dropdown.options}
            >
        </ Field>
        <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
        { 
        property.dropdown.options.find( element => element.id === dropdownStates[property.id]) &&
        property.dropdown.options.find( element => element.id === dropdownStates[property.id]).desc
        }
        </div>
    </RowWrapper>)
};

const listIsEqual = (property, list1, list2) => {
    if(list1?.length !== list2?.length) {
        return false
    }
    for(var i = 0; i < list1.length; ++i) {
        for(var j = 0; j < property.objectslist.properties.length; ++j ) {
            const prop = property.objectslist.properties[j];
            if(list1[i][prop.id] !== list2[i][prop.id]) {
                return false
            }
        }
    }

    return true
}

const ObjectList = ({list, property, disable, didChange}) => {
    const [initialItemsList, setInitialItemsList] = useState();
    const [visibleForm, setVisibleForm] = useState(false);
    const [itemsList, setItemsList] = useState(list);

    const addTitle = property.addTitle ? property.addTitle : "Add Item";

    useEffect(() => {
        if (!listIsEqual(property, list, initialItemsList) && list.length > 0) {
            setInitialItemsList(list)
            setItemsList(list)
        }
    }, [initialItemsList, list]);

    return (
        <>
                    <StructuredListWrapper selection ariaLabel="Structured list">
                    <StructuredListHead>
                        <StructuredListRow
                            head
                            tabIndex={0}
                            >
                                { property.objectslist.properties.map( item => (
                                    (<StructuredListCell head>
                                        {item.title}
                                        </StructuredListCell>)
                                ))
                                }
                            <StructuredListCell head style={{width:"50px"}}>{''}</StructuredListCell>
                            </StructuredListRow>
                        </StructuredListHead>
                    <StructuredListBody>
                    {itemsList.map((item, i) => {
                        const values = _getInitialValues(item, property.objectslist.properties)
                        return (
                        <StructuredListRow 
                        tabIndex={0}
                        onClick={()=>{}}
                        >
                            {property.objectslist.properties.map(prop => (
                                <StructuredListCell>
                                    {prop.type === 'custom' ? 
                                        prop.component({item:values[prop.id]}) :
                                        values[prop.id]
                                    }
                                </StructuredListCell>
                            ))}
                        <StructuredListCell>
                            <OverflowMenu flipped>
                            <OverflowMenuItem
                                itemText="Delete"
                                isDelete
                                onClick={()=>{
                                    let newList = itemsList.filter(obj => 
                                        property.objectslist.identifier(obj) !== property.objectslist.identifier(item)
                                        );
                                    didChange(newList);
                                    setItemsList(newList);
                                }}
                            />
                            </OverflowMenu>
                        </StructuredListCell>
                        </StructuredListRow>)})}
                    </StructuredListBody>
                </StructuredListWrapper>
                <Button kind="secondary" size="sm" onMouseUp={()=>setVisibleForm(true)}>{addTitle}</Button>
                <ModalForm
                    modalHeading = {addTitle}
                    action = {"Add"}
                    open = {visibleForm}
                    properties={property.objectslist.properties}
                    cancel={()=> setVisibleForm(false)}
                    submit={(value) => {
                        let updatedItemsList = itemsList
                        updatedItemsList.push(value)

                        didChange(updatedItemsList);
                        setItemsList(updatedItemsList);
                        setVisibleForm(false);
                    }}
                />
        </>
    )
}
FormContent.propTypes = {
    submit: PropTypes.func.isRequired,
    setTrigger: PropTypes.func.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        changed: PropTypes.func,
        type: PropTypes.oneOf(["toggle", "text", "textArea", "htmlEditor", "radioButtons", "dropdown", "image", "date", "custom"]).isRequired,
        toggle: PropTypes.shape({
            labelA: PropTypes.string,
            labelB: PropTypes.string
        }),
        text: PropTypes.shape({
            placeHolder: PropTypes.string
        }),
        textArea: PropTypes.shape({
            placeHolder: PropTypes.string
        }),
        radioButtons: PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                labelText: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                desc: PropTypes.string
            })),
        }),
        dropdown: PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                labelText: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                desc: PropTypes.string
            })),
        }),
        custom: PropTypes.shape({
            component: PropTypes.any.isRequired
        }),
        convertFrom: PropTypes.func,
        convertTo: PropTypes.func,
        required: PropTypes.bool,
        style: PropTypes.any,
        default: PropTypes.any,
    })).isRequired
};

export default FormContent;