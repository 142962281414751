import React from 'react';

export const WaveImage = () => {
  return (
    <svg width="50" height="60" viewBox="0 0 50 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="48" height="58" rx="5" stroke="#6A6D73" strokeWidth="2"/>
      <rect x="26" y="20" width="2" height="20" rx="1" fill="#6A6D73"/>
      <rect x="22" y="22" width="2" height="16" rx="1" fill="#6A6D73"/>
      <rect x="18" y="26" width="2" height="8" rx="1" fill="#6A6D73"/>
      <rect x="14" y="24" width="2" height="12" rx="1" fill="#6A6D73"/>
      <rect x="10" y="27" width="2" height="6" rx="1" fill="#6A6D73"/>
      <rect x="30" y="25" width="2" height="10" rx="1" fill="#6A6D73"/>
      <rect x="34" y="22" width="2" height="16" rx="1" fill="#6A6D73"/>
      <rect x="38" y="27" width="2" height="6" rx="1" fill="#6A6D73"/>
    </svg>
  )
}
