import React from 'react';
// import PT from 'prop-types';
import cn from 'classnames';
import cx from './Content.module.scss';
import mn from 'src/styles/global.module.scss';
import { getTheme } from 'src/modules/actions/theme.actions';

export const Content = ({style, children, width, ...props}) => {
  const theme = getTheme(props.theme)
  
  return (
    <div className={cn(cx.center, mn.bodyText1, theme.bodyColor)} style={{maxWidth: width ?? 1024, ...style}} >
      <div>
        <div className={cn(cx.content)}>
        {children}
        </div>
      </div>
    </div>
  )
};

Content.propTypes = {
};

export default Content;