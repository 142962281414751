import React, { useEffect, useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import Item from '../Item';
import Layout from 'src/layout/ContentLayout';
import Content from 'src/components/_shared/Content';
import cx from './styles.module.scss';
import cn from 'classnames';
import { Button, OverflowMenu, OverflowMenuItem, StructuredListBody, StructuredListCell, StructuredListHead, StructuredListInput, StructuredListRow, StructuredListWrapper } from 'carbon-components-react';
import history from 'src/history';
import { Loading } from 'carbon-components';
import { useContext } from 'react';
import { NotificationContext } from 'src/components/ErrorContext/ErrorContext';

const scrollDiff = 150

export const LOADING_STATES = {
  NONE: 0,
  STARTED: 1,
  LOADING: 2,
};

export const VISUAL_STYLE = {
  CELL: "cell",
  LIST: "list",
};

const List = ({ getAll, remove, title, deleteTitle = 'Delete', editRoute, addRoute, addTitle, theme, list, actions, style = VISUAL_STYLE.CELL, menuActions }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastPage, setlastPage] = useState(false);
    const [loadingState, setLoadingState] = useState(LOADING_STATES.NONE);
    const notificationContext = useContext(NotificationContext);

    const fetchAll = useCallback( () => {
      getAll(0).then( result => setItems(result));
    }, [getAll]);

    useEffect( () => {
      fetchAll();
    }, [fetchAll]);

    const didScroll = useCallback( async (e) => {
        const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop < e.target.scrollingElement.clientHeight + scrollDiff;
        if(bottom && !loading && !lastPage && loadingState === LOADING_STATES.NONE){
          setLoadingState(LOADING_STATES.STARTED)
          getAll(items.length ?? 0).then(newItems => {
            setlastPage(newItems.length === 0)
            setItems(items.concat(newItems))
          })
        }
    }, [loading, lastPage, loadingState]);
    
    useEffect( () => {
        window.addEventListener('scroll', didScroll);
        return () => {
          window.removeEventListener('scroll', didScroll);
        }
    }, [didScroll]);

    const ActionButtons = () => {
        return (
          <div className={cx.buttonsBlock}>
            {actions}
            {addRoute &&
              <Button kind='tertiary'
                      style={{ paddingRight: '16px'}}
                      onClick={() => history.push(addRoute)}
              >
                {addTitle ?? "Add"}
              </Button>
            }
          </div>
        );
      }
    return (
        <>
            <Layout
                title={title}
                    renderAction={ActionButtons}>
                {(style === VISUAL_STYLE.CELL) &&
                  <Content theme={theme}>
                      <div className={cn(cx.grid)}>
                      {items &&
                      items.map((item, i) =>
                          <div className={cn(cx.item)}>
                            <Item
                                key={i} 
                                object={item}
                                editRoute={() => editRoute(item.uuid)}
                                onDelete={() => {
                                  remove(item.uuid)
                                    .then((response) => {
                                      if (response && response.status === 200) {
                                        fetchAll();
                                      } else {
                                        notificationContext.showMessage(`Deletion Failed: "${response.data.details}"`, 'error');
                                      }
                                    })
                                    .catch(error =>{
                                      notificationContext.showMessage(`"${deleteTitle}" failed`, 'error');
                                    })
                                }}
                                />
                            </div>
                      )
                      }
                      </div>
                      {loading && <Loading key="bottomProgress" style={{margin: "0 auto"}} active={loading} withOverlay={false}/>}
                  </Content>
                }
                {(style === VISUAL_STYLE.LIST) &&
                  <StructuredListWrapper selection ariaLabel="Structured list">
                  <StructuredListHead>
                  <StructuredListRow
                      head
                      tabIndex={0}
                    >
                    {list?.colums && 
                      list?.colums.map((name) =>
                        (<StructuredListCell head>
                          {name}
                          </StructuredListCell>)
                      )
                    }
                      <StructuredListCell head style={{width:"50px"}}>{''}</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                  {items &&
                      items.map((item, i) => (
                        <StructuredListRow 
                          tabIndex={0}
                          onClick={()=>{editRoute && history.push(editRoute(item.uuid));}}
                          >
                          {list?.rowComponents && 
                          list?.rowComponents(item).map((title) => (
                            <StructuredListCell>
                              {title}
                            </StructuredListCell>
                          ))}
                          <StructuredListCell>
                            <OverflowMenu flipped>
                              <OverflowMenuItem
                                itemText={deleteTitle}
                                isDelete
                                onClick={()=>{
                                  remove(item.uuid)
                                  .then((response) => {
                                    if (response && response.status === 200) {
                                      fetchAll();
                                    } else {
                                      notificationContext.showMessage(`Deletion Failed: "${response.data.details}"`, 'error');
                                    }                                  
                                  }).catch(error =>{
                                    notificationContext.showMessage(`"${deleteTitle}" failed`, 'error');
                                  })
                                }}
                              />
                              {menuActions?.map((action) =>
                                <OverflowMenuItem
                                  itemText={action.title}
                                  isDelete={action.isDelete}
                                  onClick={() => action.onClick(item.uuid)}
                                />
                              )}
                            </OverflowMenu>
                          </StructuredListCell>
                        </StructuredListRow>)
                      )
                  }
                  </StructuredListBody>
                  </StructuredListWrapper>
             }
            </Layout>
        </>
    );
};

List.propTypes = {
  getAll: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired, 
  title: PropTypes.string.isRequired, 
  editRoute: PropTypes.string.isRequired, 
  addRoute: PropTypes.string.isRequired, 
  addTitle: PropTypes.string,
  theme: PropTypes.string,
  actions: PropTypes.func,
  style: PropTypes.string,
  menuActions: PropTypes.array,
};
  
export default List;