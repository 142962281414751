import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup } from 'carbon-components-react';

const InputSelect = ({ input, meta, disabled, options, defaultSelected }) => {

  return (
    <RadioButtonGroup
      orientation="vertical"
      onChange={value => input.onChange(value)}
      name={input.name}
      defaultSelected={defaultSelected}
      valueSelected={input.value}
      disabled={disabled}
    >
      {
        options.map((option, index) => (
            <RadioButton key={index} {...option}
                         labelText={<>
                           <span style={{ color: '#f1c21b'}}>{option.labelText}</span>
                           {' '}
                           <div style={{ color: '#c6c6c6'}}>{option.desc}</div>
                         </>} />
        ))
      }
    </RadioButtonGroup>
  )
};

InputSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  defaultSelected: PropTypes.string.isRequired
};

export default InputSelect;
