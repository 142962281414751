import React from 'react';
import { Switch, Route } from 'react-router';
import List from './List';
import Edit from './Edit';

const ContentPackage = ({ path, getAll, get, post, put, remove, title, addTitle, properties, style, list, actions, menuActions}) => {
    const addRoute = `${path}/add`

    return (
        <>
            <Switch>
                <Route path={addRoute}
                       render={(props) => <Edit listRoute={`${path}`}
                                                properties={properties}
                                                get={get}
                                                post={post}
                                                put={put}
                                                {...props} />
                                                }
                />
                <Route path={`${path}/:uuid/edit`} c
                       render={(props) => <Edit listRoute={`${path}`}
                                                properties={properties}
                                                get={get}
                                                post={post}
                                                put={put}
                                                title={title}
                                                {...props} />
                                                }
                />
                <Route 
                render={(props) => <List title={title}
                                         getAll={getAll} 
                                         remove={remove} 
                                         editRoute={uuid=> `${path}/${uuid}/edit`}
                                         addRoute={addRoute}
                                         addTitle={addTitle}
                                         style={style}
                                         list={list}
                                         actions={actions}
                                         menuActions={menuActions}
                                         {...props} />
                                        } 
                            />

            </Switch>
        </>
    );
};

export default ContentPackage;