import React, { useState } from 'react';
import { TextInput, Button } from 'carbon-components-react';

import { forgotPassword } from 'src/modules/actions/authentication.actions';
import basicModalPage from 'src/layout/basicModalPage';
import history from 'src/history';

import cs from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setSubmitError(null);
    if (!email || email.trim() === '') {
      setEmailInvalid('Email is required');
    } else {
      setLoading(true);
      const sent = await forgotPassword(email);
      setLoading(false);
      if (sent) {
        history.push('/password-sent');
      } else {
        setSubmitError('Some error happened :(');
      }
    }
  }

  return (
    <>
      <div className={cs.inputRow}>
        <div>Email*</div>
          <TextInput labelText=''
                     id='email'
                     value={email}
                     onChange={e => setEmail(e.target.value)}
                     error='sdfsf'
                     invalid={emailInvalid}
                     invalidText={emailInvalid}
                     disabled={loading}
                     onFocus={() => {
                       setEmailInvalid(null)
                       setSubmitError(null);
                     }}
          />
      </div>
      <Button onClick={onSubmit} disabled={loading} className={cs.submit}>
        Submit
      </Button>
      {submitError && <div className={cs.error}>{submitError}</div>}
    </>
  )
}

export default basicModalPage(ForgotPasswordPage, "Forgot Password", { textAlign: 'center' });
