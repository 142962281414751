import React from 'react';
import PT from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FixedSizeList as List } from 'react-window';
import CustomScrollbarsVirtualList from 'src/components/MainPage/CustomScrollbarsVirtualList';

const ScrollingList = (props) => {
  const { screenWidth, width, height, items, children } = props;

  return (
    <List
      width={screenWidth}
      itemSize={width}
      height={height}
      itemCount={items.length}
      layout="horizontal"
      outerElementType={CustomScrollbarsVirtualList} >
      {
        ({index, style}) => {
          const item = items[index];
          return (
            <div style={style}>
              {
              children(item)
              }
            </div>
          );
        }
      }
    </List>
  )
};

ScrollingList.propTypes = {
  items: PT.array.isRequired,
  width: PT.number.isRequired,
  height: PT.number.isRequired,
  screenWidth: PT.number.isRequired,
  children: PT.func.isRequired
};

const HorizontalItemList = (props) => (
  <>
    {
      props.items.length > 0 ?
        <>
          <ScrollingList {...props} />
        </> : null
    }
  </>
);

HorizontalItemList.defaultProps = {
  screenWidth: 0,
  items: []
};

HorizontalItemList.propTypes = {
  items: PT.array.isRequired,
  width: PT.number.isRequired,
  height: PT.number.isRequired,
  screenWidth: PT.number.isRequired,
  children: PT.func.isRequired
};

export default HorizontalItemList;
