import React from 'react';
import PropTypes from 'prop-types';
import { Tile } from 'carbon-components-react';
import classNames from 'classnames';

import history from 'src/history';
import cs from './styles.module.scss';
import gs from 'src/styles/styles.module.scss';

import { RES_PATH } from 'src/config/environment';
import Author from 'src/components/_shared/Author';
import Badge from 'src/components/_shared/Badge';
import Bookmark from 'src/components/_shared/Book/Bookmark';
import Like from 'src/components/_shared/Like';
import Href from "src/components/_shared/Href";

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

export const NewThisWeekItem = ({ book, chapter, author, updatedAt, ...rest }) => {

  const bookURL = () => {
    return `/books/${book.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };

  const nameRef = React.useRef(null);
  const descRef = React.useRef(null);
  React.useEffect(() => {
    const name = nameRef.current;
    const desc = descRef.current;
    if (name && desc) {
      if (name.offsetHeight > 30) {
        desc.style = "-webkit-line-clamp: 2";
      }
    }
  }, [nameRef]);

  return (
    <div className={classNames({ [cs.flexible]: rest.flexible })}>
      <Tile className={classNames(cs.tile, gs.noselect)}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp(goBook)}
      >
        <div
          className={cs.main}
        >
          <div className={cs.authorArea} style={{ minHeight: '43px', paddingRight: '90px' }}>
            <Author {...author} updatedAt={updatedAt}/>
          </div>
          <div
            className={classNames(cs.bookName, gs.noselect)}
            title={book.name}
          >
            <Href href={bookURL()} style={{ cursor: 'pointer', display: 'inline' }} ref={nameRef}>
              {book.name}
            </Href>
          </div>

          <div
            className={classNames(cs.chapterName, gs.noselect)}
            title={chapter.name}
          >
            <span
              onMouseDown={onMouseDown}
              style={{ cursor: 'pointer' }}
            >
              {chapter.name}
            </span>
          </div>
          <div className={cs.desc} ref={descRef}>
            {book.about}
          </div>
        </div>


        <div
          className={cs.cover}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp(goBook)}
        >
          <div
            className={cs.coverImg}
            style={{ backgroundImage: `url(${RES_PATH}${book.image})`}}
          />
          {rest.new && <Badge style={{bottom: '9px', left: '-9px'}}/>}

          <div style={{ position: 'absolute', zIndex: 100, left: -88, top: 5 }}>
            <Like {...book} size='m' style={{padding: '4px 0'}} author={author}/>
          </div>
          <div style={{ position: 'absolute', zIndex: 100, left: -45, top: 5 }}>
            <Bookmark uuid={book.uuid}/>
          </div>

        </div>
      </Tile>
    </div>
  )
};

NewThisWeekItem.defaultProps = {
  flexible: true
};

NewThisWeekItem.propTypes = {
  new: PropTypes.bool.isRequired,
  updatedAt: PropTypes.number.isRequired,

  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired
  }).isRequired,

  chapter: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,

  author: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,

  flexible: PropTypes.bool
};

export default NewThisWeekItem;
