export const ACTION_TYPES = {
  REQUEST_CHECKOUT_PENDING: 'payment/REQUEST_CHECKOUT_PENDING',
  REQUEST_CHECKOUT_FULFILLED: 'payment/REQUEST_CHECKOUT_FULFILLED',
  REQUEST_CHECKOUT_REJECTED: 'payment/REQUEST_CHECKOUT_REJECTED',

  CLOSE_ERROR: 'payment/CLOSE_ERROR'
};

const defaultState = {
  loading: false,
  error: null
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_CHECKOUT_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.REQUEST_CHECKOUT_FULFILLED:
      return {
        ...state,
        loading: false,
        purchased: true
      };
    case ACTION_TYPES.REQUEST_CHECKOUT_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ACTION_TYPES.CLOSE_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}

