import React from 'react';
import PropTypes from 'prop-types';
import * as utils
  from 'src/components/reader/ListenPage/chapters.util';

import cs from './ChapterName.module.scss';

const ChapterName = ({ book, currentChapter }) => {
  const chapterId = utils.findCurrentChapter(book?.chapters, currentChapter);
  const chapterData = chapterId && book?.chapters?.find(c => c.uuid === chapterId);
  return (
    <>
      {chapterData && <div className={cs.chapterName}>{chapterData.name}</div>}
    </>
  )
}

ChapterName.propTypes = {
  chapters: PropTypes.array,
  currentChapter: PropTypes.string
}

export default ChapterName;
