import React from 'react';
import PropTypes from 'prop-types';
import history from 'src/history';
import { Button, OverflowMenu, OverflowMenuItem, Tile } from 'carbon-components-react';

import cs from './Item.module.scss';
import { RES_PATH } from 'src/config/environment';

export const Item = ({object, onDelete, deleteTitle='Delete', editRoute, ...props}) => {
  const image = object.image

  return (
    <Tile className={cs.bookCard}>
      {image ?
      <div
        className={cs.poster}
        style={{ backgroundImage: `url("${RES_PATH}${image}")`, backgroundSize: 'cover' }}
        onClick={() => history.push(`/blog/${object.id}`)}
      /> :
      <div className={cs.poster} style={{ backgroundColor: "gray",  textAlign: "center"}}>
        No Image
      </div>
      }
      <div className={cs.desc}>
        <div>
          <div className={cs.title} title={object.name}>{object.name}</div>
          <div className={cs.counters}>
        </div>
      </div>
      <div className={cs.toolbar}>
          <Button
            size='small'
            kind='secondary'
            onClick={() => history.push(editRoute)}
          >
            Edit
          </Button>
          <OverflowMenu>
            <OverflowMenuItem
              itemText={deleteTitle}
              isDelete
              onClick={onDelete}
            />
          </OverflowMenu>
        </div>
      </div>
    </Tile>
  )
};

Item.propTypes = {
  fetchBlog: PropTypes.func.isRequired,
};

export default Item;
