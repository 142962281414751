import React, { useState, useRef } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Search20, Close20 } from '@carbon/icons-react'

import history from 'src/history';
import cn from 'classnames';
import cs from './styles.module.scss';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { globalSearchInput, prepareSearching } from 'src/modules/actions/search.actions';

export const Search = ({ globalQuery, ...actions }) => {

  const [opened, setOpened] = useState(false);
  const [contClass, setContClass] = useState(cs.container);
  const inputEl = useRef(null);
  const container = useRef(null);

  useOnClickOutside(container, () => setOpened(false));

  const inputFocused = () => {
    setContClass(cn(cs.container, cs.inputFocusWithin));
  };

  const inputBlured = () => {
    setContClass(cs.container);
  };

  const close = () => setOpened(false);

  const open = () => {
    setOpened(true);
    inputEl.current.focus();
  };

  const onChange = evt => {
    actions.globalSearchInput(evt.target.value);
  };

  const handleEnter = env => {
    if (env.key === 'Enter') {
      actions.prepareSearching();
      history.push('/results');
    }
  };

  return (
    <div
      className={cn(contClass, { [cs.containerHidden]: !opened })}
      ref={container}
    >
      <div className={cs.inputWrapper}>

        <button
          className={cn(cs.searchButton, {[cs.searchButtonOpened]: opened})}
          onClick={open}
        >
          <Search20/>
        </button>

        <input
          type='text'
          className={cn(cs.input, { [cs.elementHidden]: !opened })}
          onFocus={inputFocused}
          onBlur={inputBlured}
          ref={inputEl}
          placeholder='Search'
          onChange={onChange}
          value={globalQuery}
          onKeyDown={handleEnter}
        />

        <button
          className={cn(cs.searchButton, cs.closeButton, { [cs.elementHidden]: !opened })}
          onClick={close}
        >
          <Close20/>
        </button>

      </div>
    </div>
  )
};

Search.propTypes = {
  globalQuery: PT.string,
  globalSearchInput: PT.func.isRequired,
  prepareSearching: PT.func.isRequired
};

function mapStateToProps(state) {
  return {
    globalQuery: state.search.globalQuery
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ globalSearchInput, prepareSearching }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);