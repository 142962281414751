import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, InlineNotification, Modal } from 'carbon-components-react';
import { Information20 } from '@carbon/icons-react';
import { connect } from 'react-redux';

import Layout from 'src/layout/ContentLayout';
import { useStripePayouts } from 'src/hooks';
import { Loading } from 'carbon-components-react';
import ps from 'src/modules/model/payout-state';
import PayoutTip from 'src/components/_shared/PayoutTip';

import cs from './earnings-page.module.scss';
import { Link } from 'react-router-dom';
import ModalForm from '../_shared/form/ModalForm';
import { COUNTRY_OPTIONS } from './Countries';
import { fetchStatistics, fetchReferralInfo, fetchBooks } from 'src/modules/actions/writers.actions';
import { fetchBook, forgetBook } from 'src/modules/actions/books.actions';
import { AreaChart, SimpleBarChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import Delimiter from '../_shared/Delimiter';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const EarningsPage = ({ email, statistics, referralInfo, fetchStatistics, fetchBooks, fetchBook, forgetBook, fetchReferralInfo, books, book }) => {
  const { loading, payoutState, configurePayouts, fetchState, createStripeAccount, openDashboard, resetPayouts, error, errorMessage } = useStripePayouts();
  const [modalOpen, setModalOpen] = useState(false);
  const [countrySelectionOpen, setCountrySelectionOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [bookSelection, setBookSelection] = useState([]);
  const [bookOptions, setBookOptions] = useState([]);
  const [chaptersBook, setChaptersBook] = useState(null);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [chapterSelection, setChapterSelection] = useState([]);

  useEffect(() => {
    fetchStatistics(bookSelection?.id, chapterSelection?.id);
    fetchReferralInfo();
    fetchBooks();
  }, [fetchReferralInfo, fetchStatistics, fetchBooks, bookSelection, chapterSelection]);

  useEffect(() => {
    const options = books?.map(b => ({name: b.name, id: b.uuid})) ?? [];
    if(bookOptions.length !== options.length) {
      setBookOptions(options);
      setBookSelection(options[0]);
    }
  }, [bookOptions, books]);

  useEffect(() => {
    if (bookSelection?.id) {
      fetchBook(bookSelection?.id);
    } else {
      forgetBook();
    }
  }, [bookSelection, fetchBook, forgetBook]);

  useEffect(() => {
    if(book?.uuid !== chaptersBook?.uuid) {
      const options = book?.chapters?.map(b => ({name: b.name, id: b.uuid})) ?? [];

      setChaptersBook(book)
      setChapterOptions(options);
      setChapterSelection(options[0]);
    }
  }, [book, chaptersBook]);

  const _configure = () => {
    setCountrySelectionOpen(true);
  }

  const _createAccount = async (value) => {
    const url = await createStripeAccount(value.country);
    setCountrySelectionOpen(false);
    window.location.href = url  }
  
  const _resetPayouts = async (value) => {
    await resetPayouts(); 
    await fetchState();
    setModalOpen(false);
  };

  const optionsSubscription = {
    title: "Active Subscriptions",
    axes: {
      left: {
        mapsTo: "group",
        scaleType: "labels"
      },
      bottom: {
        mapsTo: "value"
      }
    },
    height: "400px",
    theme: "g100"
  };

  const optionsEvents = {
    axes: {
      left: {
        mapsTo: "value",
        scaleType: "linear"
      },
      bottom: {
        mapsTo: "date",
        scaleType: "time"
      }
    },
    height: "400px",
    theme: "g100"
  };

  return (
    <>
      <Loading active={loading}
               withOverlay
      />

      <Layout title='Earnings'>
        <div className={cs.content}>
          <div className={cs.title}>
            <h2>Referrals</h2>
            <Delimiter style={{marginTop: "8px"}} />
          </div>
          <div>
            <div className={classNames(cs.chart, cs.howto)}>
              <h3>How To</h3>
              <ol>
                <li><p>Invite a friend with your referral link</p></li>
                <li><p>Your friend writer will get {referralInfo?.recepientFeeDecrease}% of Fictionate.me fee waived for a year. ({5 - referralInfo?.recepientFeeDecrease}% instead of 5%).</p></li>
                <li><p>You get {referralInfo?.senderFeeDecrease}% waived of fictionate.me fee for a year for each active writer (at least 3 subscribers) and get {referralInfo?.revenueShare}% income match on referred writers subscriptions revenue.</p></li>
              </ol>
              <div className={cs.refferal}>https://fictionate.me/register?code={referralInfo?.referralCode}
              <CopyToClipboard text={`https://fictionate.me/register?code=${referralInfo?.referralCode}`}
                       onCopy={() => setCopied(true)}>
                <div className={cs.button}>{!copied ? 'Copy' : 'Copied'}</div>
              </CopyToClipboard>
            </div>
            </div>
            <div className={classNames(cs.chart, cs.howto)}>
              <h3>Status</h3>
              <ol>
                <li><p>Invited Writers: {referralInfo?.totalReferrals}</p></li>
                <li><p>Invited Active Writers: {referralInfo?.activeReferrals}</p></li>
                <li><p>My earnings share: {referralInfo?.totalRevenuePercentage}% {(referralInfo?.totalRevenuePercentage !== 95) && "(default is 95%)"}</p></li>
                <li><p>My refferal income: ${referralInfo?.referralIncome/100.0}</p></li>
              </ol>
            </div>
          </div>
          <div className={cs.title}>            
            <h2>Statistics</h2>
            <Delimiter style={{marginTop: "8px"}} />
          </div>
          <div className={cs.charts}>
            <div className={cs.chart}>
            <SimpleBarChart
              data={statistics.allSubscriptions ?? []}
              options={optionsSubscription}>
            </SimpleBarChart>
            </div>
            <div className={cs.chart}>
            <AreaChart
              data={statistics.bookReadsPerDay ?? []}
              options={{...optionsEvents, title: "Book Reads Per Day"}}>
            </AreaChart>
            </div>
            <div className={cs.chart}>
            <AreaChart
              data={statistics.profileViewsPerDay ?? []}
              options={{...optionsEvents, title: "Profile Views Per Day"}}>
            </AreaChart>
            </div>
            <div className={cs.chart}>
            <AreaChart
              data={statistics.bookViewsPerDay ?? []}
              options={{...optionsEvents, title: "Books Views Per Day"}}>
            </AreaChart>
            </div>
            <div className={cs.chart}>
            <AreaChart
              data={statistics.subscriptionsPerDay ?? []}
              options={{...optionsEvents, title: "New Subscriptions"}}>
            </AreaChart>
            </div>
            <div className={cs.chart}>
            <AreaChart
              data={statistics.bookPurchasePerDay ?? []}
              options={{...optionsEvents, title: "Book Purchases"}}>
            </AreaChart>
            </div>
            <div className={cs.chart}>
              <div className={cs.dropdownBlock}>
                <Dropdown
                  className={cs.dropdown}
                  type="inline"
                  size='sm'
                  titleText="Book:"
                  items={bookOptions}
                  itemToString={(item) => item.name}
                  selectedItem={bookSelection}
                  onChange={(item)=> setBookSelection(item.selectedItem)}
                />
                <Dropdown
                  className={cs.dropdown}
                  type="inline"
                  size='sm'
                  titleText="Chapter:"
                  items={chapterOptions}
                  itemToString={(item) => item.name}
                  selectedItem={chapterSelection}
                  onChange={(item)=> setChapterSelection(item.selectedItem)}
                />
                </div>
            <AreaChart
              data={statistics.chapterReadsPerDay ?? []}
              options={{...optionsEvents, title: "By Chapter Reads"}}>
            </AreaChart>
            </div>
          </div>
          <div className={cs.title}>
            <h2>Configuration</h2>
            <Delimiter style={{marginTop: "8px"}} />
          </div>
          { !loading && payoutState ?
            <div>
              {payoutState === ps.NOT_CONFIGURED ?
                <>
                  <div className={cs.info}><Information20/> In order to receive payments, Stripe payouts need to be configured.</div>
                  <div><Button kind='tertiary' onClick={_configure}>Configure Stripe Payouts</Button></div>
                </> :
                payoutState === ps.CONFIGURED || payoutState === ps.TRANSFERS_INACTIVE?
                  <>
                    <div><Button kind='tertiary' onClick={openDashboard}>Open Stripe Dashboard</Button></div>
                    <div style={{marginTop:"16px", marginLeft:"8px"}}><Link kind='tertiary' onClick={() => setModalOpen(true)}>Reset Connection</Link></div>
                  </> :

                  <PayoutTip payoutState={payoutState} onClick={_configure}/>
              }
              {error &&
                <InlineNotification kind='warning'
                                            lowContrast
                                            hideCloseButton
                                            title={error}
                >
                  {errorMessage}
                </InlineNotification>
              }
            </div> : null
          }
        </div>
        <Modal
          aria-label=''
          open={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          onSecondarySubmit={() => setModalOpen(false)}
          onRequestSubmit={() => {
            _resetPayouts();
          }}
          primaryButtonText='Reset Stripe'
          secondaryButtonText='Cancel'
          modalHeading='Are you sure you want to reset the stripe connection?'
        />
        <ModalForm
          modalHeading = "Select your country"
          open = {countrySelectionOpen}
          action = "Configure Payouts"
          properties={[
            {
              title: "Country", type: "dropdown", id: "country", 
              default: "US",
              dropdown: {
                options: COUNTRY_OPTIONS
              }
            },
          ]}
          cancel={()=> setCountrySelectionOpen(false)}
          submit={(value) => {
            _createAccount(value);
          }}
        />      
      </Layout>
    </>
  )
}

EarningsPage.defaultProps = {
  email: ''
}

EarningsPage.propTypes = {
  email: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    email: state.authentication.account.email,
    statistics: state.writers.statistics,
    referralInfo: state.writers.referralInfo,
    books: state.writers.mybooks,
    book: state.books.book,
  }
}

export default connect(mapStateToProps, { fetchStatistics, fetchReferralInfo, fetchBooks, fetchBook, forgetBook })(EarningsPage);
