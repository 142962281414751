import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'carbon-components-react';
import { Bookmark24, BookmarkFilled24 } from '@carbon/icons-react';
import { Bookmark16, BookmarkFilled16 } from '@carbon/icons-react';

import classNames from 'classnames';
import cs from './Bookmark.module.scss';
import { addToMyBooks, removeFromMyBooks } from 'src/modules/actions/books.actions';
import history from 'src/history';

export const Bookmark = ({ uuid, bookmarked, isAuthenticated, style, size = "24", className, ...actions }) => {

  const onClick = (e) => {
    e.stopPropagation();
    if (!isAuthenticated) {
      history.push('/login', { from: history.location })
    } else {
      if (bookmarked !== -1) actions.removeFromMyBooks({
        uuid,
        index: bookmarked
      });
      else actions.addToMyBooks(uuid);
    }
  };

  var bookmark
  if(size === "24") {
    bookmark = bookmarked !== -1 ? <BookmarkFilled24/> : <Bookmark24 />
  }  else if(size === "16") {
    bookmark = bookmarked !== -1 ? <BookmarkFilled16/> : <Bookmark16 />
  } 

  return (
    <Button kind='ghost' size='small' onMouseUp={onClick} style={{ border: '0', padding: '0 8px', ...style }} className={classNames(cs.bookmark, className)}>
      {bookmark}
    </Button>
  )
};

Bookmark.defaultProps = {
  style: {}
};

Bookmark.propTypes = {
  uuid: PropTypes.string.isRequired,
  bookmarked: PropTypes.number.isRequired,
  addToMyBooks: PropTypes.func.isRequired,
  removeFromMyBooks: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  style: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    bookmarked: state.me.books.findIndex(entry => entry.book.uuid === ownProps.uuid),
    isAuthenticated: state.authentication.isAuthenticated
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addToMyBooks, removeFromMyBooks }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark);
