import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'carbon-components-react';
import { Swipeable } from 'react-swipeable';
import { EpubView } from 'react-reader';

import defaultStyles from './style';
import { useCombinedRefs } from 'src/hooks';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = (prev, center, next) => e => {
  if (e.clientX === x && e.clientY === y) {
    if (window.innerWidth / 3 > e.clientX) prev();
    else if (window.innerWidth * 2.0 / 3.0 < e.clientX) next();
    else center();
  }
};

const CustomReader = memo(forwardRef((props, forwardedRef) => {
  const {
    title,
    loadingView,
    styles,
    locationChanged,
    swipeable,
    onNextPage,
    onPrevPage,
    onCenter,
    ...rest
  } = props;
  const readerRef = useCombinedRefs(forwardedRef);

  return (
    <div style={styles.container}>
      <div style={Object.assign(
        {},
        styles.readerArea
      )}
           onMouseDown={onMouseDown}
           onMouseUp={onMouseUp(onPrevPage, onCenter, onNextPage)}
      >
        <Swipeable
          onSwipedRight={onPrevPage}
          onSwipedLeft={onNextPage}
          trackMouse
        >
          <div style={styles.reader}>
            <EpubView
              ref={readerRef}
              loadingView={loadingView}
              {...rest}
              locationChanged={locationChanged}
            />
            {swipeable && <div style={styles.swipeWrapper} />}
          </div>
        </Swipeable>
      </div>
    </div>
  )
}));

CustomReader.defaultProps = {
  locationChanged: null,
  styles: defaultStyles,
  loadingView: <Loading />
};

CustomReader.propTypes = {
  loadingView: PropTypes.element,
  locationChanged: PropTypes.func,
  swipeable: PropTypes.bool,
  styles: PropTypes.object,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func
};

export default CustomReader;
