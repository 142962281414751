import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'

export async function getManageCoupons(offset, limit = 8) {
    try {
      const response = await axios.get(`/me/coupons/created?managing=true&limit=${limit}&offset=${offset}`, getAuthHeader());
      if (response && response.status === 200) {
        return response.data.items;
      }
      return null;
    } catch (error) {
      return error.response;
    }
}

export async function get(uuid) {
  try {
    const response = await axios.get(`/coupons/${uuid}`, getAuthHeader());
    if (response && response.status === 200) {
      return response.data
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function put(uuid, object) {
  try {
    return await axios.put(`/coupons/${uuid}`, object, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function post(object) {
  try {
    return await axios.post(`/coupons/bulk`, {
      "coupon": {
        "type": "subscription",
        "content": {
          "maxRating": object.maxRating,
          "managed": true
        }
      },
      "count": parseInt(object.count),
    }, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function remove(uuid) {
  try {
    return await axios.delete(`/coupons/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
