import { ACTION_TYPES as BOOKS_ACTIONS } from 'src/modules/reducers/books.reducer';

export const ACTION_TYPES = {
  OPEN: 'modal/OPEN',
  CLOSE: 'modal/CLOSE',
  NAME_REQUIRED_OPEN: 'modal/NAME_REQUIRED_OPEN',
  NAME_REQUIRED_CLOSE: 'modal/NAME_REQUIRED_CLOSE'
};

const defaultState = {
  open: false,
  options: {},
  nameRequired: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.OPEN:
      return {
        ...state,
        open: true,
        options: action.options
      };
    case ACTION_TYPES.CLOSE:
    case BOOKS_ACTIONS.DELETE_BOOK_FULFILLED:
    case BOOKS_ACTIONS.DELETE_BOOK_REJECTED:
      return {
        ...state,
        open: false,
        options: {}
      };
    case ACTION_TYPES.NAME_REQUIRED_OPEN:
      return {
        ...state,
        nameRequired: true
      };
    case ACTION_TYPES.NAME_REQUIRED_CLOSE:
      return {
        ...state,
        nameRequired: false
      };
    default:
      return state;
  }
}
