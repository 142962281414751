import React, { useEffect, useState } from 'react';
import { Loading } from 'carbon-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cs from './styles.module.scss';
import { fetchBook, forgetBook } from 'src/modules/actions/books.actions';
import NotFound from 'src/components/_shared/NotFound';
import history from 'src/history';

const ReadImmediately = ({ match, book, error, loading, ...actions }) => {

  const [prevLoading, setPrevLoading] = useState(false);
  const { fetchBook, forgetBook } = actions;

  useEffect(() => {
    fetchBook(match.params.bookUuid);
    return () => forgetBook();
  }, [fetchBook, forgetBook, match]);


  useEffect(() => {
    if (error) return;

    if (prevLoading && !loading) {
      readIt();
    } else {
      setPrevLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, prevLoading, error]);

  const findChapterToContinueReading = () => {
    if (book.progress) return book.progress.chapter;
    const freeChapter = book.chapters.find(chapter => chapter.free);
    return freeChapter && freeChapter.uuid
  };

  const readIt = () => {
    const chapter = findChapterToContinueReading();
    if (chapter) {
      history.replace(`/books/${book.uuid}/${chapter}`);
    } else {
      history.replace(`/books/${book.uuid}`)
    }
  };

  return (
    <>
      { loading &&
        <div className={cs.loading}>
          <Loading small withOverlay={false}/>
        </div>
      }
      { error &&
        <NotFound/>
      }
    </>
  )
};

ReadImmediately.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookUuid: PropTypes.string.isRequired
    })
  }),
  book: PropTypes.shape({
    chapters: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      published: PropTypes.bool,
      name: PropTypes.string,
      filePath: PropTypes.string,
      fileType: PropTypes.string,
      free: PropTypes.bool,
      charactersCount: PropTypes.number,
      readCount: PropTypes.number
    })),
    progress: PropTypes.shape({
      progress: PropTypes.number,
      cfiOffset: PropTypes.string,
      chapter: PropTypes.string
    })
  }),
  fetchBook: PropTypes.func.isRequired,
  forgetBook: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    loading: state.books.loading,
    book: state.books.book,
    error: state.books.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBook, forgetBook }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadImmediately);
