import React from 'react';
import PropTypes from 'prop-types';

const Delimiter = ({ style }) => <div

  style={{ backgroundColor: "#565656", height: "1px", marginBottom: '0.5rem', ...style }}
/>;

Delimiter.propTypes = {
  style: PropTypes.any
};

export default Delimiter;

