import React, { useCallback } from 'react';
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, [forwardedRef]);

  return (
    <Scrollbars
      ref={refSetter}
      style={{ ...style, overflow: "hidden" }}
      onScroll={onScroll}
      renderTrackHorizontal={({ style, ...props }) => {
        const finalStyle = {
          ...style,
          right: 2,
          bottom: 2,
          left: 2,
          borderRadius: 3,
          position: 'absolute',
          height: 10
        };
        return (
          <div style={finalStyle} {...props} />
        )
      }}
      renderThumbHorizontal={({ style, ...props }) => {
        const finalStyle = {
          ...style,
          cursor: 'pointer',
          borderRadius: 'inherit',
          backgroundColor: 'rgba(0,0,0,.7)'
        };
        return <div style={finalStyle} {...props} />;
      }}
    >
      {children}
    </Scrollbars>
  );
};

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

export default CustomScrollbarsVirtualList;
