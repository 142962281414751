import React from 'react';
import cn from 'classnames';
import cx from './PageHeader.module.scss';
import { getTheme } from 'src/modules/actions/theme.actions';
import { Href } from '../Href';
import ShareButton from 'src/components/_shared/ShareButton';

const PageHeader = ({sharing, image, name, subtitle, subtitleLink, ...props}) => {
  const theme = getTheme(props.theme);

  const lightGradien = "#FFFFFF1A, #FFFFFF7E";
  const darkGradient = "#00000033, #000000B4";
  const gradient = (props.theme === "light") ? lightGradien : darkGradient;
  return (
    <>
    { image ?
        <div className={cn(cx.header)} style={{backgroundImage: `url(${image})`}}>
            <div style={{width:"100%", height:"100%", background:`linear-gradient(180deg, ${gradient})`}} />
            <div className={cn(cx.titleContainer)}>
                <h1 className={cn(cx.title, theme.bodyColor)}>{name}</h1>
                <div style={{marginLeft:"auto", marginRight:"auto", height: "32px", display: "flex"}}>
                    {subtitle && <div  style={{ display: "inline-flex"}} className={cn(cx.subtitle, theme.bodyColor)}><Href href={subtitleLink}>{subtitle}</Href></div>}
                    {sharing && <div style={{ flexGrow:"100", display: "inline-flex", height: "32px"}}>
                    <ShareButton className={cn(cx.share)} image={image} title={name} url={window.location.href} />
                    </div>}
                </div>
                {/* {subtitle && <div className={cn(cx.subtitle, theme.bodyColor)}><Href href={subtitleLink}>{subtitle}</Href></div>} */}
            </div>
            {/* {sharing && <ShareButton className={cn(cx.share)} image={image} title={name} url={window.location.href} />} */}
        </div>
        :
        <div className={cn(cx.center)}>
            <h1 className={cn(cx.clearTitle, theme.bodyColor)}>
                {name}
            </h1>
        </div>
    }
    </>
  )
};

export default PageHeader;
