import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import basicModalPage from 'src/layout/basicModalPage';
import { Button, TextInput } from 'carbon-components-react';
import { recoverPassword, displayAuthMessage } from 'src/modules/actions/authentication.actions';
import { useQuery } from 'src/hooks';

import cs from './RestorePasswordPage.module.scss';

const RestorePasswordPage = (props) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [formError, setFormError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');

  const clearErrors = () => {
    setFormError(null);
  }

  const submit = async () => {
    if (!password || password.trim().length === 0 || !confirm || confirm.trim().length === 0) {
      setFormError('Password and Confirm are required');
      return;
    }
    if (password !== confirm) {
      setFormError('Passwords must match');
      return;
    }

    props.recoverPassword(email, token, password, (err) => {
      setSubmitError(err);
    });
  }

  const error = formError || submitError;

  return (
    <>
      <div className={cs.twoColumn}>
        <div className={cs.column1}>
          Password*
        </div>
        <div className={cs.column2}>
          <div className={cs.fields}>
            <div className={cs.password}>
              <TextInput id='password'
                         labelText=''
                         type='password'
                         placeholder='Password'
                         value={password}
                         onChange={e => {setPassword(e.target.value)}}
                         onFocus={clearErrors}
                         invalid={formError && true}
              />
            </div>
            <div className={cs.confirm}>
              <TextInput id='confirm'
                         labelText=''
                         type='password'
                         placeholder='Confirm'
                         value={confirm}
                         onChange={e => {setConfirm(e.target.value)}}
                         onFocus={clearErrors}
                         invalid={formError && true}
              />
            </div>
          </div>
          {error && <div className={cs.error}>{error}</div>}
          <div className={cs.hint}>
            Use 8 or more characters with a mix of letters, numbers & symbols
          </div>
          <div>
            <Button onClick={submit} className={cs.submit}>Submit</Button>
          </div>
        </div>
      </div>
    </>
  )
}

RestorePasswordPage.propTypes = {
  recoverPassword: PropTypes.func
}

const RestorePasswordPage__Connected = connect(() => ({}), { displayAuthMessage, recoverPassword })(RestorePasswordPage);
export default basicModalPage(RestorePasswordPage__Connected, "Restore Password");
