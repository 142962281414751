import { ACTION_TYPES as BOOK_TYPES } from 'src/modules/reducers/books.reducer';

export const ACTION_TYPES = {
  FETCH_LIBRARY_PENDING: 'me/FETCH_LIBRARY_PENDING',
  FETCH_LIBRARY_FULFILLED: 'me/FETCH_LIBRARY_FULFILLED',
  FETCH_LIBRARY_REJECTED: 'me/FETCH_LIBRARY_REJECTED',

  FETCH_ME_PENDING: 'me/FETCH_ME_PENDING',
  FETCH_ME_FULFILLED: 'me/FETCH_ME_FULFILLED',
  FETCH_ME_REJECTED: 'me/FETCH_ME_REJECTED',

  FETCH_NOTIFICATIONS_CONFIG_PENDING: 'me/FETCH_NOTIFICATIONS_CONFIG_PENDING',
  FETCH_NOTIFICATIONS_CONFIG_FULFILLED: 'me/FETCH_NOTIFICATIONS_CONFIG_FULFILLED',
  FETCH_NOTIFICATIONS_CONFIG_REJECTED: 'me/FETCH_NOTIFICATIONS_CONFIG_REJECTED',
  CHANGE_NOTIFICATIONS_CONFIG_PROPERTY: 'me/CHANGE_NOTIFICATIONS_CONFIG_PROPERTY',
  CHANGE_NOTIFICATIONS_CONFIG_PROPERTY_END: 'me/CHANGE_NOTIFICATIONS_CONFIG_PROPERTY_END'
};

const defaultState = {
  loading: false,
  books: [],
  stripeCustomer: null,
  notificationsLoading: false,
  notificationsConfig: []
};

function fetchMe(state, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ME_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_ME_FULFILLED:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case ACTION_TYPES.FETCH_ME_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LIBRARY_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_LIBRARY_FULFILLED:
      return {
        ...state,
        loading: false,
        books: action.books
      };
    case ACTION_TYPES.FETCH_LIBRARY_REJECTED:
      return {
        ...state,
        loading: false
      };

    case BOOK_TYPES.ADD_MYBOOKS_FULFILLED:
      return {
        ...state,
        books: [...state.books, { book: { uuid: action.uuid }}]
      };
    case BOOK_TYPES.REMOVE_MYBOOKS_FULFILLED:
      return {
        ...state,
        books: state.books.filter((item, index) => index !== action.index)
      };
    case ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_PENDING:
      return {
        ...state,
        notificationsLoading: true
      }
    case ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_FULFILLED:
      return {
        ...state,
        notificationsLoading: false,
        notificationsConfig: action.payload
      }
    case ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_REJECTED:
      return {
        ...state,
        notificationsLoading: false
      }
    case ACTION_TYPES.CHANGE_NOTIFICATIONS_CONFIG_PROPERTY:
      return {
        ...state,
        notificationsConfig: state.notificationsConfig.map((conf, i) => {
          if (i !== action.index) return conf;

          return {
            ...conf,
            [action.group]: {
              ...state.notificationsConfig[i][action.group],
              'state': action.state
            }
          }
        })
      }
    case ACTION_TYPES.CHANGE_NOTIFICATIONS_CONFIG_PROPERTY_END:
      return {
        ...state,
        notificationsLoading: false
      }
    case ACTION_TYPES.FETCH_ME_PENDING:
    case ACTION_TYPES.FETCH_ME_FULFILLED:
    case ACTION_TYPES.FETCH_ME_REJECTED:
      return fetchMe(state, action);
    default:
      return state;
  }
}
