import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tile} from 'carbon-components-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import cs from './styles.module.scss';
import history from 'src/history';
import {RES_PATH} from 'src/config/environment';
import Author from 'src/components/_shared/Author/index';
import Badge from 'src/components/_shared/Badge/index';
import Bookmark from 'src/components/_shared/Book/Bookmark';

import {addToMyBooks} from 'src/modules/actions/books.actions';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb();
};

export const TrendingBookItem = ({ updatedAt, chapter, book, author, className, ...rest}) => {

  const onReadBook = () => {
    rest.addToMyBooks(book.uuid);
    history.push(`/books/${book.uuid}/read`);
  };

  const onChapter = () => {
    history.push(`/books/${book.uuid}/${chapter.uuid}`)
  };

  const onBookPage = () => history.push(`/books/${book.uuid}`);

  return (
    <Tile className={cs.tile}>
      <div
        className={cs.cover}
        style={{ backgroundImage: `url(${RES_PATH}${book?.image})`, backgroundSize: 'cover'}}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp(onBookPage)}
      >
        {rest.new && <Badge style={{ bottom: '7px', left: '-8px'}} />}
      </div>

      <div
        className={cs.bookName}
        title={book.name}
      >
        <span onMouseDown={onMouseDown}
              onMouseUp={onMouseUp(onBookPage)}
              style={{ cursor: 'pointer' }}
        >
          {book.name}
        </span>
      </div>

      { chapter &&
        <div
          className={cs.chapterName}
          title={chapter.name}
        >
          <span onMouseDown={onMouseDown}
                onMouseUp={onMouseUp(onChapter)}
                style={{ cursor: 'pointer' }}
          >
            {chapter.name}
          </span>
        </div>
      }

      { author &&
        <div className={cs.author}>
          <Author {...author} updatedAt={updatedAt} />
        </div>
      }

      <div className={cs.toolbar}>
        <Button
          kind='tertiary'
          size='small'
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp(onReadBook)}
        >Read</Button>
        <Bookmark uuid={book.uuid}/>
      </div>
    </Tile>
  )
};

TrendingBookItem.defaultProps = {
  new: false,
  className: ''
};

TrendingBookItem.propTypes = {
  new: PropTypes.bool,
  updatedAt: PropTypes.number,

  book: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    lastChapter: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired,

  author: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string
  }),

  className: PropTypes.string,
  addToMyBooks: PropTypes.func
};

export default connect(() => ({}), dispatch => bindActionCreators({ addToMyBooks }, dispatch))(TrendingBookItem);
