import React from 'react';
import cs from './Collage.module.scss';
import { RES_PATH } from 'src/config/environment';

export const Collage = () => {

    return (
        <div className={cs.content}>
            <div className={cs.collageContainer}>
                <div className={cs.collageImage1}>
                    <img src={`${RES_PATH}/resources/83B21DAE-80AE-40EC-ACBB-70376FAE5291.jpg`} alt=""/>
                </div>
                <div className={cs.collageImage2}>
                    <img src={`${RES_PATH}/resources/9D36079A-64D0-4FE9-85FC-58B2D3BF4B80.jpg`} alt=""/>
                </div>
                <div className={cs.collageImage3}>
                    <img src={`${RES_PATH}/resources/1D4BB9E6-4DEF-446A-9692-3B3EF2E4C60D.jpg`} alt=""/>
                </div>
                <div className={cs.collageImage4}>
                    <img src={`${RES_PATH}/resources/A26575B5-1897-4B15-A4BE-9B2E26BE8254.jpg`} alt=""/>
                </div>
                <div className={cs.collageImage5}>
                    <img src={`${RES_PATH}/resources/AF708D8E-26CA-4E97-B7A7-EE0DFEF78481.jpg`} alt=""/>
                </div>
                <div className={cs.collageImage6}>
                    <img src={`${RES_PATH}/resources/95CF33F6-A8CB-4EE6-A243-F17F437EC7BE.jpg`} alt=""/>
                </div>
            </div>
        </div>
    )
}