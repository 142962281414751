import axios from 'axios';

export async function searchUser(query) {
  try {
    const data = (await axios.get(`/search?books=false&query=${query}`))?.data
    const authors = data?.authors ?? [];
    console.log(`authors - ${JSON.stringify(authors)}`);
    return authors
  } catch (e) {
    console.log(e);
  }
}