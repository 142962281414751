import React, {
  useEffect, useCallback, useState
  } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Layout from 'src/layout/ContentLayout';
import Content from 'src/components/_shared/Content';
import Item from './Item';
import { Button } from 'carbon-components-react';
import history from 'src/history';

import cn from 'classnames';
import cx from './EditorBlogPosts.module.scss';
import { fetchMyPosts, fetchMyPostsNextPage, updateMyBlogPost, deleteMyBlogPost } from 'src/modules/actions/blog.actions';

export const EditorBlogPosts = props => {
  const { match, id, loading, content, lastPage, ...actions } = props;
  const { fetchMyPosts, fetchMyPostsNextPage, updateMyBlogPost, deleteMyBlogPost } = actions;
  const [postsCount, setPostsCount] = useState(content.items.length);
  if(postsCount !== content.items.length){
    setPostsCount(content.items.length)
  }
  useEffect(() => {
    fetchMyPosts();
  }, [fetchMyPosts, match]);

  const didScroll = useCallback((e) => {
    const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight;
    if(bottom && !loading && !lastPage){
      fetchMyPostsNextPage(postsCount)
    }
  }, [fetchMyPostsNextPage, loading, lastPage, postsCount]);

  useEffect( () => {
    window.addEventListener('scroll', didScroll);
    return () => {
      window.removeEventListener('scroll', didScroll);
    }
  }, [didScroll]);

  const ActionButtons = () => {
    return (
      <div className={cx.buttonsBlock}>
        <Button kind='tertiary'
                // disabled={disabled}
                onClick={() => history.push(`/me/posts/add`)}
        >
          Add Blog Post
        </Button>
      </div>
    );
  }

  return (
    <>
      <Layout
          title="My Blog Post"
          renderAction={ActionButtons}>
        <Content>
          <div className={cn(cx.grid)}>
          {
            content.items.map((item, i) =>
              <div className={cn(cx.item)}>
                <Item
                  key={i} 
                  object={item}
                  onTogglePublished={() => {
                    updateMyBlogPost(item.uuid, {state: (item.state === "published")? "draft" : "published"})
                      .then(fetchMyPosts)
                  }}
                  onDelete={() => {
                    deleteMyBlogPost(item.uuid)
                      .then(fetchMyPosts)
                  }}
                  /></div>
            )
          }
          </div>
        </Content>
      </Layout>
    </>
  )
};

EditorBlogPosts.propTypes = {
  fetchBlog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    ...state.blog,
    ...state.theme
  }
}

function mapDispatchToProps(dispatch) {
return bindActionCreators({ fetchMyPosts, fetchMyPostsNextPage, updateMyBlogPost, deleteMyBlogPost }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorBlogPosts);
