import React from 'react';

import cs from './styles.module.scss';
import { RES_PATH } from 'src/config/environment';
import PropTypes from "prop-types";
import history from 'src/history';
import cn from 'classnames';
import Href from '../Href';
import Button from '../DesignV2/Button';

export const AuthorWide = ({ uuid, loading, name, photo, imagePath, about, publisher, books, followers, following, links, clickable, follow, unfollow }) => {

  const onClick = () => clickable ? history.push('/writers/' + uuid) : null;

  const linkSrc = (link) => {
    switch (link.type) {
      case 'link':
        return `${process.env.PUBLIC_URL}/link_icon.svg`
      case 'twitter':
        return `${process.env.PUBLIC_URL}/twitter_icon.svg`
      default:
        return `${process.env.PUBLIC_URL}/link_icon.svg`
    }
  };

  return (
    <>
      { name &&
        <div className={cn([cs.main], { [cs.clickable]: clickable })}
             onClick={onClick}
        >

          <div className={cs.author}>
            <div className={cs.imgArea}>
              <div
                className={cs.avatar}
                style={{
                  backgroundImage: `url('${RES_PATH}${photo || imagePath}')`,
                  backgroundSize: 'cover'
                }}/>
              <div className={cs.nameSection}>
                <div className={cs.name}>
                  <p className={cs.accountType}>{publisher ? 'PUBLISHER' : 'AUTHOR'}</p>
                </div>
                <div className={cs.name}>
                  <h3 className={cs.text}>{name}</h3>
                </div>
                <div className={cs.infoBlock}>
                  <p className={cs.info}>{`${books?.length} books   │   ${followers ?? 0} followers`}</p>
                  <Button 
                    kind={following ? 'normal' : 'cta'}
                    onClick={following ? unfollow : follow}
                    size='small'
                    className={cs.following}>
                      { following ? 'Unfollow' : 'Follow'}
                    </Button>
                </div>
              </div>
            </div>

            <div className={cs.nameArea}>
              {links?.map((item) => (
                <div className={cs.linkRow}>
                  
                  <Href href={item.link} external={true} className={cs.link}>
                    <img className={cs.icon} width={24} height={24} alt={''} src={linkSrc(item)} />
                    <div className={cs.text}>
                      {item.link}
                    </div>
                  </Href>
                </div>
              ))}
              <div className={cs.descr}>{about}</div>
            </div>
          </div>
        </div>
      }
    </>
  )
};

AuthorWide.defaultProps = {
  clickable: false
};

AuthorWide.propTypes = {

  uuid: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  imagePath: PropTypes.string,
  about: PropTypes.string,
  followers: PropTypes.number,
  books: PropTypes.array,
  clickable: PropTypes.bool
};

export default AuthorWide;
