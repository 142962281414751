const SEC = 1;
const MIN = SEC * 60;
const HOUR =  MIN * 60;

export function formatDuration(num) {
  let str = '';
  let rest = num;
  const h = Math.floor(num / HOUR);
  if (h > 0) str = h + ':';
  rest -= (h * HOUR);
  const m = Math.floor(rest / MIN);
  if (m > 0) {
    if (m < 10 && h > 0) str += '0' + m + ':';
    else str += m + ':';
  } else {
    if (h > 0) {
      str += '00:';
    } else {
      str += '0:';
    }
  }
  rest -= (m * MIN);
  const s = Math.floor(rest / SEC);
  if (s > 0) {
    if (s < 10) str += '0' + s;
    else str += s;
  } else {
    str += '00';
  }
  return str
}
