export const ACTION_TYPES = {
  FETCH_GENRES_PENDING: 'genres/FETCH_GENRES_PENDING',
  FETCH_GENRES_FULFILLED: 'genres/FETCH_GENRES_FULFILLED',
  FETCH_GENRES_REJECTED: 'genres/FETCH_GENRES_REJECTED',
};

const defaultState = {
  sections: [],
  loading: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_GENRES_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_GENRES_FULFILLED:
      return {
        ...state,
        loading: false,
        sections: action.data.sections
      };
    case ACTION_TYPES.FETCH_GENRES_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
