import React from 'react';

export const RecorderStopIcon = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.25" cx="36" cy="36" r="36" fill="white"/>
      <circle cx="36" cy="36" r="28" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M36.1748 39.2174H35.825C33.4217 39.2174 31.4736 37.2927 31.4736 34.9203V30.2971C31.4736 27.9237 33.4217 26 35.825 26H36.1748C38.5781 26 40.5272 27.9237 40.5272 30.2971V34.9203C40.5272 37.2927 38.5781 39.2174 36.1748 39.2174ZM42.5626 34.7829C42.5626 34.2539 42.9966 33.8263 43.5313 33.8263C44.066 33.8263 44.5 34.2539 44.5 34.7829C44.5 39.0866 41.2006 42.6404 36.9692 43.1178V45.0434C36.9692 45.5714 36.5352 46 36.0005 46C35.4648 46 35.0318 45.5714 35.0318 45.0434V43.1178C30.7994 42.6404 27.5 39.0866 27.5 34.7829C27.5 34.2539 27.934 33.8263 28.4687 33.8263C29.0034 33.8263 29.4374 34.2539 29.4374 34.7829C29.4374 38.3558 32.3814 41.2629 36.0005 41.2629C39.6186 41.2629 42.5626 38.3558 42.5626 34.7829Z" fill="white"/>
      <path d="M40.6602 26H31.3302C27.9202 26 26.0002 27.92 26.0002 31.33V40.67C26.0002 44.06 27.9202 46 31.3302 46H40.6702C44.0702 46 46.0002 44.06 46.0002 40.67V31.33C46.0002 27.92 44.0602 26 40.6602 26Z" fill="black"/>
    </svg>
  )
}
