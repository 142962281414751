import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Select, SelectItem } from 'carbon-components-react';

import cx from './CompetitionPage.module.scss';
import request from './competition.request';
import { useQuery, useResizeEffect } from 'src/hooks';
import { REQUEST_LIMIT, SORT_OPTIONS } from './constants';
import AppLayout from 'src/layout/AppLayout';
import ListWrapper from './list-wrapper';

const CompetitionPage = ({ match }) => {
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [listHeight, setListHeight] = useState(0);
  const [pageName, setPageName] = useState('');
  const [invalidPage, invalidatePage] = useState(false);
  const contentRef = useRef(null);
  const query = useQuery();
  const [sortOrder, setSortOrder] = useState(
    SORT_OPTIONS.find(or =>
      or.value === query.get('sort'))?.value ?? SORT_OPTIONS[0].value);

  const resizeListener = useCallback(() => {
    if (contentRef?.current) {
      setListHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef]);

  useResizeEffect(resizeListener);

  useEffect(() => {
    resizeListener();
  }, [contentRef, resizeListener]);

  const _handleSortOrderChange = e => {
    setSortOrder(e.target.value);
    setItems([]);
  };

  const _loadNextPage = useCallback(async () => {
    setNextPageLoading(true);
    const tag = match?.params?.tag;
    const { books: nextItems = [], name } = await request(tag, sortOrder, items.length, REQUEST_LIMIT);
    setHasNextPage(nextItems.length === REQUEST_LIMIT);
    setNextPageLoading(false);
    setItems([...items].concat(nextItems));
    if (name) {
      setPageName(name);
    }
  }, [items, match, sortOrder]);

  useEffect(() => {
    invalidatePage(true);
  }, [sortOrder]);

  useEffect(() => {
    if (invalidPage === true) {
      invalidatePage(false);
      _loadNextPage().then(() => {});
    }
  }, [_loadNextPage, invalidPage]);

  return (
    <AppLayout>
      <div className={cx.page}>
        <div className={cx.titleWrapper}>
          <div className={cx.title}>
            <h2>{pageName}</h2>
          </div>
          <div className={cx.sort}>
            <Select id="sort-1"
                    defaultValue={sortOrder}
                    labelText=''
                    onChange={_handleSortOrderChange}
            >
              { SORT_OPTIONS.map((option, index) => <SelectItem key={index} {...option} />)}
            </Select>
          </div>
        </div>
        <div className={cx.content} ref={contentRef}>
          { listHeight > 0 ?
            <ListWrapper
              hasNextPage={hasNextPage}
              isNextPageLoading={isNextPageLoading}
              items={items}
              sortOrder={sortOrder}
              loadNextPage={_loadNextPage}
              listHeight={listHeight}
            /> : null
          }
        </div>
      </div>
    </AppLayout>
  )
};

export { CompetitionPage };
