import React, { useEffect } from 'react';
import axios from 'axios';

import { useQuery } from 'src/hooks';
import history from 'src/history';
import { getAuthHeader } from 'src/modules/api/utils';
import { Loading } from 'carbon-components-react';


const PayoutsConfigured = () => {
  const query = useQuery();

  useEffect(() => {
    async function completePayout() {
      try {
        const state = query.get("state");
        const code = query.get("code");
        await axios.post('/me/payouts/complete',
          { state, code},
          getAuthHeader()
        );
      } catch (e) {
        console.log(e);
      } finally {
        history.push('/earnings');
      }
    }

    completePayout();
  }, [query]);

  return (
    <Loading active withOverlay />
  )
}

export default PayoutsConfigured;
