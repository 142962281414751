import React from 'react';

import AppLayout from 'src/layout/AppLayout';
import StaticLayout from './static-layout';

import commonStyles from './common.module.scss';

const PrivacyPolicy = () => {
  return (
    <AppLayout>
      <StaticLayout contentClass={commonStyles.staticWrapper}>
        <h1>Privacy Policy</h1>
        Fictionate.me’s Privacy Policy is a part of, and subject, to its Terms of Service, and is intended to clearly lay out the policies guiding the use, collection, disclosure, processing, and protection of users’ personal information on Fictionate.me’s Services and Site, as well as any related websites, networks, and applications. To provide any personal information on these platforms is to consent to transfer, storage, and processing of this information as described below.
        <h2>Personal Information</h2>
        ‘Personal’ information is herein defined as any information relating to an identified, or identifiable, individual.
        COLLECTION. Fictionate.me always states whether and why (i.e. collection is legally required) a user’s personal information must be collected and explains the consequences of failure to provide such items, such as restriction from the full range of features and services offered by Fictionate.me. Sources from which personal information is collected are described below.
        <ul>
        <li>User-provided personal information is that which is gathered from voluntary user input, such as when the user is asked to input their name, gender, email and mailing address, phone number, credit card/other billing address, date of birth, and geographic area. Any other information the user shares on the Site is also collected.</li>
        <li>Automatic collection of personal information involves use of the following:</li>
        <ul>
        <li>Session/persistent cookies as well as third party cookies (i.e. Google Analytics)</li>
        <ul>
        <li>As previously mentioned, users must accept and never delete cookies in order to experience all Services offered by Fictionate.me.</li>
        </ul>
        <li>Technology, including “clear gifs” and/or “web beacons,” to gather from user devices:</li>
        <ul>
        <li>IP address or other device address or ID</li>
        <li>Type of web browser and/or device</li>
        <li>Web pages/websites visited immediately prior to and after visiting Services</li>
        <li>Pages or content viewed on Services</li>
        <li>Dates and times the user visits, accesses, or uses Services</li>
        <li>User interaction with emails (i.e. the opening, clicking on, or forwarding of a message)</li>
        </ul>
        <li>GPS and geolocation features of the user’s device, or inference from other collected information, to collect location information (i.e. user’s physical location).</li>
        <li>Third-Party Web Beacons and Buttons displayed on Fictionate.me collect information about the user’s interaction(s) with third-party content on Fictionate.me’s Site only for the benefit of third parties, not for Fictionate.me’s. Some third-party buttons function as web beacons even without user interaction. In addition, any information collected this way is subject to the third party’s privacy policy, not this Privacy Policy.</li>
        </ul>
        <li>Other Sources include:</li>
        <ul>
        <li>Partners</li>
        <li>Advertisers</li>
        <li>Publicly accessible sources, such as Google, Facebook, or Twitter</li>
        </ul>
        </ul>
        USE. All personal information collected by Fictionate.me is used in operation, maintenance, improvement, and provision of all products, services, features, and functionality of Services, including:
        <ul>
        <li>Analysis of usage trends and preferences of users</li>
        <li>Provision of requested information</li>
        <li>User support, including administrative support (e.g. customer service)</li>
        <li>Correspondence (i.e. response to comments and questions)</li>
        <li>Marketing (i.e. of events and promotions especially of products and services offered by Fictionate.me and third parties)</li>
        </ul>
        Processing and delivery of entries and rewards related such promotions
        <ul>
        <li>Personal information automatically collected serves the purposes of:</li>
        <li>Personalization (e.g. remembering information about users to ensure users are not inconvenienced by needing to re-enter such details during use or repeated visits)</li>
        <li>Providing customized ads, content, and information</li>
        <li>Monitoring and analyzing effectiveness of Services and third-party marketing</li>
        <li>Monitoring aggregate site usage metrics (e.g. total number of visitors and pages used)</li>
        <li>Tracking user entries, submissions, and status in Service promotions and activities</li>
        </ul>
        For Fictionate.me users located in the European Economic Area (EEA), personal information is only processed upon the following legal grounds:
        <ul>
        <li>Consent (given by users)</li>
        <li>Necessity for fulfillment of a contract (e.g. personal information must be processed to respond to a user inquiry)</li>
        <li>In the event that user rights and interests are overridden by legitimate third-party interest(s), including, but not limited to, the provision of tailored content, the conducting of business analytics, or the improvement of the safety, security, and performance of Services)</li>
        <li>Compliance with Fictionate.me legal obligation(s)</li>
        </ul>
        DISCLOSURE. Fictionate.me may disclose personal user information to third parties only as written in this Privacy Policy, as disclosed at the time of collection, with consent of users, and in the case that user information is:
        <ul>
        <li>Voluntarily shared by users in a publicly accessible part of Services</li>
        <li>Necessary for third party service providers to provide website and application development, hosting, maintenance, and other functions</li>
        <li>Required to be disclosed by law or in the “good-faith belief” that disclosure by Fictionate.me is necessary for compliance with state and federal law and cooperation with law enforcement and governmental agencies, is court-ordered, or is subpoenaed or warranted by a judicial power or government</li>
        <li>Appropriate or necessary, as believed by Fictionate.me in good faith, to:</li>
        <ul>
        <li>Take precautionary measures against liability</li>
        <li>Protect Fictionate.me or other parties against fraudulent, abusive, or unlawful uses or activities</li>
        <li>Investigate and defend Fictionate.me against third-party claims or allegations</li>
        <li>Protect the security or integrity of Services as well as the facilities or equipment used for availability of Services</li>
        <li>Protect Fictionate.me property or legal rights (e.g. enforcement of agreements) or the rights, property, or safety of other parties</li>
        <li>Necessary to transfer as part of a merger, acquisition, debt-financing, sale of assets, or similar transaction to an acquirer, successor, or assignee</li>
        <li>Necessary to transfer to a third party or parties as part of Fictionate.me’s business assets in the event of insolvency, bankruptcy, or receivership</li>
        <li>Included in an automatically collected or de-identified aggregate made available to third parties in order to:</li>
        <ul>
        <li>Fulfill reporting obligation(s)</li>
        <li>Perform business or marketing</li>
        <li>Facilitate third parties’ interpretation of user interests, habits, and usage patterns involving specific programs, content, services, ads, promotions, or functionality of Fictionate.me.</li>
        </ul>
        </ul>
        </ul>
        <h2>Data</h2>
        <ul>
        <li>Transfer – Internationally: Transfer of personal information outside the EEA is always performed in compliance with appropriate EU data protection law(s). Fictionate.me may transfer personal information as follows:</li>
        <ul>
        <li>To countries outside of a user’s country of residence or location</li>
        <li>To countries providing adequate protection under EU law(s)</li>
        <li>Using contractual protections</li>
        </ul>
        <li>Security. While Fictionate.me does employ physical, managerial, and technical safeguards for personal information in its possession against accidental/unlawful destruction, accidental loss, unauthorized alteration, disclosure, or access, misuse, or other types of unlawful processing, Fictionate.me can’t guarantee that personal information won’t still be accessed, viewed, disclosed, altered, or destroyed by a breach of such safeguards.</li>
        <li>Retention. Personal information no longer needed for processing purposes is either deleted or, if required by law, stored for required retention period such that it cannot be used to identify a user. Fictionate.me uses the following factors to determine the retention period of a user’s personal information:</li>
        <ul>
        <li>Kind(s) of products and services requested or provided</li>
        <li>Nature and length of relationship with Fictionate.me</li>
        <li>Probability of re-enrollment</li>
        <li>Impact deletion of personal information may have on service(s) provided</li>
        <li>Mandatory retention periods decreed by law(s) and statute(s) of limitation.</li>
        </ul>
        </ul>
        Any request for a copy of safeguards used in transferring personal information outside the EEA should be directed to info@fictionate.me.
        <h2>User Rights and Choices</h2>
        <ul>
        <li>Account. Declining to share certain pieces of personal information with Fictionate.me may result in restriction from full functionality and access to features. Users may update, correct, or delete any account information at any time by accessing the ‘account preferences’ page on the Service. However, while any changes will be reflected in active user databased within a reasonable period, Fictionate.me may retain all information shared for backups, archives, prevention of fraud and abuse, analytics, satisfaction of legal obligations, or where Fictionate.me believes there is a legitimate reason to do so.</li>
        <li>Marketing Communications. Users will always receive administrative messages from Fictionate.me regarding the Service. To unsubscribe from commercial email, follow instructions given within, or request to opt out of the mailing list at info@fictionate.me. Additionally, the nature and/or frequency of promotional communications may be viewed and/or modified by accessing the ‘user account functionality’ page on the Service. Any changes (including un-subscription) will be processed and reflected on user accounts within 10 business days.</li>
        <li>Automated Collection. Because the Site uses cookies, setting browsers to ‘decline cookies’ may restrict full functionality and access to features. Furthermore, Fictionate.me does not currently respond to Do Not Track signals.</li>
        <li>Additional Rights and Choices. Concerning personal information, users residing or located in the EEA or Switzerland may additionally:</li>
        <ul>
        <li>Request to access and know about any maintained information</li>
        <li>Update and correct inaccuracies</li>
        <li>Restrict, object to, or withdraw consent previously given for, processing</li>
        <li>Have information anonymized or deleted (as appropriate)</li>
        <li>Have the right to:</li>
        <ul>
        <li>Exercise data portability</li>
        <li>Lodge a complaint with a supervisory authority (e.g. country of residence or place of occupation</li>
        </ul>
        </ul>
        </ul>
        <h2>Third Party Services</h2>
        Features or links to third party websites and services may be found on the Fictionate.me website. Personal information supplied to these sites and services is subject to the policies created by the third party operators of those sites and services. Those policies may have different terms and practices regarding privacy and security than Fictionate.me and Fictionate.me is not responsible for the treatment of personal information freely given to third parties accessible through the links or features found on Fictionate.me.
        <h2>Children’s Privacy</h2>
        Personal information from children younger than 13 is never collected without parental consent. Any such information discovered to have been collected without verified parental consent will be promptly deleted. Parents or guardians who discover or suspect that personal information has been collected from their under-13 child, or that the child has an account, through Fictionate.me should send an alert and request for deletion to info@fictionate.me.
        <h2>California Privacy Rights</h2>
        California residents reserve the right to request a disclosure stating what personal information has been shared, and which third parties have been granted access to this information for direct marketing activities, in the past calendar year. Any request for a copy of this disclosure should be directed to info@fictionate.me.
        <h2>Changes and Updates to the Privacy Policy</h2>
        Fictionate.me will occasionally update this Privacy Policy. All updated versions are accessible through the Service and indicate the date of the most recent revision. Some changes may materially modify users’ rights or obligations. In such instances, Fictionate.me will notify users that this modification has occurred (e.g. through an email or a notification on the Site). Any user’s continued use of the Service after a Privacy Policy revision is effected will be understood as agreement to the revised edition.
        <h2>Contact</h2>
        Any questions or comments regarding this Privacy Policy, information collected or obtained from users, Fictionate.me’s use and disclosure practices, or user consent options should be directed to info@fictionate.me.
      </StaticLayout>
    </AppLayout>
  )
};

export default PrivacyPolicy;
