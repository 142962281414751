import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loading, Modal, Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import history from 'src/history';
import classes from './index.module.scss';
import Layout from 'src/layout/ContentLayout';
import ChapterItem from './ChapterItem';
import { fetchBook, forgetBook, toggleChapterPublished, toggleChapterFree } from 'src/modules/actions/books.actions';
import { deleteNarration } from 'src/modules/actions/narration.actions';
import * as coupons from 'src/modules/api/coupons.api';
import { deleteChapter, uploadChapterAudioFile } from 'src/modules/actions/chapters.actions';
import BookPortrait from 'src/components/_shared/Book/BookPortrait';
import BookDescription from 'src/components/_shared/Book/BookDescription';
import ModalForm from 'src/components/_shared/form/ModalForm';
import ShareBanner from 'src/components/_shared/ShareBanner';
import Delimiter from 'src/components/_shared/Delimiter';
import { LinkItem } from './LinkItem';
import * as chaptersApi from 'src/modules/api/chapters.api';
import { fetchBook as fetchBookApi } from 'src/modules/api/books.api';
import * as narrationApi  from 'src/modules/api/narration.api';
import * as utils from 'src/modules/api/utils'

const Crumbs = () => (
  <Breadcrumb>
    <BreadcrumbItem><Link to='/my-books'>My Books</Link></BreadcrumbItem>
  </Breadcrumb>
);
const ProgressBar = ({value}) => {
  return (
    <div className={classes.progressBar}>
      <div className={classes.bar}><div className={classes.content} style={{width: `${value}%`}}/></div>
      <div className={classes.label}>{Math.round(value)}%</div>
    </div>);
  };

export const BookPage = ({ match, book, loading, fetchBook, forgetBook, deleteChapter, toggleChapterPublished, toggleChapterFree, uploadChapterAudioFile, deleteNarration }) => {
  const ActionButton = () => (
    <div className={classes.actionButtons}>
      <Button
        kind='ghost'
        disabled={loading}
        onClick={() => history.push(`${match.url}/edit`)}
      >
        Edit
      </Button>
        <Button className={classes.publicView} onClick={() => history.push(`/books/${book.uuid}`)}>
        { book?.published ? "Public View" : "Preview" }
        </Button>
    </div>
  );

  const [uploadingState, setUploadingState] = useState(null);

  const [deletingChapter, setDeletingChapter] = useState(null);
  const [sharingChapter, setSharingChapter] = useState(null);
  const [chapterCoupons, setChapterCoupons] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [bookObject, setBookObject] = React.useState(null);

  const { name = '', about = '', chapters = [] } = bookObject || {};

  let submitTrigger = null;

  useEffect(() => {
    fetchBook(match.params.id);
    return forgetBook;
  }, [match, forgetBook, fetchBook]);

  useEffect(() => {
    setBookObject(book)
  }, [book]);

  const fetchBookAsync = useCallback(async () => {
    const res = await fetchBookApi(match.params.id);
    if (res?.status !== 200) throw new Error("cannot fetch book");
    setBookObject(res.data);
    setWaiting(false);
  }, [match.params.id]);

  const dateString = (expiration) => {
    const date = new Date(expiration * 1000)
    const dateString = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    return dateString;
  }

  const hasProcessingChapters = (bookObject?.chapters?.filter(c => c?.audioState?.state === "inProgress") ?? []).length > 0;
  if(hasProcessingChapters && !waiting) {
    setWaiting(true);
    setTimeout(fetchBookAsync, 2000);
  }

  const CouponsList = useCallback(() => {
    console.log('chapterCoupons: ', JSON.stringify(chapterCoupons));
    return (chapterCoupons.map && chapterCoupons.map((coupon)=> 
    <LinkItem 
      href={ link(coupon.shortId) }
      expiration= {dateString(coupon.expiration)}
      onDelete={() => {
        coupons.deleteCoupon(coupon.uuid).then(() => {
          coupons.getChapterCoupons(sharingChapter[0].uuid).then((response) => {
            setChapterCoupons(response.data.items)
          })
        })
      }}
    />));
  }, [chapterCoupons, sharingChapter])

  return (<>
    <Layout
      title={name}
      renderAction={ActionButton}
      breadcrumb={<Crumbs />}
    >
      <Loading
        active={loading}
        withOverlay
      />

      <div className={classes.bookPage}>

        <BookPortrait book={bookObject} showLikes={false}/>
        <BookDescription>{about}</BookDescription>
        {bookObject?.published && <ShareBanner book={bookObject}/>}
        <Delimiter style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
        <div className={classes.addChapterButton}>
          <Button
            kind="tertiary"
            onClick={() => history.push(`${match.url}/chapter/add`)}
          >Add Chapter</Button>
        </div>
        {
          chapters.map((chapter, i) => (
            <>
              {(chapter.uuid === uploadingState?.chapter) && <ProgressBar value={uploadingState.progress}/>}
              {(uploadingState === null || uploadingState.chapter !== chapter.uuid) && <ChapterItem
                key={i}
                narration={bookObject?.narration}
                chapter={chapter}
                onDeleteChapter={() => setDeletingChapter([chapter, i])}
                onTogglePublished={() => toggleChapterPublished(chapter.uuid, i, !chapter.published)}
                onToggleFree={() => toggleChapterFree(chapter.uuid, i, !chapter.free)}
                onAddChapterAbove={() => history.push(`${match.url}/chapter/add?before=${chapter.uuid}`)}
                onSharableLink={() => {
                  coupons.getChapterCoupons(chapter.uuid).then((response) => {
                    setChapterCoupons(response.data.items)
                    setSharingChapter([chapter, i])
                  })
                }}
                generate={() => {
                  chaptersApi.generateChapterAudio(chapter.uuid).then(() => 
                    fetchBook(match.params.id)
                  )
                }}
                onPreview={() => history.push(`/books/${bookObject.uuid}/${chapter.uuid}`)}
                createNarration={() => history.push(`/narration/${chapter.uuid}`)}
                deleteNarration={() => deleteNarration(chapter.uuid).then(() => fetchBook(match.params.id))}
                uploadFile={(file) => {
                  const method = async () =>{
                    const b64Sound = (await utils.toBase64(file)).split(',')[1];
                    const payload = {
                      info: {},
                        file: {
                        data: b64Sound,
                        type: file ? 'mp3' : 'webm'
                      }
                    }
                    const {promise} = chaptersApi.postChapterAudioFile(
                      chapter.uuid, 
                      payload, 
                      (progress) => {
                        setUploadingState({progress, chapter: chapter.uuid})
                      })
                    const response = await promise;
                    fetchBookAsync();
                    setUploadingState(null);
                  }
                  method();
                }}
                match={match}
              />}
            </>
          ))
        }
      </div>
    </Layout>
    <Modal
      open={deletingChapter}
      danger
      shouldSubmitOnEnter
      modalHeading={deletingChapter && deletingChapter[0].name}
      modalLabel="Delete chapter"
      aria-label='delete-chapter'
      primaryButtonText="Yes"
      secondaryButtonText="No"
      onRequestClose={() => setDeletingChapter(null)}
      onRequestSubmit={() => {
        deleteChapter(deletingChapter[0].uuid, deletingChapter[1]);
        setDeletingChapter(null);
      }}
    >
      <p className={`bx--modal-content__text`}>
        Are you sure that you really want to delete this chapter?
      </p>
    </Modal>
    <ModalForm
      modalHeading = "Create Sharable Chapter Link"
      open = {!!sharingChapter}
      setTrigger = {(trigger) => submitTrigger = trigger}
      properties={[
        {
          type: "custom", 
          custom : {
            component: CouponsList()
          }
        },
        { title: "Expiration", type: "date", id: "expiration", default: Date.now()/1000 + 60*60*24+7},
        {
          type: "custom", 
          custom : {
            component: (<Button onMouseUp={()=>submitTrigger()}>Create</Button>)
          }
        }
      ]}
      passive
      cancel={()=> setSharingChapter(null)}
      submit={(value) => {
        coupons.postChapterCoupon(sharingChapter[0].uuid, value.expiration).then((response) => {
          coupons.getChapterCoupons(sharingChapter[0].uuid).then((response) => {
            setChapterCoupons(response.data.items)
          })
        }).catch( error => {
        })
      }}
    />
  </>)
};

const link = (code) => {
  // This link is parsed by renderingg server and redirected to the end page.
  return `${window.location.origin}/l/c/${code}`
}

export default connect(
  state => ({
    book: state.books.book,
    loading: state.books.loading
  }),
  dispatch => bindActionCreators({ 
    fetchBook, 
    forgetBook, 
    deleteChapter, 
    toggleChapterPublished,
    toggleChapterFree,
    deleteNarration,
    uploadChapterAudioFile }, dispatch))(BookPage);
