const WEEK_PASS = 'Please use 8 or more characters with a mix of uppercase & lowercase letters, numbers & special symbols.';

export const registrationMessages = (key) => {
  switch (key) {
    case 'tooShort':
      return 'Password is too short. ' + WEEK_PASS;
    case 'missingLowercase':
      return 'Password missing a lowercase letter. ' + WEEK_PASS;
    case 'missingUppercase':
      return 'Password missing an uppercase letter. ' + WEEK_PASS;
    case 'missingNumber':
      return 'Password missing a number. ' + WEEK_PASS;
    case 'missingSpecial':
      return 'Password missing a special character. ' + WEEK_PASS;
    case 'exist':
      return "Account with such email is already registered.";
    default:
      return `Unknown error ${key} happened`;
  }
};

export const loginMessages = (key) => {
  switch (key) {
    case 'dontExist':
      return "We couldn't find a user for given login and password.";
    default:
      return `Unknown error ${key} happened`;
  }
};
