import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import cs from './styles.module.scss';

import history from 'src/history';
import {
  saveBookLocation,
  fetchReadingChapter,
  addToMyBooks,
  fetchShareState,
  putBookShared,
  fetchBook
} from 'src/modules/actions/books.actions';
import { fullScreenMode } from 'src/modules/actions/application.actions';
import Toolbar from 'src/components/reader/ReadingPage/Toolbar';
import UnavailableChapter from 'src/components/reader/ReadingPage/UnavailableChapter';
import {
  getLocation,
  getPrevChapterUuid,
  getNextChapterUuid,
  isChapterAvailable,
  findLastChapter
} from 'src/modules/model/book';
import * as helpers from './helpers';
import { useBookTitle, useLayoutAdjustments } from 'src/hooks';
import CustomReader from './CustomReader';
import ChapterPopup from './ChapterPopup';
import EndSheet from 'src/components/reader/ReadingPage/EndSheet';
import { isAuthenticated } from 'src/modules/api/cookie.api';
import { useIfMobileScreen } from 'src/hooks';
import ReactGA from 'react-ga4';

const THE_END = 'end';

export const ReadingPage = ({ match, loading, account, book, fullScreen, ...actions }) => {

  const readerRef = useRef(null);
  const [styles, setStyles] = useState(helpers.getCurrentTheme().styles);
  const [curChap, setCurChap] = useState(null);
  const [_rendition, setRendition] = useState(null);
  const [forceRender, setForceRender] = useState(1);
  const init = useRef(false);
  const [percent, setPercent] = useState(null);
  const [fullScreenReading, setFullScreenReading] = useState(false);
  const isMobile = useIfMobileScreen();

  const {
    fetchReadingChapter,
    fullScreenMode,
    addToMyBooks,
    fetchBook,
    saveBookLocation
  } = actions;

  const { params: {bookUuid, chapterUuid }} = match;
  const {orientationState: screenOrientation, contentHeight} = useLayoutAdjustments();
  const location = useRef(null);

  useEffect(() => {
    ReactGA.event({ category: 'user', action: 'read'});
    
    function handleResize() {
      if(isMobile) {
        window.location.reload();
      }
    }
    window.addEventListener('resize', handleResize)
  }, []);

  useBookTitle(book);
  useEffect(() => {
    isAuthenticated() && addToMyBooks(bookUuid);
    if (chapterUuid === THE_END) {
      fetchBook(bookUuid);
      setCurChap(null);
    } else fetchReadingChapter(bookUuid, chapterUuid);

    return () => {
      init.current = false;
      location.current = null;
      setPercent(null);
      setCurChap(null);
    }
  }, [bookUuid, chapterUuid, fetchReadingChapter, addToMyBooks, fetchBook]);

  useEffect(() => {
    fullScreenMode(true);
    return () => fullScreenMode(false);
  }, [fullScreenMode]);

  useEffect(() => {
    if (book) {
      const chapter = book.chapters.find(ch => ch.uuid === chapterUuid);
      if (chapter) {
        setCurChap(chapter);
      }
    }
  }, [book, chapterUuid]);

  const locationChanged = (epubcifi) => {
    location.current = epubcifi;
    isAuthenticated() && saveBookLocation(bookUuid, chapterUuid, epubcifi).then((response) => setPercent(response && response.progress));
  };

  const getRendition = rendition => {
    setRendition(rendition);
    helpers.setUpFontSize(rendition);
    helpers.setUpThemes(rendition);
  };

  const _onNextPage = () => {
    if (_rendition?.currentLocation()?.atEnd) {
      _onNextChapter();
    } else {
      _rendition && _rendition.next();
    }
  }

  const _onPrevPage = () => {
    if (percent === null) {
      _rendition.prev();
      return;
    }
    if ((book?.chapters[0]?.uuid !== chapterUuid) || percent > 0) {
      if (_rendition?.currentLocation()?.atStart) {
        _onPrevChapter(true);
      } else {
        _rendition && _rendition.prev();
      }
    }
  }

  const _onCenter = () => {
    setFullScreenReading(!fullScreenReading)
  }

  const _onNextChapter = () => {
    const nextChapterUuid = getNextChapterUuid(book, chapterUuid);
    setCurChap(null);
    if (nextChapterUuid) {
      history.push(`/books/${bookUuid}/${nextChapterUuid}`);
    } else {
      history.push(`/books/${bookUuid}/${THE_END}`);
    }
  }

  const _onPrevChapter = (lastPage) => {
    if (book?.chapters[0]?.uuid !== chapterUuid) {
      const prevChapterUuid = getPrevChapterUuid(book, chapterUuid) || findLastChapter(book);
      if (prevChapterUuid) {
        const promise = /* lastPage ? saveBookLocation(bookUuid, prevChapterUuid, null, 0.9) : */ Promise.resolve();
        promise.then(() => {
          setCurChap(null);
          history.push(`/books/${bookUuid}/${prevChapterUuid}`)
        })
      }
    }
  }

  const onMore = () => _rendition ? helpers.fontBigger(_rendition) : null;
  const onLess = () => _rendition ? helpers.fontSmaller(_rendition) : null;

  const onToggleNightMode = () => {
    if (_rendition) {
      helpers.toggleNightMode(_rendition);
      setStyles(helpers.getCurrentTheme().styles);
      setForceRender(curr => curr + 1);
    }
  };

  const key = useMemo(() => {
    return forceRender + '' + book?.chapter?.uuid + screenOrientation
  }, [book, screenOrientation, forceRender]);

  useEffect(() => {
    if (!init.current && _rendition) {
      const loc = getLocation(book, chapterUuid);
      _rendition.display(loc).then(() => { init.current = true });
    }
  }, [init, _rendition, book, chapterUuid]);

  useEffect(() => {
    if (init.current === true && _rendition && screenOrientation && forceRender > 1 && location.current) {
      _rendition.display(location.current);
    }
  }, [init, _rendition, book, chapterUuid, location, forceRender, screenOrientation])

  const chapterAvailable = isChapterAvailable(book, curChap, account);
  const customStyles = (book?.customStyles ?? false)
  return (
    <div className={cn(cs.container)} key={key} style={{ height: contentHeight}}>
      <Loading active={loading} />
      {(!loading && book) &&
        <>
          {chapterAvailable && book.chapter &&
            <div className={cn(cs.reader)}>
              <CustomReader
                ref={readerRef}
                url={book.chapter.arrayBuffer}
                title={undefined}
                location={1}
                locationChanged={locationChanged}
                swipeable
                showToc={false}
                getRendition={getRendition}
                styles={styles}
                epubOptions={{ stylesheet: process.env.PUBLIC_URL +  (customStyles ? '/epub-custom.css' : '/epub-default.css') }}
                onNextPage={_onNextPage}
                onPrevPage={_onPrevPage}
                onCenter={_onCenter}
              />
            </div>
          }

          { !loading && !chapterAvailable && book && curChap &&
            <UnavailableChapter book={book}
                                chapter={curChap}
                                className={cn(cs.reader)}
                                style={{ paddingTop: 40 }}
                                fetchShareState={actions.fetchShareState}
                                putBookShared={actions.putBookShared}
            />
          }

          { !loading && book && !curChap && chapterUuid === THE_END &&
            <EndSheet book={book}
                      className={cn(cs.reader)}
                      style={{ paddingTop: 40 }}
            />
          }

          <Toolbar
            bookUuid={bookUuid}
            chapterUuid={chapterUuid}
            chapters={book.chapters}
            narration={book.narration}
            chapterAvailable={chapterAvailable}
            reader={readerRef}
            onNextChapter={_onNextChapter}
            onPrevChapter={_onPrevChapter}
            onNextPage={_onNextPage}
            onPrevPage={_onPrevPage}
            percent={percent}
            fullScreen={fullScreenReading}
            {...{onMore, onLess, onToggleNightMode}}
          />
          {curChap && <ChapterPopup>{curChap?.name ?? ''}</ChapterPopup>}
        </>
      }
    </div>
  )
};

ReadingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookUuid: PropTypes.string.isRequired,
      chapterUuid: PropTypes.string.isRequired
    }).isRequired
  }),
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    chapter: PropTypes.exact({
      uuid: PropTypes.string,
      arrayBuffer: PropTypes.instanceOf(ArrayBuffer),
      location: PropTypes.string
    }),
    chapters: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      published: PropTypes.bool.isRequired
    })),
    price: PropTypes.shape({
      currency: PropTypes.string,
      paymentType: PropTypes.string,
      contentType: PropTypes.string
    }),
    purchased: PropTypes.bool.isRequired
  }),
  fullScreen: PropTypes.bool.isRequired,
  fullScreenMode: PropTypes.func.isRequired,
  saveBookLocation: PropTypes.func.isRequired,
  fetchShareState: PropTypes.func,
  putBookShared: PropTypes.func
};

function mapStateToProps(state) {
  return {
    loading: state.books.loading,
    book: state.books.book,
    fullScreen: state.application.fullScreen,
    account: state.authentication.account,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveBookLocation,
    fetchReadingChapter,
    fullScreenMode,
    addToMyBooks,
    fetchShareState,
    putBookShared,
    fetchBook
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadingPage);
