import { ReactReaderStyle } from 'react-reader';

function createTheme(color, background, invert) {
  let hh = { };
  const theme = {
    body: {
      background,
    },
    span: {
    },
    li: {},
    img: {},
    p: hh, h1: hh, h2: hh, h3: hh, h4: hh, h5: hh, h6: hh,
  };
  if (invert) {
    theme.body.filter = "invert(1)";
    theme.img.filter = "invert(1)";
  }
  return theme;
}

function createStyles(backgroundColor) {
  return {
    ...ReactReaderStyle,
    readerArea: {
      ...ReactReaderStyle.readerArea,
      backgroundColor
    },
    reader: {
      ...ReactReaderStyle.reader,
      left: 0,
      right: 0,
      top: 30
    }
  }
}

const LIGHT = 'light';
const DARK = 'dark';

const THEMES = {
  dark: {
    id: DARK,
    onToggle: LIGHT,
    readerTheme: { [DARK]: createTheme('#fff', '#000', true) },
    readerStyles: createStyles('#000')
  },
  light: {
    id: LIGHT,
    onToggle: DARK,
    readerTheme: { [LIGHT]: createTheme('#000', '#fff') },
    readerStyles: createStyles('#fff')
  }
};

class ReaderTheme {
  constructor(theme) {
    this.theme = { ...theme };
  }

  get id() {
    return this.theme.id;
  }

  get name() {
    return this.theme.id;
  }

  get styles() {
    return this.theme.readerStyles;
  }

  isLight() {
    return this.theme.id === THEMES.light.id;
  }

  toggle() {
    return ReaderTheme.themeOf(this.theme.onToggle);
  }

  static defaultTheme() {
    return new ReaderTheme(THEMES.light);
  }

  static themeOf(id) {
    return (id && THEMES.hasOwnProperty(id)) ?
      new ReaderTheme(THEMES[id]) : ReaderTheme.defaultTheme();
  }

  static themesToRegister() {
    return Object.getOwnPropertyNames(THEMES).reduce( ( acc, name ) => ({...acc, ...THEMES[name].readerTheme }), {});
  }
}

export default ReaderTheme;
