export const ACTION_TYPES = {
  GLOBAL_SEARCH_TYPE: 'search/GLOBAL_SEARCH_TYPE',
  GLOBAL_SEARCH_LOCAL_QUERY_TYPE: 'search/LOCAL_SEARCH_LOCAL_QUERY_TYPE',
  PREPARE_SEARCHING: 'search/PREPARE_SEARCHING',

  GLOBAL_SEARCH_PENDING: 'search/GLOBAL_SEARCH_PENDING',
  GLOBAL_SEARCH_FULFILLED: 'search/GLOBAL_SEARCH_FULFILLED',
  GLOBAL_SEARCH_REJECTED: 'search/GLOBAL_SEARCH_REJECTED'
};

const defaultState = {
  globalQuery: '',
  localQuery: '',
  loading: false,
  message: null,
  books: [],
  authors: []
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.GLOBAL_SEARCH_TYPE:
      return {
        ...state,
        globalQuery: action.text
      };
    case ACTION_TYPES.GLOBAL_SEARCH_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GLOBAL_SEARCH_FULFILLED:
      return {
        ...state,
        loading: false,
        ...action.data
      };
    case ACTION_TYPES.GLOBAL_SEARCH_REJECTED:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case ACTION_TYPES.PREPARE_SEARCHING:
      return {
        ...state,
        localQuery: state.globalQuery,
        globalQuery: ''
      };
    case ACTION_TYPES.GLOBAL_SEARCH_LOCAL_QUERY_TYPE:
      return {
        ...state,
        localQuery: action.localQuery
      };
    default:
      return state;
  }
}
