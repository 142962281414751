import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'carbon-components-react';
import { CheckmarkOutline16, RequestQuote16 } from '@carbon/icons-react';

import cs from './styles.module.scss';
import history from 'src/history';
import { setIsAuthorSelected } from 'src/modules/actions/authentication.actions';


const BlueButton = ({ text, onClick }) => (
  <Button size="small"
          style={{
            fontSize: 16,
            letterSpacing: '0.182857px',
            paddingRight: 12
          }}
          onClick={onClick}
  >
    {text}
  </Button>
);

BlueButton.propTypes = {
  text: PT.string.isRequired,
  onClick: PT.func.isRequired
};

const MarketingCover = (props) => {
  const { isAuthenticated, isAuthor } = props;

  const goRegister = (asAuthor) => () => {
    if (asAuthor) {
      props.setIsAuthorSelected(true);
    }
    history.push('/register');
  };

  const createBook = () => history.push('/my-books');
  const becomeWriter = () => history.push('/become');

  return (
    <div className={cs.cover}>
      <div className={cs.title}>Fictionate.me</div>

      {isAuthenticated === false &&
        <>
          <div className={cs.checkmark}><RequestQuote16/> Publish your books</div>
          <div className={cs.checkmark}><RequestQuote16/> Stay connected with your readers</div>
          <div style={{ textAlign: 'center', marginBottom: '0.75rem' }}>
            <BlueButton onClick={goRegister(true)} text="Create writer account" />
          </div>
        </>
      }

      <div className={cs.checkmark}><CheckmarkOutline16/> All your favourite authors in one place</div>
      <div className={cs.checkmark}><CheckmarkOutline16/> Never ever miss new chapters</div>
      {isAuthenticated === false &&
        <div style={{textAlign: 'center', marginBottom: '1rem'}}>
          <Button style={{
                    paddingRight: 12,
                    fontSize: 14,
                    letterSpacing: '0.16px'
                  }}
                  onClick={() => history.push('/register')}
                  kind='ghost'
                  size="small"
          >
            Create reader's account
          </Button>
        </div>
      }
      {isAuthenticated === true && isAuthor === true &&
        <div style={{ textAlign: 'center', marginBottom: '0.75rem' }}>
          <BlueButton onClick={createBook} text="Create your first book" />
        </div>
      }
      {isAuthenticated === true && isAuthor === false &&
      <div style={{ textAlign: 'center', marginBottom: '0.75rem' }}>
        <BlueButton onClick={becomeWriter} text="Become a writer" />
      </div>
      }

      <div style={{position: 'relative'}}>
        <div className={cs.line} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/floor.jpg)` }} />
        <div className={cs.image} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/zaglushka.jpg)` }}/>
      </div>

    </div>
  )
};

MarketingCover.propTypes = {
  setIsAuthorSelected: PT.func.isRequired,
  isAuthenticated: PT.bool.isRequired,
  isAuthor: PT.bool.isRequired
};

export default connect((state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  isAuthor: state.authentication.account.writer
}), dispatch => bindActionCreators({setIsAuthorSelected}, dispatch))(MarketingCover);
