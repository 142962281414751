export const ACTION_TYPES = {
  FETCH_PERSONAS_PENDING: 'personas/FETCH_PERSONAS_PENDING',
  FETCH_PERSONAS_FULFILLED: 'personas/FETCH_PERSONAS_FULFILLED',
  FETCH_PERSONAS_REJECTED: 'personas/FETCH_PERSONAS_REJECTED',

  TOGGLE_FORM: 'personas/TOGGLE_FORM',

  SAVE_CHARACTER_PENDING: 'personas/SAVE_CHARACTER_PENDING',
  SAVE_CHARACTER_FULFILLED: 'personas/SAVE_CHARACTER_FULFILLED',
  SAVE_CHARACTER_FULFILLED_UPDATE: 'personas/SAVE_CHARACTER_FULFILLED_UPDATE',
  SAVE_CHARACTER_REJECTED: 'personas/SAVE_CHARACTER_REJECTED',

  UPDATE_CHARACTER: 'personas/UPDATE_CHARACTER',

  CLEAR_FORM_ERROR: 'personas/CLEAR_FORM_ERROR',

  DELETE_PERSONA_ON: 'personas/DELETE_PERSONA_ON',
  DELETE_PERSONA_OFF: 'personas/DELETE_PERSONA_OFF',
  DELETE_PERSONA_PENDING: 'personas/DELETE_PERSONA_PENDING',
  DELETE_PERSONA_SUCCESS: 'personas/DELETE_PERSONA_SUCCESS',
  DELETE_PERSONA_REJECTED: 'personas/DELETE_PERSONA_REJECTED',
}

const formDefaultState = {
  form: false,
  savingCharacter: false,
  charError: undefined,
  editPersonaId: undefined,
  chapterUuid: undefined
}

const defaultDeletePersonaState = {
  deletePersonaDialog: undefined,
  deletingPersona: undefined,
}

const defaultState = {
  loading: false,
  personas: [],
  ...defaultDeletePersonaState,
  ...formDefaultState
}

function narratorOffCopy(personas, theOne) {
  return !theOne.narrator ? [...personas] : personas.map(per => ({ ...per, narrator: undefined }));
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSONAS_PENDING:
      return {
        ...state,
        loading: true
      }
    case ACTION_TYPES.FETCH_PERSONAS_FULFILLED:
      return {
        ...state,
        loading: false,
        personas: action.personas
      }
    case ACTION_TYPES.FETCH_PERSONAS_REJECTED:
      return {
        ...state,
        loading: false
      }
    case ACTION_TYPES.TOGGLE_FORM:
      return {
        ...state,
        ...formDefaultState,
        form: !state.form,
        editPersonaId: action.editPersonaId,
        chapterUuid: action.chapterUuid
      };

    case ACTION_TYPES.SAVE_CHARACTER_PENDING:
      return {
        ...state,
        savingCharacter: true,
      }
    case ACTION_TYPES.SAVE_CHARACTER_FULFILLED:
      return {
        ...state,
        savingCharacter: false,
        personas: [...narratorOffCopy(state.personas, action.persona), action.persona]
      }
    case ACTION_TYPES.SAVE_CHARACTER_FULFILLED_UPDATE:
      return {
        ...state,
        savingCharacter: false,
        personas: narratorOffCopy(state.personas, action.persona).map(per => (per.id !== action.persona.id) ? per : action.persona)
      }

    case ACTION_TYPES.SAVE_CHARACTER_REJECTED:
      return {
        ...state,
        charError: action.error,
        savingCharacter: false
      }

    case ACTION_TYPES.UPDATE_CHARACTER:
      return {
        ...state,
        personas: state.personas.map(p => {
          if (p.uuid === action.character.uuid) return action.character;
          else return p;
        })
      }

    case ACTION_TYPES.CLEAR_FORM_ERROR:
      return {
        ...state,
        charError: undefined
      }

    case ACTION_TYPES.DELETE_PERSONA_ON:
      return {
        ...state,
        deletePersonaDialog: true
      }
    case ACTION_TYPES.DELETE_PERSONA_OFF:
      return {
        ...state,
        ...defaultDeletePersonaState
      }
    case ACTION_TYPES.DELETE_PERSONA_PENDING:
      return {
        ...state,
        deletingPersona: true
      };
    case ACTION_TYPES.DELETE_PERSONA_SUCCESS:
      return {
        ...state,
        ...defaultDeletePersonaState,
        ...formDefaultState,
        personas: state.personas.filter(p => p.uuid !== action.uuid)
      };
    case ACTION_TYPES.DELETE_PERSONA_REJECTED:
      return {
        ...state,
        ...defaultDeletePersonaState,
        charError: action.error
      };

    default:
      return state;
  }
}
