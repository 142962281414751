export const ACCOUNT_KEY = 'read-it.ACCOUNT';
export const BOOKS_KEY = 'read-it.BOOKS';
export const READER_KEY = 'read-it.READER';

export function saveAccount(account) {
  const acc = { ...account };
  localStorage.setItem(ACCOUNT_KEY, JSON.stringify(acc));
}

export function updateAccount(account) {
  if (!account) return;
  const ex = getAccount() || {};
  saveAccount({ ...ex, ...account });
}

export function getAccount() {
  const str = localStorage.getItem(ACCOUNT_KEY);
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      console.log(e)
    }
  }
}

export function removeAccount() {
  localStorage.removeItem(ACCOUNT_KEY);
  clearBooks();
}

export function getBooks() {
  const str = localStorage.getItem(BOOKS_KEY);
  return str ? JSON.parse(str) : [];
}

export function setBooks(books) {
  localStorage.setItem(BOOKS_KEY, JSON.stringify(books));
}

export function clearBooks() {
  localStorage.removeItem(BOOKS_KEY);
}

export function addBook(book) {
  const newBook = { ...book };
  setBooks([ ...getBooks(), newBook]);
  return newBook;
}

export function putBook(book) {
  const books = getBooks();

  const storedBook = getBook(book.uuid);
  if (storedBook) {
    books.splice(books.indexOf(storedBook), 1);
  }

  books.push(book);
  setBooks(books);
}

export function getBook(uuid) {
  return getBooks().find(book => book.uuid === uuid);
}

export function getReaderSettings() {
  const str = localStorage.getItem(READER_KEY);
  return str ? JSON.parse(str) : {};
}

export function setReaderSettings(settings) {
  localStorage.setItem(READER_KEY, JSON.stringify(settings));
}
