import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';

const ImportEpubDialog = memo(forwardRef(({ handleEpubFileChange, onError }, ref) => {
  const [errOff, setErrOff] = React.useState(null);

  const onChange = async e => {
    const file = e.target?.files?.[0];
    if (file) {
      if (file.type !== 'application/epub+zip') {
        onError('Only Epubs can be imported.');
        setErrOff(setTimeout(() => onError(null), 1500));
      } else {
        handleEpubFileChange(file);
      }
    }
  }

  React.useEffect(() => {
    return () => {
      if (errOff) {
        clearTimeout(errOff);
      }
    }
  }, [errOff]);

  return (
    <>
      <input type='file'
             style={{ display: 'none' }}
             ref={ref}
             onChange={onChange}
      />
    </>
  )
}))

ImportEpubDialog.propTypes = {
  handleEpubFileChange: PropTypes.func.isRequired,
  onError: PropTypes.func
}

export default ImportEpubDialog;
