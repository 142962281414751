import React from 'react';

import PropTypes from 'prop-types';
import { timeAgo } from 'src/modules/api/utils';

import cn from 'classnames';
import cx from './Item.module.scss';
import cg from 'src/styles/global.module.scss';
import { getTheme } from 'src/modules/actions/theme.actions';
import { RES_PATH } from 'src/config/environment';
import { Href } from 'src/components/_shared/Href';

export const Item = ({object, ...props}) => {
  const theme = getTheme(props.theme)

  return (
    <div>
      <h2 className={cn(cx.title, cg.h2)}>{object.name}</h2>
      <div className={cn(cx.time, cg.h2Subtitle, theme.h2Subtitle)}>{object.writer && <><Href href={`writer/${object.writer.uuid}`} style={{ display: 'inline' }}>{object.writer.name}</Href> | </>}{timeAgo(object.time)}</div>
      {object.image && <div className={cn(cx.image)} style={{backgroundImage:`url(${RES_PATH}${object.image})`}}/>}
      <div dangerouslySetInnerHTML={{ __html: object.summary}} />
    </div>
  )
};

Item.propTypes = {
  fetchBlog: PropTypes.func.isRequired,
};

export default Item;
