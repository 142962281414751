import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'

export async function getHome2() {
  try {
    return await axios.get('/home/v2', getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getCategory(id) {
  try {
    return await axios.get(`/content?id=${id}&offset=0&limit=100`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getNewBooks() {
  try {
    return await axios.get('/new?offset=0&limit=100', getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getTrendingBooks() {
  try {
    return await axios.get('/trending?offset=0&limit=100', getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function getGenres() {
  try {
    return await axios.get('/genres', getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
