export default {
  NOT_CONFIGURED: 'notConfigured',
  CONFIGURED: 'configured',
  UPLOAD_PHOTO_ID: 'uploadPhotoId',
  UNKNOWN_ERROR: 'unknownError',
  API_ERROR: 'apiError',
  TRANSFERS_INACTIVE: 'transfersInactive',

  getTitle(payoutState) {
    switch (payoutState) {
      case this.CONFIGURED:
        return '';
      case this.NOT_CONFIGURED:
        return 'Payouts not configured';
      case this.UPLOAD_PHOTO_ID:
        return 'Payouts configuration Issue';
      case this.UNKNOWN_ERROR:
        return 'Payouts configuration unknown error';
      default:
        return 'Cannot get status of payouts configuration';
    }
  },

  getSubtitle(payoutState) {
    switch (payoutState) {
      case this.CONFIGURED:
        return '';
      case this.NOT_CONFIGURED:
        return 'To receive payments, payouts need to be configured.';
      case this.UPLOAD_PHOTO_ID:
        return 'Please complete your payouts configuration';
      case this.UNKNOWN_ERROR:
        return '';
      default:
        return '';
    }
  }
}
