var ObjectType = {
  chapter: 1,
  book: 2,
  subscription: 4
}

function getValueString(coupon) {
  return "$" + (coupon.content.content.amount / 100);
}

function getPercetString(coupon) {
  return coupon.content.content.percents + "%";
}

function getContentString(coupon) {
  if(coupon.content.type & ObjectType.chapter & ObjectType.book){
    return ""
  } else if(coupon.content.type & ObjectType.chapter){
    return "Chapter"
  } else if(coupon.content.type & ObjectType.book){
    return "Book"
  } else if(coupon.content.type & ObjectType.subscription){
    return "Subscribtion"
  }

  return "";
}

function getChapterName(coupon) {
  return coupon.chapter.name
}

function getBookName(coupon) {
  return coupon.book.name
}

export default class {
  constructor(data) {
    this.data = data;
  }

  get shortId() {
    return this.data.shortId;
  }

  get content() {
    return this.data.content;
  }

  get book() {
    return this.data.book;
  }

  get chapter() {
    return this.data.chapter;
  }

  getMessage() {
    switch(this.content.type) {
      case "book":
        return `Now received '${getBookName(this)}' book.`
      case "chapter":
        return `You recevied chapter '${getChapterName(this)}' from '${getBookName(this)}' book.`
      case "discountAmount":
        return `You will have ${getValueString(this)} off your next ${getContentString(this)} purchase.`
      case "discountPercent":
        return `You will have ${getPercetString(this)} off your next ${getContentString(this)} purchase.`
      default:
        return `You will have ${getValueString(this)} off your next ${getContentString(this)} purchase.`
    }
  }
}
