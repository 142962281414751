export const ACTION_TYPES = {
  FETCH_BLOG_POST_PENDING: 'blog/FETCH_BLOG_POST_PENDING',
  FETCH_BLOG_POST_FULFILLED: 'blog/FETCH_BLOG_POST_FULFILLED',
  FETCH_BLOG_POST_REJECTED: 'blog/FETCH_BLOG_POST_REJECTED',
  FETCH_BLOG_POST_DELETED: 'blog/FETCH_BLOG_POST_DELETED',
  FETCH_BLOG_POST_UPDATED: 'blog/FETCH_BLOG_POST_UPDATED',
  FORGET: 'blog/FORGET',
};

const defaultState = {
  content: { id:"", summary: "", content: "", writer: {name:"", uuid:""}},
  loading: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BLOG_POST_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_BLOG_POST_FULFILLED:
      return {
        ...state,
        loading: false,
        content: action.data
      };
    case ACTION_TYPES.FETCH_BLOG_POST_REJECTED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.FORGET:
    return {
      ...state,
      content: null
    };
    default:
      return state;
  }
}
