export const ACTION_TYPES = {
  CHANGED: 'theme/CHANGED',
};

const defaultState = {
  theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.CHANGED:
      return {
        ...state,
        theme: action.theme
      };
    default:
      return state;
  }
}
