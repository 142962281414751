import { useEffect, useState } from 'react';

export function useIfMobileScreen() {

  function checkIfMediaScreen() {
    return window.matchMedia('(max-width: 768px)').matches;
  }

  const [isMediaScreen, setIsMediaScreen] = useState(checkIfMediaScreen);

  useEffect(() => {
    let time;

    function handleResize() {
      if (time) {
        clearTimeout(time);
      }

      time = setTimeout(() => {
        setIsMediaScreen(checkIfMediaScreen())
      }, 200);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMediaScreen;
}
