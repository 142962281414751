import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Button from 'src/components/_shared/DesignV2/Button';
import { Loading } from 'carbon-components-react';
import ReactGA from 'react-ga4';
import { PayPalButton } from "react-paypal-button-v2";
import { PAYPAL_CLIENT_ID } from 'src/config/environment';

import { redirectToCheckout, redirectToCoinbaseCheckout } from 'src/modules/actions/payment.actions';
import { isBookPriceTier, priceAmount, isChapterPriceTier, isBookSubscriptionTier } from 'src/modules/model/book';
import cx from './BuyButton.module.scss';
import { completePayPalOrder, getPayPalOrder } from 'src/modules/api/payments.api';

const BuyButton = ({ book, chapter, showOriginalPrice, className, style, completed, ...actions }) => {
  const coinbasePending = book?.pricing.state === "coinbasePending";
  const [visible, setVisible] = useState(false);

  const onClick = (subscription) => {
    if (coinbasePending) { return; }
    if (isBookSubscriptionTier(book)) {
      return;
    }
    if(!visible) {
      setVisible(true);
    }
  };

  const onCryptoClick = () => {
    if (coinbasePending) { return; }
    actions.redirectToCoinbaseCheckout(book, chapter);
  };

  const onCreditClick = () => {
    if (coinbasePending) { return; }
    actions.redirectToCheckout(book, chapter);
  };

  const _toggleOverlay = (event) => {
    ReactGA.event({ category: 'Payment', action: 'Open-Selection'});
    setVisible(false);
  };

  const _buttonContent = () => {
    if (coinbasePending) {
      return (<Loading style={{display:'inline-block'}} small={true} withOverlay={false}/>)
    }
    if (isBookPriceTier(book)) {
      return (`Buy for ${priceAmount(book)}`);
    }
    if (isBookSubscriptionTier(book)) {
      return (`Subscribe and support`);
    }
    return ''
  }
  return (
    <>
      {visible &&
      <div className={cx.overlay} onClick={_toggleOverlay}></div>}
        <div className={classNames(cx.base, className)} style={{...style}}>
          <Button kind='cta'
                  className={cx.btn}
                  onClick={onClick}
                  size='small'
          >
            {_buttonContent()}
          </Button>
          {coinbasePending ? 
            <div className={cx.originalPrice}>
            Waiting for coinbase confirmation
            </div> :
            book.originalPrice && (book.pricing.price?.amount !== undefined) && book.originalPrice.amount > book.pricing.price.amount && showOriginalPrice &&
              <div className={cx.originalPrice}>
                price without coupon ${book.originalPrice.amount / 100}
              </div>
          }
          {visible &&
          <div style={{minWidth: '220px', position: 'absolute', width: '100%', marginTop: '8px', padding: '8px',backgroundColor:'#343537', borderRadius: '5px', zIndex: 1000}}>
            <Button kind='cta'
                    className={classNames(cx.btn, cx.card)}
                    onClick={onCreditClick}
                    size='small'
            />
            <div style={{height: '0px'}}/>
            <Button kind='cta'
                    className={cx.btn}
                    style={{marginTop: '8px'}}
                    onClick={onCryptoClick}
                    size='small'
            >
            Pay with Crypto
            </Button>
            <div style={{height: '8px'}}/>
            <PayPalButton kind='cta'
              className={cx.btn}
              style={{marginTop: '8px'}}
              shippingPreference='NO_SHIPPING'
              createOrder={(data, actions) => {
                return getPayPalOrder(book, chapter)
                .then((response) => {
                  ReactGA.event({ category: 'Payment', action: 'Open-Paypal'});
                  return response.paypalOrder
                });
              }}
              onApprove={(data, actions) => {
                ReactGA.event({ category: 'Payment', action: 'Complete-Paypal'});
                return completePayPalOrder(data.orderID).then((data) => {
                  if (completed) {
                    completed();
                  }
                  return data
                });
              }}
              options={{
                clientId: PAYPAL_CLIENT_ID,
                disableFunding: 'card'
              }}
            />
          </div>
          }
        </div>
    </>
  )
};

BuyButton.defaultProps = {
  showOriginalPrice: false,
  className: ''
};

BuyButton.propTypes = {
  book: PropTypes.shape({
    purchased: PropTypes.bool.isRequired,
    price: PropTypes.shape({
      paymentType: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    }),
    originalPrice: PropTypes.shape({
      amount: PropTypes.number
    })
  }).isRequired,
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    purchased: PropTypes.bool
  }),
  redirectToCheckout: PropTypes.func.isRequired,
  completed: PropTypes.func.isRequired,
  showOriginalPrice: PropTypes.bool,
  className: PropTypes.any
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ redirectToCheckout, redirectToCoinbaseCheckout }, dispatch);
}

export default connect(() => ({}), mapDispatchToProps)(BuyButton);

export const canBuy = (book, chapter) => {
  return ((isBookSubscriptionTier(book) && book.pricing.state !== 'purchased') || (isBookPriceTier(book) && book.pricing.state !== 'purchased') || (isChapterPriceTier(book) && chapter && !chapter.purchased))
};
