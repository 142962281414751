import { ACTION_TYPES } from 'src/modules/reducers/tags.reducer';
import { getTagsHome, getTagBooks } from 'src/modules/api/tags.api';

export function fetchTagsHome(tags) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_PENDING });

    const response = await getTagsHome(tags);

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_FULFILLED, data });
    }
  }
}

export function fetchTagsBooks(tags, sort) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_ALL_PENDING });

    const response = await getTagBooks(tags, sort, 0);

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_ALL_FULFILLED, data });
    }
  }
}

export function fetchTagsBooksNextPage(tags, sort, offset) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_NEW_PAGE_PENDING });

    const response = await getTagBooks(tags, sort, offset);

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_TAGS_HOME_NEW_PAGE_FULFILLED, data });
    }
  }
}