import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Loading, Toggle } from 'carbon-components-react';

import cs from './NotificationsPage.module.scss';
import ContentLayout from 'src/layout/ContentLayout';
import {
  getNotificationsConfig,
  toggleNotificationConfigProperty,
  saveNotificationConfig
} from 'src/modules/actions/me.actions';

const WRITER_START_INDEX = 4;

const NotificationsPage = ({
                             loading,
                             notificationsConfig,
                             getNotificationsConfig,
                             toggleNotificationConfigProperty,
                             saveNotificationConfig }) => {

  React.useEffect(() => {
    getNotificationsConfig();
  }, [getNotificationsConfig])

  const onToggle = e => {
    const target = e.target;
    const group = target.dataset.group;
    const index = Number.parseInt(target.dataset.index);
    const checked = target.checked;
    toggleNotificationConfigProperty(group, index, checked);
  }

  return (
    <>
      <Loading active={loading} />
      <ContentLayout title={'Email notifications'}
                     renderAction={() => (
                       <Button disabled={loading} onClick={saveNotificationConfig}>
                         Save
                         <Loading small={true} withOverlay={false} active={loading}/>
                       </Button>
                     )}
      >
        <div className={cs.notifications}>
          <table><tbody>
            {notificationsConfig.map((notif, i) => {
              return (
                <React.Fragment key={i}>
                  {i === 0 &&
                    <tr>
                      <td className={cs.name}><div /></td>
                      <td className={cs.email}>Email</td>
                      <td>Push</td>
                    </tr>
                  }

                  {
                    i === WRITER_START_INDEX &&
                      <tr className={cs.writer}>
                        <td colSpan={3}>Writer's Notifications</td>
                      </tr>
                  }

                  <tr>
                    <td className={cs.name}>
                      <span>
                        {notif.email.name}
                      </span>
                    </td>
                    <td className={cs.email}>
                      <div>
                        <Toggle onClick={onToggle}
                                data-key={notif.email.key}
                                data-group={'email'}
                                data-index={i}
                                id={'email' + i}
                                labelA=''
                                labelB=''
                                checked={notif.email.state}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Toggle data-key={notif.push.key}
                                data-group={'push'}
                                data-index={i}
                                onClick={onToggle}
                                id={'push' + i}
                                labelA=''
                                labelB=''
                                checked={notif.push.state}
                        />
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody></table>
        </div>
      </ContentLayout>
    </>
  )
}

NotificationsPage.propTypes = {
  loading: PropTypes.bool,
  notificationsConfig: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      state: PropTypes.bool
    }),
    push: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      state: PropTypes.bool
    })
  })),
  getNotificationsConfig: PropTypes.func,
  toggleNotificationConfigProperty: PropTypes.func
}

function mapStateToProps(state) {
  return {
    loading: state.me.notificationsLoading,
    notificationsConfig: state.me.notificationsConfig
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getNotificationsConfig,
    toggleNotificationConfigProperty,
    saveNotificationConfig
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
