import { InlineNotification } from 'carbon-components-react';
import React, {createContext} from 'react';
import { useState } from 'react';
import cs from './styles.module.scss';

export const NotificationContext = createContext({
  showMessage: (message, kind) => {},
  isRegistered: async () => {},
  reportToServer: async () => {},
});

export const NotificationConsumer = NotificationContext.Consumer;

export const NotificationProvider = ({children}) => {
  const contextValue = {
    showMessage: (message, kind) => {
      setShowMessage(true);
      setMessage(message);
      setKind(kind)
    },
  };

  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState(false)
  const [kind, setKind] = useState(false)

  return (
    <NotificationContext.Provider value={contextValue}>
     { showMessage &&
       <InlineNotification kind={kind}
                           title={message}
                           className={cs.toast}
                           onCloseButtonClick={() => {setShowMessage(false)}}
                           timeout={5000}
                           lowContrast={true}
       />
     }
      {children}
    </NotificationContext.Provider>
  );
};
