import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'

export async function getSubscriptions(offset, limit = 8) {
    try {
      const response = await axios.get(`me/writer/subscriptions?limit=${limit}&offset=${offset}`, getAuthHeader());
      if (response && response.status === 200) {
        return response.data.items;
      }
      return null;
    } catch (error) {
      return error.response;
    }
}

export async function getMySubscriptions(offset, limit = 8) {
  try {
    const response = await axios.get(`me/subscriptions?limit=${limit}&offset=${offset}`, getAuthHeader());
    if (response && response.status === 200) {
      return response.data.items;
    }
    return null;
  } catch (error) {
    return error.response;
  }
}

export async function unsubscribe(uuid) {
  try {
    const response = await axios.delete(`/subscriptions/${uuid}/subscribe`, getAuthHeader());
    if (response && response.status === 200) {
      return response.data.items;
    } else {
      throw TypeError(response.data);
    }
  } catch (error) {
    throw TypeError(error.rresponse.data);
  }
}

export async function get(uuid) {
  try {
    const response = await axios.get(`/subscriptions/${uuid}`, getAuthHeader());
    if (response && response.status === 200) {
      return response.data
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function put(uuid, object) {
  try {
    return await axios.put(`/subscriptions/${uuid}`, object, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function post(object) {
  try {
    return await axios.post(`/me/writer/subscriptions`, object, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function remove(uuid) {
  try {
    return await axios.delete(`/subscriptions/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
