export function throttle(f, t) {
  let lastCall;
  return function (args) {
    const now = Date.now();
    if (lastCall === undefined || (now - lastCall) > t) {
      lastCall = now;
      f(args);
    }
  }
}
