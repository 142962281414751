import * as meApi from 'src/modules/api/me.api';
import { ACTION_TYPES } from 'src/modules/reducers/me.reducer';
import axios from "axios/index";
import {getAuthHeader} from "../api/utils";

export function fetchLibrary() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_LIBRARY_PENDING });

    const response = await meApi.getBooks();
    if (response.status === 200) {
      dispatch({ type: ACTION_TYPES.FETCH_LIBRARY_FULFILLED, books: response.data.books });
    } else {
      dispatch({ type: ACTION_TYPES.FETCH_LIBRARY_REJECTED });
    }
  }
}

export function getProfile() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_ME_PENDING });

    const getBooks = () => axios.get('/me/books', getAuthHeader());
    const getMe = () => axios.get('/me', getAuthHeader());

    axios.all([getBooks(), getMe()])
      .then(axios.spread(async function (br, mr) {
        const books = br.data.books;
        const stripeCustomer = mr.data.stripeCustomer;
        dispatch({ type: ACTION_TYPES.FETCH_ME_FULFILLED, payload: { books, stripeCustomer }})
      }))
      .catch(() => {
        dispatch({ type: ACTION_TYPES.FETCH_ME_REJECTED })
    });
  }
}

export function getNotificationsConfig() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_PENDING });

    function zip(emailArr, pushArr) {
      return emailArr.map((email, i) => ({ email, push: pushArr[i] ?? {} }))
    }

    try {
      const res = await meApi.getNotificationsConfig();
      if (res.data && Array.isArray(res.data.emailNotifications)) {
        const payload = zip(res.data.emailNotifications, res.data.pushNotifications);
        dispatch({ type: ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_FULFILLED, payload });
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_REJECTED });
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_REJECTED });
    }
  }
}

export function toggleNotificationConfigProperty(group, index, state) {
  return {
    type: ACTION_TYPES.CHANGE_NOTIFICATIONS_CONFIG_PROPERTY,
    index, group, state
  }
}

export function saveNotificationConfig() {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.FETCH_NOTIFICATIONS_CONFIG_PENDING });
    const notificationsConfig = getState?.()?.me?.notificationsConfig.reduce((acc, cur) => ({
      emailNotifications: cur.email.state ? [...acc.emailNotifications, cur.email.key] : acc.emailNotifications,
      pushNotifications: cur.push.state ? [...acc.pushNotifications, cur.push.key] : acc.pushNotifications
    }), {
      emailNotifications: [],
      pushNotifications: []
    });

    try {
      meApi.saveNotificationsConfig(notificationsConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: ACTION_TYPES.CHANGE_NOTIFICATIONS_CONFIG_PROPERTY_END })
    }
  }
}
