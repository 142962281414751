import React from 'react';
import PropTypes from 'prop-types';

import cx from './CommentItem.module.scss';
import { timeAgo } from 'src/modules/api/utils';
import Author from 'src/components/_shared/Author';

const CommentItem = ({ uuid, text, author, created }) => {

  return (
    <div className={cx.commentItem}>
      <div className={cx.author}>
        <Author {...author} noLink updatedAt={created} dateFormat={timeAgo} isComment/>
      </div>
      <div className={cx.text}>
        {text}
      </div>
    </div>
  )
};

CommentItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    badges: PropTypes.arrayOf(PropTypes.string),
    imagePath: PropTypes.string
  }),
  created: PropTypes.number.isRequired
};

export default CommentItem;
