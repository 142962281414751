import React, { useState, useEffect } from 'react';
import { Modal, TextInput, InlineNotification } from 'carbon-components-react';
import * as booksApi from 'src/modules/api/books.api';
import { fetchSeriesObject, fetchSeries, forgetSeriesObject } from 'src/modules/actions/books.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const EditSeries = ({ open, setOpen, uuid, seriesObject, mode, fetchSeries, fetchSeriesObject, forgetSeriesObject }) => {
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [about, setAbout] = useState(null);

  useEffect(() => {
    if(uuid?.length > 0) {
      fetchSeriesObject(uuid);
    } else {
      forgetSeriesObject();
    }
  }, [fetchSeriesObject, forgetSeriesObject, uuid]);

  useEffect(() => {
    setName(seriesObject?.name);
    setAbout(seriesObject?.about);
  }, [seriesObject]);

  const _onRequestSubmit = () => {
    if(uuid?.length > 0) {
      booksApi.updateSeries(uuid, name, about).then(response => {
        if (!response || response.status !== 200) {
          setError("Request failed");
        } else {
          fetchSeries()
          setOpen(false)
        }
      }).catch(e => {
        setError(e.message);
      });
    } else {
      booksApi.createSeries(name, about).then(response => {
        if (!response || response.status !== 200) {
          setError("Request failed");
        } else {
          fetchSeries()
          setOpen(false)
        }
      }).catch(e => {
        setError(e.message);
      });
    }
  };

  return (
    <Modal
      modalHeading={(uuid?.length > 0) ? "Edit Book Series" : "Add Book Series" }
      primaryButtonText={(uuid?.length > 0) ? "Save" : "Add" }
      secondaryButtonText="Cancel"
      open={open}
      onRequestSubmit={_onRequestSubmit}
      onRequestClose={() => setOpen(false)}
      >
          <TextInput labelText='Name'
                     id='name'
                     value={name ?? ""}
                     onChange={e => setName(e.target.value)}
          />
          <TextInput labelText='Description'
                     id='about'
                     value={about ?? ""}
                     onChange={e => setAbout(e.target.value)}
          />
          {error && <InlineNotification kind='error' lowContrast title="Error" subtitle={error} />}
    </Modal>
  )
};

function mapStateToProps(state) {
  return {
    seriesObject: state.books.seriesObject
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSeriesObject, fetchSeries, forgetSeriesObject }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSeries);