import React, {
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppLayout from 'src/layout/AppLayout';
import cx from './GenresPage.module.scss';
import cn from 'classnames';
import Section from 'src/components/_shared/Tags';
import { fetchGenres } from 'src/modules/actions/genres.actions';

export const GenresPage = props => {
  const { match, sections, ...actions } = props;
  const { fetchGenres } = actions;

  useEffect(() => {
    fetchGenres();
  }, [fetchGenres, match]);

  return (
    <>
      <AppLayout>
        <div className={cn('bx--grid', cx.content)}>
          {
            sections.map((item, i) => (
              <div className={cn(cx.sectionContent)}>
                <Section key={i} object={item}/>
              </div>
            ))
          }
        </div>
      </AppLayout>
    </>
  )
};

GenresPage.propTypes = {
  fetchGenres: PropTypes.func.isRequired,
  sections: PropTypes.array
};

function mapStateToProps(state) {
  return {
    ...state.genres
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchGenres }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GenresPage);