import React from 'react';
import { hashCode } from 'src/utils';
import { generateSample, getJob } from 'src/modules/actions/bgsounds.actions';
import { useDispatch } from 'react-redux';
import { saveSampleId } from 'src/modules/actions/bgsounds.actions';
import { useSelector } from 'react-redux';
import { isAuthenticated } from 'src/modules/api/cookie.api';
import * as cookiesApi from 'src/modules/api/cookie.api';
import { API_URL } from 'src/config/environment';

export default ({
                  htmlFragment,
                  innerDelay,
                  chapterUuid,
                  narratorUuid,
                  bookUuid

                }) => {
  const dispatch = useDispatch();
  const [play, setPlay] = React.useState(false);
  const [jobId, setJobId] = React.useState();
  const [sampleId, setSampleId] = React.useState();
  const audioRef = React.useRef();
  const [generating, setGenerating] = React.useState(false);
  const [html, setHtml] = React.useState(htmlFragment);

  const generatedSamples = useSelector(state => state.bgsounds.generatedSamples);
  const samplesCache = React.useMemo(() => generatedSamples || {}, [generatedSamples]);

  const findSampleIdInCache = React.useCallback(() => {
    return samplesCache && html && samplesCache[hashCode(html + narratorUuid)];
  }, [html, samplesCache, narratorUuid]);

  const genSample = React.useCallback(() => {
    setGenerating(true);
    generateSample({bookUuid, chapterUuid, narratorUuid, htmlFragment: html}).then(res => setJobId(res.data?.id));
  }, [html, chapterUuid, narratorUuid, bookUuid]);

  const cleanUp = React.useCallback(() => {
    setPlay(false);
    setSampleId(undefined);
  }, []);

  React.useEffect(() => {
    if (html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const body = doc.getElementsByTagName('sound');
      const sound = body[0];
      if (sound) {
        sound.setAttribute('delay', '' + innerDelay);
        setHtml(new XMLSerializer().serializeToString(sound));
        cleanUp();
      }
    }
  }, [html, innerDelay, cleanUp]);



  // 1
  React.useEffect(() => {
    if (play && !sampleId) {
      const sampleId_ = findSampleIdInCache();
      if (sampleId_) {
        setSampleId(sampleId_);
      } else {
        genSample();
      }
    }
  }, [play, sampleId, findSampleIdInCache, genSample]);

  // 2
  React.useEffect(() => {
    let handle;
    if (jobId && play) {
      function repeat() {
        getJob(jobId).then(res => {
          const { id, state } = res.data;
          if (state === 'inProgress') {
            handle = setTimeout(repeat, 1000);
          } else if (state === 'completed') {
            if (handle) {
              clearTimeout(handle);
            }
            setGenerating(false);
            setSampleId(id);
            setJobId(undefined);
            dispatch(saveSampleId(hashCode(html + narratorUuid), id));
          } else { // notStarted, failed
            cleanUp();
          }
        })
      }

      repeat();
    }

    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    }
  }, [jobId, play, html, cleanUp, dispatch, narratorUuid]);

  // 3
  React.useEffect(() => {
    if (sampleId) {
      if (play) {
        const withToken = isAuthenticated() ? `?AuthToken=${cookiesApi.getAuthToken()}` : '';
        audioRef.current = new Audio(`${API_URL}/samples/${sampleId}${withToken}`);
        audioRef.current.preload = 'metadata';
        audioRef.current.autoplay = false;
        const promise = audioRef.current.play();
        if (promise !== undefined) {
          promise.catch(_ => { /* do nothing */ }).then(() => { /* do nothing */ });
        }
      } else if (audioRef.current) {
        audioRef.current.pause();
      }
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }
  }, [sampleId, play, audioRef]);

  React.useEffect(() => {
    if (!play) {
      setGenerating(false);
    }
  }, [play]);

  return {
    play,
    setPlay,
    generating
  }
}
