import React from 'react';
import PropTypes from 'prop-types';
import { SkipBackFilled32, Rewind_3032, PlayFilledAlt32, PauseFilled32, Forward_3032, SkipForwardFilled32 } from '@carbon/icons-react';
import classNames from 'classnames';

import cs from './AudioPlayer.module.scss';
import * as utils from './chapters.util';
import { isChapterAvailable } from 'src/modules/model/book';
import BuyAndShare from 'src/components/_shared/BuyAndShare';

const AudioPlayer = ({
                       currentChapter, wouldStartPlaying, playing,
                       disabled, book, playlist,
                       fetchShareState, putBookShared }) => {
  const chapters = book?.chapters;
  const chapterId = utils.findCurrentChapter(book, currentChapter);
  const chapter = chapterId && playlist?.find?.(c => c.uuid === chapterId);
  const chapterAvailable = isChapterAvailable(book, chapter);
  const [rewindDisabled, setRewindDisabled] = React.useState(true);
  const [forwardDisabled, setForwardDisabled] = React.useState(true);

  const controlClasses = React.useMemo(() => {
    return {
      skipBackClass: classNames({ [cs.disabled]: chapters?.[0]?.uuid === chapterId || disabled }),
      rewindClass: classNames({ [cs.disabled]: disabled || rewindDisabled }),
      playClass: classNames({ [cs.disabled]: disabled || !chapterId }),
      forwardClass: classNames({ [cs.disabled]: disabled || forwardDisabled }),
      skipForwardClass: classNames({ [cs.disabled]: disabled || (chapters?.length > 0 && chapters[chapters.length -1].uuid === chapterId) })
    }
  }, [chapters, chapterId, disabled, rewindDisabled, forwardDisabled])

  const _play = () => {
    if (!disabled && chapter) {
      wouldStartPlaying(chapter);
    }
  }

  const _pause = () => {
    if (playing) {
      wouldStartPlaying(chapter);
    }
  }

  const _skipBack = () => {
    if (!disabled) {
      const backChapter = utils.getPrevChapterObject(chapters, chapterId);
      if (backChapter) {
        wouldStartPlaying(backChapter);
      }
    }
  }

  const _skipForward = () => {
    if (!disabled) {
      const nextChapter = utils.getNextChapterObject(chapters, chapterId);
      if (nextChapter) {
        wouldStartPlaying(nextChapter);
      }
    }
  }

  const _rewind30 = () => {
    if (playing && !rewindDisabled) {
      const playingChapter = playlist.find(c => c.uuid === currentChapter);
      if (playingChapter) {
        playingChapter.howl.rewind30();
      }
    }
  }

  const _forward30 = () => {
    if (playing && !forwardDisabled) {
      const playingChapter = playlist.find(c => c.uuid === currentChapter);
      if (playingChapter) {
        playingChapter.howl.forward30();
      }
    }
  }

  React.useEffect(() => {
    let timeHandler;

    function pos(trk) {
      const howl = trk.howl;
      let _seek = howl?.seek() ?? 0;
      if (typeof _seek === 'number') {
        _seek = Math.floor(_seek);
        setForwardDisabled(_seek + 30 > trk.audio.duration);
        setRewindDisabled(_seek - 30 < 0);
      }
      timeHandler = setTimeout(() => pos(trk), 1000);
    }


    if (currentChapter && playing) {
      const trk = playlist.find(t => t.uuid === currentChapter);
      if (trk && trk.howl) {
        pos(trk);
      }
    }

    return () => {
      if (timeHandler) {
        clearTimeout(timeHandler);
      }
    }
  }, [playing, currentChapter, playlist]);

  return (
    <div className={cs.player}>
      { chapterAvailable ?
        <div className={cs.controls}>
          <SkipBackFilled32 className={controlClasses.skipBackClass} onClick={_skipBack}/>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Rewind_3032 className={controlClasses.rewindClass} onClick={_rewind30}/>
          {playing && !disabled ?
            <PauseFilled32 onClick={_pause}/> :
            <PlayFilledAlt32 className={controlClasses.playClass} onClick={_play}/>
          }
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Forward_3032 className={controlClasses.forwardClass} onClick={_forward30}/>
          <SkipForwardFilled32 className={controlClasses.skipForwardClass} onClick={_skipForward}/>
        </div> :
        <div className={cs.unavailable}>
          {book && chapter &&
            <BuyAndShare putBookShared={putBookShared}
                         fetchShareState={fetchShareState}
                         buyButtonContainerClass={cs.buyButton}
                         shareContainerClass={cs.shareContainerClass}
                         shareBlockClass={cs.shareBlock}
                         authReqBlockClass={cs.authBlock}
                         book={book}
                         chapter={chapter}
                         authReqTheme={'dark'}
            />
          }
        </div>
      }
    </div>
  )
}

AudioPlayer.propTypes = {
  wouldStartPlaying: PropTypes.func,
  currentChapter: PropTypes.string,
  disabled: PropTypes.bool,
  playing: PropTypes.bool,
  book: PropTypes.shape({
    chapters: PropTypes.array,
    progress: PropTypes.shape({
      progress: PropTypes.number,
      chapter: PropTypes.string
    })
  }),
  fetchShareState: PropTypes.func.isRequired,
  putBookShared: PropTypes.func.isRequired
}

export default AudioPlayer;
