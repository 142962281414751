import axios from 'axios';

import { REQUEST_LIMIT } from 'src/components/CompetitionPage/constants';
import { getAuthHeader } from 'src/modules/api/utils';

export default async (tag, sort, offset, limit = REQUEST_LIMIT) => {
  try {
    const response = await axios.get(`/tags/books?tag=${tag}&sort=${sort}&limit=${limit}&offset=${offset}`, getAuthHeader());
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
}
