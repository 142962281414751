import React from 'react';
import PropTypes from 'prop-types';
import { Tile } from 'carbon-components-react';

import cs from './JobImport.module.scss';
import JobUpload from 'src/modules/model/job-upload';

function readStatus(job) {
  switch (job.state) {
    case JobUpload.state.IN_PROGRESS: return 'Processing'
    case JobUpload.state.UPLOADING: return 'Uploading'
    default: return job.state
  }
}

const JOB_CHECK_INTERVAL = 300;

const JobImport = ({ job, checkJob }) => {

  React.useEffect(() => {
    let tt;
    function check() {
      if (tt) clearTimeout(tt);
      checkJob(job.id);
      tt = setTimeout(check, JOB_CHECK_INTERVAL);
    }

    if (job.state === JobUpload.state.IN_PROGRESS) {
      check();
    }

    return () => {
      if (tt) clearTimeout(tt);
    }
  }, [job.id, job.state, checkJob])

  return (
    <Tile className={cs.jobCard}>
      <div className={cs.content}>
        <div className={cs.title}>Epub Import</div>
        <div className={cs.state}>{readStatus(job)}</div>
        <div className={cs.progress}>
          {job.progress >=  0.002 && <div style={{width: `${job.progress * 100}%`}}/>}
        </div>
      </div>
    </Tile>
  )
}

JobImport.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string.isRequired,
    progress: PropTypes.number,
    state: PropTypes.string.isRequired
  }).isRequired,
  checkJob: PropTypes.func.isRequired
}

export default JobImport;
