import { ACTION_TYPES as AUTH_TYPES } from 'src/modules/reducers/authentication.reducer';

export const ACTION_TYPES = {
  FETCH_HOME_SCREEN_DATA_PENDING: 'home/FETCH_HOME_SCREEN_DATA_PENDING',
  FETCH_HOME_SCREEN_DATA_FULFILLED: 'home/FETCH_HOME_SCREEN_DATA_SUCCESS',
  FETCH_HOME_SCREEN_DATA_REJECTED: 'home/FETCH_HOME_SCREEN_DATA_REJECT',

  FETCH_CATEGORY_PENDING: 'home/FETCH_CATEGORY_PENDING',
  FETCH_CATEGORY_FULFILLED: 'home/FETCH_CATEGORY_FULFILLED',
  FETCH_CATEGORY_REJECTED: 'home/FETCH_CATEGORY_REJECTED',

  CLEANUP_CATEGORY: 'home/CLEANUP_CATEGORY'
};

const defaultState = {
  loading: false,
  continueReading: [],

  books: [],
  categoryName: '',

  banners: [],
  content: [],
  tags: {name:"", tags: []},
  reloadHomeScreen: false
};

function fetchCategory(state, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CATEGORY_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_CATEGORY_FULFILLED:
      return {
        ...state,
        loading: false,
        books: action.books,
        categoryName: action.categoryName
      };
    case ACTION_TYPES.FETCH_CATEGORY_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_HOME_SCREEN_DATA_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_HOME_SCREEN_DATA_FULFILLED:
      return {
        ...state,
        loading: false,
        continueReading: action.continueReading,
        banners: action.banners,
        content: action.content,
        tags: action.tags,
        reloadHomeScreen: false
      };
    case ACTION_TYPES.FETCH_HOME_SCREEN_DATA_REJECTED:
      return {
        ...state,
        loading: false,
        reloadHomeScreen: false
      };
    case ACTION_TYPES.FETCH_CATEGORY_PENDING:
    case ACTION_TYPES.FETCH_CATEGORY_FULFILLED:
    case ACTION_TYPES.FETCH_CATEGORY_REJECTED:
      return fetchCategory(state, action);
    case ACTION_TYPES.CLEANUP_CATEGORY:
      return {
        ...state,
        books: [],
        categoryName: ''
      };
    case AUTH_TYPES.CLEAR_AUTH:
    case AUTH_TYPES.LOGIN_ACCOUNT_FULFILLED:
      return {
        ...state,
        continueReading: [],
        content: [],
        reloadHomeScreen: true
      };
    default:
      return state;
  }
}
