import React, { useState, useEffect, useRef } from 'react';
import cs from './Recorder.module.scss';
import { CloseIcon } from './icons/CloseIcon';
import { RecorderStartIcon } from './icons/RecorderStartIcon';
import { RecorderStopIcon } from './icons/RecorderStopIcon';
import classNames from 'classnames';
import { formatDuration } from 'src/utils/duration';
import { recordAudio } from './recordAudio';
import PropTypes from 'prop-types';
import { Loading } from 'carbon-components-react';

const Recorder = ({ onRecord, onClose, isLoading = false }) => {
  const [recording, setRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const recorderRef = useRef(null);

  useEffect(() => {
    let intervalHandle = undefined;

    if (recording) {
      intervalHandle = setInterval(() => {
        setDuration(o => o + 1);
      }, 1000);
    }

    return () => {
      if (intervalHandle) {
        clearInterval(intervalHandle);
      }
    }
  }, [recording, setDuration]);

  const handleRecord = async () => {
    try {

      if (recording) {
        const recorder = recorderRef.current;
        const audio = await recorder.stop();
        onRecord(audio);
      } else {
        recorderRef.current = await recordAudio();
        recorderRef.current.start();
      }

      setRecording(!recording);

    } catch (error) {
      // do nothing
    }
  }

  return (
    <div className={classNames(cs.recorder, { [cs.recording]: recording && !isLoading })}>

      <div className={cs.close} onClick={onClose}>
        {!recording && <CloseIcon/>}
      </div>

      {
        isLoading ? (
          <div className={cs.button}>
            <div />
            <div><Loading withOverlay={false} small/></div>
            <div />
          </div>
        ) : (
          <div className={cs.button}>
            <div>
              {recording && <span style={{position: 'absolute'}}>{formatDuration(duration)}</span>}
            </div>
            <div onClick={handleRecord}>
              {recording ? <RecorderStopIcon /> : <RecorderStartIcon/>}
            </div>
            <div />
          </div>
        )
      }

      <div className={cs.hint}>
        {isLoading ?  'Uploading...' : recording ? 'Recording...' : 'Click the button to start recording'}
      </div>
    </div>
  )
}

Recorder.propTypes = {
  onRecord: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export { Recorder };
