export const COUNTRY_OPTIONS = 
[
  { id: "AG", labelText: "🇦🇬 Antigua & Barbuda", value: "AG"},
  { id: "AR", labelText: "🇦🇷 Argentina", value: "AR"},
  { id: "AM", labelText: "🇦🇲 Armenia", value: "AM"},
  { id: "AU", labelText: "🇦🇺 Australia", value: "AU"},
  { id: "AT", labelText: "🇦🇹 Austria", value: "AT"},
  { id: "AZ", labelText: "🇦🇿 Azerbaijan", value: "AZ"},
  { id: "BH", labelText: "🇧🇭 Bahrain", value: "BH"},
  { id: "BD", labelText: "🇧🇩 Bangladesh", value: "BD"},
  { id: "BE", labelText: "🇧🇪 Belgium", value: "BE"},
  { id: "BJ", labelText: "🇧🇯 Benin", value: "BJ"},
  { id: "BT", labelText: "🇧🇹 Bhutan", value: "BT"},
  { id: "BO", labelText: "🇧🇴 Bolivia", value: "BO"},
  { id: "BA", labelText: "🇧🇦 Bosnia & Herzegovina", value: "BA"},
  { id: "BN", labelText: "🇧🇳 Brunei", value: "BN"},
  { id: "BG", labelText: "🇧🇬 Bulgaria", value: "BG"},
  { id: "CA", labelText: "🇨🇦 Canada", value: "CA"},
  { id: "CL", labelText: "🇨🇱 Chile", value: "CL"},
  { id: "CO", labelText: "🇨🇴 Colombia", value: "CO"},
  { id: "CR", labelText: "🇨🇷 Costa Rica", value: "CR"},
  { id: "CI", labelText: "🇨🇮 Côte d’Ivoire", value: "CI"},
  { id: "HR", labelText: "🇭🇷 Croatia*", value: "HR"},
  { id: "CY", labelText: "🇨🇾 Cyprus", value: "CY"},
  { id: "CZ", labelText: "🇨🇿 Czech Republic*", value: "CZ"},
  { id: "DK", labelText: "🇩🇰 Denmark", value: "DK"},
  { id: "DO", labelText: "🇩🇴 Dominican Republic", value: "DO"},
  { id: "EC", labelText: "🇪🇨 Ecuador", value: "EC"},
  { id: "EG", labelText: "🇪🇬 Egypt", value: "EG"},
  { id: "SV", labelText: "🇸🇻 El Salvador", value: "SV"},
  { id: "EE", labelText: "🇪🇪 Estonia", value: "EE"},
  { id: "FI", labelText: "🇫🇮 Finland", value: "FI"},
  { id: "FR", labelText: "🇫🇷 France", value: "FR"},
  { id: "GM", labelText: "🇬🇲 Gambia", value: "GM"},
  { id: "DE", labelText: "🇩🇪 Germany", value: "DE"},
  { id: "GH", labelText: "🇬🇭 Ghana", value: "GH"},
  { id: "GR", labelText: "🇬🇷 Greece", value: "GR"},
  { id: "GT", labelText: "🇬🇹 Guatemala", value: "GT"},
  { id: "GY", labelText: "🇬🇾 Guyana", value: "GY"},
  { id: "HK", labelText: "🇭🇰 Hong Kong", value: "HK"},
  { id: "HU", labelText: "🇭🇺 Hungary", value: "HU"},
  { id: "IS", labelText: "🇮🇸 Iceland*", value: "IS"},
  { id: "IN", labelText: "🇮🇳 India", value: "IN"},
  { id: "ID", labelText: "🇮🇩 Indonesia", value: "ID"},
  { id: "IE", labelText: "🇮🇪 Ireland", value: "IE"},
  { id: "IL", labelText: "🇮🇱 Israel", value: "IL"},
  { id: "IT", labelText: "🇮🇹 Italy", value: "IT"},
  { id: "JM", labelText: "🇯🇲 Jamaica", value: "JM"},
  { id: "JP", labelText: "🇯🇵 Japan", value: "JP"},
  { id: "KE", labelText: "🇰🇪 Kenya", value: "KE"},
  { id: "KW", labelText: "🇰🇼 Kuwait", value: "KW"},
  { id: "LV", labelText: "🇱🇻 Latvia", value: "LV"},
  { id: "LI", labelText: "🇱🇮 Liechtenstein*", value: "LI"},
  { id: "LT", labelText: "🇱🇹 Lithuania", value: "LT"},
  { id: "LU", labelText: "🇱🇺 Luxembourg", value: "LU"},
  { id: "MY", labelText: "🇲🇾 Malaysia", value: "MY"},
  { id: "MT", labelText: "🇲🇹 Malta", value: "MT"},
  { id: "MU", labelText: "🇲🇺 Mauritius", value: "MU"},
  { id: "MX", labelText: "🇲🇽 Mexico", value: "MX"},
  { id: "MD", labelText: "🇲🇩 Moldova", value: "MD"},
  { id: "MC", labelText: "🇲🇨 Monaco", value: "MC"},
  { id: "MA", labelText: "🇲🇦 Morocco", value: "MA"},
  { id: "NA", labelText: "🇳🇦 Namibia", value: "NA"},
  { id: "NL", labelText: "🇳🇱 Netherlands", value: "NL"},
  { id: "NZ", labelText: "🇳🇿 New Zealand", value: "NZ"},
  { id: "NE", labelText: "🇳🇪 Niger", value: "NE"},
  { id: "MK", labelText: "🇲🇰 North Macedonia", value: "MK"},
  { id: "NO", labelText: "🇳🇴 Norway", value: "NO"},
  { id: "OM", labelText: "🇴🇲 Oman", value: "OM"},
  { id: "PA", labelText: "🇵🇦 Panama", value: "PA"},
  { id: "PY", labelText: "🇵🇾 Paraguay", value: "PY"},
  { id: "PE", labelText: "🇵🇪 Peru", value: "PE"},
  { id: "PH", labelText: "🇵🇭 Philippines", value: "PH"},
  { id: "PL", labelText: "🇵🇱 Poland", value: "PL"},
  { id: "PT", labelText: "🇵🇹 Portugal", value: "PT"},
  { id: "QA", labelText: "🇶🇦 Qatar", value: "QA"},
  { id: "RO", labelText: "🇷🇴 Romania", value: "RO"},
  { id: "SM", labelText: "🇸🇲 San Marino", value: "SM"},
  { id: "SA", labelText: "🇸🇦 Saudi Arabia", value: "SA"},
  { id: "SN", labelText: "🇸🇳 Senegal", value: "SN"},
  { id: "RS", labelText: "🇷🇸 Serbia", value: "RS"},
  { id: "SG", labelText: "🇸🇬 Singapore", value: "SG"},
  { id: "SK", labelText: "🇸🇰 Slovakia", value: "SK"},
  { id: "SI", labelText: "🇸🇮 Slovenia", value: "SI"},
  { id: "ZA", labelText: "🇿🇦 South Africa", value: "ZA"},
  { id: "KR", labelText: "🇰🇷 South Korea", value: "KR"},
  { id: "ES", labelText: "🇪🇸 Spain", value: "ES"},
  { id: "LC", labelText: "🇱🇨 St. Lucia", value: "LC"},
  { id: "SE", labelText: "🇸🇪 Sweden", value: "SE"},
  { id: "CH", labelText: "🇨🇭 Switzerland*", value: "CH"},
  { id: "TH", labelText: "🇹🇭 Thailand", value: "TH"},
  { id: "TT", labelText: "🇹🇹 Trinidad & Tobago", value: "TT"},
  { id: "TN", labelText: "🇹🇳 Tunisia", value: "TN"},
  { id: "TR", labelText: "🇹🇷 Turkey", value: "TR"},
  { id: "AE", labelText: "🇦🇪 United Arab Emirates", value: "AE"},
  { id: "GB", labelText: "🇬🇧 United Kingdom", value: "GB"},
  { id: "US", labelText: "🇺🇸 United States", value: "US"},
  { id: "UY", labelText: "🇺🇾 Uruguay", value: "UY"},
]
