import React, {
  useEffect, useCallback, useState
  } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppLayout from 'src/layout/AppLayout';
import PageHeader from 'src/components/_shared/PageHeader';
import Content from 'src/components/_shared/Content';
import Href from 'src/components/_shared/Href';
import Item from './Item';
import { Loading } from 'carbon-components-react';

import cn from 'classnames';
import cx from './Blog.module.scss';
import { fetchBlog, fetchBlogNextPage } from 'src/modules/actions/blog.actions';
import { Helmet } from 'react-helmet';

const scrollDiff = 150

export const LOADING_STATES = {
  NONE: 0,
  STARTED: 1,
  LOADING: 2,
};

export const Blog = props => {
  const { match, id, loading, content, lastPage, ...actions } = props;
  const { fetchBlog, fetchBlogNextPage } = actions;
  const [loadingState, setLoadingState] = useState(LOADING_STATES.NONE);

  if(loading && loadingState === LOADING_STATES.STARTED) {
    setLoadingState(LOADING_STATES.LOADING)
  } else if(loadingState === LOADING_STATES.LOADING) {
    setLoadingState(LOADING_STATES.NONE)
  }

  useEffect(() => {
    fetchBlog();
  }, [fetchBlog, match]);

  const didScroll = useCallback((e) => {
    const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop < e.target.scrollingElement.clientHeight + scrollDiff;
    if(bottom && !loading && !lastPage && loadingState === LOADING_STATES.NONE){
      setLoadingState(LOADING_STATES.STARTED)
      fetchBlogNextPage(content?.items?.length ?? 0)
    }
  }, [fetchBlogNextPage, loading, lastPage, content, loadingState]);

  useEffect( () => {
    window.addEventListener('scroll', didScroll);
    return () => {
      window.removeEventListener('scroll', didScroll);
    }
  }, [didScroll]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Blog about book" />
        <meta property="og:image" content="header.jpg"/>
      </Helmet>
      <AppLayout theme={props.theme}>
        <PageHeader theme={props.theme} name="Blog about books" image="header.jpg"/>
        <Content theme={props.theme}>
        {
          content.items.map((item, i) =>
            <Href href={`/blog/${item.id}`}>
              <div className={cn(cx.item)}><Item key={i} object={item} theme={props.theme}/></div>
            </Href>
          )
        }
        {loading && <Loading key="bottomProgress" style={{margin: "0 auto"}} active={loading} withOverlay={false}/>}
        </Content>
      </AppLayout>
    </>
  )
};

Blog.propTypes = {
  fetchBlog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    ...state.blog,
    ...state.theme
  }
}

function mapDispatchToProps(dispatch) {
return bindActionCreators({ fetchBlog, fetchBlogNextPage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
