import axios from 'axios';
import * as ls from './local-storage.api';
import { getAuthHeader } from 'src/modules/api/utils';

export async function postBook(book) {
  const account = ls.getAccount();
  if (account) {
    const writerUuid = account.uuid;
    try {
      return await axios.post(`/writers/${writerUuid}/books`, book, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  }
  return {}
}

export async function getBooks() {
  const account = ls.getAccount();
  if (account) {
    const writerUuid = account.uuid;
    try {
      return await axios.get(`/writers/${writerUuid}/books`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  } else return {};
}

export async function getWriter(uuid) {
  try {
    return await axios.get(`/writers/${uuid}`, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

export async function follow(uuid) {
  try {
    return await axios.post(`/writers/${uuid}/follow`, {}, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

export async function unfollow(uuid) {
  try {
    return await axios.delete(`/writers/${uuid}/follow`, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

export async function getJobStatus(uuid) {
  try {
    return await axios.get(`/jobs/${uuid}`, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

export async function postWriterEmail(writerUuid, email) {
  const account = ls.getAccount();
  if (account) {
    try {
      return await axios.post(`/writers/${writerUuid}/email`, email, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  }
  return {}
}


export async function getStatistics(bookUUID, chapterUUID) {
  const account = ls.getAccount();
  if (account) {
    try {
      return await axios.get( bookUUID ? `me/writer/statistics?bookUUID=${bookUUID}&chapterUUID=${chapterUUID}` : `me/writer/statistics`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  }
  return {}
}

export async function getReferralInfo() {
  const account = ls.getAccount();
  if (account) {
    try {
      return await axios.get(`me/writer/referrals`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  }
  return {}
}