export const ACTION_TYPES = {
  FETCH_TAGS_HOME_PENDING: 'tags/FETCH_TAGS_HOME_PENDING',
  FETCH_TAGS_HOME_FULFILLED: 'tags/FETCH_TAGS_HOME_FULFILLED',
  FETCH_TAGS_HOME_REJECTED: 'tags/FETCH_TAGS_HOME_REJECTED',
  FETCH_TAGS_HOME_ALL_PENDING: 'tags/FETCH_TAGS_HOME_ALL_PENDING',
  FETCH_TAGS_HOME_ALL_FULFILLED: 'tags/FETCH_TAGS_HOME_ALL_FULFILLED',
  FETCH_TAGS_HOME_NEW_PAGE_PENDING: 'tags/FETCH_TAGS_HOME_NEW_PAGE_PENDING',
  FETCH_TAGS_HOME_NEW_PAGE_FULFILLED: 'tags/FETCH_TAGS_HOME_NEW_PAGE_FULFILLED',
};

const defaultState = {
  loading: false,
  pageLoading: false,
  content: { name: "", 
             image:"path/to/image", 
             newBooks: 24,
             content: [],
            books: []
          },
  lastPage: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TAGS_HOME_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_FULFILLED:
      return {
        ...state,
        loading: false,
        content: action.data
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_REJECTED:
      return {
        ...state,
        loading: false,
        pageLoading: false
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_ALL_PENDING:
      return {
        ...state,
        pageLoading: true,
        content: {
          ...state.content,
          books: []
        }
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_ALL_FULFILLED:
      return {
        ...state,
        pageLoading: false,
        content: {
          ...state.content,
          books: action.data.books
        },
        lastPage: action.data.books.length === 0
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_NEW_PAGE_PENDING:
      return {
        ...state,
        pageLoading: true,
        content: {
          ...state.content
        }
      };
    case ACTION_TYPES.FETCH_TAGS_HOME_NEW_PAGE_FULFILLED:
      return {
        ...state,
        pageLoading: false,
        content: {
          ...state.content,
          books: state.content.books.concat(action.data.books)
        },
        lastPage: action.data.books.length === 0
      };
    default:
      return state;
  }
}