import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'
import { throttle } from 'src/utils';

export async function getChapter(uuid) {
  try {
    return await axios.get(`/chapters/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function putChapter(uuid, chapter) {
  try {
    return await axios.put(`/chapters/${uuid}`, chapter, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export function postChapterAudioFile(uuid, payload, onProgressUpdate) {
  const abortController = new AbortController();

  const promise = (async function() {
    const onUploadProgress = throttle(
      ({ loaded, total }) => onProgressUpdate(Math.round(loaded / total * 100)),
      500
    );

    try {
      return await axios.post(`/chapters/${uuid}/narration/upload`, payload, {
        ...getAuthHeader(),
        signal: abortController.signal,
        onUploadProgress,
      });
    } catch (error) {
      return error.response;
    }
  })()

  return { abortController, promise };
}

export async function deleteChapter(uuid) {
  try {
    return await axios.delete(`/chapters/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function generateChapterAudio(uuid, forced = false) {
  try {
    return await axios.post(`/chapters/${uuid}/mp3/generate`, {forced}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export function getChapterBlob(uuid, type) {
  return axios({
    url: `/chapters/${uuid}/${type}`,
    headers: getAuthHeader().headers,
    method: 'GET',
    responseType: 'blob'
  });
}

export function chapterBlobToArrayBuffer(data) {
  const reader = new FileReader();

  const promise = new Promise(resolve => {
    reader.onload = function(e) {
      resolve(e.target.result);
    };
  });

  reader.readAsArrayBuffer(new Blob([data]));
  return promise;
}

export async function getChapterText(uuid) {
  const response = await getChapterBlob(uuid, 'html');
  const reader = new FileReader();
  const promise = new Promise(resolve => {
    reader.onload = function(e) {
      resolve(e.target.result);
    };
  });
  reader.readAsText(new Blob([response.data]));
  return promise;
}
