import React from 'react';

import cx from './styles.module.scss';
import cn from 'classnames';
import history from 'src/history';
import Bookmark from 'src/components/_shared/Book/Bookmark';
import { RES_PATH } from 'src/config/environment';
import Like from 'src/components/_shared/Like';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

const BookCellWide = ({ book, ...props }) => {
  const [likes, setLikes] = React.useState(book.book?.likes);
  const [liked, setLiked] = React.useState(book.book?.liked);


  const bookURL = () => {
    return `/books/${book.book.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };

  return (
    <div className={cn(cx.bookCell)}
         onMouseDown={onMouseDown}
         onMouseUp={onMouseUp(goBook)}
    >
      <div className={cn(cx.imageContainer)} style={{ backgroundImage: `url(${RES_PATH}${book.book.image})` }} />
      <div className={cn(cx.content)}>
        {book.book.tags.length > 0 ? <div className={cn(cx.genres)}>{book.book.tags.map(tag => tag.toUpperCase()).join(' | ')}</div> : <></>}
        <h4 className={cn(cx.name)}>{book.book.name}</h4>
        <div className={cn(cx.writer)}>by {book.author.name}</div>
        <div className={cn(cx.bottomContainer)}>
          <div className={cn(cx.likes)}>
            <Like {...book.book}
              size='xs'
              showLikes={true}
              style={{ alignItems: 'flex-start' }}
              likesChanged={setLikes}
              likedChanged={setLiked}
              likes={likes}
              liked={liked}
              likesFirst={false}
            />
          </div>
          <div className={cn(cx.bookmark)}><Bookmark uuid={book.book.uuid} size="16"/></div>
        </div>
      </div>
    </div>
  )
};

BookCellWide.propTypes = {
};

export default BookCellWide;