import React from 'react';
import { TextArea, FormGroup } from 'carbon-components-react';

import styles from './styles.module.scss';

export const InputArea = ({ input, meta, placeholder, disabled = false }) => {

  return (
    <FormGroup legendText=''>
      <TextArea
        {...input}
        invalid={meta.touched && meta.error && true}
        invalidText={meta.error}
        placeholder={placeholder}
        rows={4}
        className={styles.wide}
        labelText=''
        disabled={disabled}
      />
    </FormGroup>
  )
};

export default InputArea;
