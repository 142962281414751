import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import cs from './styles.module.scss';

const SectionName = ({ name, children }) => {
  
  return (
    <div className={cn('bx--row', cs.section)}>
    <div className='bx--col'>
      <h3 style={{ marginRight: '1.5rem'}}>{name}</h3>
      {children}
    </div>
  </div>
  );
}

SectionName.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element
};

export default SectionName;
