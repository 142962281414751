import React from 'react';

export const PlayerPlayIcon = (width=56,height=56) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#6340EF"/>
      <path d="M39.2616 30.262C39.1486 30.378 38.7219 30.874 38.3244 31.282C35.9942 33.848 29.9152 38.048 26.7336 39.33C26.2504 39.536 25.0287 39.972 24.376 40C23.7506 40 23.1544 39.856 22.5855 39.564C21.8763 39.156 21.3074 38.514 20.9956 37.756C20.7949 37.23 20.4832 35.656 20.4832 35.628C20.1715 33.906 20 31.108 20 28.016C20 25.07 20.1715 22.386 20.4267 20.638C20.4559 20.61 20.7677 18.654 21.1086 17.984C21.734 16.76 22.9557 16 24.263 16H24.376C25.2275 16.03 27.018 16.79 27.018 16.818C30.0283 18.102 35.9669 22.096 38.3536 24.75C38.3536 24.75 39.0258 25.432 39.3181 25.858C39.774 26.47 40 27.228 40 27.986C40 28.832 39.7448 29.62 39.2616 30.262Z" fill="white"/>
    </svg>
  )
}
