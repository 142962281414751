import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tile } from 'carbon-components-react';
import { Link } from 'react-router-dom';

import classes from './layout.module.scss';
import {authenticate, fbAuthenticate, hideMessages} from 'src/modules/actions/authentication.actions';

export const Layout = ({ brand, title, message, messageKind, messageTitle, hideMessages, children }) => (
  <>

    <h4 className={classes.brand}>
      <Link to={'/'}>
        {brand}
        </Link>
    </h4>


    <Tile className={classes.card}>
      <h2>{title}</h2>

      <div className={classes.form}>
        {children({ message, messageKind, messageTitle, hideMessages })}
      </div>
    </Tile>
  </>
);

function mapStateToProps(state) {
  return {
    message: state.authentication.message,
    messageKind: state.authentication.messageKind,
    messageTitle: state.authentication.messageTitle
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ authenticate, fbAuthenticate, hideMessages }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
