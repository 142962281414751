import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.scss';
import { useResizeEffect } from 'src/hooks'
import { fetchHomeScreenData } from 'src/modules/actions/home.actions';
import cn from 'classnames';
import cs from './MainPage.module.scss';
import AppLayout from 'src/layout/AppLayout';
import ContinueReadingItem from 'src/components/MainPage/ContinueReadingItem';
import NewThisWeekItem from 'src/components/MainPage/NewThisWeekItem';
import PublicBookCard from 'src/components/_shared/PublicBookCard';
import SectionName from 'src/components/_shared/DesignV2/SectionName';
import HorizontalItemList from 'src/components/MainPage/HorizontalItemList';
import MarketingCover from 'src/layout/MarketingCover';
import { MARKETING_COVER_ENABLED } from 'src/config/constants';
import { Loading } from 'carbon-components-react';
import Section from 'src/components/_shared/Tags';

SectionName.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element
};

const MainPage = ({ loading, content, continueReading, banners, tags, reloadHomeScreen, ...actions }) => {

  const grid = useRef(null);
  const [gridWidth, setGridWidth] = useState(0);
  const resizeListener = () => {
    if (grid && grid.current) {
      const lrpad = window.innerWidth <= 671 ? 32 : 64;
      setGridWidth(grid.current.offsetWidth - lrpad);
    }
  };

  useResizeEffect(resizeListener);

  useEffect(() => {
    if (grid && grid.current) {
      resizeListener();
    }
  }, [grid]);

  const { fetchHomeScreenData } = actions;
  useEffect(() => {
    fetchHomeScreenData();
  }, [fetchHomeScreenData, reloadHomeScreen]);

  return (
    <>
      <Loading active={loading} withOverlay/>
      <AppLayout showBanners>
        {MARKETING_COVER_ENABLED === true ? <MarketingCover /> : loading ? null :
          <div className={cn('bx--grid', cs.content)} style={{marginBottom: '2rem'}} ref={grid}>
            <div className={cs.section}>
              <HorizontalItemList title='Continue Reading'
                                  pushTo='library'
                                  actionName='My Library'
                                  isMobileDevice={false}
                                  items={continueReading}
                                  width={325}
                                  height={147}
                                  screenWidth={gridWidth}>
                {(item) => (
                  <div className={cs.continueReadingItem}>
                    <ContinueReadingItem item={item} />
                  </div>
                )}
              </HorizontalItemList>
            </div>
            <div className={cs.section}>
              <Section pushTo={`/genres`} object={tags}/>
            </div>
            {content.map((contentItem, c_index) => {
              const { id, name, type, items} = contentItem;
              const listPossibleProps = {
                short: { width: 246, height: 480, renderFunction: (item) => (
                    <div className={cs.trendingItem}>
                      <PublicBookCard {...item} showAuthor/>
                    </div>
                  )
                },
                detail: { width: 537, height: 250, renderFunction: (item) => (
                    <div className={cs.newThisWeekItem}>
                      <NewThisWeekItem {...item} flexible={false}/>
                    </div>
                  )
                }
              }
              const listProps = listPossibleProps[type];
              return (
                <div key={c_index} className={cs.section}>
                  <HorizontalItemList title={name}
                                      pushTo={`/seeAll/${id}`}
                                      actionName='See all'
                                      isMobileDevice={false}
                                      items={items}
                                      width={listProps.width}
                                      height={listProps.height}
                                      screenWidth={gridWidth}
                                      key={c_index}
                  >
                    {listProps.renderFunction}
                  </HorizontalItemList>
                </div>
              )
            })}
          </div>
        }
    </AppLayout>
  </>
  );
};

MainPage.propTypes = {
  fetchHomeScreenData: PropTypes.func.isRequired,
  content: PropTypes.array,
  reloadHomeScreen: PropTypes.bool.isRequired
};

export default connect(
  store => ({
    loading: store.home.loading,
    continueReading: store.home.continueReading,
    content: store.home.content,
    tags: store.home.tags,
    reloadHomeScreen: store.home.reloadHomeScreen
  }),
  dispatch => bindActionCreators({
    fetchHomeScreenData
  }, dispatch)
)(MainPage);
