import { ACTION_TYPES } from 'src/modules/reducers/narration.reducer';
import * as narrationApi from 'src/modules/api/narration.api';
import * as utils from '../api/utils';

export const fetchOrCreateNarration = (chapterId, silent = false) => {
  return async dispatch => {
    if(!silent) {
      dispatch({ type: ACTION_TYPES.CREATE_NARRATION_PENDING });
    }
    const getResponse = await narrationApi.getNarration(chapterId);
    let narrationInfo = (getResponse.status === 200 && getResponse.data) ?? undefined;
    if (!narrationInfo) {
      const postResponse = await narrationApi.postNarration(chapterId);
      narrationInfo = (postResponse.status === 200 && postResponse.data) ?? undefined;
    }

    if (narrationInfo) {
      const payload = { ...narrationInfo, voices: narrationInfo.voices.map(voice => ({ ...voice, hasAudio: !!voice.source })) }
      dispatch({ type: ACTION_TYPES.CREATE_NARRATION_SUCCESS, payload });
    } else {
      dispatch({ type: ACTION_TYPES.CREATE_NARRATION_FAIL });
    }
  }
}

export const deleteNarration = (chapterId) => {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_NARRATION_PENDING });
    const response = await narrationApi.deleteNarration(chapterId);
    if (response.status !== 200) {
      dispatch({ type: ACTION_TYPES.DELETE_NARRATION_FAIL });
      return
    }
    dispatch({ type: ACTION_TYPES.DELETE_NARRATION_SUCCESS });
  }
}

export const uploadAudioFile = ({
                                  chapterId,
                                  narrationId,
                                  voiceId,
                                  audioBlob,
                                  audioUrl,
                                  file,
                                  setAbortController,
                                }) => {
  return async dispatch => {
    const fileName = file ? file.name : 'Captured voice';
    dispatch({ type: ACTION_TYPES.POST_AUDIO_FILE_PENDING, voiceId, fileName });

    const b64Sound = (await utils.toBase64(file ?? audioBlob)).split(',')[1];
    const payload = {
      info: {},
      file: {
        data: b64Sound,
        type: file ? 'mp3' : 'webm'
      }
    }

    const handleProgressUpdate = (percent) => dispatch({ type: ACTION_TYPES.POST_AUDIO_FILE_PENDING, percent, voiceId, fileName });
    const { abortController, promise } = narrationApi.postAudioFile(narrationId, voiceId, payload, handleProgressUpdate);

    if (typeof setAbortController === 'function') {
      setAbortController(abortController);
    }

    const response = await promise;

    if (typeof setAbortController === 'function') {
      setAbortController(null);
    }

    if (response?.status === 200) {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }

      dispatch({ type: ACTION_TYPES.POST_AUDIO_FILE_SUCCESS, voiceId });
      dispatch(fetchOrCreateNarration(chapterId));
    } else {
      dispatch({ type: ACTION_TYPES.POST_AUDIO_FILE_ABORT });
    }
  }
}

export const deleteAudio = (narrationId, voiceId) => {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_AUDIO_PENDING });
    const response = await narrationApi.deleteAudioFile(narrationId, voiceId);
    if (response.status === 200) {
      dispatch({ type: ACTION_TYPES.DELETE_AUDIO_SUCCESS, voiceId });
    } else {
      dispatch({ type: ACTION_TYPES.DELETE_AUDIO_FAIL });
    }
  }
}

export const generate = (narrationId) => {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.CREATE_NARRATION_SUCCESS, payload: {audioState: {state: 'inProgress', progress: 0.0}}});
    const response = await narrationApi.generate(narrationId);
    if (response.status === 200) {
      dispatch({ type: ACTION_TYPES.CREATE_NARRATION_SUCCESS, payload: {audioState: {state: 'inProgress', progress: 0.0}}});
    } else {
    }
  }
}
