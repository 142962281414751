import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'carbon-components-react';
import { Tag } from 'carbon-components-react';
import { VolumeUpFilled24, TextAlignCenter24 } from '@carbon/icons-react';

import cs from './ChapterItem.module.scss';
import history from 'src/history';
import { isNotFree, isChapterPriceTier, isFree, priceAmount } from 'src/modules/model/book';

export const ChapterItem = ({ book, uuid, name, free, buyClicked, ...rest }) => {

  return (
    <>
      <div className={cs.chapter}>
        <div className={cs.name}>
          {name}
        </div>

        { isNotFree(book) && free &&
          <Tag type='green' style={{ marginLeft: '1rem', marginRight: '0.25rem', whiteSpace: 'noWrap' }}>free</Tag>
        }

        <div style={{flexGrow: 1}} />

        { (free || isFree(book) || book.pricing.state === 'purchased' || rest.purchased) &&
          <>
            <Button size='small'
                    onClick={() => history.push(`/books/${book.uuid}/${uuid}`)}
                    kind='ghost'
                    className={cs.onChapterButton}
            >
              <TextAlignCenter24 />
            </Button>

            { book.narration === true &&
              <Button size='small'
                      kind='ghost'
                      onClick={() => history.push(`/listen/${book.uuid}/${uuid}`)}
                      className={cs.onChapterButton}
              >
                <VolumeUpFilled24/>
              </Button>
            }

            <div className={cs.pagesCount}>
              {rest.pagesCount}
            </div>
          </>
        }

        { !free && !rest.purchased && isChapterPriceTier(book) &&
          <Button size='small'
                  kind='ghost'
                  onClick={buyClicked}
                  className={cs.buyButton}
          >
            ${priceAmount(book)}
          </Button>
        }

      </div>
    </>
  )
};

ChapterItem.propTypes = {
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    purchased: PropTypes.bool.isRequired
  }).isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  filePath: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  free: PropTypes.bool.isRequired,
  charactersCount: PropTypes.number.isRequired,
  readCount: PropTypes.number.isRequired,
  purchased: PropTypes.bool,
  buyClicked: PropTypes.func.isRequired
};

export default ChapterItem;
