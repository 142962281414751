import axios from 'axios';

import { API_URL } from './environment';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = API_URL;

const setupAxiosInterceptors = (onUnauthenticated, onForbidden, instance = axios) => {
  const onRequestSuccess = config => {
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || err.response.status;
    if (status === 401) {
      onUnauthenticated();
    }
    if (status === 403) {
      onForbidden();
    }
    return Promise.reject(err);
  };
  instance.interceptors.request.use(onRequestSuccess);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
