import { ACTION_TYPES } from 'src/modules/reducers/application.reducer';
import STATUS from 'src/modules/model/status';

export const displayInfoMessage = infoMessage => ({ type: ACTION_TYPES.INFO_MESSAGE, infoMessage });

export const hideInfoMessage = () => displayInfoMessage(null);

export const fullScreenMode = on => ({ type: ACTION_TYPES.FULL_SCREEN_MODE, fullScreen: on });

export const setStatus = status => ({ type: ACTION_TYPES.STATUS, status });
export const notifyForbidden = () => setStatus(STATUS.FORBIDDEN);
export const clearStatus = () => ({ type: ACTION_TYPES.STATUS, status: null });
