import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

function preventAction(e) {
  e.preventDefault();
}

export function useLayoutAdjustments() {
  const [orientationState, setOrientationState] = useState(window.innerWidth > window.innerHeight);
  const [contentHeight, setContentHeight] = useState(1);
  const resizeHandler = useCallback(() => {
    setTimeout( () => {
      enablePageScroll();
      window.scrollTo(0, 10);
      window.scrollTo(0, 0);
      const _contentHeight = window.innerHeight;
      setContentHeight(_contentHeight);
      setOrientationState(window.innerWidth > window.innerHeight);
      setTimeout(() => {
        if (window.innerHeight > _contentHeight) {
          setContentHeight(window.innerHeight);
          setOrientationState(window.innerWidth > window.innerHeight);
        }
      }, 300);
      disablePageScroll();
    }, 60)
  }, []);

  useLayoutEffect(() => {
    disablePageScroll();
    resizeHandler();
    document.addEventListener('gesturestart', preventAction, true);
    window.addEventListener('resize', resizeHandler);

    return () => {
      document.removeEventListener('gesturestart', preventAction, true);
      window.removeEventListener('resize', resizeHandler);
    }
  }, [resizeHandler]);

  useEffect(() => {
    return () => {
      enablePageScroll();
    }
  }, []);
  return {contentHeight, orientationState};
}
