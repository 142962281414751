import React from 'react';
import { Button } from 'carbon-components-react';
import { CopyFile24, Delete24 } from '@carbon/icons-react';

export const LinkItem = ({ href, note, onDelete, showDelete = true, showCopy = true }) => {

  return (<div style={{lineHeight: "32px", width: "100%"}}>
        <div style={{width:"calc(100% - 116px)", display:"inline-block", overflowWrap: "anywhere"}}>{href}, {note && note}</div>
        {showCopy &&
            <Button size='small'
                  onClick={() => navigator.clipboard.writeText(href)}
                  kind='ghost'
            >
                  <CopyFile24 />
            </Button>
        }
        {showDelete && 
            <Button size='small'
                  onClick={() => onDelete() }
                  kind='ghost'
            >
                  <Delete24 />
            </Button>
        }
   </div>)
};