import { ACTION_TYPES } from 'src/modules/reducers/home.reducer';
import { getCategory, getHome2 } from 'src/modules/api/home.api';

export function fetchHomeScreenData() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_HOME_SCREEN_DATA_PENDING });

    const response = await getHome2();

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_HOME_SCREEN_DATA_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_HOME_SCREEN_DATA_FULFILLED, ...data });
    }
  }
}

export function fetchCategory(id) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_CATEGORY_PENDING });

    const response = await getCategory(id);

    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_CATEGORY_REJECTED });
    } else {
      const data = response.data;
      dispatch({
        type: ACTION_TYPES.FETCH_CATEGORY_FULFILLED,
        books: data.items,
        categoryName: data.name
      });
    }
  }
}

export const cleanUp = () => ({ type: ACTION_TYPES.CLEANUP_CATEGORY });
