import React from 'react';
import PropTypes from 'prop-types';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import history from 'src/history';

export const Chapters = ({ chapters }) => {

  const handleChapterClick = (chapterId) => {
    history.push(`/narration/${chapterId}`);
  }

  return (
    <div>
      <OverflowMenu
        direction='bottom'
        flipped={true}
      >
        {chapters.map(chapter => (
          <OverflowMenuItem
            key={chapter.uuid}
            itemText={chapter.name}
            onClick={() => handleChapterClick(chapter.uuid)}
          />
        ))}
      </OverflowMenu>


    </div>
  )
}

Chapters.propTypes = {
  chapters: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}
