import axios from 'axios';

import { ACTION_TYPES } from 'src/modules/reducers/coupon.reducer.js';
import { getAuthHeader } from 'src/modules/api/utils';
import Coupon from 'src/modules/model/coupon';
import { openModal } from 'src/modules/actions/modal.actions';

export const couponInput = (text) => ({ type: ACTION_TYPES.COUPON_INPUT, coupon: text });

const rejected = () => dispatch => {
  dispatch({ type: ACTION_TYPES.COUPON_SUBMIT_REJECTED });
  dispatch(openModal({
    modalLabel: '',
    passiveModal: true,
    modalHeading: 'Coupon is not activated :(',
    body: 'The code you entered is not a valid coupon code.'
  }))
};

export function couponSubmit(id) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.COUPON_SUBMIT_PENDING });
    try {
      const response = await axios.post('/me/coupons', { id } , getAuthHeader());
      if (!response || response.status !== 200) {
        dispatch(rejected());
      } else {
        const data = response.data;
        const coupon = new Coupon(data);
        dispatch({ type: ACTION_TYPES.COUPON_SUBMIT_FULFILLED });
        dispatch(openModal({
          modalLabel: '',
          passiveModal: true,
          modalHeading: `Coupon ${data.applied ? 'applied' : 'activated'}`,
          body: coupon.getMessage()
        }));
      }
    } catch (e) {
      console.log(e);
      dispatch(rejected());
    }
  }
}
