import React from 'react';
import PropTypes from 'prop-types';
import { Slider, FormGroup } from 'carbon-components-react';

const InputSlider = ({ input: { value, ...inputRest }, max, min,
                       className, step,
                       hideTextInput, disabled}) => {
  return (
    <FormGroup legendText={''}>
      <Slider value={Number(value)}
              {...inputRest}
              max={max}
              min={min}
              hideTextInput={hideTextInput}
              disabled={disabled}
              className={className}
              step={step}
              onChange={ ( { value } ) => inputRest.onChange(value) }
      />
    </FormGroup>
  )
}

InputSlider.defaultProps = {
  step: 1,
  disabled: false,
  min: 0,
  max: 100,
  className: '',
  hideTextInput: true
}

InputSlider.propTypes = {
  input: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  className: PropTypes.string,
  hideTextInput: PropTypes.bool,
  disabled: PropTypes.bool,
  step: PropTypes.number
}

export default InputSlider;
