import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tag } from 'carbon-components-react';

import cs from './styles.module.scss';
import { RES_PATH } from 'src/config/environment';
import { shortDateFormat } from 'src/modules/api/utils';
import DefaultImage from 'src/components/_shared/Author/DefaultImage';
import Href from "src/components/_shared/Href";

export const Author = ({ uuid, name, imagePath, updatedAt, badges, noLink, dateFormat, isComment, size37 }) => {
  const writersUrl = () => {
    return `/writers/${uuid}`
  }

  return (
    <div
      className={classNames(cs.author, { [cs.pointer]: !noLink, [cs.isComment]: isComment })}
    >
      <Href href={writersUrl()}>
        { imagePath ?
          <div
            style={{ backgroundImage: `url(${RES_PATH}${imagePath})`, backgroundSize: 'cover' }}
            className={classNames(cs.image, { [cs.size32]: isComment, [cs.size37]: size37 })}
          /> :

          <div className={classNames(cs.image, { [cs.size32]: isComment, [cs.size37]: size37 })}>
            <DefaultImage/>
          </div>
        }

        <div className={cs.desc}>
          <div className={classNames(cs.name, { [cs.bold]: isComment })}>
            {name}
            {badges.map((badge, index) => (
              <Tag key={index} type='green'>{badge}</Tag>
            ))}
          </div>
          { updatedAt &&
            <div className={cs.date}>
              {dateFormat(updatedAt)}
            </div>
          }
        </div>
      </Href>
    </div>
  )
};

Author.defaultProps = {

  name: 'unknown',
  image: '/author.png',
  badges: [],
  noLink: false,
  dateFormat: shortDateFormat,
  isComment: false,
  size37: false
};

Author.propTypes = {

  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imagePath: PropTypes.string,
  updatedAt: PropTypes.number,
  badges: PropTypes.arrayOf(PropTypes.string),
  noLink: PropTypes.bool,
  dateFormat: PropTypes.func,
  isComment: PropTypes.bool,
  size37: PropTypes.bool
};

export default Author;
