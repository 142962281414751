import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import {
  List20,
  Close20,
  LightFilled20,
  AsleepFilled20,
  PageFirst20, ChevronLeft20, ChevronRight20, PageLast20, VolumeUp16
} from '@carbon/icons-react';
import cn from 'classnames';

import cs from './themes.module.scss';
import history from 'src/history';
import FontSizeButton from './FontSizeButton';
import { getCurrentTheme } from 'src/components/reader/ReadingPage/helpers';

export const Toolbar = ({
                          bookUuid, chapterUuid, chapters, reader, percent,
                          onPrevChapter, onNextChapter, onNextPage,
                          onPrevPage, narration, fullScreen, ...rest }) => {

  const [theme, setTheme] = useState(getCurrentTheme());
  const style = { maxWidth: '40px' };

  const toggleNightMode = () => {
    rest.onToggleNightMode();
    setTheme(getCurrentTheme());
  };

  const menuRef = React.useRef();
  const onOpenMenu = () => {
    const $scrollableElement = document.querySelector("ul[aria-label=chapters-ov]");
    $scrollableElement.setAttribute('data-scroll-lock-scrollable', 'true');
  }
  const firstChapter = chapters[0]?.uuid === chapterUuid;
  const firstPage = percent === 0;

  return (
    <>
      <div className={cs.toolbar}>
        <div className={cn('bx--btn-set', [cs[theme.name]])}>
        {!fullScreen &&
          <>
          {narration === true &&
            <Button className={cs.listen}
                    style={{width: 119}}
                    onClick={() => {
                      history.push(`/listen/${bookUuid}/${chapterUuid}`)
                    }}
            >
              <div>
                Listen
                <VolumeUp16/>
              </div>
            </Button>
          }
          <OverflowMenu
            ref={menuRef}
            style={{backgroundColor: 'transparent', padding: 0, width: 40}}
            flipped
            direction='bottom'
            ariaLabel={'chapters-ov'}
            renderIcon={() => <div kind='ghost' className={cn(cs.btn, cs.ovbtn)}><List20/></div>}
            menuOptionsClass={cs[`overflow-menu-options__${theme.name}`]}
            data-scroll-lock-scrollable
            onOpen={onOpenMenu}
          >
            {
              chapters
                .filter(chapter => chapter.published)
                .map((chapter, index) => (
                  <OverflowMenuItem
                    disabled={chapter.uuid === chapterUuid}
                    key={index}
                    itemText={chapter.name}
                    onClick={() => history.push(`/books/${bookUuid}/${chapter.uuid}`)}
                  />
                ))
            }
          </OverflowMenu>

          <FontSizeButton {...rest} theme={theme} />


          <Button kind='ghost'
                  style={style}
                  onClick={toggleNightMode}
                  className={cs.btn}
                  disabled={!rest.chapterAvailable}
          >
            { theme.isLight() ? <LightFilled20/> : <AsleepFilled20/> }
          </Button>
          <Button kind='ghost'
                  style={style}
                  onClick={() => history.push(`/books/${bookUuid}`)}
                  className={cs.btn}
          >
            <Close20/>
          </Button>
          </>
          }
        </div>
      </div>
      <div className={cn(cs.navigation, [cs[theme.name]])}>
        <div className={cs.navigationInnerContainer}>
        {!fullScreen &&
          <>
          <div onClick={onPrevChapter}
               className={cn({ [cs.disabled]: firstChapter })}>
            <PageFirst20/>
          </div>
          <div onClick={onPrevPage}
               className={cn({ [cs.disabled]: (firstChapter && firstPage) })}>
            <ChevronLeft20/>
          </div>
          {percent >= 0 &&
            <div className={cs.percent}>{Math.floor(percent * 100) + "%"}</div>
          }
          <div onClick={onNextPage}><ChevronRight20/></div>
          <div onClick={onNextChapter}><PageLast20/></div>
          </>
        }
        {fullScreen && 
            <>
            {percent >= 0 &&
              <div className={cs.percent}>{Math.floor(percent * 100) + "%"}</div>
            }
            </>
        }
        </div>
      </div>

    </>
  )
};

Toolbar.propTypes = {
  bookUuid: PropTypes.string.isRequired,
  chapterUuid: PropTypes.string.isRequired,
  chapters: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired
  })).isRequired,
  onMore: PropTypes.func.isRequired,
  onLess: PropTypes.func.isRequired,
  onToggleNightMode: PropTypes.func.isRequired,
  chapterAvailable: PropTypes.any,
  reader: PropTypes.any,
  onPrevChapter: PropTypes.func,
  onNextChapter: PropTypes.func,
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  narration: PropTypes.bool,
  percent: PropTypes.number
};

export default Toolbar;
