import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import ReactGA from 'react-ga4';
import { v1 } from 'uuid';

import * as booksApi from 'src/modules/api/books.api';
import * as chaptersApi from 'src/modules/api/chapters.api';
import * as utils from 'src/modules/api/utils';
import history from 'src/history';
import { ACTION_TYPES } from 'src/modules/reducers/books.reducer';
import { ACTION_TYPES as WRITERS_ACTIONS } from 'src/modules/reducers/writers.reducer';
import { updateJobProgress } from 'src/modules/actions/writers.actions';
import JobUpload from 'src/modules/model/job-upload';
import { getAuthHeader } from 'src/modules/api/utils';
import { DEFAULT_NARRATOR_ALWAYS_ID } from 'src/modules/actions/personas.actions';

const rejected = () => ({ type: ACTION_TYPES.FETCH_BOOK_REJECTED });

export function fetchBook(id) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_BOOK_PENDING });

    const response = await booksApi.fetchBook(id);
    if (!response || response.status !== 200) {
      dispatch(rejected());
    } else {

      let book = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_FULFILLED, book });
    }

  }
}

export function fetchSubscriptions(id) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_BOOK_SUBSCRIPTION_PENDING });
    const response = await booksApi.fetchSubscriptions(id);
    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_SUBSCRIPTION_REJECTED });
    } else {
      let data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_SUBSCRIPTION_FULFILLED, data });
    }
  }
}

export function deleteSubscription(uuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_BOOK_SUBSCRIPTION_PENDING });
    const response = await booksApi.deleteSubscriptions(uuid);
    if (!response || response.status !== 200) {
      dispatch({ type: ACTION_TYPES.DELETE_BOOK_SUBSCRIPTION_REJECTED });
    } else {
      dispatch({ type: ACTION_TYPES.DELETE_BOOK_SUBSCRIPTION_FULFILLED, data:{uuid: uuid} });
    }
  }
}

export function fetchSeries() {
  return async dispatch => {
    const response = await booksApi.fetchSeries();
    if (!response || response.status !== 200) {
      dispatch(rejected());
    } else {

      let series = response.data.items;
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_SERIES_FULFILLED, series });
    }
  }
}

export function fetchSeriesObject(uuid) {
  return async dispatch => {
    const response = await booksApi.fetchSeriesObject(uuid);
    if (!response || response.status !== 200) {
      dispatch(rejected());
    } else {

      let seriesObject = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_SERIES_OBJECT_FULFILLED, seriesObject });
    }
  }
}
export function deleteSeries(uuid) {
  return async dispatch => {
    const response = await booksApi.deleteSeries(uuid);
    if (!response || response.status !== 200) {
      dispatch(rejected());
    } else {
      const response = await booksApi.fetchSeries();
      if (!response || response.status !== 200) {
        dispatch(rejected());
      } else {
  
        let series = response.data.items;
        dispatch({ type: ACTION_TYPES.FETCH_BOOK_SERIES_FULFILLED, series });
      }
    }
  }
}

export const forgetSeriesObject = () => ({ type: ACTION_TYPES.FORGET_BOOK_SERIES_OBJECT_FULFILLED });

export function fetchReadingChapter(bookUuid, chapterUuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_BOOK_PENDING });
    try {
      const book = await booksApi.getBookAndChapter(bookUuid, chapterUuid);
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_FULFILLED, book});

    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.FETCH_BOOK_REJECTED });
    }
  }
}

export function fetchShareState(bookUuid, chapterUuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_SHARED_STATE_PENDING });
    try {
      const payload = (await booksApi.postBookShare(bookUuid, chapterUuid)).data;
      dispatch({ type: ACTION_TYPES.FETCH_SHARED_STATE_FULFILLED, payload });
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.FETCH_SHARED_STATE_REJECTED });
    }
  }
}

export const clearShareState = () => ({ type: ACTION_TYPES.CLEAR_SHARED_STATE })

export function putBookShared(uuid, cb) {
  return async dispatch => {
    try {
      await booksApi.putBookShared(uuid);
      dispatch({ type: ACTION_TYPES.PUT_BOOK_SHARED });
      cb && setTimeout(cb, 1000);
    } catch (e) {
      console.log(e);
    }
  }
}

export const forgetBook = () => ({ type: ACTION_TYPES.FORGET_BOOK });

export function importEpub(file) {
  return async dispatch => {
    try {
      const pendingJobId = v1();
      dispatch({ type: ACTION_TYPES.IMPORT_EPUB_PENDING, job: { id: pendingJobId, state: JobUpload.state.UPLOADING, progress: 0 }});

      const epub = (await utils.toBase64(file)).split(',')[1];
      const res = await booksApi.importEpubFile(epub, progressEvent => {
        const progress = progressEvent.loaded / progressEvent.total;
        dispatch(updateJobProgress(pendingJobId, progress));
      });

      dispatch({ type: ACTION_TYPES.IMPORT_EPUB_FULFILLED, job: res.data, pendingJobId });
    } catch (e) {
      dispatch({ type: ACTION_TYPES.IMPORT_EPUB_REJECTED });
    }
  }
}

// TODO: not going to save epub-s anymore
export function submitChapterForm(bookUuid, values, draft, publishBook, before) {
  return async (dispatch, getState) => {

    const dispatchError = message => {
      dispatch({ type: ACTION_TYPES.CREATE_CHAPTER_REJECTED });
      return { [FORM_ERROR]: message };
    };

    const validateFile = file => {
      if (!file.name.toLowerCase().endsWith('.epub'))
        return "Only .epub file format is acceptable";
      return null;
    };

    const makeChapterData = async ({ name, file, text, free }) => {
      const data = { name, free };
      if (file) {
        data.fileType = 'epub';
        data.fileData = (await utils.toBase64(values.file)).split(',')[1];
      }
      if (text) {
        data.fileType = 'html';
        data.fileData = btoa(unescape(encodeURIComponent(text)));
      }
      if (!free) {
        data.free = false;
      }
      return data;
    };

    const requireContent = () => dispatchError("You must fill the text area.");

    dispatch({ type: ACTION_TYPES.CREATE_CHAPTER_PENDING });

    // validate file if specified
    if (values.file) {
      const error = validateFile(values.file);
      if (error)
        return dispatchError(error);
    }

    let uuid = values.uuid;

    // editing
    if (uuid) {
      if (!values.filePath && !values.file && !values.text) {
        return requireContent();
      }

      const chapter = await makeChapterData(values);
      chapter.published = !draft;

      const response = await chaptersApi.putChapter(uuid, chapter);
      if (response.status !== 200) {
        return dispatchError("Chapter hasn't been saved.");
      }

    // create new chapter
    } else {
      // require content
      if (!values.file && !values.text) {
        return requireContent();
      }
      
      const chapter = await makeChapterData(values);
      if (!draft) {
        chapter.published = true;
      }
      const response = await booksApi.postChapter(bookUuid, chapter, before);
      if (response.status === 200) {
        ReactGA.event({
          category: 'writer',
          action: 'add_chapter'
        });

        const narrator = getState().personas.personas.find(pers => pers.narrator);
        if (narrator && narrator.id !== DEFAULT_NARRATOR_ALWAYS_ID) {
          axios.put(
            `/chapters/${response.data.uuid}/narrator`,
            { uuid: narrator.uuid },
            getAuthHeader()
          ).then(() => {});
        }
      } else {
        return dispatchError("Chapter hasn't been saved.");
      }
    }

    if (publishBook) {
      await booksApi.putBook({ uuid: bookUuid, published: true });
    }

    dispatch({ type: ACTION_TYPES.CREATE_CHAPTER_FULFILLED });
    history.push(`/my-books/${bookUuid}`);
  }
}

export function toggleChapterPublished(chapterUuid, chapterIndex, published) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.UPDATE_CHAPTER_PENDING });

    try {

      await chaptersApi.putChapter(chapterUuid, { published });
      dispatch({
        type: ACTION_TYPES.UPDATE_CHAPTER_FULFILLED,
        index: chapterIndex,
        chapter: { published }
      });

    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.UPDATE_CHAPTER_REJECTED });
    }
  }
}

export function toggleChapterFree(chapterUuid, chapterIndex, free) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.UPDATE_CHAPTER_PENDING });

    try {

      await chaptersApi.putChapter(chapterUuid, { free });
      dispatch({
        type: ACTION_TYPES.UPDATE_CHAPTER_FULFILLED,
        index: chapterIndex,
        chapter: { free }
      });

    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.UPDATE_CHAPTER_REJECTED });
    }
  }
}
export function addToMyBooks(bookUuid) {
  return async (dispatch, getState) => {
    const inLib = getState().me.books.find(el => el.book.uuid === bookUuid);
    if (!inLib) {
      dispatch({ type: ACTION_TYPES.ADD_MYBOOKS_FULFILLED, uuid: bookUuid }); //happy path
      await booksApi.postBookAdded(bookUuid);
    }
  }
}

export function removeFromMyBooks({ uuid, index }) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.REMOVE_MYBOOKS_PENDING });

    dispatch({ type: ACTION_TYPES.REMOVE_MYBOOKS_FULFILLED, index }); //happy path
    await booksApi.deleteBookAdded(uuid);
  }
}

export function saveBookLocation(bookUuid, chapterUuid, cfiOffset, progress) {
  return async () => {
    try {
      const params = {
        chapter: chapterUuid
      };
      cfiOffset && (params.cfiOffset = cfiOffset);
      progress && (params.progress = progress);

      return (await axios.put(`/books/${bookUuid}/progress`, params, utils.getAuthHeader())).data;

    } catch (err) {
      console.log(err);
    }

  }
}

export function deleteBook(bookUuid, index) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_BOOK_PENDING });
    await booksApi.deleteBook(bookUuid);
    dispatch({ type: ACTION_TYPES.DELETE_BOOK_FULFILLED, index });
  }
}

export function toggleLiked(bookUuid, liked) {
  try {
    if (liked) {
      return axios.delete(`/books/${bookUuid}/like`, utils.getAuthHeader());
    } else {
      return axios.post(`/books/${bookUuid}/like`, {}, utils.getAuthHeader());
    }
  } catch (e) {
    console.log(e);
  }
}

export const toggleComments = (show) => ({ type: ACTION_TYPES.TOGGLE_COMMENTS, show });

export const toggleBookVisibility = (uuid, published) => {
  return async dispatch => {
    dispatch({ type: WRITERS_ACTIONS.UPDATE_BOOK_PENDING });
    try {
      await booksApi.putBook({ uuid, published });
      dispatch({ type: WRITERS_ACTIONS.UPDATE_BOOK_FULFILLED, book: { uuid, published } });
    } catch (e) {
      console.log(e);
      dispatch({ type: WRITERS_ACTIONS.UPDATE_BOOK_REJECTED})
    }
  }
}
