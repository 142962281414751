import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import cs from './styles.module.scss';
import { getCurrentTheme } from '../helpers';
import BuyAndShare from 'src/components/_shared/BuyAndShare';

const UnavailableChapter = ({ fetchShareState, putBookShared, book, chapter, className, style }) => {

  return (
    <div className={classNames(className, cs[getCurrentTheme().name])} style={{...style}}>
      <div style={{ marginTop: '48px', padding: '1rem'}}>
        <div className={cs.chapterName}>{chapter?.name ?? ''}</div>
        <BuyAndShare putBookShared={putBookShared}
                     fetchShareState={fetchShareState}
                     book={book}
                     chapter={chapter}
                     buyButtonContainerClass={cs.buyButton}
                     shareContainerClass={cs.relativeContainer}
                     shareBlockClass={cs.shareBlock}
        />
      </div>
    </div>
  )
};

UnavailableChapter.propTypes = {
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    priceTier: PropTypes.string.isRequired,
    share: PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  }).isRequired,
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    free: PropTypes.bool,
    'public': PropTypes.bool
  }).isRequired,
  style: PropTypes.any,
  fetchShareState: PropTypes.func.isRequired,
  putBookShared: PropTypes.func.isRequired
};

export default UnavailableChapter;
