import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

import { isAuthenticated } from 'src/modules/api/cookie.api';
import BuyButton, { canBuy } from 'src/components/_shared/BuyButton';
import ShareBlockReading from 'src/components/_shared/ShareBlockReading';
import AuthReqBlockReading from 'src/components/AuthReqBlockReading';
import { getCurrentTheme } from 'src/components/reader/ReadingPage/helpers';

const BuyAndShare = ({
                       fetchShareState, putBookShared, book, chapter,
                       authReqTheme,
                       buyButtonContainerClass, shareContainerClass, authReqBlockClass, shareBlockClass}) => {

  React.useEffect(() => {
    isAuthenticated() && fetchShareState(book.uuid, chapter.uuid);
  }, [fetchShareState, book.uuid, chapter.uuid]);

  const onShared = () => {
    if (book.share) {
      ReactGA.event({
        category: 'Share',
        action: (chapter.free || chapter.public) ? 'FreeChapter' : 'Chapter'
      });

      const { state, uuid } = book.share;
      if (state === 'created') {
        putBookShared(uuid, () => {
          document.location.reload();
        });
      }
    }
  }

  return (
    <>
      {
        (book && chapter) &&
        <>
          {isAuthenticated() && canBuy(book, chapter) &&
          <div className={buyButtonContainerClass}>
            <BuyButton book={book} chapter={chapter}/>
          </div>
          }
          <div className={shareContainerClass}>
            <ShareBlockReading className={shareBlockClass} book={book} onShared={onShared}/>
            <AuthReqBlockReading className={authReqBlockClass || shareBlockClass} chapter={chapter} theme={authReqTheme || getCurrentTheme().name}/>
          </div>
        </>
      }
    </>
  )
}

BuyAndShare.propTypes = {
  book: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    priceTier: PropTypes.string.isRequired,
    share: PropTypes.shape({
      code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  }).isRequired,
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    free: PropTypes.bool,
    'public': PropTypes.bool
  }).isRequired,
  fetchShareState: PropTypes.func.isRequired,
  putBookShared: PropTypes.func.isRequired,

  buyButtonContainerClass: PropTypes.string,
  shareContainerClass: PropTypes.string,
  shareBlockClass: PropTypes.string,
  authReqBlockClass: PropTypes.string,
  authReqTheme: PropTypes.string
}

export default BuyAndShare;
