export const ACTION_TYPES = {
  COUPON_INPUT: 'coupon/COUPON_INPUT',
  COUPON_SUBMIT_PENDING: 'coupon/COUPON_SUBMIT_PENDING',
  COUPON_SUBMIT_FULFILLED: 'coupon/COUPON_SUBMIT_FULFILLED',
  COUPON_SUBMIT_REJECTED: 'coupon/COUPON_SUBMIT_REJECTED'
};

const defaultState = {
  coupon: '',
  loading: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.COUPON_INPUT:
      return {
        ...state,
        coupon: action.coupon
      };
    case ACTION_TYPES.COUPON_SUBMIT_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.COUPON_SUBMIT_FULFILLED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.COUPON_SUBMIT_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
