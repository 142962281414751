import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils'

export async function getAll(offset, limit = 8) {
    try {
      const response = await axios.get(`/newsletters?limit=${limit}&offset=${offset}`, getAuthHeader());
      if (response && response.status === 200) {
        return response.data.items;
      }
      return null;
    } catch (error) {
      return error.response;
    }
}

export async function get(uuid) {
  try {
    const response = await axios.get(`/newsletters/${uuid}`, getAuthHeader());
    if (response && response.status === 200) {
      return response.data
    }
  } catch (err) {
    console.log(err);
    return null;
  }
  return null;
}

export async function put(uuid, object) {
  try {
    return await axios.put(`/newsletters/${uuid}`, object, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function post(object) {
  try {
    return await axios.post(`/newsletters`, object, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function testSend(uuid) {
  try {
    return await axios.post(`/newsletters/${uuid}/test`, {}, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}

export async function remove(uuid) {
  try {
    return await axios.delete(`/newsletters/${uuid}`, getAuthHeader());
  } catch (error) {
    return error.response;
  }
}
