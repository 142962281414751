import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Modal } from 'carbon-components-react';
import { bindActionCreators } from 'redux';
import {
  toggleFormVisibility,
  saveSound,
  clearFormError
} from 'src/modules/actions/bgsounds.actions';
import RowWrapper from 'src/layout/form/ChapterRowWrapper';
import InputText from 'src/components/_shared/form/InputText';

import cs from './add-sound.module.scss';
import Mp3Uploader from 'src/components/_shared/form/Mp3Uploader';

const required = value => (value ? undefined : 'Required');

const emptyInitialValues = {
  info: {
    name: ''
  },
  file: {
    type: 'mp3',
    data: undefined
  }
};

const AddSound = ({ modalOpen, savingSound, soundError, ...actions }) => {

  const handleOfSubmit = React.useRef();
  const formApi = React.useRef();

  const onRequestSubmit = () => {
    handleOfSubmit.current();
  }

  const onSubmit = async values => {
    actions.saveSound(values);
  }

  React.useEffect(() => {
    if (!modalOpen && formApi.current) {
      formApi.current.reset({...emptyInitialValues});
    }
  }, [modalOpen]);

  return (
    modalOpen ?
    <Modal aria-label=''
           open
           onRequestClose={actions.toggleFormVisibility}
           onSecondarySubmit={actions.toggleFormVisibility}
           onRequestSubmit={onRequestSubmit}
           primaryButtonText='Ok'
           secondaryButtonText='Cancel'
           modalHeading={'Upload new audio file'}
           primaryButtonDisabled={savingSound}
    >
      <p className={'bx--modal-content__text'} style={{ marginBottom: '2rem' }}>
        Upload new audio file, that will be used by AI narration.
      </p>
      <Form onSubmit={onSubmit}
            render={ ( { handleSubmit, form, submitting, submitError, values } ) => {
              handleOfSubmit.current = handleSubmit;
              formApi.current = form;
              const disabled = submitting;
              return (
                <>
                  <form onSubmit={handleSubmit} onFocus={actions.clearFormError}>
                    <div className="bx--grid">
                      <RowWrapper title={'Name*'}>
                        <Field name={'info.name'}
                               id={'name'}
                               validate={required}
                               component={InputText}
                               placeholder={'Character name'}
                               disable={disabled}
                        />
                      </RowWrapper>
                      <RowWrapper title={'File*'} childrenClass={cs.mp3Container}>
                        <Field name={'file.file'}
                               id={'file'}
                               validate={required}
                               disable={disabled}
                               className={cs.mp3Uploader}
                               component={Mp3Uploader}
                        />
                      </RowWrapper>
                    </div>
                  </form>
                </>
              )
            }}
      />
      {soundError && <div className={cs.soundError}>{soundError}</div>}
    </Modal> : null
  )
}

AddSound.propTypes = {
  modalOpen: PropTypes.bool,
  savingSound: PropTypes.bool,
  soundError: PropTypes.string,
  saveSound: PropTypes.func,
  toggleFormVisibility: PropTypes.func,
  clearFormError: PropTypes.func
}

function mapStateToProps(state) {
  return {
    modalOpen: state.bgsounds.form,
    savingSound: state.bgsounds.savingSound,
    soundError: state.bgsounds.soundError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveSound,
    toggleFormVisibility,
    clearFormError
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSound);