import React from 'react';

import cx from './Tags.module.scss';
import cn from 'classnames';
import button from 'src/components/MainPage/HorizontalItemList/styles.module.scss';
import Href from "src/components/_shared/Href";
import { Button } from 'carbon-components-react';
import { ChevronRight20 } from '@carbon/icons-react';
import SectionName from 'src/components/_shared/DesignV2/SectionName';

export const Tag = ({ object }) => {
  return (
    <div
      className={cn('bx--label', cx.tag)}
    >
      <Href href={object.url} aStyle={{ display: 'inline' }}>
      # {object.name}
      </Href>
    </div>
  )
};

export const Section = ({ pushTo, object }) => {
  return (
      <div>
        <SectionName name={object.name}>
          { pushTo && 
          <Button size='small'
                  kind='ghost'
                  className={button.seeAllButton}
          >
            <Href href={pushTo}>See All</Href>
            <ChevronRight20/>
          </Button>
          }
        </SectionName>
        <div className={cn(cx.tagsList)}>
          {
            object.tags.map((item, index, array) => {
              if(array.length - 2 === index) {
                return <div key={index} className={cn(cx.tagItem)}><Tag object={item}/> , and</div>
              } else if(array.length - 1 === index) {
                return <div key={index} className={cn(cx.tagItem)}><Tag object={item}/></div>
              } else {
                return <div key={index} className={cn(cx.tagItem)}><Tag object={item}/> ,</div>
              }
            })
          }
        </div>
      </div>
  )
};

export default Section;