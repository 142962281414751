import React from 'react';
import classNames from 'classnames';
import { FileUploaderButton } from 'carbon-components-react';
import cs from './styles.module.scss';

export const Mp3Uploader = ({ input, disable = false, className, meta}) => {
  return (
    <FileUploaderButton
      buttonKind='tertiary'
      labelText={input?.value?.name || 'Select File'}
      className={classNames(className, { [cs.error]: meta.invalid && meta.touched })}
      onChange={e => input.onChange(e.target.files[0])}
      accept={['audio/mpeg']}
      disabled={disable}
    />
  )
};

export default Mp3Uploader;