import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  PinterestShareButton, PinterestIcon,
  RedditShareButton, RedditIcon,
  TelegramShareButton, TelegramIcon,
  LinkedinShareButton, LinkedinIcon
} from 'react-share';

import cs from './styles.module.scss';
import cn from 'classnames';

const ShareButton = ({ image, url, title, style, className }) => {
  return (
    <div style={style} className={cn(cs.shareButtonsContainer, className)}>
        <div className={cn(cs.socialItem, cs.facebook)}>
        <FacebookShareButton
            url={url}
            quote={title}
            className={cs.shareButton}
        >
            <FacebookIcon size={32} round/>
        </FacebookShareButton>
        </div><div className={cn(cs.socialItem, cs.twitter)}>
        <TwitterShareButton
            url={url}
            title={title}
            className={cs.shareButton}
        >
            <TwitterIcon size={32} round/>
        </TwitterShareButton>
        </div>

        <div className={cn(cs.socialItem, cs.whatsapp)}>
        <WhatsappShareButton
            url={url}
            title={title}
            separator=":: "
            className={cs.shareButton}
        >
            <WhatsappIcon size={32} round/>
        </WhatsappShareButton>
        </div>

        <div className={cn(cs.socialItem, cs.pinterest)}>
        <PinterestShareButton
            url={url}
            media={image}
            className={cs.shareButton}
        >
            <PinterestIcon size={32} round/>
        </PinterestShareButton>
        </div>

        <div className={cn(cs.socialItem, cs.reddit)}>
        <RedditShareButton
            url={url}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={cs.shareButton}
        >
            <RedditIcon size={32} round/>
        </RedditShareButton>
        </div>

        <div className={cn(cs.socialItem, cs.telegram)}>
        <TelegramShareButton
            url={url}
            title={title}
            className={cs.shareButton}
        >
            <TelegramIcon size={32} round/>
        </TelegramShareButton>
        </div>

        <div className={cn(cs.socialItem, cs.linkedIn)}>
        <LinkedinShareButton
            url={url}
            className={cs.shareButton}>
            <LinkedinIcon size={32} round/>
        </LinkedinShareButton>
        </div>

    </div>)
}

ShareButton.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
}

export default ShareButton;
