import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';
import { Modal } from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';
import { bindActionCreators } from 'redux';
import {
  togglePersonasFormVisibility,
  saveCharacter,
  clearFormError,
  toggleDeletePersonaDialog
} from 'src/modules/actions/personas.actions';
import RowWrapper from 'src/layout/form/ChapterRowWrapper';
import InputText from 'src/components/_shared/form/InputText';
import InputDropdown from 'src/components/_shared/form/InputDropdown';
import InputSlider from 'src/components/_shared/form/InputSlider';
import Toggle from 'src/components/_shared/form/Toggle';
import './personas.css';

import cs from './add-personas.module.scss';
import common from 'src/layout/form/common.module.scss';

const required = value => (value ? undefined : 'Required');
const VOICES = [
  { id: 'male5', labelText: 'Male (US) Expressive', value: 'male5' },
  { id: 'male1', labelText: 'Male (US) 1', value: 'male1' },
  { id: 'male2', labelText: 'Male (US) 2', value: 'male2' },
  { id: 'male3', labelText: 'Male (US) 3', value: 'male3' },
  { id: 'male4', labelText: 'Male (GB)', value: 'male4' },
  { id: 'female7', labelText: 'Female (US) Expressive 1', value: 'female7' },
  { id: 'female8', labelText: 'Female (US) Expressive 2', value: 'female8' },
  { id: 'female1', labelText: 'Female (US) 1', value: 'female1' },
  { id: 'female2', labelText: 'Female (US) 2', value: 'female2' },
  { id: 'female3', labelText: 'Female (US) 3', value: 'female3' },
  { id: 'female4', labelText: 'Female (US) 4', value: 'female4' },
  { id: 'female5', labelText: 'Female (GB) 1', value: 'female5' },
  { id: 'female6', labelText: 'Female (GB) 2', value: 'female6' },
];

const emptyInitialValues = {
  id: undefined,
  name: undefined,
  pitch: 100,
  speed: 100,
  uuid: undefined,
  voiceId: 'nr',
  narrator: false
};

const AddPersonas = ({ modalOpen, savingCharacter, charError, open, editPersona, chapterUuid, ...actions }) => {

  const handleOfSubmit = React.useRef();
  const formApi = React.useRef();

  const onRequestSubmit = () => {
    handleOfSubmit.current();
  }

  const onSubmit = async values => {
    actions.saveCharacter(values);
  }

  React.useEffect(() => {
    if (!open) {
      formApi.current.reset({...emptyInitialValues});
    } else if (editPersona) {
      formApi.current.reset({...editPersona});
    }
  }, [open, editPersona]);

  return (
    <Modal
      aria-label=''
      open={modalOpen}
      onRequestClose={() => actions.togglePersonasFormVisibility(undefined, undefined)}
      onSecondarySubmit={() => actions.togglePersonasFormVisibility(undefined, undefined)}
      onRequestSubmit={onRequestSubmit}
      primaryButtonText='Ok'
      secondaryButtonText='Cancel'
      modalHeading={editPersona ? 'Edit the character' : 'Configure new character'}
      primaryButtonDisabled={savingCharacter}
    >
      <p className={'bx--modal-content__text'} style={{ marginBottom: '2rem' }}>
        Setup character base information, some variables could be
        modified on per case basis. {editPersona && <span className={cs.deleteBlock}>To delete
        this character <span className={cs.deleteLink} onClick={actions.toggleDeletePersonaDialog}><TrashCan16 /> click here</span>.</span>}
      </p>
      <Form onSubmit={onSubmit}
            render={ ( { handleSubmit, form, submitting, submitError, values } ) => {
              handleOfSubmit.current = handleSubmit;
              formApi.current = form;
              const disabled = submitting;
              return (
                <>
                  <form onSubmit={handleSubmit} onFocus={actions.clearFormError}>
                    <div className="bx--grid">
                      <RowWrapper title={'Name*'}>
                        <Field name={'name'}
                               id={'name'}
                               validate={required}
                               component={InputText}
                               placeholder={'Character name'}
                               disable={disabled}
                        />
                      </RowWrapper>
                      <RowWrapper title={'Voice*'}>
                        <Field name={'voiceId'}
                               id={'voiceId'}
                               component={InputDropdown}
                               disabled={disabled}
                               defaultValue={'nr'}
                               validate={v => (v === 'nr') ? 'Required' : undefined}
                               options={VOICES}
                               onChange={() => {}}
                        />
                      </RowWrapper>
                      <RowWrapper title={'Pitch*'}>
                        <Field name={'pitch'}
                               component={InputSlider}
                               min={50}
                               max={150}
                               className={cs.pitchSlider}
                        />
                      </RowWrapper>
                      <RowWrapper title={'Rate*'}>
                        <Field name={'speed'}
                               component={InputSlider}
                               min={50}
                               max={150}
                        />
                      </RowWrapper>
                      <RowWrapper title={'Narrator'}
                                   childrenClass={classNames(common.fieldTitle, cs.narrator)}>
                        <Field name={'narrator'}
                               id={'narrator'}
                               type={'checkbox'}
                               component={Toggle}
                        />
                      </RowWrapper>
                    </div>
                  </form>
                </>
              )
            }}
      />
      {charError && <div className={cs.charError}>{charError}</div>}
    </Modal>
  )
}

AddPersonas.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  togglePersonasFormVisibility: PropTypes.func,
  saveCharacter: PropTypes.func,
  savingCharacter: PropTypes.bool,
  charError: PropTypes.string,
  clearFormError: PropTypes.func,
  open: PropTypes.bool,
  chapterUuid: PropTypes.string,
  toggleDeletePersonaDialog: PropTypes.func
}

function mapStateToProps(state) {
  return {
    modalOpen: state.personas.form,
    savingCharacter: state.personas.savingCharacter,
    charError: state.personas.charError,
    open: state.personas.form,
    editPersona: (function(id) {
      if (id) {
        return state.personas.personas.find(p => p.id === id);
      }
    })(state.personas.editPersonaId),
    chapterUuid: state.personas.chapterUuid
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    togglePersonasFormVisibility, saveCharacter, clearFormError,
    toggleDeletePersonaDialog
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonas);
