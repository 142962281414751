import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'src/utils';
import { formatDuration } from 'src/utils/duration';
import cs from './styles.module.scss';
import classNames from 'classnames';
import { PauseFilled24 } from '@carbon/icons-react';
import { PlayerPlayIcon as PlayIcon } from './PlayerPlayIcon';

export const PlayAudioButton = ({ src, playButton, pauseButton, className, style}) => {
  const audioEl = useRef();
  const [canPlay, setCanPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [rest, setRest] = useState(null);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const audio = audioEl.current;

    if (!audio) return;

    audio.volume = 1.0;

    const onCanPlayThrough = () => {
      setCanPlay(true);
    }

    const onCanPlay = () => {
      setCanPlay(true);
    }

    const onPlay = () => {
      setPlaying(true);
      audio.autoplay = true;
    }

    const onTimeUpdate = throttle((e) => {
      const audio = e.target;
      const currentTime = audio.currentTime;
      const duration = audio.duration;

      setPlayed(currentTime);

      if (Number.isFinite(duration)) {
        setRest(duration - currentTime);
        setPercent(currentTime * 100 / duration);
      }
    }, 1000);

    const onEnded = () => {
      setPlaying(false);
      setPercent(0);
      audio.autoplay = false;
    }

    const onPause = () => {
      setPlaying(false);
      audio.autoplay = false;
    }

    const onLoadedMetadata = (e) => {
      const duration = e.target.duration;
      if (Number.isFinite(duration)) {
        setRest(duration);
      }
    }

    audio.addEventListener('canplaythrough', onCanPlayThrough);
    audio.addEventListener('canplay', onCanPlay);
    audio.addEventListener('play', onPlay);
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', onEnded);
    audio.addEventListener('pause', onPause);
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    return () => {
      if (audio) {
        audio.removeEventListener('canplaythrough', onCanPlayThrough);
        audio.removeEventListener('canplay', onCanPlay);
        audio.removeEventListener('play', onPlay);
        audio.removeEventListener('timeupdate', onTimeUpdate);
        audio.removeEventListener('ended', onEnded);
        audio.removeEventListener('pause', onPause);
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    }
  }, [audioEl]);

  useEffect(() => {
    setRest(null);
    setPercent(0);
    setPlayed(0);
    setCanPlay(false);
    setPlaying(false);
  }, [src]);

  // const handleSeekerClick = (e) => {
  //   const audio = audioEl.current;

  //   if (audio?.duration) {
  //     const clickPercent = getSeekerPercentClick(e);
  //     audio.currentTime = clickPercent > 0 ? (audio.duration / 100 * clickPercent) : 0;
  //   }
  // }

  const handlePlayClick = (e) => {
    e.stopPropagation();
    if (canPlay) {
      audioEl.current.play();
    }
  }

  const handlePause = (e) => {
    e.stopPropagation();
    audioEl.current.pause();
  }

  const timePlayed = formatDuration(played);
  const timeRest = rest ? `- ${formatDuration(rest)}` : '--';
  const percentPlayed = `${percent}%`;

  const progress = {
    background: `radial-gradient(closest-side, #6340EF 90%, transparent 90%, transparent 100%), conic-gradient(#492CBA, 0deg, #492CBA, ${percent*3.6}deg, #7456EC, ${percent*3.6}deg, #7456EC)`,
  };

  return(
    <div className={classNames(cs.controlsContainer, className)} style={style}>
      <audio
        autoPlay={false}
        controls={false}
        loop={false}
        preload='auto'
        src={src}
        ref={audioEl}
      />
      <div className={cs.controls} style={progress} >
        {playing ? (
          <div className={classNames(cs.pla, cs.pause)} onClick={handlePause}>
            <PauseFilled24/>
          </div>
        ) : (
          <div className={classNames(cs.play, {[cs.disabled]: !canPlay})} onClick={handlePlayClick}>
            <PlayIcon/>
          </div>
        )}
      </div>
    </div>);
};

PlayAudioButton.propTypes = {
  src: PropTypes.string.isRequired,
  playButton: PropTypes.any,
  pauseButton: PropTypes.any,
}
