import React from 'react';
import classNames from 'classnames';

import cs from './competition.module.scss';
import AppLayout from 'src/layout/AppLayout';

import commonStyles from './common.module.scss';

const Item = ({ img, order, title, titleTopGap, titleCentered, children }) => {
  return (
    <div className={cs.item}>
      {order && <span className={cs.order}>{order}</span>}
      <img src={`${process.env.PUBLIC_URL}/${img}`} alt={''}/>
      <div className={classNames(cs.itemTitle, { [cs.titleCentered]: titleCentered })}
           style={{ marginTop: titleTopGap }}>
        {title}
      </div>
      {children}
    </div>
  )
};

const Competition = () => {
  return (
    <AppLayout>
      <img src={`${process.env.PUBLIC_URL}/banner-3.jpg`} style={{ width: '100%' }} alt={''}/>
      <div className={cs.stepsBlock}>
        <div className={'bx--grid'}>
          <h3 className={cs.h3}>Winners and Prizes</h3>
          <div className={cs.itemsContainer}>
            <Item img={'number_large.svg'}
                  order={1}
                  title={'The Winner!'}
                  titleTopGap={14}
            >
              <div className={cs.list}>
                <div>1.</div>
                <div>300$</div>
              </div>
              <div className={cs.list}>
                <div>2.</div>
                <div>Unlimited free access to Writers' Group Resource room</div>
              </div>
              <div className={cs.list}>
                <div>3.</div>
                <div>A spotlight in a banner on Fictionate.me</div>
              </div>
            </Item>
            <Item img={'number_large.svg'}
                  order={2}
                  title={'Second Place'}
                  titleTopGap={14}
            >
              <div className={cs.list}>
                <div>1.</div>
                <div>150$</div>
              </div>
              <div className={cs.list}>
                <div>2.</div>
                <div>Unlimited free access to Writers' Group Resource room</div>
              </div>
            </Item>
            <Item img={'number_large.svg'}
                  order={3}
                  title={'Third Place'}
                  titleTopGap={14}
            >
              <div className={cs.list}>
                <div>1.</div>
                <div>50$</div>
              </div>
              <div className={cs.list}>
                <div>2.</div>
                <div>Unlimited free access to Writers' Group Resource room</div>
              </div>
            </Item>
          </div>
        </div>
      </div>
      <div className={cs.timeframeBlock}>
        <div className={'bx--grid'} style={{ marginTop: 16 }}>
          <h3 className={cs.h3}>Competition Timeframe</h3>
          <div>
            <img width={57} height={57} alt={''} src={`${process.env.PUBLIC_URL}/clock.svg`} />
            <div style={{ lineHeight: '20px' }}>
              Submission starts on September 14<br/>
              The results will be calculated on September 21.
            </div>
          </div>
        </div>
      </div>
      <div className={cs.stepsBlock}>
        <div className={'bx--grid'}>
          <h3 className={cs.h3}>How to win?</h3>
          <div className={cs.itemsContainer}>
            <Item img={'like_splash.svg'}
                  title={'Become the favorite one'}
                  titleTopGap={9}
                  titleCentered
            >
              <div style={{ lineHeight: '20px' }}>
                Get the most number of likes. The top<br/>
                three liked books will get the first,<br/>
                second, and third places.
              </div>
            </Item>
            <Item img={'events.svg'}
                  title={'Share with your Friends'}
                  titleTopGap={13}
                  titleCentered
            >
              <div style={{ lineHeight: '20px' }}>
                Give your friends a heads up.<br/>
                Every like counts.
              </div>
            </Item>
            <Item img={'icons_social.svg'}
                  title={'Share online'}
                  titleTopGap={9}
                  titleCentered
            >
              <div style={{ lineHeight: '20px' }}>
                Share on forums, social networks, and<br/>
                other platforms to gain more<br/>
                exposure.
              </div>
            </Item>
          </div>
        </div>
      </div>
      <div className={classNames('bx--grid', commonStyles.staticWrapper)} style={{ marginBottom: '1rem'}}>
        <div className='bx--row'>
          <div className='bx--col'>
          <br />
            <h3>How to participate?</h3>
          <br/>
          <h4> 1. Create a writer&#39;s account <a href="https://fictionate.me/register">here</a>.</h4>
          <br/>
          <img src="/competition-0.png" alt="" />
          <h4>2. Create a book and add the <strong><a style={{ color: 'inherit', 'text-decoration': 'inherit'}} href="/tags/writersgroupcompetition">#writersgroupcompetition</a></strong> tag </h4>
          <br/>
          <img src="/competition-1.png" alt="" />
          <br/>
          <img src="/competition-2.png" alt="" />
          <h4>3. Add at least 2 chapters with 1500 words or more each.</h4>
          <br/>
          <h4>4. Invite your friends and family to vote for YOU!</h4>
          <br/>
          <br/>
          </div>
        </div>
      </div>
    </AppLayout>
  )
};

export default Competition;
