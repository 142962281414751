import React, {useCallback} from 'react';
import PT from "prop-types";
import PropTypes from "prop-types";
import history from 'src/history';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

export const Href = ({ external = false, href, children, style, aStyle, forwardedRef, className }) => {

  const onClick = useCallback((e) => {
    e.stopPropagation();
    if (external) {
      window.open(href, '_blank', 'noreferrer');
    } else {
      history.push(href);
    }
  }, [external, href])

  return (
    <div className={className} style={{ cursor: 'pointer', display: 'flex', ...style}}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp(onClick)}>
      <a style={{ color: 'inherit', pointerEvents: 'none', textDecoration: 'inherit', position: 'inherit', display: 'inherit', width: '100%', height: '100%', ...aStyle}}
        ref={forwardedRef}
        href={href}>
        {children}
      </a>
    </div>
  )
};

Href.defaultProps = {
  style: {}
};

Href.propTypes = {
  external: PropTypes.bool,
  href: PT.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.any,
};

export default React.forwardRef((props, ref) => <Href {...props} forwardedRef={ref}/>);
