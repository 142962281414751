import ReaderTheme from 'src/modules/model/ReaderTheme';

import * as lss from 'src/modules/api/local-storage.api';

const MAX_FONT_SIZE = 400;
const MIN_FONT_SIZE = 50;

function getCurrentFontSize() {
  const settings = lss.getReaderSettings();
  return settings.fontSize || 100;
}

function saveCurrentFontSize(fontSize) {
  const settings = lss.getReaderSettings();
  lss.setReaderSettings({ ...settings, fontSize })
}

export function setUpFontSize(rendition) {
  const fs = getCurrentFontSize();
  rendition.themes.fontSize(fs + '%');
}

export function fontBigger(rendition) {
  let fs = getCurrentFontSize();
  fs = fs + 10;
  saveCurrentFontSize(fs);
  rendition.themes.fontSize(fs + '%');
}

export function fontSmaller(rendition) {
  let fs = getCurrentFontSize();
  fs = fs - 10;
  saveCurrentFontSize(fs);
  rendition.themes.fontSize(fs + '%');
}

export function isFontBiggerDisabled() {
  return getCurrentFontSize() >= MAX_FONT_SIZE;
}

export function isFontSmallerDisabled() {
  return getCurrentFontSize() <= MIN_FONT_SIZE;
}

export function getCurrentTheme() {
  const settings = lss.getReaderSettings();
  return ReaderTheme.themeOf(settings.theme);
}

function saveCurrentTheme(theme) {
  const settings = lss.getReaderSettings();
  lss.setReaderSettings({ ...settings, theme: theme.id });
}

export function setUpThemes(rendition) {
  rendition.themes.register(ReaderTheme.themesToRegister());
  rendition.themes.select(getCurrentTheme().name);
}

export function toggleNightMode(rendition) {
  const theme = getCurrentTheme();
  const nextTheme = theme.toggle();
  rendition.themes.select(nextTheme.name);
  saveCurrentTheme(nextTheme);
}
