import React from 'react';
import AppLayout from 'src/layout/AppLayout';
import Content from 'src/components/_shared/Content';

export const NotFoundPage = () => {
  return (
    <AppLayout>
      <Content>
        <h1>Page is not found</h1>
      </ Content>
    </AppLayout>
  )
};

export default NotFoundPage;
