import React from 'react';

import cx from './styles.module.scss';
import cn from 'classnames';
import history from 'src/history';
import { RES_PATH } from 'src/config/environment';
import Like from 'src/components/_shared/Like';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

const BookCellSmall = ({ book, author, showLike = true, style, ...props }) => {
  const [likes, setLikes] = React.useState(book?.likes);
  const [liked, setLiked] = React.useState(book?.liked);

  const bookURL = () => {
    return `/books/${book.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };

  return (
    <div className={cn(cx.bookCell)}
         onMouseDown={onMouseDown}
         onMouseUp={onMouseUp(goBook)}
         style={style}
    >
      <div className={cn(cx.imageContainer)} style={{ backgroundImage: `url(${RES_PATH}${book.image})` }} />
      <div className={cn(cx.likes)}>  
      {showLike &&
        <Like {...book}
            size='xs'
            showLikes={true}
            style={{ alignItems: 'flex-start' }}
            likesChanged={setLikes}
            likedChanged={setLiked}
            likes={likes}
            liked={liked}
            likesFirst={false}
        />}
      </div>
      {book.tags?.length > 0 ? <div className={cn(cx.genres)}>{book.tags?.map(tag => tag.toUpperCase()).join(' | ')}</div> : <></>}
      <h4 className={cn(cx.name)}>{book.name}</h4>
      {author && <div className={cn(cx.writer)}>by {author.name}</div>}
    </div>
  )
};

BookCellSmall.propTypes = {
};

export default BookCellSmall;