const LOCAL_ADDR = 'http://localhost:8181';
const REMOTE_ADDR = 'https://api.fictionate.me';
const STAGING_ADDR = "https://api.staging.fictionate.me";

const LOCAL_STRIPE_KEY = 'pk_test_CeN6C50zELl25m84g1xpFAJM';
const REMOTE_STRIPE_KEY = 'pk_live_tlm7lEAsbgA1Oh51ESePs0TB';

const LOCAL_PAYOUT_KEY = 'ca_H5nL9izHioN2nLs7LixcG7ntP7gEUaGL';
const REMOTE_PAYOUT_KEY = 'ca_H5nLS1sg08Xpn7YJBpfZaLPfVmONJUpW';

const LOCAL_PAYPAL_CLIENT_ID = 'AT2kn2sTjJM39n5AkSIiSWa5om7MMIyuJ0FGJ8bAlIruTrVPCcGS-4hiC60xAQf-qSEalzDyZ-kAkCSH';
const REMOTE_PAYPAL_CLIENT_ID = 'AbKrSMjC5yTBc-XNMf6mDsFyev7fT6ImA-p1G45d0Pur2g1VCs7kYek_4yF66oBIjEhWMUYUkfHw2q3p';

const configs = {
  production: {
    "default": {
      API_URL: REMOTE_ADDR,
      RES_PATH: REMOTE_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY,
      PAYPAL_CLIENT_ID: REMOTE_PAYPAL_CLIENT_ID,
    },
    staging: {
      API_URL: STAGING_ADDR,
      RES_PATH: STAGING_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY,
      PAYPAL_CLIENT_ID: REMOTE_PAYPAL_CLIENT_ID,
    }
  },
  development: {
    local: {
      API_URL: LOCAL_ADDR,
      RES_PATH: LOCAL_ADDR,
      STRIPE_KEY: LOCAL_STRIPE_KEY,
      PAYOUT_KEY: LOCAL_PAYOUT_KEY,
      PAYPAL_CLIENT_ID: LOCAL_PAYPAL_CLIENT_ID,
    },
    remote: {
      API_URL: REMOTE_ADDR,
      RES_PATH: REMOTE_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY,
      PAYPAL_CLIENT_ID: REMOTE_PAYPAL_CLIENT_ID,
    },
    staging: {
      API_URL: STAGING_ADDR,
      RES_PATH: STAGING_ADDR,
      STRIPE_KEY: LOCAL_STRIPE_KEY,
      PAYOUT_KEY: LOCAL_PAYOUT_KEY,
      PAYPAL_CLIENT_ID: LOCAL_PAYPAL_CLIENT_ID,
    }
  }
}

const config = configs[process.env.NODE_ENV][process.env.REACT_APP_SERVER];

module.exports = {
  API_URL: config['API_URL'],
  RES_PATH: config['RES_PATH'],
  STRIPE_KEY: config['STRIPE_KEY'],
  PAYOUT_KEY: config['PAYOUT_KEY'],
  PAYPAL_CLIENT_ID: config['PAYPAL_CLIENT_ID']
};
