import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';

import cs from './styles.module.scss';
import { fetchAuthor, sortBooks, follow, unfollow } from 'src/modules/actions/writers.actions';
import { RES_PATH } from 'src/config/environment';
import AppLayout from 'src/layout/AppLayout';
import AuthorWide from 'src/components/_shared/AuthorWide';
import PublicBookCard from  'src/components/_shared/PublicBookCard';
import { useAuthorTitle } from 'src/hooks';
import HorizontalItemList from 'src/components/MainPage/HorizontalItemList';
import { redirectToWriterSubscriptionCheckout } from 'src/modules/actions/payment.actions';
import cn from 'classnames';
import { ArrowDown } from 'src/components/Narration/icons/ArrowDown';
import { useCallback } from 'react';
import BookTile from 'src/components/_shared/Book/BookCell';
import { Modal } from 'carbon-components-react';
import Button from 'src/components/_shared/DesignV2/Button';
import { useState } from 'react';
import { unsubscribe } from 'src/modules/api/subscriptions.api';
import ReactGA from 'react-ga4';
import history from 'src/history';

export const AuthorPage = props => {
  const { fetchAuthor, follow, unfollow, sortBooks, isAuthenticated, ...rest } = props;
  const { match, name, books, about, photo, subscriptions, redirectToWriterSubscriptionCheckout } = rest;
  
  const [subscription, setSubscription] = useState();
  const [opened, setOpened] = useState(false);

  useAuthorTitle(name);
  useEffect(() => {
    fetchAuthor(match.params.uuid);
  }, [fetchAuthor, match]);

  const onSort = (e) => sortBooks(e.target.value);

  let index = 0;
  let data = [];
  const inProgress = books.filter(
    (item) => item.book.completed === false,
  );
  const completed = books.filter(
    (item) => item.book.completed === true,
  );  
  if (inProgress.length > 0) {
    data.push({title: 'Books In Progress', data: inProgress, index: index++});
  }
  if (completed.length > 0) {
    data.push({title: 'Completed Books', data: completed, index: index++});
  }

  const openSubscriptionModal = useCallback((uuid) => {
    const items = subscriptions.filter(item => item.uuid === uuid)
    if (items.length > 0) {
      ReactGA.event({ category: 'Subscriptions', action: 'OpenDetails'});
      setSubscription(items[0])
      setOpened(true);
    }
  }, [subscriptions]);

  const active = subscription && (subscription.info.state === 'subscribed' ||
  (subscription.info.state === 'canceled' &&
  subscription.info.expiration * 1000 > Date.now()));

  return (
    <>
      <Helmet>
        {name && <meta property="og:title" content={`Fictionate.me: Novels by ${name}`} />}
        {about && <meta property="og:description" content={about} />}
        {photo && <meta property="og:image" content={`${RES_PATH}${photo}`} />}
      </Helmet>
      <AppLayout style={{backgroundColor: "black"}}>
        <div className={cs.content}>
          <div className={cs.header}>
            <AuthorWide {...rest} 
              follow={() => follow(match.params.uuid)}
              unfollow={() => unfollow(match.params.uuid)} />
            {subscriptions && (subscriptions.length > 0) &&
              <SubscriptionSection 
                subscriptions={subscriptions} 
                onSubscribe={redirectToWriterSubscriptionCheckout}
                onMore={openSubscriptionModal}
              />
            }
          </div>
          <div className={cs.booksList}>
            {data.map(({title, data}) => <BookSection title={title} books={data}  author={{name, imagePath: photo}}/>)}
          </div>
        </div>
        <div className={cs.test}>
          <Modal
            size='xs'
            open={opened}
            passiveModal
            onRequestClose={() => setOpened(false)}
            >
              {subscriptions && subscription && 
              (active ? 
              <SubscriptionContentSubscribed
                large={true}
                subscription={subscription} 
                onSubscribe={() => {
                  redirectToWriterSubscriptionCheckout(subscription.uuid);
                }}
                onUnsubscribe={() => {
                  ReactGA.event({ category: 'Subscriptions', action: 'Unsubscribe'});
                  unsubscribe(subscription.uuid).then(() => {fetchAuthor(match.params.uuid)});
                  setOpened(false);
                }
                }/> :
              <SubscriptionContent 
                large={true} subscription={subscription} 
                onSubscribe={() => {
                  if(!isAuthenticated) {
                    ReactGA.event({ category: 'Subscriptions', action: 'Login'});
                    history.push('/login', { from: history.location })
                    return;
                  }
                  
                  redirectToWriterSubscriptionCheckout(subscription.uuid)
                }}
              />)}
          </Modal>
        </div>
      </AppLayout>
    </>
  )
};

const SubscriptionSection = ({subscriptions, onSubscribe, onMore}) => {
  return (
    <div>
      <div className={cs.title}>
        <div>Become a Supporter</div>
      </div>

      <div className={cn(['bx--grid--full-width', cs.subscriptionContent])}>
        <div className='bx--row' style={{ justifyContent: 'space-between'}}>
          <HorizontalItemList
            showHeader={false}
            isMobileDevice={false}
            items={subscriptions}
            width={198}
            height={222}
            screenWidth={520}>
              {(item) => (
                <SubscriptionCard 
                  subscription={item} 
                  onSubscribe={() => onSubscribe(item.uuid)}
                  onMore={() => onMore(item.uuid)}
                />
              )}
          </HorizontalItemList>
        </div>
      </div>
    </div>
  );
};

const SubscriptionCard = ({subscription, onSubscribe, onMore}) => {
  const active = subscription.info.state === 'subscribed' ||
  (subscription.info.state === 'canceled' &&
  subscription.info.expiration * 1000 > Date.now());

  const colorSyle = {
    backgroundColor: subscription.color,
    borderWidth: active ? '5px' : '0px',
    borderColor: active ? '#FFFFFF23' : '#000',
  };

  const _onMore = (event) => {
    event.stopPropagation();
    onMore();
  };

  return (
    <div className={cs.subscription} style={colorSyle} onClick={active ? onMore : onMore}>
      {active ? 
      <SubscriptionContentSubscribed subscription={subscription}/> : 
      <SubscriptionContent subscription={subscription} onSubscribe={onMore} />
      }
      {!active && <div className={cs.moreIcon} onClick={_onMore}><ArrowDown/></div>}
    </div>
  );
};

const SubscriptionContent = ({large = false, subscription, onSubscribe}) => {
  const ref = useRef(null);
  const [aboutStyle, setAboutStyle] = useState({ maxHeight: "52px" });
  const contentStyle = large ? { minHeight: '260px'} : {maxHeight: '150px'}
  const iconStyle = large ? { fontSize: '39px', lineHeight: '60px', width: '60px', height: '60px'} : {}
  const priceStyle = large ? { position: 'relative', paddingLeft: '20px', marginTop: '2px', maxWidth: 'calc(100% - 85px)'} : 
    {
      position: 'absolute',
      top: '0px',
      right: '0px'
    }
  const textStyle = large ? { display: 'inline-block'} : {} 
  
  useEffect(() => {
    const largeContent = ref?.current && (ref.current.clientHeight / ref.current.lineHeight >= 2); 
    console.log(`Large? - ${largeContent}. Ref - ${ref?.current?.clientHeight} / ${ref?.current?.lineHeight}`);
    setAboutStyle({ maxHeight: largeContent ? "78px" : "52px" });
  }, [ref]);
  
  const monthStyle = large ?  {...textStyle, fontSize: '14px'} : textStyle

    const onItemClick = () => {
      if (!large) {
        onSubscribe();
      }
    }

    return (
    <>
      <style dangerouslySetInnerHTML={{__html: `.bx--modal-container { background-color: ${subscription.color}; }`}} />
      <div className={cn(cs.subscriptionItemContent, {[cs.subscriptionItemContentLarge]: large})} style={contentStyle} onClick={onItemClick}>
        <div className={cs.nameRow}>
          <div className={cs.emoji} style={iconStyle}>{subscription.emoji}</div>
          <div className={cs.price} style={priceStyle}>
            {large && <div ref={ref} className={cs.name} style={{ fontSize: '26px', marginTop: '5px'}}>{subscription.name}</div>}
            <p className={cs.value} style={textStyle}>${subscription.price.amount/100}</p>
            <p className={cs.month} style={monthStyle}>/month</p>
          </div>
        </div>
        {!large && <div className={cs.name}>{subscription.name}</div>}
        <div className={cn(cs.about, {[cs.largeAbout]: large}, aboutStyle)} dangerouslySetInnerHTML={{ __html: subscription.about }} />
      </div>
      {large && <div className={cs.button}><Button kind='cta' className={cs.buttonContent} onClick={onSubscribe}>Subscribe</Button></div>}
    </>
  );
};

const SubscriptionContentSubscribed = ({large = false, subscription, onSubscribe, onUnsubscribe}) => {
  
  const contentStyle = large ? { minHeight: '260px'} : {}
  const priceStyle = large ? { bottom: '60px'} : {}

  const action = useCallback((event) => {
    event.stopPropagation();
    if (subscription.info.state === 'subscribed') {
      onUnsubscribe();
    } else {
      onSubscribe();
    }
  }, [onSubscribe, onUnsubscribe, subscription.info.state]);

  return (
    <>
      <style dangerouslySetInnerHTML={{__html: `.bx--modal-container { background-color: ${subscription.color}; }`}} />
      <div className={cs.subscriptionItemContentSubscribed} style={contentStyle}>
        <div className={cs.emojiRow}>
          <div className={cs.emoji}>{subscription.emoji}</div>
        </div>
        <div className={cs.name}>{subscription.name}</div>
        <div className={cs.state}>
          {subscription.info.state === 'canceled' ? 'CANCELED' : 'ACTIVE'}
        </div>
        <div className={cs.price} style={priceStyle}>
          <p className={cs.value}>${subscription.price.amount/100}</p>
          <p className={cs.month}>/month</p>
        </div>
        {large && <div className={cs.button}>
          <Button kind='cta' className={cs.buttonContent} onClick={action}>
            {subscription.info.state === 'subscribed' ? 'Unsubscribe' : 'Re-Subscribe'}
          </Button>
        </div>}
      </div>
    </>
  );
};

const BookSection = ({books, title, author}) => {
  return (
    <div>
      <div className={cn([cs.title, cs.offset])}>
        <div>{title}</div>
      </div>

      <div className='bx--grid--full-width'>
        <div style={{ justifyContent: 'space-between', paddingTop: '10px'}}>
        {
          books.map((item, index) => (
            <div className={cs.row} key={index}>
              <div className={cs.itemWrapper} key={index}>
                {/* <PublicBookCard key={index} book={item.book} className={cs.bookCard} /> */}
                  <BookTile book={item.book} author={author}/>
              </div>
            </div>
          ))
        }
        </div>
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,

  fetchAuthor: PropTypes.func.isRequired,
  sortBooks: PropTypes.func.isRequired,
  name: PropTypes.string,
  about: PropTypes.string,
  photo: PropTypes.string
};

function mapStateToProps(state) {
  return {
    ...state.writers,
    isAuthenticated: state.authentication.isAuthenticated
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAuthor, sortBooks, follow, unfollow, redirectToWriterSubscriptionCheckout }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorPage);
