import React from 'react';
import FormContent from 'src/components/_shared/form/FormContent';
import { Modal } from 'carbon-components-react';
import PropTypes from 'prop-types';

export const ModalForm = ({title, action, open, danger, cancel, properties, setTrigger, ...props}) => {
    
    let submitTrigger = null;

    return (
        <Modal
        style={props.style}
        open={open}
        modalHeading={title}
        danger={danger}
        shouldSubmitOnEnter
        aria-label='modal-form'
        passiveModal={props.passive}
        primaryButtonText={action ?? "Submit"}
        secondaryButtonText="Cancel"
        onRequestSubmit={() => { submitTrigger(); }}
        onRequestClose={()=> { cancel(); }}
        size="sm"
        >
            <FormContent
            properties={properties}
            setTrigger={(trigger) => {
                submitTrigger = trigger;
                if(setTrigger !== undefined) { setTrigger(trigger); }
            }} 
            {...props}
            />
        </Modal>
    )
}

ModalForm.propTypes = {
    title: PropTypes.string,
    action: PropTypes.string,
    danger: PropTypes.bool,
    open: PropTypes.bool,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    properties: PropTypes.any.isRequired,
};

export default ModalForm;